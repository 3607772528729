import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';
import { AccessRule, AccessRuleUpdate } from './access';


export type ImageFormat = OutputFormat | 'stream' | 'heic' | 'heif';
export type VideoFormat = 'mp4' | 'webm' | 'wmv' | 'mov' | 'ogg' | '3gp' | 'mpeg' | 'flv' | 'avi' | 'stream';
export type AudioFormat = 'mp3' | 'wav' | 'ogg' | 'webm' | 'mp4' | 'm4a' | 'amr' | 'stream';

export const IMAGE_FORMATS: ImageFormat[] = ['png', 'jpeg', 'webp', 'bmp', 'ico', 'stream', 'heic', 'heif'];
export const VIDEO_FORMATS: VideoFormat[] = ['mp4', 'webm', 'wmv', 'mov', 'ogg', '3gp', 'mpeg', 'flv', 'avi', 'stream'];
export const AUDIO_FORMATS: AudioFormat[] = ['mp4', 'webm', 'mp3', 'wav', 'ogg', 'm4a', 'amr', 'stream'];

export enum FileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
}

export const FORMATS: { [key in FileType]: string[] } = {
  [FileType.IMAGE]: IMAGE_FORMATS,
  [FileType.VIDEO]: VIDEO_FORMATS,
  [FileType.AUDIO]: AUDIO_FORMATS,
  [FileType.DOCUMENT]: ['*'],
};

export const IMAGE_MIME_TYPES: {[type in ImageFormat]: string} = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  webp: 'image/webp',
  bmp: 'image/bmp',
  ico: 'image/x-icon',
  heic: 'image/heic',
  heif: 'image/heif',
  stream: 'application/octet-stream'
}

export const VIDEO_MIME_TYPES: {[type in VideoFormat]: string} = {
  mp4: 'video/mp4',
  webm: 'video/webm',
  wmv: 'video/x-ms-asf',
  mov: 'video/quicktime',
  ogg: 'video/ogg',
  '3gp': 'video/3gpp',
  mpeg: 'video/mpeg',
  flv: 'video/x-flv',
  avi: 'video/x-msvideo',
  stream: 'application/octet-stream'
};

export const AUDIO_MIME_TYPES: {[type in AudioFormat]: string} = {
  mp3: 'audio/mpeg',
  mp4: 'audio/mp4',
  ogg: 'audio/ogg',
  wav: 'audio/wav',
  webm: 'audio/webm',
  m4a: 'audio/mpeg',
  amr: 'audio/AMR',
  stream: 'application/octet-stream'
};


export const MIME_TYPES: {[type in FileType]: {[key in ImageFormat | VideoFormat | AudioFormat]?: string}} = {
  [FileType.IMAGE]: IMAGE_MIME_TYPES,
  [FileType.VIDEO]: VIDEO_MIME_TYPES,
  [FileType.AUDIO]: AUDIO_MIME_TYPES,
  [FileType.DOCUMENT]: {},
}

export const ALL_FILE_TYPES: FileType[] = Object.keys(FileType).map(t => FileType[t]);

export const FILE_TYPE_ICONS: {[type in FileType]: string} = {
  IMAGE: 'image',
  VIDEO: 'film',
  AUDIO: 'musical-notes',
  DOCUMENT: 'document',
};

export interface DisplayableFile {
  fileType: FileType;
  extUrl: string;
  file: string;
  extension: string;
  public: boolean;
  name: string;
  size?: number;
  credit?: string;
}

export interface StoredFile extends DisplayableFile {
  id: number;
  credit: string;
}

export interface UsedSpace {
  byType: { [type in FileType]: number };
  total: number;
}

export interface StoredFileDetails extends StoredFile {
  writeRule: AccessRule;
  readRule: AccessRule;
  versions: string[];
  author: FileAuthor;
  update: string;
}

export interface FileAuthor {
	id: number;
	fullname: string;
	slug: string;
}

export interface FileUpload {
  id?: number;
  extension?: string;
  name?: string;
  isPublic?: boolean;
  extUrl?: string;
  readRule?: AccessRuleUpdate;
  writeRule?: AccessRuleUpdate;
  renameFile?: boolean;
}

export interface ImageUpload extends FileUpload {
  base64?: string;
  credit?: string;
}

export interface RawUpload extends FileUpload {
  fileType?: FileType;
  tempFile?: string;
  extUrl?: string;
}

export interface TempUploadSuccess {
  name: string;
  extra: string;
}

export interface DocumentTable {
  header: string[];
  lines: any[][];
}

export interface DocumentSheet {
  name: string;
  tables: DocumentTable[];
}

export function getAllowedExtensions(...fileType: FileType[]): string[] {
  if (fileType.includes(FileType.DOCUMENT)) {
    return FORMATS[FileType.DOCUMENT];
  }
  const extensions = [];
  for (const type of fileType) {
    extensions.push(...FORMATS[type]);
  }
  return extensions;
}

export function resolveTypeAndExtension(mimeOrExt: string, prefType?: FileType) {
  let type: FileType = FileType.DOCUMENT;
  let extension: string;
  for (const fileType of ALL_FILE_TYPES) {
    const ofType = MIME_TYPES[fileType];
    for (const ext of Object.keys(ofType)) {
      if (mimeOrExt === ext || mimeOrExt === ofType[ext]) {
        type = fileType;
        extension = ext;
        if (!prefType || prefType === fileType) {
          break;
        }
      }
    }
  }
  return { extension, type };
}

export function getFileExtension(mimeType: string, fileName: string, prefType?: FileType): {extension: string, type: FileType} {
  let { type, extension } = mimeType ? resolveTypeAndExtension(mimeType, prefType) : {type: FileType.DOCUMENT, extension: null};
  if (!extension) {
    const nameExtension = fileName.split('.').pop().toLowerCase();
    const fromName = resolveTypeAndExtension(nameExtension, prefType);
    type = fromName.type;
    extension = fromName.extension || nameExtension;
  }
  return { extension, type };
}

export function getFilenameWithoutExtension(file: string) {
  return file ? file.split('.').slice(0, -1).join('.') : file;
}

export function getExtensionDisplay(type: FileType, req: string[]) {
  return req?.length ? req : Object.keys(MIME_TYPES[type]).join(', ');
}