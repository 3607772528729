import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCaptureComponent } from './video-capture.component';
import { FileCaptureModule } from '../file-capture/file-capture.module';

@NgModule({
  imports: [
    CommonModule,
    FileCaptureModule,
  ],
  declarations: [VideoCaptureComponent],
  exports: [VideoCaptureComponent],
})
export class VideoCaptureModule { }
