import { Injectable } from '@angular/core';
import { GroupedOrder, GroupedOrderUpdate, ListedGroupedOrder, Order, OrderProductUpdate } from '../model/order';
import { Page } from '../model/page';
import { IonstackService, RequestParams } from './ionstack.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private ionstackService: IonstackService) {}

  updateOrder(orderId: number, orderProductId: number, update: OrderProductUpdate) {
    return this.ionstackService.put<Order>(`/orders/${orderId}/products/${orderProductId}`, update);
  }

  getGroupedOrder(groupedOrderId: number) {
    return this.ionstackService.get<GroupedOrder>(`/grouped-orders/${groupedOrderId}`);
  }

  updateGroupedOrder(groupedOrderId: number, update: GroupedOrderUpdate) {
    return this.ionstackService.put<GroupedOrder>(`/grouped-orders/${groupedOrderId}`, update);
  }

  invoiceGroupedOrder(groupedOrderId: number) {
    return this.ionstackService.get<GroupedOrder>(`/grouped-orders/${groupedOrderId}`);
  }

  getOrders(params: RequestParams) {
    return this.ionstackService.get<Page<Order>>('/orders', {params});
  }

  getGroupedOrders(params: RequestParams) {
    return this.ionstackService.get<Page<ListedGroupedOrder>>('/grouped-orders', {params});
  }

  getAllGroupedOrders(params: RequestParams) {
    return this.ionstackService.get<Page<ListedGroupedOrder>>('/admin/grouped-orders', {params});
  }

}
