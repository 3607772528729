import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagedComponent } from './paged.component';
import { IonicModule } from '@ionic/angular';
import { PaginatorModule } from '@adeprez/ionstack/ui/paginator';
import { TranslateModule } from '@adeprez/ionstack';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PaginatorModule,
    TranslateModule,
  ],
  declarations: [PagedComponent],
  exports: [PagedComponent]
})
export class PagedModule { }
