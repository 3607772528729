import { Injectable, InjectionToken, Injector } from '@angular/core';
import { InjectableService } from './injectable.service';

export type Action = (...args: any[]) => any | Promise<any>;
const ACTION_TOKENS: {[name: string]: InjectionToken<Action>} = {};

export function actionToken(name: string): InjectionToken<Action> {
  return ACTION_TOKENS[name] ?? (ACTION_TOKENS[name] = new InjectionToken<Action>(name));
}

@Injectable({
  providedIn: 'root'
})
export class ActionService extends InjectableService<Action> {
  defaultInjectable = () => {};
  tokenStore = ACTION_TOKENS;

  constructor(injector: Injector) {
    super(injector);
  }

  getActionNames(): string[] {
    return super.getInjectablesNames();
  }

  hasAction(name: string): boolean {
    return super.hasInjectable(name);
  }

  getAction(name: string, bindTo?: any, defaultAction: Action = this.defaultInjectable): Action {
    const action = super.getInjectable(name, defaultAction);
    return bindTo ? action.bind(bindTo) : action;
  }
  
  setAction(name: string, action: Action) {
    super.setInjectable(name, action);
  }

}
