import { IonMenu } from '@ionic/angular';
import { ChangeDetectorRef } from '@angular/core';
import { IonstackService } from '../service/ionstack.service';

export function fixMenu(menuGetter: () => IonMenu, ionstackService: IonstackService, cd: ChangeDetectorRef, trial = 3) {
  if (ionstackService.isBrowser() && trial > 0) {
    setTimeout(() => {
      const m = menuGetter();
      if (m && m.disabled) {
        m.disabled = false;
        cd.markForCheck();
      } else {
        fixMenu(menuGetter, ionstackService, cd, trial - 1);
      }
    }, 600);
  }
}