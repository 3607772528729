import { AutocompleteTypes } from '@ionic/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TextBoldMatcherPipe, FormControlComponent } from '@adeprez/ionstack';
import { AutocompleteItemsComponent, AutocompleteItemsController } from '../items/autocomplete-items.component';
import { NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteComponent),
    multi: true
  }],
  selector: 'ionstack-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent<T> extends FormControlComponent<T> implements AutocompleteItemsController<T>, OnDestroy {
  @Output() filteredItemsChange = new EventEmitter<T[]>();
  @Output() highlightedItemChange = new EventEmitter<number>();
  @Output() valueChange = new EventEmitter<T>();
  @Output() inputChange = new EventEmitter<string>();
  @Output() onSubmit = new EventEmitter<T>();
  @Input() autocomplete: AutocompleteTypes = 'off';
  @Input() expand = true;
  @Input() clear = false;
  @Input() popoverClass = 'autocomplete-popover scrollable-popover';
  @Input() placeholder: string;
  @Input() labelGetter: (value: T) => string = (value: T) => value + '';
  @Input() valueGetter: (input: string, values: T[]) => T = (input: string) => input as any;
  @Input() newValueGetter: (input: string) => T;
  @Input() autoConvertToString = false;
  @Input() filter = true;
  @Input() maxLength: number;
  @Input() inputText = '';
  @Input() isDisabled: boolean;
  private _value: T;
  private _with: T[] = [];
  ignoreOpen = false;
  dismissing = false;
  highlighted = -1;
  filteredItems: T[] = [];
  popover: HTMLIonPopoverElement;
  lastEvent: any;

  constructor(
    private popoverController: PopoverController,
    public cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.dismiss();
  }

  @Input() set value(value: T) {
    this._value = value;
    this.inputText = value == null ? null : this.labelGetter(value);
  }

  get value() {
    return this._value;
  }

  writeValue(value: T) {
    this.inputText = value == null ? null : this.labelGetter(value);
    super.writeValue(value);
  }

  @Input() set with(items: T[]) {
    this._with = items;
    this.filterItems();
    if (this.lastEvent) {
      this.changed(this.lastEvent, false);
    } else if (!items?.length) {
      this.dismiss();
    }
  }

  get with() {
    return this._with;
  }

  async filterItems(showAll = false) {
    const count = this.filteredItems.length;
    if (this.filter && !showAll) {
      if (this.inputText) {
        const matcher = new TextBoldMatcherPipe().getRegex(this.inputText, 'i');
        this.filteredItems = this._with.filter(w => matcher.test(this.labelGetter(w)));
      } else {
        this.filteredItems = [];
      }
    } else {
      this.filteredItems = this._with;
    }
    if (this.filteredItems.length !== count && this.popover) {
      // Height changes: we need to recreate the popover to set it to a valid position
      await this.popover.dismiss();
      if (this.filteredItems.length) {
        await this.showPopover(this.lastEvent);
      }
    }
    this.filteredItemsChange.emit(this.filteredItems);
  }

  async inputChanged(ev: any) {
    if (this.inputText !== ev.detail.value) {
      this.inputText = ev.detail.value;
      this.inputChange.emit(this.inputText);
      const value = this.valueGetter?.(this.inputText, this._with);
      if (value !== undefined && value !== null) {
        super.setValue(value);
      } else if (this.newValueGetter) {
        const newVal = this.newValueGetter(this.inputText);
        if (newVal !== null && newVal !== undefined) {
          super.setValue(newVal);
        }
      } else if (this.autoConvertToString && typeof this.value === 'string' && this.inputText !== this.value) {
        super.setValue(this.inputText as any);
      } else {
        super.setValue(null);
      }
      await this.changed(ev, false);
    }
  }

  setValue(value: T) {
    const changed = super.setValue(value);
    this.dismiss();
    this.inputText = this.labelGetter(this.value);
    return changed;
  }

  pickValue(value: T) {
    this.ignoreOpen = true;
    setTimeout(() => this.ignoreOpen = false, 2000);
    this.setValue(value);
    this.onSubmit.emit(this.value);
    this.cd.markForCheck();
  }

  submit() {
    if (this.highlighted > -1 && this.highlighted < this.filteredItems.length) {
      this.setValue(this.filteredItems[this.highlighted]);
    }
    if (this.value) {
      this.onSubmit.emit(this.value);
    }
  }

  highlightItem(delta: number, event: Event) {
    if (this.filteredItems.length) {
      if (delta < 0) {
        this.setHighlightedItem(this.highlighted < 0 ? this.filteredItems.length - 1 : Math.max(0, this.highlighted + delta));
      } else {
        this.setHighlightedItem(Math.min(this.filteredItems.length - 1, this.highlighted + delta));
      }
    } else {
      this.setHighlightedItem(-1);
    }
    if (this.highlighted !== -1 && this.popover) {
      event.preventDefault();
    }
  }

  setHighlightedItem(highlighted: number) {
    this.highlighted = highlighted;
    this.highlightedItemChange.emit(this.highlighted);
  }

  dismiss() {
    return this.popover?.dismiss();
  }

  async showPopover(ev: any) {
    this.popover = await this.popoverController.create({
      component: AutocompleteItemsComponent,
      cssClass: this.popoverClass,
      showBackdrop: false,
      backdropDismiss: true,
      keyboardClose: false,
      animated: false,
      event: ev || this.lastEvent,
      componentProps: {
        controller: this
      }
    });
    this.popover.onWillDismiss().then(skipReset => {
      this.dismissing = true;
      if (!skipReset?.data) {
        this.popover = undefined;
        this.setHighlightedItem(-1);
      }
    });
    await this.popover.present();
    if (!this.filteredItems?.length) {
      await this.dismiss();
    }
  }

  async changed(ev: any, showAll: boolean) {
    if (this.dismissing) {
      this.dismissing = false;
    } else if (!this.ignoreOpen) {
      this.setTouched();
      await this.filterItems(showAll);
      if (this.filteredItems.length) {
        const hasSingleValue = this.filteredItems.length === 1 && this.inputText === this.labelGetter(this.filteredItems[0]);
        if (this.popover) {
          if (hasSingleValue) {
            await this.dismiss();
          }
        } else if (!hasSingleValue) {
          await this.showPopover(ev);
        }
      } else {
        await this.dismiss();
      }
    }
    this.lastEvent = ev;
  }

}
