import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[ionstackFileDrop]'
})
export class FileDropDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<File[]>();
  @Input() ionstackFileDrop: string | boolean;

  constructor() { }

  @HostListener('dragover', ['$event'])
  onDragOver(evt: DragEvent) {
    if (this.ionstackFileDrop !== false && evt.dataTransfer.types && evt.dataTransfer.types.includes('Files')) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = true;
    } else {
      this.fileOver = false;
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  ondrop(evt: Event) {
    if (this.ionstackFileDrop !== false) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
      const list: FileList = (evt as any).dataTransfer.files;
      if (list.length > 0) {
        const files: File[] = [];
        for (let i = 0; i < list.length; i++) {
          files.push(list[i]);
        }
        this.fileDropped.emit(files);
      }
    }
  }
}
