import { TranslatableModule, WIDGET, IonstackDirectivesModule, IonstackPipesModule } from '@adeprez/ionstack';
import { SimpleFormModule } from '@adeprez/ionstack/ui/simple-form';
import { WidgetChildrenModule } from '@adeprez/ionstack/ui/widget-children';
import { FormContainerComponent } from './form-container/form-container.component';
import { BaseFormWidget } from './base-form-widget';
import { FormInputComponent } from './form-input/form-input.component';
import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HiddenInputComponent } from './hidden-input/hidden-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { SimpleFormWidgetComponent } from './simple-form-widget.component';
import { ChipListModule } from '@adeprez/ionstack/ui/chip-list';
import { FileInputComponent } from './file-input/file-input.component';
import { EditableFileModule } from '@adeprez/ionstack/file';
import { DateInputComponent } from './date-input/date-input.component';
import { DatePickerModule } from '@adeprez/ionstack/ui/date-picker';

const components: Type<BaseFormWidget>[] = [
  CheckboxInputComponent,
  FileInputComponent,
  FormInputComponent,
  HiddenInputComponent,
  SelectInputComponent,
  TextareaInputComponent,
  DateInputComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleFormModule,
    TranslatableModule,
    WidgetChildrenModule,
    IonstackDirectivesModule,
    IonstackPipesModule,
    ChipListModule,
    EditableFileModule,
    DatePickerModule,
  ],
  declarations: [
    FormContainerComponent,
    SimpleFormWidgetComponent,
    ...components,
  ],
  exports: [
    FormContainerComponent,
    SimpleFormWidgetComponent,
    ...components,
  ],
  providers: [
    { provide: WIDGET, useValue: FormContainerComponent, multi: true },
    { provide: WIDGET, useValue: SimpleFormWidgetComponent, multi: true },
    ...components.map(c => ({ provide: WIDGET, useValue: c, multi: true })),
  ]
})
export class IonstackFormModule { }
