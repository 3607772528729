<ionstack-widget-children
  [value]="children"
  [parent]="params.holder"
  [context]="params.context"
  #childrenWidget
>
  <ng-template childrenWrapper let-ct="childrenTemplate">
    <ng-template #editMode>
      <ng-container [ngTemplateOutlet]="ct"></ng-container>
    </ng-template>
    <swiper
      [navigation]="navigation"
      [slidesPerView]="slidesPerView"
      [pagination]="pagination && {clickable: true}"
      [slideToClickedSlide]="true"
      [centerInsufficientSlides]="center"
      [ngClass]="swiperClass"
      *ngIf="!params.context.postOptions.layoutMode; else editMode">
      <ng-template swiperSlide *ngFor="let child of children; let i = index">
        <ionstack-widget
          [context]="childrenWidget.context"
          [container]="childrenWidget"
          [widget]="child"
          [index]="i"
          [siblings]="children.length"
          (widgetChange)="childrenWidget.updateChild(childrenWidget.getModelIndex(i), $event)"
        ></ionstack-widget>
      </ng-template>
    </swiper>
  </ng-template>
</ionstack-widget-children>
