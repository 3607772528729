<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ infos.meta.widgetName }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ionstack-translater [key]="form.controls.content.value" [translations]="translations" [submitBar]="false" *ngIf="translations"></ionstack-translater>
  <form [formGroup]="form" *ngIf="form">
    <ion-item class="ion-margin-bottom" color="light" [counter]="false">
      <ion-label position="stacked">{{ 'admin.Translation key' | translate }}</ion-label>
      <ion-input formControlName="content" [maxlength]="64"></ion-input>
    </ion-item>
    <ion-item color="light">
      <ion-label position="floating">{{ 'admin.CSS classes' | translate }}</ion-label>
      <ion-input formControlName="classes"></ion-input>
    </ion-item>
    <ion-item color="light">
      <ion-label position="floating">{{ 'admin.CSS style rules' | translate }}</ion-label>
      <ion-textarea formControlName="style" [rows]="5"></ion-textarea>
    </ion-item>
    <ion-item color="light" *ngIf="parentHolder?.slots?.length">
      <ion-label position="floating">{{ 'admin.Slot' | translate }}</ion-label>
      <ion-select formControlName="slot">
        <ion-select-option [value]="''">{{ 'admin.No slot' | translate }}</ion-select-option>
        <ion-select-option [value]="slot" *ngFor="let slot of parentHolder.slots">{{ slot }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item color="light">
      <ion-label position="floating">{{ 'admin.Permissions' | translate }}</ion-label>
      <ion-select formControlName="permissions" color="light" [multiple]="true">
        <ion-select-option [value]="perm" *ngFor="let perm of permissions">{{ perm }}</ion-select-option>
      </ion-select>
    </ion-item>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="deleteWidget()" *ngIf="parentHolder && index != null">
        <ion-icon name="trash" slot="start" color="danger"></ion-icon>
        <ion-label class="ion-hide-sm-down">{{ 'Delete' | translate }}</ion-label>
      </ion-button>
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="start" color="danger"></ion-icon>
        <ion-label class="ion-hide-sm-down">{{ 'Cancel' | translate }}</ion-label>
      </ion-button>
      <ion-button (click)="confirm()">
        <ion-icon slot="start" name="checkmark" color="success"></ion-icon>
        <ion-label class="ion-hide-sm-down">{{ 'Save' | translate }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>