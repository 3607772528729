<ion-item (click)="load(); modal.present()" [button]="true" [detail]="false" class="clickable input-padding" [ngClass]="{placeholder}">
  <ion-input
    [labelPlacement]="labelPosition"
    [label]="title"
    [readonly]="true"
    [placeholder]="placeholder"
    [value]="displayValue && (labelGetter | call:displayValue)"
  ></ion-input>
  <ion-icon *ngIf="clearInput && value" (click)="setValue(null); $event.preventDefault(); $event.stopPropagation()" name="close" size="small" slot="end"></ion-icon>
</ion-item>

<ion-modal #modal>
  <ng-template>
    <ionstack-picker
      (searchChange)="setSearch($event)"
      (pick)="pickValue($event[0]); modal.dismiss()"
      (cancel)="modal.dismiss()"
      [canSearch]="true"
      [filterSearch]="filterSearch"
      [title]="title"
      [height]="height"
      [maxSelected]="1"
      [selected]="selected ? [selected] : []"
      [placeholder]="searchPlaceholder"
      [cancelText]="cancelText"
      [confirmText]="confirmText"
      [confirm]="confirm"
      [labelGetter]="labelGetter"
      [subtitleGetter]="subtitleGetter"
      [iconGetter]="iconGetter"
      [pageable]="from"
      [searchText]="search"
    ></ionstack-picker>
  </ng-template>
</ion-modal>