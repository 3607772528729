import { EventEmitter, Injectable } from '@angular/core';
import { Page } from '../model/page';
import { EditablePost, ListedPost, Post, PostUpdate } from '../model/post';
import { IonstackService, RequestParams } from './ionstack.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  readonly postChange = new EventEmitter<EditablePost | string>();

  constructor(private ionstackService: IonstackService) { }

  getPosts(req: RequestParams, all = false): Promise<Page<ListedPost>> {
    return this.ionstackService.get<Page<ListedPost>>(all ? '/posts' : '/public/posts', {params: req});
  }

  getPost(slug: string): Promise<Post> {
    return this.ionstackService.get<Post>('/public/posts/' + slug);
  }

  getEditablePost(slug: string): Promise<EditablePost> {
    return this.ionstackService.get<EditablePost>('/posts/' + slug);
  }

  async savePost(slug: string, postUpdate: PostUpdate): Promise<EditablePost> {
    const p = await this.ionstackService.put<EditablePost>('/posts/' + slug, postUpdate);
    this.postChange.emit(p);
    return p;
  }

  async deletePost(slug: string): Promise<void> {
    await this.ionstackService.delete('/posts/' + slug);
    this.postChange.emit(slug);
  }

  getPostSlugs(): Promise<string[]> {
    return this.ionstackService.get<string[]>('/posts/slugs/all');
  }

}
