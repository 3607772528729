import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from '@adeprez/ionstack';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RichtextEditorComponent),
    multi: true
  }],
  selector: 'ionstack-richtext-editor',
  templateUrl: './richtext-editor.component.html',
  styleUrls: ['./richtext-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichtextEditorComponent extends FormControlComponent<string> {
  @Output() valueChange = new EventEmitter<string>();
  @Input() value: string;
  @Input() isDisabled = false;
  @Input() placeholder = 'Aa';
  @Input() toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ 'align': [] }],
  ];

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

}
