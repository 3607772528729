import { StartSubscription } from '../model/subscription';
import { EventEmitter, Injectable } from '@angular/core';
import { Page } from '../model/page';
import { Sub, UserPlan } from '../model/subscription';
import { IonstackService, RequestParams } from './ionstack.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  readonly onSubChange = new EventEmitter<'start' | 'cancel' | 'refresh'>();

  constructor(private ionstackService: IonstackService) { }

  getPlans(): Promise<UserPlan[]> {
    return this.ionstackService.get<UserPlan[]>('/public/plans');
  }

  getPlanFreeDays(plan: string): Promise<number> {
    return this.ionstackService.get<number>(`/subscriptions/plans/${plan}/free`);
  }

  getActivePlans(): Promise<string[]> {
    return this.ionstackService.get<string[]>('/subscriptions/mine/active/name');
  }

  getSubs(req: RequestParams): Promise<Page<Sub>> {
    return this.ionstackService.get<Page<Sub>>('/subscriptions/mine', {params: req});
  }

  async cancelSub(id: number) {
    await this.ionstackService.delete<void>('/subscriptions/mine/' + id);
    this.onSubChange.emit('cancel');
  }

  async startSubscription(data: StartSubscription) {
    await this.ionstackService.post<void>('/subscriptions/mine', data);
    this.onSubChange.emit('start');
  }

  async refreshStatus() {
    await this.ionstackService.post<void>('/subscriptions/status/refresh', {});
    this.onSubChange.emit('refresh');
  }

}
