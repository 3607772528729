import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './input/autocomplete.component';
import { IonicModule } from '@ionic/angular';
import { IonstackPipesModule, TranslateModule } from '@adeprez/ionstack';
import { AutocompleteItemsComponent } from './items/autocomplete-items.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonstackPipesModule,
    TranslateModule,
  ],
  declarations: [
    AutocompleteComponent,
    AutocompleteItemsComponent
  ],
  exports: [
    AutocompleteComponent,
  ]
})
export class AutocompleteModule { }
