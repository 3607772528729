import { Injectable } from '@angular/core';
import { PaymentFormModule } from './payment-form.module';
import { Browser } from '@capacitor/browser';
import { HttpErrorResponse } from '@angular/common/http';
import { CleanSubscriber, WebsocketService } from '@adeprez/ionstack';
import { LoadingController, ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Payment3DSModalComponent } from './3ds-modal.component';

@Injectable({
  providedIn: PaymentFormModule
})
export class PaymentFormController {

  constructor(
    private websocketService: WebsocketService,
    private modalController: ModalController,
  ) { }

  async performPayment<T>(paymentFunction: (confirmPaymentId?: string) => Promise<T>) {
    try {
      return await paymentFunction();
    } catch (e) {
      if (e instanceof HttpErrorResponse && e.status === 402 && e.error.extras?.url) {
        if (await this.open3DS(e.error.extras.url)) {
          return await paymentFunction(e.error.extras.paymentId);
        }
        delete e.error.extras;
      }
      throw e;
    }
  }

  open3DS(url: string) {
    return new Promise<boolean>(async resolve => {
      try {
        const sub = new CleanSubscriber();
        let modal: HTMLIonModalElement;
        let pending = true;
        const onEnd = (complete: boolean) => {
          if (pending) {
            pending = false;
            sub.unsubscribeAll();
            if (Capacitor.isNativePlatform()) {
              Browser.removeAllListeners();
              Browser.close();
            } else {
              modal?.dismiss();
            }
            resolve(complete);
          }
        };
        sub.subscribe(this.websocketService.userSubscribe('/payment/3ds/complete'), () => onEnd(true));
        if (Capacitor.isNativePlatform()) {
          await Browser.open({ url });
          Browser.addListener('browserFinished', () => onEnd(true));
        } else {
          modal = await this.modalController.create({
            component: Payment3DSModalComponent,
            componentProps: { url, modalRef: () => modal },
            cssClass: 'large-modal',
            backdropDismiss: false,
          });
          await modal.present();
          const data = (await modal.onDidDismiss()).data;
          onEnd(!!data?.complete);
        }
      } catch {
        resolve(false);
      }
    });
  }

}
