<ionstack-widget-children
  [value]="children"
  [parent]="params.holder"
  [context]="params.context"
>
  <ion-grid *childrenWrapper="let ct = childrenTemplate">
    <ion-row>
      <ng-container [ngTemplateOutlet]="ct"></ng-container>
    </ion-row>
  </ion-grid>
  <ion-col *widgetWrapper="let tpl = template let ctx = templateContext, let p = parentProps"
    [size]="propGetter | call:p.size:ctx.index:0"
    [sizeSm]="propGetter | call:p.sizeSm:ctx.index:1"
    [sizeMd]="propGetter | call:p.sizeMd:ctx.index:2"
    [pullSm]="propGetter | call:p.pullSm:ctx.index:3"
    [pullMd]="propGetter | call:p.pullMd:ctx.index:4"
  >
    <ng-container [ngTemplateOutlet]="tpl" [ngTemplateOutletContext]="{templateContext: ctx}"></ng-container>
  </ion-col>
</ionstack-widget-children>
