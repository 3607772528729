import { CRUDEvent, ObservableCRUD } from './observable-crud';
import { PartialObserver, ReplaySubject, Subscribable, Unsubscribable } from 'rxjs';
import { CleanSubscriber } from './subscriber';

export class Cached<T> implements Subscribable<T> {
  private subject = new ReplaySubject<T>(1);
  private loaded = false;
  protected value: T;

  constructor(private loader: () => Promise<T>) {}

  async toPromise(): Promise<T> {
    if (this.value) {
      return this.value;
    }
    return await this.loader();
  }

  async load() {
    this.loaded = true;
    try {
      this.setValue(await this.loader());
    } catch (e) {
      console.error('Failed to load cacheable', e?.error?.message || e?.message || e);
    }
    return this.value;
  }

  async reset() {
    this.loaded = false;
    if (this.subject.observers.length) {
      await this.load();
    }
  }

  getCurrent() {
    return this.value;
  }

  setValue(value: T) {
    this.value = value;
    this.setUpdated();
    return value;
  }

  setUpdated() {
    this.subject.next(this.value);
  }

  updateValue<K extends keyof T>(key: K, valueGetter: (original: T[K]) => T[K]) {
    if (this.value) {
      this.setValue({...this.value, [key]: valueGetter(this.value[key])});
    }
  }

  updateValues(valueGetter: (original: T) => any) {
    if (this.value) {
      this.setValue({...this.value, ...valueGetter(this.value)});
    }
  }

  subscribe(observer?: PartialObserver<T>): Unsubscribable;
  subscribe(next: null, error: null, complete: () => void): Unsubscribable;
  subscribe(next: null, error: (error: any) => void, complete?: () => void): Unsubscribable;
  subscribe(next: (value: T) => void, error: null, complete: () => void): Unsubscribable;
  subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Unsubscribable;
  subscribe(next?: any, error?: any, complete?: any): Unsubscribable {
    if (!this.loaded) {
      this.load();
    }
    return this.subject.subscribe(next, error, complete);
  }
 
}

export class CachedCRUD<K, U> extends Cached<U[]> {
  public readonly events: ObservableCRUD<K, U>;
  private cleanSub = new CleanSubscriber();

  constructor(loader: () => Promise<U[]>, private keyGetter: (u: U) => K) {
    super(loader);
    this.events = new ObservableCRUD(keyGetter);
  }

  connect() {
    this.cleanSub.subscribe<U[]>(this.events.readAll$, value => this.setValue(value));
    this.cleanSub.subscribe<CRUDEvent<K, U>>(this.events, event => {
      const list = [...super.value];
      switch (event.type) {
        case 'create':
          list.unshift(event.value);
          break;
        case 'update':
          for (let i = 0; i < list.length; i++) {
            if (this.keyGetter(list[i]) === event.key) {
              list[i] = event.value;
              break;
            }
          }
          break;
        case 'delete':
          for (let i = 0; i < list.length; i++) {
            if (this.keyGetter(list[i]) === event.key) {
              list.splice(i, 1);
              break;
            }
          }
        case 'read':
          return;
      }
      this.setValue(list);
    });
  }

  disconnect() {
    this.cleanSub.unsubscribeAll();
  }

  load() {
    const r = super.load();
    this.connect();
    return r;
  }

}
