import {
  WidgetParent, WidgetData, parseWidgetParentProps, AlertService, EditWidgetController, SimpleFormField, isJSONContentWidget,
  parseWidgetContent, stringifyWidgetContent, WidgetPopupBase, PermissionService
} from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PickerPopup } from '@adeprez/ionstack/ui/picker';
import { SimpleFormComponent } from '@adeprez/ionstack/ui/simple-form';

@Component({
  selector: 'ionstack-edit-widget-popup',
  templateUrl: './edit-widget-popup.component.html',
  styleUrls: ['./edit-widget-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditWidgetPopupComponent<T> extends WidgetPopupBase implements OnInit, WidgetParent {
  @ViewChild('simpleForm') form: SimpleFormComponent<T>;
  @ViewChild('propsForm') propsForm: SimpleFormComponent<T>;
  @Input() extraValue: any;
  @Input() extraContent: any;
  @Input() beforeSave: (value: any) => Promise<void>;
  @Input() index: number;
  @Input() siblings: number;
  editMode: 'widget' | 'parentProps' | 'children' = 'widget';
  fields: SimpleFormField[];
  parentPropsFields: SimpleFormField[];

  constructor(
    private modalController: ModalController,
    private permissionService: PermissionService,
    private cd: ChangeDetectorRef,
    private editWidgetController: EditWidgetController,
    private alertService: AlertService,
  ) {
    super();
  }

  async ngOnInit() {
    this.parseParentProps();
    await this.parseContent();
    this.cd.markForCheck();
  }

  private parseParentProps() {
    this.parentPropsFields = [];
    if (this.container?.parent?.propsFields) {
      const propsContent = parseWidgetParentProps(this.widget);
      for (const field of this.container.parent.propsFields) {
        this.parentPropsFields.push({...field, value: field.name in propsContent ? propsContent[field.name] : field.value});
      }
    }
  }

  private async parseContent() {
    const fields: SimpleFormField[] = [];
    const content = parseWidgetContent(this.infos.meta, this.widget);
    if (isJSONContentWidget(this.infos.meta)) {
      for (const field of this.infos.meta.contentFields ?? []) {
        const value = field.name in content ? content[field.name] : field.value;
        fields.push({...field, name: 'content.' + field.name, value});
      }
    } else if (this.infos.meta.contentFields?.length) {
      for (const field of this.infos.meta.contentFields) {
        fields.push({...field, value: content});
      }
    }
    fields.push({
      name: 'permissions', optional: true, type: 'chip-list', label: 'Permissions', value: this.widget.permissions,
      options: await this.permissionService.getKnownPermissions()
    });
    if (this.parentHolder?.slots?.length) {
      fields.push({name: 'slot', optional: true, type: 'select', label: 'admin.Slot', options: this.parentHolder.slots, defaultOption: {value: ''}});
    }
    fields.push({name: 'classes', optional: true, label: 'admin.CSS classes', maxLength: 100, value: this.widget.classes});
    fields.push({name: 'style', optional: true, type: 'multiline-text', label: 'admin.CSS style rules', rows: 4, value: this.widget.style, autoGrow: true});
    this.fields = fields;
  }

  deleteWidget() {
    this.parentHolder.deleteChild(this.index);
    this.cancel();
  }

  cancel() {
    this.modalController.dismiss();
  }

  async confirm() {
    try {
      let content: string;
      if (isJSONContentWidget(this.infos.meta)) {
        content = JSON.stringify({...this.form.formGroup.value.content, ...(this.extraContent ?? {})});
      } else {
        content = this.form.formGroup.value.content;
        if (this.extraContent) {
          content = (content ?? '') + this.extraContent; 
        }
      }
      let val: WidgetData = {
        ...this.form.formGroup.value,
        content,
        ...(this.extraValue || {}),
      };
      if (this.beforeSave) {
        await this.beforeSave(val);
      }
      val = {
        ...val,
        type: this.infos.meta.widgetID,
        children: this.widget.children,
        content: stringifyWidgetContent(this.infos.meta, val),
        parentProps: JSON.stringify(this.propsForm.formGroup.value)
      };
      this.modalController.dismiss(val);
    } catch (e) {
      this.alertService.showError(e);
    }
  }
  
  addWidget() {
    this.editWidgetController.addWidget(this.infos.meta.childSupport, widget => {
      this.setWidget({...this.widget, children: [...this.widget.children, widget]});
      this.cd.markForCheck();
    }, {
      context: this.context,
      parentHolder: this.parentHolder,
      container: this.container,
      component: PickerPopup,
    });
  }

  changeWidget() {
    this.editWidgetController.changeWidgetType(this.container.parent?.childSupport || 'all', PickerPopup, this.widget, async ({data, infos}) => {
      this.infos = infos;
      this.setWidget(data);
      await this.parseContent();
      this.cd.markForCheck();
    });
  }

  setChildren(children: WidgetData[]) {
    this.widget.children = children;
    this.setWidget(this.widget);
    this.cd.markForCheck();
  }

  get slots() {
    return this.infos.meta.slots;
  }

  get propsFields() {
    return this.infos.meta.childrenFields;
  }

  get childSupport() {
    return this.infos.meta.childSupport;
  }

}
