
export function searchable(text: string): string {
    return text ? text.toLowerCase()
        .replace(/[àâä]/g, 'a')
        .replace(/[éèêë]/g, 'e')
        .replace(/[îï]/g, 'i')
        .replace(/[ôö]/g, 'o')
        .replace(/[ûùµü]/g, 'u')
        .replace(/[ç]/g, 'c')
        .replace(/[^a-z0-9]/g, '')
        .replace(/([a-z])\1+/g, '$1') : text;
}

export function getEventValue(event: any): any {
    return event?.target?.value;
}

export function omit<T, K extends keyof T>(v: T, ...keys: K[]): Omit<T, K> {
    return Object.entries(v).filter(([k]) => !keys.includes(k as any)).reduce(
        (acc, [k, v]) => ({...acc, [k]: v}), {} as any
    );
}

export function toUrlString(url: string, query: {[param: string]: string | number | boolean}) {
    const q = Object.entries(filterNotNull(query) ?? {}).map(([k, v]) => k + '=' + encodeURIComponent(v)).join('&');
    return q ? url + '?' + q : url;
}

export function isCallable<T>(tOrF: T | Function): tOrF is Function {
    return typeof tOrF === 'function';
}

export function resolveValueOrFn<T>(valueOrFn: T | ((...args: any[]) => T), ...args: any[]) {
    return isCallable(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

export function isEqualPromise<T>(o: T, k: keyof T) {
    return (o2: T) => o[k] === o2[k];
}

export function dateSorter<T>(fieldGetter: (t: T) => string, recentFirst = false) {
    return (a: any, b: any) => (new Date(fieldGetter(a)).getTime() - new Date(fieldGetter(b)).getTime()) * (recentFirst ? -1 : 1); 
}

export function b64decode(str: string, utf8 = false): string {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output: string = '';
    str = String(str).replace(/=+$/, '');
    if (str.length % 4 == 1) {
        throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
        let bc: number = 0, bs: any, buffer: any, idx: number = 0;
        buffer = str.charAt(idx++);
        ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
        buffer = chars.indexOf(buffer);
    }
    if (utf8) {
        return decodeURIComponent(Array.prototype.map.call(b64decode(str, false), (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    }
    return output;
}

export function b64encode(s: string): string {
    const keystr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let out = "";
    for (let i = 0; i < s.length; i += 3) {
      const groupsOfSix = [undefined, undefined, undefined, undefined];
      groupsOfSix[0] = s.charCodeAt(i) >> 2;
      groupsOfSix[1] = (s.charCodeAt(i) & 0x03) << 4;
      if (s.length > i + 1) {
        groupsOfSix[1] |= s.charCodeAt(i + 1) >> 4;
        groupsOfSix[2] = (s.charCodeAt(i + 1) & 0x0f) << 2;
      }
      if (s.length > i + 2) {
        groupsOfSix[2] |= s.charCodeAt(i + 2) >> 6;
        groupsOfSix[3] = s.charCodeAt(i + 2) & 0x3f;
      }
      for (let j = 0; j < groupsOfSix.length; j++) {
        if (typeof groupsOfSix[j] === "undefined") {
          out += "=";
        } else if (groupsOfSix[j] >= 0 && groupsOfSix[j] < 64) {
          out += keystr[groupsOfSix[j]];
        }
      }
    }
    return out;
  }

export function base64toBlob(base64Data: string, contentType: string) {
    const sliceSize = 1024;
    const chars = b64decode(base64Data);
    const len = chars.length;
    const slices = Math.ceil(len / sliceSize);
    const byteArrays = new Array(slices);

    for (let sliceIndex = 0; sliceIndex < slices; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, len);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = chars[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export function slugify(str: string) {
    str = (str ?? '').trim().replace(/[\s'·\/_,:;]+/g, '-').toLowerCase();
    const from = 'àáäâèéëêìíïîòóöôùúüûñç';
    const to   = 'aaaaeeeeiiiioooouuuunc';
    for (var i = 0, l = from.length ; i < l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    return str.replace(/[^a-z0-9 -]/g, '').replace(/-+/g, '-');
}

export function search(text: string, into: string) {
    return text && into && into.includes(text);
}

export class ScopedId {
    base = randomString(8);
    get(key: string | number, prefix: any = '') {
        return prefix + key + this.base;
    }
}

export function randomIntId() {
    return 1 + Math.round(Math.random() * 10000);
}

export class Debounce {
    timeout: any;
    callback: () => void;

    constructor(private delay = 800) {}

    trigger(callback: () => void) {
        this.clear();
        this.callback = callback;
        this.timeout = setTimeout(callback, this.delay);
        return this;
    }
    setCallback(callback: () => void) {
        this.callback = callback;
        return this;
    }
    update() {
        this.callback && this.trigger(this.callback);
        return this;
    }
    clear() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        return this;
    }
    isPending() {
        return !!this.timeout;
    }
}

export function mapToMappedList<T, V>(map: {[key: string]: T}, valueGetter: (key: string, value?: T) => V): V[] {
    return Object.keys(map).map(key => valueGetter(key, map[key]));
}

export function listToMappedMap<T, U>(list: T[], keyGetter: (t: T) => string, valueGetter: (t: T) => U): {[key: string]: U} {
    const map: {[key: string]: U} = {};
    if (list) {
        for (const l of list) {
            map[keyGetter(l)] = valueGetter(l);
        }
    }
    return map;
}

export function listToMappedNumberMap<T, U>(list: T[], keyGetter: (t: T) => number, valueGetter: (t: T) => U): {[key: number]: U} {
    const map: {[key: number]: U} = {};
    if (list) {
        for (const l of list) {
            map[keyGetter(l)] = valueGetter(l);
        }
    }
    return map;
}

export function listToMap<T>(list: T[], keyGetter: (t: T) => string): {[key: string]: T} {
    return listToMappedMap(list, keyGetter, v => v);
}

export function listToNumberMap<T>(list: T[], keyGetter: (t: T) => number): {[key: number]: T} {
    return listToMappedNumberMap(list, keyGetter, v => v);
}

export function toggleInList<T>(list: T[], elem: T) {
    const idx = list.indexOf(elem);
    if (idx < 0) {
        list.push(elem);
    } else {
        list.splice(idx, 1);
    }
}

export function foreachInMap<T>(map: {[key: string]: T}, consumer: (t: T) => void) {
    for (const key of Object.keys(map)) {
        consumer(map[key]);
    }
}

export function findInTree<T>(nodes: T[], predicate: (t: T, parent?: T, index?: number) => boolean | void, childGetter: (t: T) => T[], parent = null): T {
    if (nodes) {
        let i = -1;
        for (const n of nodes) {
            if (predicate(n, parent, ++i)) {
                return n;
            }
            const c = findInTree(childGetter(n), predicate, childGetter, n);
            if (c) {
                return c;
            }
        }
    }
}

export function flattenTree<T>(nodes: T[], childGetter: (t: T) => T[]): T[] {
    const array: T[] = [];
    findInTree(nodes, (node: T) => {
        array.push(node);
        return false;
    }, childGetter);
    return array;
}

export function findTreeLeafIds<T, U>(nodes: T[], childGetter: (t: T) => T[], idGetter: (t: T) => U): U[] {
    const ids = nodes.map(n => idGetter(n));
    for (const node of nodes) {
        const children = childGetter(node);
        if (children) {
            for (const child of children) {
                const idx = ids.indexOf(idGetter(child));
                if (idx > -1) {
                    ids.splice(idx, 1);
                }
            }
        }
    }
    return ids;
}

export function findTreeParentOf<T>(item: T, into: T[], childGetter: (t: T) => T[]): T {
    if (into) {
        for (const node of into) {
            const children = childGetter(node);
            if (children) {
                for (const child of children) {
                    if (child === item) {
                        return node;
                    }
                    const childParent = findTreeParentOf(item, [child], childGetter);
                    if (childParent) {
                        return childParent;
                    }
                }
            }
        }
    }
}

export function randomString(length: number) {
    if (typeof window !== 'undefined' && crypto && typeof (crypto as any).randomUUID === 'function') {
        let result = '';
        while (result.length < length) {
          result += (crypto as any).randomUUID().replace(/-/g, '');
        }
        return result.substring(0, length);
    }
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    while (result.length < length) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function replaceInList<T>(items: T[], replaceValue: T, acceptor: (t: T) => boolean, stopWhenFound = true) {
    var replaced = 0;
    for (let i = 0; i < items.length; i++) {
        if (acceptor(items[i])) {
            items[i] = replaceValue;
            if (stopWhenFound) break;
        }
    }
    return replaced;
}

export function getSlugsFromString(path: string): string[] {
    return (path || '').split('--');
}

export function getStringFromSlugs(slugs: string[], defaultPath = 'all'): string {
    return (slugs || []).sort().join('--') || defaultPath;
}

export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export function filterNotNull<T, R extends boolean>(value: T, opts: {
    keyIgnorer?: (k: string) => boolean,
    emptyArrayValue?: any,
    recursive?: R,
} = {
    recursive: true as R,
    emptyArrayValue: null,
}): R extends true ? RecursivePartial<T> : Partial<T> {
    if (value == null) {
        return null;
    }
    if (Array.isArray(value) && opts.recursive) {
        const newVal = value.filter(v => filterNotNull(v, opts));
        return newVal.length ? newVal : opts.emptyArrayValue;
    }
    if (typeof value === 'object') {
        const newVal = {};
        for (const k of Object.keys(value)) {
            if (opts?.keyIgnorer && opts.keyIgnorer(k)) {
                newVal[k] = value[k];
            } else if (opts.recursive) {
                const v = filterNotNull(value[k], opts);
                if (v != null) {
                    newVal[k] = v;
                }
            } else if (value[k] != null) {
                newVal[k] = value[k];
            }
        }
        return Object.keys(newVal).length ? newVal : null;
    }
    return value;
}

export function swapKeyValues(obj: any) {
    const newObj = {};
    for (const k of Object.keys(obj)) {
        const v = obj[k];
        if (v in newObj) {
            newObj[v].push(k);
        } else {
            newObj[v] = [k];
        }
    }
    return newObj;
}

export function swapKeyNumberValues(obj: any) {
    if (!obj) return obj;
    const newObj = {};
    for (const k of Object.keys(obj)) {
        const v = obj[+k];
        if (v in newObj) {
            newObj[v].push(+k);
        } else {
            newObj[v] = [+k];
        }
    }
    return newObj;
}

export type MergeOperation = 'UNCHANGED' | 'CREATED' | 'UPDATED' | 'DELETED';

export type MergeChanges  = {[op in MergeOperation]: number};

export function getMergeCount(changes: MergeChanges) {
  return changes.UNCHANGED + changes.UPDATED + changes.CREATED - changes.DELETED;
}