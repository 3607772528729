import { WidgetChildrenHolder, WidgetContext, WidgetData, WidgetableComponent, WidgetInfo, WidgetParent, WidgetService } from '@adeprez/ionstack';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';
import { InjectComponent } from '@adeprez/ionstack/ui/inject';

@Component({
  selector: 'ionstack-widget',
  template: '<ng-template anchor></ng-template>',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetComponent extends InjectComponent<WidgetableComponent> implements WidgetParent {
  @Output() widgetChange = new EventEmitter<WidgetData>();
  @HostBinding('style') hostStyle: SafeStyle;
  @HostBinding('class') hostClasses: string;
  @HostBinding('slot') slot: string;
  @Input() container: WidgetChildrenHolder;
  @Input() index: number;
  @Input() siblings: number;
  infos: WidgetInfo;
  private _context: WidgetContext;
  private _widget: WidgetData;

  constructor(
    private widgetService: WidgetService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  get childSupport() {
    return this.infos.meta.childSupport;
  }

  get propsFields() {
    return this.infos.meta.childrenFields;
  }

  private buildWidget() {
    if (this._widget) {
      if (this.infos) {
        this.component = {
          type: this.infos.type,
          inputs: {
            widget: {
              holder: this,
              data: this._widget,
              context: this._context
            }
          } as WidgetableComponent
        };
      } else {
        this.component = null;
        console.error('No provider for widget: ' + this._widget.type);
      }
      this.hostClasses = this.widget.classes;
      this.slot = this.widget.slot || '';
      this.hostStyle = this._widget.style ? this.sanitizer.bypassSecurityTrustStyle(this._widget.style) : null;
    } else {
      this.slot = null;
      this.hostStyle = null;
      this.hostClasses = null;
    }
  }

  @Input() set context(context: WidgetContext) {
    this._context = context;
    this.buildWidget();
  }

  get context() {
    return this._context;
  }

  @Input() set widget(widget: WidgetData) {
    this._widget = widget;
    this.infos = widget ? this.widgetService.getWidget(widget.type) : null;
    this.buildWidget();
  }

  setChildren(children: WidgetData[]) {
    this.widget = {...this._widget, children};
    this.widgetChange.emit(this.widget);
    this.cd.markForCheck();
  }

  get widget() {
    return this._widget;
  }

  get slots() {
    return this.infos?.meta?.slots;
  }

}
