import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OTPUrlParam } from '../model/verification';
import { VerificationService } from '../service/verification.service';
import { b64decode } from '../util/util';

@Injectable({
    providedIn: 'root'
})
export class OTPGuard implements CanActivate {

    constructor(private verificationService: VerificationService) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const otpParam = route.queryParamMap.get('otp');
        if (otpParam) {
            let otp: OTPUrlParam;
            try {
                otp = JSON.parse(b64decode(otpParam));
            } catch (e) {
                console.warn('invalid otp: ' + e);
                return true;
            }
            return this.verificationService.handleOTP(otp);
        }
        return true;
    }


}