import { TranslatableModule, IonstackPipesModule, IonstackDirectivesModule } from '@adeprez/ionstack';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleFormComponent, SimpleFormPopover } from './simple-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ChipListModule } from '@adeprez/ionstack/ui/chip-list';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatableModule,
    IonstackDirectivesModule,
    ChipListModule,
    IonstackPipesModule,
  ],
  declarations: [SimpleFormComponent, SimpleFormPopover],
  exports: [SimpleFormComponent, SimpleFormPopover],
})
export class SimpleFormModule { }
