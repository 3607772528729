import { ModalGalleryComponent } from './component/modal-gallery/modal-gallery.component';
import { ModalGalleryModule } from './component/modal-gallery/modal-gallery.module';
import { NotifRoutesService } from './service/notif-routes.service';
import { APP_INITIALIZER, NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SubscribeModalModule } from '@adeprez/ionstack/ui/subscribe-modal';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import { API_CLIENT, AppBaseModule, IonstackHttpInterceptor, IonstackModule, TranslateModule, NotifRouteProvider, TranslatableModule, actionToken } from '@adeprez/ionstack';

import localeFr from '@angular/common/locales/fr';
import { IonstackWidgetsModule } from '@adeprez/ionstack/widgets';
import { RoutesModule } from './routes.module';
import { AnalyticsService } from './service/analytics.service';
import { IntercomService } from './service/intercom.service';
import { ControllerModule } from './controller/controller.module';
registerLocaleData(localeFr, 'fr');

const INIT_SERVICES: Type<{init: () => unknown}>[] = [AnalyticsService, IntercomService];

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        AppRoutingModule,
        IonstackModule.forRoot({
            ...environment,
            controllerState: {
                topMenu: 'toolbar',
                titleSuffix: ' | ' + environment.appName,
            },
            termsOfServiceRoute: '/cgu',
            salesTermsRoute: '/cgv',
            galleryComponent: ModalGalleryComponent,
        }),
        BrowserModule.withServerTransition({ appId: 'wepost' }),
        IonicModule.forRoot({
            scrollAssist: false
        }),
        TranslateModule,
        AppBaseModule,
        RoutesModule,
        ModalGalleryModule,
        SubscribeModalModule,
        IonstackWidgetsModule,
        TranslatableModule,
        ControllerModule,
    ],
    providers: [
        { provide: API_CLIENT, useExisting: HttpClient },
        { provide: HTTP_INTERCEPTORS, useClass: IonstackHttpInterceptor, multi: true },
        { provide: NotifRouteProvider, useClass: NotifRoutesService, multi: true },
        ...INIT_SERVICES.map(s => ({
            provide: APP_INITIALIZER,
            useFactory: (s: {init: () => unknown}) => s.init.bind(s),
            deps: [s],
            multi: true
        })),
        { provide: actionToken('intercom-help'), useFactory: (s: IntercomService) => () => s.intercom('showSpace', 'messages') , deps: [IntercomService]},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
