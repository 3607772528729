<input
  type="file"
  (change)="fileChangeEvent($event)"
  [accept]="formatExtensions | call:config?.allowedTypes:requiredExtension"
  [multiple]="allowExtraUpload && !config?.disallowMultiupload"
  style="display:none"
  #input
/>
<ion-button [awaitClick]="add" [bindAwaitClickTo]="this" [color]="config.buttonColor || 'primary'" fill="clear" *ngIf="showButton" class="add-btn">
  <div slot="icon-only">
    <ion-icon *ngFor="let type of (config.allowedTypes || allTypes)" [name]="icons[type]"></ion-icon>
    <ion-icon name="add"></ion-icon>
  </div>
</ion-button>

<ion-modal [isOpen]="!!capturingType" (didDismiss)="resetCapture()" #modal>
  <ng-template>
    <ion-content [ngSwitch]="capturingType">
      <ionstack-video-capture
        *ngSwitchCase="fileTypes.VIDEO"
        [allowExtraUpload]="allowExtraUpload"
        [browseAllowed]="false"
        (formatChange)="videoFormat = $event"
        (extUrlChange)="extCaptureUrl = $event"
        (valueChange)="tmpCaptureFile = $event"
        (onFileName)="capturingName = $event"
        (onExtraUpload)="uploadExtraFile($event)"
      ></ionstack-video-capture>
      <ionstack-audio-capture
        *ngSwitchCase="fileTypes.AUDIO"
        [allowExtraUpload]="allowExtraUpload"
        [browseAllowed]="false"
        (formatChange)="audioFormat = $event"
        (extUrlChange)="extCaptureUrl = $event"
        (valueChange)="tmpCaptureFile = $event"
        (onFileName)="capturingName = $event"
        (onExtraUpload)="uploadExtraFile($event)"
      ></ionstack-audio-capture>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="modal.dismiss()">
            <ion-icon name="close" slot="start" color="danger"></ion-icon>
            <ion-label><itxt key="Cancel"></itxt></ion-label>
          </ion-button>
          <ion-button [awaitClick]="submitCapture" [bindAwaitClickTo]="this" [disabled]="!extCaptureUrl && !tmpCaptureFile">
            <ion-icon name="checkmark" slot="start"></ion-icon>
            <ion-label><itxt [key]="config?.submitCaptureText || 'Save'"></itxt></ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>
