import { PermissionChecker } from './user';

export interface IonstackMenu {
    name?: string;
    showTitle?: boolean;
    groups: IonstackMenuGroup[];
    prependMenu?: MenuInput;
    appendMenu?: MenuInput;
}

export type ShowOnType = 'ALWAYS' | 'SMALL' | 'LARGE' | 'RESPONSIVE' | 'TOOLBAR' | 'TOOLBAR_SMALL';

export interface IonstackMenuElement {
    title?: string;
    translate?: boolean;
    hasAnyPermission?: boolean;
    color?: string;
    showOn?: ShowOnType;
    permissions?: string[];
    visible?: () => boolean;
    visibleArg?: (arg: any) => boolean;
}

export interface IonstackMenuGroup extends IonstackMenuElement {
    items: IonstackMenuItem[];
    separator?: boolean;
}

export interface ClickArg {
    event: Event;
    item: IonstackMenuItem;
    arg?: any;
}

export interface IonstackMenuItem extends IonstackMenuElement {
    iconColor?: string;
    url?: string;
    icon?: string;
    styleClass?: string;
    submenu?: MenuInput;
    clickAction?: string;
    preventMenuClose?: boolean;
    selectedExactRoute?: boolean;
    hideNotifless?: boolean;
    notifType?: string;
    click?: (arg: ClickArg) => void;
}

export interface MenuProvider {
    getMenu(name: string): ComputedMenu;
}

export type MenuInput = ComputedMenu | IonstackMenu | string;

export type ComputedMenuPosition = 'toolbar' | 'smalltoolbar' | 'small' | 'regular';

export function getComputedMenuPosition(isSmall: boolean, isToolbar: boolean): ComputedMenuPosition {
    return isSmall ? (isToolbar ? 'smalltoolbar' : 'small') : (isToolbar ? 'toolbar' : 'regular');
}

const SMALL: {[position in ComputedMenuPosition]: boolean} = {
    toolbar: false,
    smalltoolbar: true,
    small: true,
    regular: false,
};

const TOOLBAR: {[position in ComputedMenuPosition]: boolean} = {
    toolbar: true,
    smalltoolbar: true,
    small: false,
    regular: false,
};


export class ComputedMenu {
    private groups: {[position in ComputedMenuPosition]?: IonstackMenuGroup[]} = {};

    constructor(private base: IonstackMenu, private menuProvider: MenuProvider, private permissionChecker: PermissionChecker) {}

    isNameVisible() {
        return this.base.showTitle;
    }

    getName() {
        return this.base.name;
    }

    private hasPermission(menuElement: IonstackMenuElement) {
        const pList = menuElement.permissions ?? [];
        const toTest = pList.filter(p => p !== '!');
        const invert = pList.length !== toTest.length;
        if (menuElement.hasAnyPermission) {
            for (const permission of toTest) {
                if (this.permissionChecker.can(permission)) {
                    return !invert;
                }
            }
            return invert;
        }
        return invert ? !this.permissionChecker.canAll(toTest) : this.permissionChecker.canAll(toTest);
    }

    private isVisible(position: ComputedMenuPosition, menuElement: IonstackMenuElement, defaultVisibility: ShowOnType) {
      return this.isShowOn(position, menuElement.showOn || defaultVisibility)
        && (!menuElement.visible || menuElement.visible())
        && this.hasPermission(menuElement);
    }

    private isShowOn(position: ComputedMenuPosition, visibility: ShowOnType) {
      switch (visibility) {
        case 'ALWAYS': return true;
        case 'RESPONSIVE': return !SMALL[position] || !TOOLBAR[position];
        case 'LARGE': return !SMALL[position];
        case 'TOOLBAR': return TOOLBAR[position];
        case 'TOOLBAR_SMALL': return TOOLBAR[position] && SMALL[position];
        case 'SMALL': return SMALL[position] && !TOOLBAR[position];
      }
    }

    private computeGroups(position: ComputedMenuPosition, groups: IonstackMenuGroup[]) {
        for (const group of groups) {
            if (this.isVisible(position, group, 'ALWAYS')) {
                const items = [];
                for (const item of group.items) {
                    if (this.isVisible(position, item, 'RESPONSIVE')) {
                        items.push(item);
                    }
                }
                if (items.length) {
                    this.groups[position].push({...group, items: items});
                }
            }
        }
    }

    private computeAround(m: MenuInput, position: ComputedMenuPosition) {
        if (m) {
            let menu: ComputedMenu;
            if (typeof m === 'string') {
                menu = this.menuProvider.getMenu(m);
            } else if (m instanceof ComputedMenu) {
                menu = m;
            } else {
                menu = new ComputedMenu(m, this.menuProvider, this.permissionChecker);
            }
            if (menu && menu.getName() !== this.base.name) {
                for (const group of menu.getGroups(position)) {
                    this.groups[position].push(group);
                }
            }
        }
    }

    private computeMenu(menu: IonstackMenu, position: ComputedMenuPosition) {
        if (menu) {
            this.groups[position] = [];
            this.computeAround(menu.prependMenu, position);
            this.computeGroups(position, menu.groups);
            this.computeAround(menu.appendMenu, position);
        }
    }

    reset() {
        this.groups = {};
    }

    hasContent(position: ComputedMenuPosition) {
        return this.getGroups(position).length > 0;
    }

    getGroups(position: ComputedMenuPosition): IonstackMenuGroup[] {
        if (!(position in this.groups)) {
            this.computeMenu(this.base, position);
        }
        return this.groups[position];
    }
}