import { BrowseFileModule } from './../browse-file/browse-file.module';
import { IonstackPipesModule, IonstackDirectivesModule } from '@adeprez/ionstack';
import { IonicModule } from '@ionic/angular';
import { EditableFileModule } from './../editable-file/editable-file.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditFileGalleryComponent } from './edit-file-gallery.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    EditableFileModule,
    IonstackDirectivesModule,
    IonstackPipesModule,
    BrowseFileModule,
  ],
  declarations: [EditFileGalleryComponent],
  exports: [EditFileGalleryComponent],
})
export class EditFileGalleryModule { }
