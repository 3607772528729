import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioPlayerComponent } from './audio-player.component';
import { IonstackPipesModule } from '@adeprez/ionstack';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonstackPipesModule,
    IonicModule,
  ],
  declarations: [AudioPlayerComponent],
  exports: [AudioPlayerComponent],
})
export class AudioPlayerModule { }
