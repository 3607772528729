import { Router } from '@angular/router';
import { IonstackModuleConfig, MODULE_CONFIG, NotificationClick, ServiceWorkerPush } from '../ionstack.config';
import { DeviceService } from './device.service';
import { ContextService } from './context.service';
import { WebsocketService } from './websocket.service';
import { Cached } from '../util/cache';
import { NotifsCount, Notif, WSNotif } from '../model/notification';
import { Page } from '../model/page';
import { RequestParams, IonstackService } from './ionstack.service';
import { EventEmitter, Injectable, InjectionToken, OnDestroy, Injector, Inject, Optional } from '@angular/core';
import { CleanSubscriber } from '../util/subscriber';
import { FullRoute } from '../model/route';
import { ActionPerformed, PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { DevicePlatform } from '../model/device';

export const NotifRouteProvider = new InjectionToken<NotifRouteProvider[]>('NotifRouteProvider');

export type NotifRoute = FullRoute;

export interface NotifRouteProvider {
  getRoute(notif: Notif): FullRoute | undefined | void | null | false;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends CleanSubscriber implements OnDestroy {
    readonly count = new Cached<NotifsCount>(() => this.ionstackService.get<NotifsCount>('/notifications/unread/count'));
    readonly onNotification = new EventEmitter<WSNotif>();

    constructor(
        private ionstackService: IonstackService,
        private websocketService: WebsocketService,
        private injector: Injector,
        private deviceService: DeviceService,
        private contextService: ContextService,
        private router: Router,
        @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
        @Inject(ServiceWorkerPush) @Optional() private serviceWorkerPush: ServiceWorkerPush,
    ) {
        super();
        if (this.ionstackService.isBrowser()) {
            this.subscribe(this.contextService.user, user => {
                if (user) {
                    this.subscribe(
                        this.websocketService.userSubscribe('/notifications'),
                        (notif: WSNotif) => this.onNotifChange(notif),
                        { replace: true, name: 'notifs' }
                    );
                    this.activatePush();
                } else {
                    this.unsubscribe('notifs');
                }
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribeAll();
    }

    async getCurrentNotifCount(type: string) {
        return (await this.count.toPromise()).byType[type] || 0;
    }

    getNotifRoute(notif: Notif): NotifRoute {
      const providers = this.injector.get<NotifRouteProvider[]>(NotifRouteProvider, []);
      for (const p of providers) {
        const route = p.getRoute(notif);
        if (route) {
          return route;
        }
      }
      return null;
    }

    async performNotificationAction(notif: Notif) {
        const route = this.getNotifRoute(notif);
        if (route) {
            if (route.path || route.query) {
                return this.router.navigate(
                    route.path,
                    { queryParams: route.query }
                );
            }
            if (route.click) {
                await route.click();
                return true;
            }
        }
        return false;
    }

    private onNotifChange(notif: WSNotif) {
        const read = !!notif.readDate;
        if (notif.new !== read) {
            const incr = read ? -1 : 1;
            this.count.updateValues(value => ({
                total: Math.max(0, value.total + incr),
                byType: {
                    ...value.byType,
                    [notif.type]: Math.max(0, (value.byType[notif.type] || 0) + incr)
                }
            }));
        }
        this.onNotification.emit(notif);
    }

    setRead(id: number): Promise<Notif> {
        return this.ionstackService.put<Notif>('/notifications/' + id + '/read', {});
    }
  
    getNotifications(req: RequestParams) {
        return this.ionstackService.get<Page<Notif>>('/notifications', {params: req});
    }

    setAllRead(params: RequestParams = {}): Promise<void> {
        if (this.ionstackService.isServer() || !this.contextService.isCurrentLogged()) {
            return new Promise(r => r());
        }
        return this.ionstackService.put<void>('/notifications/all/read', {}, {params});
    }

    async activatePush() {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            this.activatePushRegistration();
            const perm = await PushNotifications.requestPermissions();
            if (perm.receive === 'granted') {
                PushNotifications.register();
            }
        } else if (this.serviceWorkerPush?.isEnabled && this.ionstackModuleConfig.webpushVapidKey) {
            this.activateWebPushRegistration();
            const sub = await this.serviceWorkerPush.requestSubscription({serverPublicKey: this.ionstackModuleConfig.webpushVapidKey});
            await this.deviceService.sendDevice(JSON.stringify(sub.toJSON()), DevicePlatform.web);
        }
    }

    private async pushAction(notif: Notif, attempts = 5) {
        if (!(await this.performNotificationAction(notif)) && attempts > 0) {
            setTimeout(() => this.pushAction(notif, attempts - 1), 500);
        }
    }

    private activatePushRegistration() {
        this.deviceService.activatePushRegistration();
        // Notification when the app is open on device
        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => console.log('Push received: ' + JSON.stringify(notification.data))
        );
        // Tapping on a notification
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: ActionPerformed) => this.pushAction(JSON.parse(notification.notification.data.payload))
        );
    }

    private activateWebPushRegistration() {
        this.subscribe<NotificationClick>(this.serviceWorkerPush.notificationClicks, msg => this.pushAction(msg.notification.data));
    }

}