import { IonstackDirectivesModule, IonstackPipesModule } from '@adeprez/ionstack';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalGalleryComponent } from './modal-gallery.component';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { FileDisplayModule } from '@adeprez/ionstack/file';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonstackPipesModule,
    IonstackDirectivesModule,
    FileDisplayModule,
    SwiperModule,
  ],
  declarations: [ModalGalleryComponent],
  exports: [ModalGalleryComponent],
})
export class ModalGalleryModule { }
