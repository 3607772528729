import { AppController } from '../controller/app.controller';
import { CleanSubscriber } from '../util/subscriber';
import { DOCUMENT } from '@angular/common';
import { Directive, Input, OnInit, OnDestroy, ElementRef, Inject, Renderer2, OnChanges } from '@angular/core';

type TargetElement = string | ElementRef;

@Directive({
  selector: '[responsiveAttach]'
})
export class ResponsiveAttachDirective extends CleanSubscriber implements OnInit, OnDestroy, OnChanges {
  @Input() attachSmall = true;
  @Input() responsiveAttach: TargetElement;
  @Input() responsiveAttachEnabled = true;
  private originalParent: HTMLElement;
  private originalIndex: number;
  private originalTarget: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private appController: AppController,
    @Inject(DOCUMENT) private document: any,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.appController.onSizeChange, () => this.compute());
  }

  ngOnDestroy() {
    this.unsubscribeAll();
    this.removeAttached();
  }

  ngOnChanges() {
    this.compute();
  }

  private compute() {
    if (this.responsiveAttachEnabled && this.responsiveAttach && this.attachSmall === this.appController.isSmall) {
      this.originalParent = this.elementRef.nativeElement.parentElement;
      this.originalIndex = Array.from(this.originalParent.children).indexOf(this.elementRef.nativeElement);
      this.originalTarget = this.getTarget();
      if (this.originalTarget) {
        this.renderer.appendChild(this.originalTarget, this.elementRef.nativeElement);
      }
    } else if (this.originalParent) {
      this.removeAttached();
      this.renderer.insertBefore(this.originalParent, this.elementRef.nativeElement, this.originalParent.children[this.originalIndex]);
    }
  }

  private removeAttached() {
    if (this.originalParent && this.elementRef.nativeElement) {
      this.renderer.removeChild(this.originalParent, this.elementRef.nativeElement);
    }
  }

  private getTarget() {
    if (typeof this.responsiveAttach === 'string') {
      return this.document.querySelector(this.responsiveAttach);
    }
    return this.responsiveAttach;
  }

}
