import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface Country {
  alpha3Code: string;
}

export interface City {
  id: number;
  center: {x: number, y: number};
  zipCode: string;
  name: string;
  countryCode: string;
}

export interface Geoposition {
  latitude: number;
  longitude: number;
}

export interface Geolocator {
  getCurrentPosition(): Promise<{coords: Geoposition}>;
  watchPosition(): Observable<{coords: Geoposition}>;
}

export const Geolocator = new InjectionToken<Geolocator>('Geolocator');

export interface CountryInputConfig {
  postalCodeLength: number;
  internationalPhoneNumberLength: number;
  internationalPhonePlaceHolder: string;
  internationalPhonePattern: string;
  phoneLength: number;
  taxIdLength: number;
  vatIdLength: number;
  accountNumberLength: number;
  routingNumberLength: number;
}

const DefaultCountryInputConfig: CountryInputConfig = {
  postalCodeLength: 10,
  internationalPhoneNumberLength: 15,
  internationalPhonePlaceHolder: '(201) 555-0123',
  internationalPhonePattern: '+000000000000000',
  phoneLength: 20,
  taxIdLength: 20,
  vatIdLength: 15,
  accountNumberLength: 34,
  routingNumberLength: 11,
};

const CONTRY_INPUT_CONFIG: {[country3code: string]: Partial<CountryInputConfig>} = {
  FRA: {
    postalCodeLength: 5,
    internationalPhonePlaceHolder: '+33612345678',
    internationalPhoneNumberLength: 12,
    internationalPhonePattern: '+330000000000',
    phoneLength: 10,
    taxIdLength: 14,
    vatIdLength: 13,
    accountNumberLength: 27,
  }
};

export function getCountryInputConfig(country: string): CountryInputConfig {
  return {...DefaultCountryInputConfig, ...CONTRY_INPUT_CONFIG[country]};
}

export function getCountryInputConfigParam(country: string, param: keyof CountryInputConfig): any {
  return CONTRY_INPUT_CONFIG[country]?.[param] ?? DefaultCountryInputConfig[param];
}