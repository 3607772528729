import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'call',
  pure: true
})
export class CallPipe<T> implements PipeTransform {

  transform<A1>(fn: (a1: A1) => T, a1: T): T;
  transform<A1, A2>(fn: (a1: A1, a2?: A2) => T, a1: A1, a2?: A2): T;
  transform<A1, A2, A3>(fn: (a1: A1, a2?: A2, a3?: A3) => T, a1: A1, a2?: A2, a3?: A3): T;
  transform<A1, A2, A3, A4>(fn: (a1: A1, a2?: A2, a3?: A3, a4?: A4) => T, a1: A1, a2?: A2, a3?: A3, a4?: A4): T;
  transform<A1, A2, A3, A4, A5>(fn: (a1: A1, a2?: A2, a3?: A3, a4?: A4, a5?: A5) => T, a1: A1, a2?: A2, a3?: A3, a4?: A4, a5?: A5): T;
  transform<A1, A2, A3, A4, A5, A6>(fn: (a1: A1, a2?: A2, a3?: A3, a4?: A4, a5?: A5, a6?: A6) => T, a1: A1, a2?: A2, a3?: A3, a4?: A4, a5?: A5, a6?: A6): T {
    return fn == null ? undefined : fn(a1, a2, a3, a4, a5, a6);
  }

}
