import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { IonstackService } from '../service/ionstack.service';
import { filterNotNull } from '../util/util';

@Pipe({
  name: 'barcode',
  pure: true
})
export class BarCodePipe implements PipeTransform {

  constructor(
    private ionstackService: IonstackService,
    private router: Router,
  ) {}

  transform(data?: string, barcode: 'qr' | 'bar' = 'qr', args: {size?: number, width?: number, height?: number, margin?: number} = {}): string {
    return this.ionstackService.apiUrl('/public/barcodes/' + barcode) + '?' +
      Object.entries(filterNotNull({d: data ?? this.router.url, ...args})).map(([k, v]) => k + '=' + encodeURIComponent(v)).join('&');
  }

}
