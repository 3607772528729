import { NgIf, NgIfContext } from '@angular/common';
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextService } from '../service/context.service';
import { PermissionService } from '../service/permission.service';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective<T> extends NgIf implements OnInit, OnDestroy {
  private permission: string | string[] | {[name: string]: boolean};
  private subscription: Subscription;
  @Input() hasPermissionCanAny = false;

  constructor(
    _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext<T>>,
    private permissionService: PermissionService,
    private contextService: ContextService,
    private cd: ChangeDetectorRef,
  ) {
    super(_viewContainer, templateRef);
  }

  ngOnInit() {
    this.subscription = this.contextService.context.subscribe(() => {
      this.update();
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  @Input() set hasPermission(permission: string | string[]) {
    this.permission = permission;
    this.update();
  }

  @Input() set hasPermissionElse(ref: TemplateRef<NgIfContext<T>>) {
    this.ngIfElse = ref;
    this.update();
  }

  update() {
    if (!this.permission) {
      this.ngIf = false;
    } else if (typeof this.permission === 'string') {
      this.ngIf = this.permissionService.can(this.permission);
    } else if (Array.isArray(this.permission)) {
      if (this.hasPermissionCanAny) {
        this.ngIf = this.permissionService.canAny(this.permission);
      } else {
        this.ngIf = this.permissionService.canAll(this.permission);
      }
    } else {
      this.ngIf = Object.entries(this.permission).every(([name, value]) => this.permissionService.can(name) === value);
    }
  }

}
