import {
  FileType, TranslateService, AlertService, IonstackService, DEFAULT_IMAGE_FORMAT, IonstackModuleConfig, MODULE_CONFIG,
  ImageFormat, IMAGE_FORMATS, getExtensionDisplay
} from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { CameraSource, Photo } from '@capacitor/camera';
import { Platform, ActionSheetController, ActionSheetButton } from '@ionic/angular';
import { ExtraFileUpload, ImageUploaderWithExtra } from '../file-capture';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageCaptureComponent),
    multi: true
  }],
  selector: 'ionstack-image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['../capture.scss', './image-capture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCaptureComponent extends ImageUploaderWithExtra<string> implements OnInit {
  @ViewChild('input') inputFile: ElementRef;
  @ViewChild('cropper') imageCropper: ImageCropperComponent;
  @Output() valueChange = new EventEmitter<string>();
  @Output() valueReset = new EventEmitter<string>();
  @Output() pickImage = new EventEmitter<void>();
  @Output() formatChange = new EventEmitter<ImageFormat>();
  @Output() onFileName = new EventEmitter<string>();
  @Output() onExtraUpload = new EventEmitter<ExtraFileUpload>();
  @Input() allowExtraUpload = false;
  @Input() maxCropperHeight = '350px';
  @Input() maintainAspectRatio = true;
  @Input() aspectRatio = 1;
  @Input() format: ImageFormat;
  @Input() roundCropper = false;
  @Input() value: string;
  @Input() isDisabled: boolean;
  @Input() imageFile: File;
  @Input() base64: string;
  @Input() imageURL: string;
  @Input() skipFirstLoadEmit = false;
  @Input() requiredExtension: string[];
  rotation = 0;
  minWidth = 32;
  minHeight = 32;
  visible: boolean;
  readonly fileType = FileType.IMAGE;
  readonly getExtensionDisplay = (requiredExtension: string[]) => getExtensionDisplay(FileType.IMAGE, requiredExtension);
  private defaultFormat: ImageFormat;

  readonly getOutputFormat = (format: ImageFormat) => format === 'stream' ? 'webp' : (format || 'webp');

  constructor(
    private ionstackService: IonstackService,
    public translateService: TranslateService,
    public platform: Platform,
    private actionSheetController: ActionSheetController,
    public alertService: AlertService,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    public cd: ChangeDetectorRef,
  ) {
    super();
    this.visible = this.ionstackService.isBrowser();
  }

  ngOnInit() {
    if (!this.format || !IMAGE_FORMATS.includes(this.format)) {
      this.setFormat(this.ionstackModuleConfig.defaultImageFormat || DEFAULT_IMAGE_FORMAT);
    }
    this.defaultFormat = this.format;
  }

  resetFormat() {
    this.setFormat(this.defaultFormat);
  }

  setFormat(format: ImageFormat) {
    if (this.format !== format) {
      this.format = format;
      this.formatChange.emit(format);
    }
  }

  fileChangeEvent(event: Event): void {
    const files: FileList = event.target?.['files'];
    this.onDrop(Array.from(files));
  }

  imageLoaded() {
    this.cd.markForCheck();
  }

  async beforeUpload(extension: string, filename: string) {
    this.pickImage.emit();
    this.onFileName.emit(filename);
    this.setFormat(extension as ImageFormat);
  }

  async afterUpload() {
    this.cd.markForCheck();
  }

  async onUpload(file: Blob) {
    this.imageFile = file as File;
    this.cd.markForCheck();
  }

  async noMatchForUpload() {
    this.alertService.showError(await this.translateService.awaitGet(
      'Supported format are: {{formats}}',
      {formats: (this.requiredExtension || IMAGE_FORMATS).join(', ')}
    ));
  }

  onDrop(files: File[]) {
    this.setTouched();
    this.pickImage.emit();
    this.uploadArray(files, async (file, isPrincipal) => await this.upload(file, file.name, isPrincipal));
    this.cd.markForCheck();
  }

  hasImage() {
    return this.base64 || this.imageFile || this.imageURL;
  }

  isCropping() {
    return this.visible && this.hasImage();
  }

  rotate() {
    this.rotation = (this.rotation + 1) % 4;
  }

  @Input() set minSize(size: number) {
    this.minWidth = size * this.aspectRatio;
    this.minHeight = size / this.aspectRatio;
  }

  reset() {
    this.valueReset.emit(this.value);
    this.base64 = null;
    this.imageFile = null;
    this.imageURL = null;
    this.setValue(null);
  }

  override async onPhotoTaken(photo: Photo) {
    this.pickImage.emit();
    this.base64 = photo.dataUrl;
    this.cd.markForCheck();
  }

  override openBrowseFile() {
    this.inputFile.nativeElement.value = '';
    this.inputFile.nativeElement.click();
  }

  async browseFromZone() {
    const buttons: ActionSheetButton[] = [{
      text: await this.translateService.awaitGet('Take picture'),
      handler: () => this.takePicture(CameraSource.Camera),
    }];
    if (!this.platform.is('mobileweb')) {
      buttons.push({
        text: await this.translateService.awaitGet('Browse gallery'),
        handler: () => this.takePicture(CameraSource.Photos),
      });
    }
    buttons.push({
      text: await this.translateService.awaitGet('Cancel'),
      role: 'cancel',
      cssClass: 'danger-color'
    });
    return (await this.actionSheetController.create({
      header: await this.translateService.awaitGet('Photo'),
      buttons
    })).present();
  }

  imageCropped(event: ImageCroppedEvent) {
    if (this.skipFirstLoadEmit) {
      this.skipFirstLoadEmit = false;
    } else {
      this.resetFormat();
      this.setValue(event.base64);
    }
  }

  async loadImageFailed() {
    this.alertService.showError(await this.translateService.awaitGet('Image load failed'));
    this.base64 = this.imageFile = this.imageURL = undefined;
    this.cd.markForCheck();
  }

}