import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'renderError',
  pure: true
})
export class RenderErrorPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(error: any, img?: string): SafeHtml {
    let html = `<div class="render-error"><span>${error.error?.message || error.statusText || error.message}</span>`
    if (img) {
      html += `<ion-img src="${img}"></ion-img>`;
    }
    return this.sanitizer.bypassSecurityTrustHtml(html + '</div>');
  }

}
