
export enum DevicePlatform {
  android = 'ANDROID', ios = 'IOS', web = 'WEB'
}

export interface Device {
  id: number;
  platform: DevicePlatform;
  name: string;
  token: string;
  addDate: string;
}

export interface SaveDevice {
  platform: DevicePlatform;
  name: string;
  token: string;
}