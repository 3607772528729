import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichtextComponent } from './richtext.component';
import { RichtextEditPopupModule } from './richtext-edit-popup/richtext-edit-popup.module';
import { WIDGET } from '@adeprez/ionstack';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    CommonModule,
    RichtextEditPopupModule,
    QuillModule.forRoot(),
  ],
  declarations: [RichtextComponent],
  exports: [RichtextComponent],
  providers: [{ provide: WIDGET, useValue: RichtextComponent, multi: true}]
})
export class RichtextModule { }
