import { IonInput } from '@ionic/angular';
import { Directive, HostListener, Input, Optional, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputPattern]',
})
export class InputPatternDirective {
  @Input() numberPattern = '0';
  @Input() ignorePattern = '*';
  @Input() ignoreButSpacePattern = '~';
  @Input() inputPattern: string;
  @Input() canOverflow: boolean;
  @Input() pushRight: boolean
  @Input() focusAtEnd: HTMLElement | ElementRef<HTMLElement> | IonInput;
 
  constructor(@Optional() private control: NgControl) { }

  @HostListener('input', ['$event'])
  async onChange(event: Event) {
    if (!this.inputPattern) return;
    const { pattern, overflow } = this.computePattern();
    const movement = this.pushRight ? 0 : 1;
    const elem = event.target as HTMLInputElement;
    let newValue = elem.value;
    for (let i = 0; i < pattern.length && i < newValue.length + 1; i++) {
      const cValue = newValue[i];
      const cPattern = pattern[i];
      if (cPattern === this.numberPattern) {
        if (cValue && (isNaN(+cValue) || cValue === ' ')) {
          newValue = newValue.slice(0, i) + newValue.slice(i + movement);
        }
      } else if (cPattern === this.ignoreButSpacePattern) {
        if (cValue === ' ') {
          newValue = newValue.slice(0, i) + newValue.slice(i + movement);
        }
      } else if (cPattern !== this.ignorePattern) {
        if (cValue !== cPattern && (cValue || (event as InputEvent).inputType !== 'deleteContentBackward')) {
          newValue = newValue.slice(0, i) + cPattern + newValue.slice(i + movement);
        }
      }
    }
    if (newValue.length > pattern.length && !overflow) {
      newValue = newValue.slice(0, pattern.length);
    }
    if (newValue !== elem.value) {
      elem.value = newValue;
      if (this.control) {
        this.control.control.setValue(newValue);
      }
    }
    if (newValue.length >= this.inputPattern.length && this.focusAtEnd) {
      if (this.focusAtEnd instanceof IonInput) {
        this.focusAtEnd.setFocus();
      } else {
        ('nativeElement' in this.focusAtEnd ? this.focusAtEnd.nativeElement : this.focusAtEnd).focus();
      }
    }
  }

  private computePattern() {
    let pattern = this.inputPattern;
    let overflow = this.canOverflow;
    if (pattern.endsWith('+')) {
      const sub = pattern.substring(0, pattern.length - 1);
      if (sub.endsWith('\\')) {
        pattern = pattern.substring(0, pattern.length - 2) + '+';
      } else {
        pattern = sub;
        overflow = true;
      }
    }
    return {pattern, overflow};
  }

}
