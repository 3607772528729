import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '../translate/translate.service';
import { ToastOptions } from '@ionic/core';

export function Try() {
    return (self: {alertService: AlertService}, __: any, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            try {
                return await originalMethod.apply(this, args);
            } catch (e) {
                this.alertService.showError(e);
            }
        };
    };
}

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private popupDisabled: boolean;

    constructor(
        private toastController: ToastController,
        private alertController: AlertController,
        private translateService: TranslateService,
        @Inject(PLATFORM_ID) platformId: any,
    ) {
        this.popupDisabled = isPlatformServer(platformId);
    }

    async showError(e: any) {
        const opts: ToastOptions = {
            buttons: ['OK'],
            color: 'danger',
            duration: 10000,
            header: await this.translateService.awaitGet('Error')
        };
        if (e instanceof HttpErrorResponse) {
            if (e.error?.extras?.skip_toast_message) {
                return;
            }
            try { return this.showToast(JSON.parse(e.error.message.substring(6))[0].message, opts); } catch {}
        }
        return this.showToast(e?.error?.error?.message || e?.error?.message || e?.error || e?.message || (typeof e === 'string' ? e : 'unknown error'), opts);
    }

    confirmDelete(what: string, action: () => (Promise<any | void>) | void = () => {}): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            this.confirm(await this.translateService.awaitGet('Delete {{name}}?', {name: what ?? ''}), async () => {
                try {
                    await action();
                    resolve(true);
                } catch (e) {
                    this.showError(e);
                    resolve(false);
                }
            }, () => resolve(false));
        });
    }

    async confirm(confirmText: string, action: () => void, cancel = () => {}, okText?: string, cancelText?: string) {
        return this.presentAlert(undefined, confirmText, [{
                text: await this.translateService.awaitGet(okText || 'OK'),
                cssClass: 'primary-color',
                handler: action
            }, {
                text: await this.translateService.awaitGet(cancelText || 'Cancel'),
                role: 'cancel',
                cssClass: 'danger-color',
                handler: cancel
        }]);
    }

    async confirmPromise(confirmText: string, okText?: string, cancelText?: string) {
        return new Promise<void>((resolve, reject) => this.confirm(confirmText, resolve, reject, okText, cancelText));
    }

    async awaitConfirmOk(confirmText: string, okText?: string, cancelText?: string) {
        try {
            await this.confirmPromise(confirmText, okText, cancelText);
            return true;
        } catch {
            return false;
        }
    }

    async presentAlert(header: string, message: string, buttons: AlertButton[]) {
        if (this.popupDisabled) {
            return new Promise<void>(resolve => resolve());
        }
        const alert = await this.alertController.create({header, message, buttons});
        alert.present();
        return alert.onDidDismiss();
    }

    async showToast(message: any, opts?: IonstackToastOptions): Promise<void> {
        if (!this.popupDisabled) {
            const i18nMessage = opts?.skipTranslate ? message : await this.translateService.awaitGet(message || 'Error', opts?.translateOptions);
            (await this.toastController.create({message: i18nMessage, duration: 3000, ...(opts || {})})).present();
        }
    }
}

export interface AlertButton {
    text: string;
    role?: string;
    cssClass?: string | string[];
    handler?: (value: any) => boolean | void | {
        [key: string]: any;
    };
}

export interface IonstackToastOptions extends ToastOptions {
    skipTranslate?: boolean;
    translateOptions?: any;
}