<ion-item
  *ngIf="route?.path || route?.query; else routeLess"
  [ngClass]="{read: !!notif.readDate}"
  [routerLink]="route.path"
  [queryParams]="route.query"
  [awaitClick]="click"
  [bindAwaitClickTo]="this"
>
  <ng-container [ngTemplateOutlet]="inner"></ng-container>
</ion-item>

<ng-template #routeLess>
  <ion-item [ngClass]="{read: !!notif.readDate, clickable: !notif.readDate, 'ion-activatable': !notif.readDate}" [awaitClick]="click" [bindAwaitClickTo]="this">
    <ng-container [ngTemplateOutlet]="inner"></ng-container>
    <ion-ripple-effect *ngIf="!notif.readDate"></ion-ripple-effect>
  </ion-item>
</ng-template>

<ng-template #inner>
  <ion-img [src]="notif.icon" *ngIf="isImageNotif | call:notif.icon; else iconNotif"></ion-img>
  <div class="inner">
    <ion-icon *ngIf="dismiss.observers.length" name="close" (click)="dismiss.emit(); $event.preventDefault(); $event.stopPropagation()"></ion-icon>
    <div class="ttl">{{ notif.title }}</div>
    <div class="msg">{{ notif.message }}</div>
    <div class="date">{{ notif.creationDate | dateAgo }}</div>
  </div>
</ng-template>

<ng-template #iconNotif>
  <ion-icon [name]="notif.icon"></ion-icon>
</ng-template>