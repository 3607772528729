<div [style.height]="(heightStyle | call:config.height:defaultHeight)" *ngIf="visible && context?.postOptions?.pageVisible !== false" class="file">
  <ng-container *ngIf="file; else noFile" [ngSwitch]="file.fileType">
  
    <ng-container *ngSwitchCase="'IMAGE'">
      <div
        *ngIf="config.imageBlurAround"
        class="blur-around"
        [style.background-image]="backgroundImage | call:file:config.imageVersion:config.defaultDisplayImageUrl"
        [style.height]="(heightStyle | call:config.height) || defaultHeight"
      ></div>
      <ion-img
        [src]="file | filepath:{version: config.imageVersion, defaultPath: config.defaultDisplayImageUrl}"
        [ngClass]="{round: config.roundImage, 'img-blur': config.imageBlurAround}"
        [style.object-fit]="config.imageFit || 'cover'"
        [alt]="alt || file.name"
      ></ion-img>
    </ng-container>
  
    <ng-container
      *ngSwitchCase="'VIDEO'"
      [ngTemplateOutlet]="videoTemplate"
      [ngTemplateOutletContext]="{$implicit: file.extUrl | extVideo:config.videoAutoplay}"
    ></ng-container>
  
    <ng-container
      *ngSwitchCase="'AUDIO'"
      [ngTemplateOutlet]="audioTemplate"
      [ngTemplateOutletContext]="{$implicit: file.extUrl | extAudio:config.audioAutoplay}"
    ></ng-container>
  
    <ng-container *ngSwitchDefault>
      <object width="100%" [height]="config.height || defaultHeight" type="application/pdf" [attr.data]="file | filepath | safe:'resourceUrl'" *ngIf="file.extension === 'pdf'; else defaultTemplate">
        <ng-container [ngTemplateOutlet]="linkTemplate"></ng-container>
      </object>
    </ng-container>

  </ng-container>

  <ng-content></ng-content>
</div>

<ng-template #defaultTemplate>
  <object width="100%" [height]="config.height || defaultHeight" [attr.data]="file | filepath | safe:'resourceUrl'">
    <ng-container [ngTemplateOutlet]="linkTemplate"></ng-container>
  </object>
</ng-template>

<ng-template #noFile>
  <ng-container *ngIf="config.defaultDisplayImageUrl">
    <div
      *ngIf="config.defaultImageBlurAround ?? config.imageBlurAround"
      class="blur-around"
      [style.background-image]="backgroundImage | call:file:config.imageVersion:config.defaultDisplayImageUrl"
      [style.height]="(heightStyle | call:config.height) || defaultHeight"
    ></div>
    <ion-img
      [src]="config.defaultDisplayImageUrl"
      [style.object-fit]="config.defaultImageFit || config.imageFit || 'cover'"
    ></ion-img>
  </ng-container>
</ng-template>

<ng-template #linkTemplate>
  <div class="default-doc">
    <a [href]="file | filepath | safe:'resourceUrl'">
      <ion-icon icon="document"></ion-icon>
      <ion-label>{{ file.name }}</ion-label>
    </a>
  </div>
</ng-template>

<ng-template #videoTemplate let-extVideo>
  <ng-container [ngSwitch]="extVideo?.type">
    <iframe
      *ngSwitchCase="'iframe'"
      width="100%" [height]="config.height || defaultHeight"
      [src]="extVideo.url | safe:'resourceUrl'"
      frameborder="0" allowtransparency="true" allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      class="video-frame"
      [style.height]="config.height || defaultHeight"
      [style.object-fit]="config.videoFit || 'contain'"
    ></iframe>
    <video
      *ngSwitchDefault
      playsinline
      [controls]="!config.hideCtrl"
      [autoplay]="config.videoAutoplay"
      [muted]="config.videoAutoplay"
      [loop]="config.loop"
      [src]="file | filepath | safe:'resourceUrl'"
      [style.height]="config.height || defaultHeight"
      [style.object-fit]="config.videoFit || 'contain'"
    ></video>
  </ng-container>
</ng-template>

<ng-template #audioTemplate let-extAudio>
  <ng-container [ngSwitch]="extAudio?.type">
    <iframe
      *ngSwitchCase="'iframe'"
      width="100%" [height]="config.height || defaultHeight"
      [src]="extAudio.url | safe:'resourceUrl'"
      frameborder="0" allowtransparency="true" allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      class="audio-frame"
      [style.height]="config.height || defaultHeight"
      [style.object-fit]="config.audioFit || 'fit'"
    ></iframe>
    <div *ngSwitchDefault [style.height]="config.height || defaultHeight" class="audio-player">
      <ionstack-audio-player [src]="file | filepath" [autoplay]="config.audioAutoplay">
        <span class="name" *ngIf="file.name && file.name !== 'audio'">{{ file.name }}</span><span class="credit" *ngIf="file.credit">{{ file.credit }}</span>
      </ionstack-audio-player>
    </div>
  </ng-container>
</ng-template>