import { ComponentRef } from '@ionic/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '../translate/translate.service';
import { AlertService } from '../service/alert.service';
import { ContextService } from '../service/context.service';
import { IonstackService, RequestParams } from '../service/ionstack.service';
import { CleanSubscriber } from '../util/subscriber';
import { ModalController } from '@ionic/angular';
import { UpgradePlanProvider } from '../tokens';
import { RouteService } from '../service/route.service';
import { routeToUrl, urlToRoute } from '../model/route';

const EXPIRED_ERROR = 'This session has been expired (possibly due to multiple concurrent logins being attempted as the same user).';

@Injectable({
  providedIn: 'root'
})
export class RedirectController extends CleanSubscriber implements OnDestroy {

  constructor(
    private contextService: ContextService,
    private alertService: AlertService,
    private router: Router,
    private ionstackService: IonstackService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private routeService: RouteService,
    @Optional() @Inject(UpgradePlanProvider) private upgradePlanProvider: ComponentRef,
  ) {
    super();
  }

  init() {
    this.subscribe<HttpErrorResponse>(this.ionstackService.onError, err => this.handleError(err));
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  checkLogged(opts?: {extraSignUpParams?: RequestParams, extraReturnParams?: RequestParams}) {
    if (this.contextService.isCurrentLogged()) {
      return true;
    }
    this.redirectToSignUp(opts);
    return false;
  }

  redirectToSignUp(opts?: {extraSignUpParams?: RequestParams, extraReturnParams?: RequestParams}) {
    const route = this.routeService.getRoute('signUp');
    const next = urlToRoute(this.router.url);
    next.query = {...(next.query ?? {}), ...(opts?.extraReturnParams ?? {})};
    return this.router.navigate(route.path, {
      queryParams: {
        ...(route.query ?? {}),
        next: routeToUrl(next),
        ...(opts?.extraSignUpParams ?? {})
      }
    });
  }

  async openUpgradePlan(componentProps?: {source: 'error' | 'intent'}) {
    if (this.upgradePlanProvider) {
      await (await this.modalController.create({
        component: this.upgradePlanProvider,
        componentProps,
        cssClass: 'large-modal'
      })).present();
    }
  }

  handleError(err: HttpErrorResponse) {
    switch (err.status) {

      case 200:
        if (!err.ok && err.error?.text === EXPIRED_ERROR) {
          this.translateService.awaitGet('Disconnected').then(txt => {
            this.alertService.showError(txt);
            this.contextService.reloadContext().then(() => this.router.navigateByUrl('/'));
          });
        }
        break;

      case 401:
        if (this.upgradePlanProvider && err.error?.extras?.can_upgrade_plan) {
          err.error.extras.skip_toast_message = true;
          this.openUpgradePlan({source: 'error'});
        }
        break;

      case 403:
        if (!err.error?.extras) {
          console.error('logged out', err);
          this.alertService.showError(err);
          this.contextService.reloadContext().then(() => this.router.navigateByUrl('/'));
        }
        break;
    }
  }

}