import { BrowseFileConfig } from './../browse-file/browse-file.component';
import { OnDestroy, ContentChild, TemplateRef } from '@angular/core';
import { CleanSubscriber, StoredFile, ALL_FILE_TYPES, FILE_TYPE_ICONS, FileType, FormControlComponent, moveArrayControl, FileService, DragAndDropController } from '@adeprez/ionstack';
import { EditFileConfig } from './../edit-file/edit-file.component';
import { NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Component, forwardRef, EventEmitter, Output, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnInit } from '@angular/core';

export interface EditFileGalleryConfig extends EditFileConfig {
  defaultNameGetter?: (files: StoredFile[]) => string;
  defaultCreditGetter?: (files: StoredFile[]) => string;
  disallowMultiupload?: boolean;
  addButtonColor?: string;
  newFileOpenPopup?: boolean;
}

interface Upload {
  file?: File;
  type: FileType;
  extension: string;
}

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditFileGalleryComponent),
    multi: true
  }],
  selector: 'ionstack-edit-file-gallery',
  templateUrl: './edit-file-gallery.component.html',
  styleUrls: ['./edit-file-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFileGalleryComponent extends FormControlComponent<number[]> implements OnInit, OnDestroy {
  @Output() valueChange = new EventEmitter<number[]>();
  @ContentChild('before', {static: false}) before: TemplateRef<any>;
  @ContentChild('after', {static: false}) after: TemplateRef<any>;
  @Input() config: EditFileGalleryConfig = {};
  @Input() visible = true;
  @Input() isDisabled: boolean;
  uploading: Upload[] = [];
  array: UntypedFormArray;
  cacheBuster = 0;
  dragging = false;
  draggingIndex: number;
  private sub = new CleanSubscriber();
  readonly allTypes = ALL_FILE_TYPES;
  readonly icons = FILE_TYPE_ICONS;
  private _filesById: {[id: number]: StoredFile} = {};

  readonly getBrowseConfig: (config: EditFileGalleryConfig) => BrowseFileConfig = config => ({
    ...config,
    buttonColor: config.addButtonColor,
    defaultNameGetter: () => config?.defaultNameGetter?.(this.files),
    defaultCreditGetter: () => config?.defaultCreditGetter?.(this.files),
  });

  constructor(
    public cd: ChangeDetectorRef,
    private fileSevice: FileService,
    private dragAndDropController: DragAndDropController,
  ) {
    super();
    this.array = new UntypedFormArray([]);
  }

  ngOnInit() {
    this.sub.subscribe(this.dragAndDropController.onDrag, () => {
      this.dragging = true;
      this.cd.markForCheck();
    });
    this.sub.subscribe(this.dragAndDropController.onDragStop, () => {
      this.dragging = false;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribeAll();
  }

  setDraggingIndex(index: number) {
    this.draggingIndex = index;
    this.cd.markForCheck();
  }

  reorder(fromIndex: number, toIndex: number) {
    moveArrayControl(this.array, fromIndex, toIndex);
    this.setValue([...this.array.value]);
    this.cd.markForCheck();
  }

  readonly getFile = (id: number) => {
    if (id in this._filesById) {
      return this._filesById[id];
    }
    this.fileSevice.getFile(id).then(file => {
      this._filesById[id] = file;
      this.cacheBuster++;
      this.cd.markForCheck();
    });
    return null;
  }

  changed(id: number, index: number) {
    if (id == null) {
      this.setValue(this.value.filter((_, i) => i !== index));
    } else {
      this.setValue(this.value.map((v, i) => i === index ? id : v));
    }
  }

  @Input() set value(value: number[]) {
    this.array = new UntypedFormArray((value || []).filter(id => id != null).map(id => new UntypedFormControl(id)));
    this.cd.markForCheck();
  }

  get value() {
    return this.array.value;
  }

  @Input() set files(files: StoredFile[]) {
    if (files) {
      for (const file of files) {
        this._filesById[file.id] = file;
      }
    }
  }

  get files() {
    return Object.values(this._filesById);
  }

  addFile(file: StoredFile) {
    this._filesById[file.id] = file;
    this.setValue(this.value.concat(file.id));
  }

}
