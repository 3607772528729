import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, InjectionToken, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { randomString, DefaultWidgetContainer, Widget, WidgetData, getFormFieldValidators, SimpleFormField, CleanSubscriber } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';
import { WidgetParentForm, WIDGET_PARENT_FORM } from '../../form/base-form-widget';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

export interface AccordionParent {
  open(index: number): void;
}

export const AccordionParent = new InjectionToken<AccordionParent>('AccordionParent');

@Component({
  selector: 'ionstack-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: WIDGET_PARENT_FORM,
    useExisting: forwardRef(() => AccordionComponent)
  }],
})
@Widget({
  widgetID: 'accordion',
  widgetName: 'Accordion',
  group: 'Container',
  icon: 'chevron-down',
  widgetDescription: 'Expandable container',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    {name: 'value', label: 'Section name value', value: () => randomString(4)},
    {name: 'next', label: 'Next button label', value: 'Next', optional: true},
  ],
  newInstance: {
    children: [
      {type: 'text', slot: 'header', content: 'Accordion'},
    ]
  }
})
export class AccordionComponent extends DefaultWidgetContainer implements WidgetParentForm, OnDestroy {
  @Input() value = randomString(4);
  @Input() next: string;
  controls: UntypedFormControl[] = [];
  controlsInvalid: boolean;
  private sub = new CleanSubscriber();

  readonly hasStartSlot = (editing: boolean, children: WidgetData[]) => editing || children.find(c => c.slot === 'start');
  readonly hasNext = (form: WidgetParentForm, next: string, index: number, siblings: number) => form && next && index < siblings - 1;

  constructor(
    @SkipSelf() @Optional() @Inject(WIDGET_PARENT_FORM) public form: WidgetParentForm,
    @Optional() @Inject(AccordionParent) private accordionParent: AccordionParent,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribeAll();
  }

  get willSave(): Observable<any> {
    return this.form?.willSave;
  }
  
  get formValid() {
    return !this.form || this.form.formValid;
  }

  get statusChanges() {
    return this.form?.statusChanges;
  }

  removeControl(field: SimpleFormField, control: UntypedFormControl) {
    this.form?.removeControl(field, control);
    this.controls = this.controls.filter(c => c !== control);
    if (field.name) {
      this.sub.unsubscribe(field.name);
      this.updateStatus();
    }
  }

  createControl(field: SimpleFormField): UntypedFormControl {
    const c = this.form?.createControl(field) ?? new UntypedFormControl(field.value, getFormFieldValidators(field));
    if (field.name) {
      this.sub.subscribe(c.statusChanges, () => this.updateStatus(), {name: field.name, replace: true});
      this.controls = [...this.controls, c];
      this.updateStatus();
    }
    return c;
  }

  updateStatus() {
    this.controlsInvalid = !!this.controls.find(c => c.invalid);
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  nextSection() {
    this.accordionParent.open(this.params.holder.index + 1)
  }

}
