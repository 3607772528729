import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { TranslateService } from '../translate/translate.service';
import { IonstackModuleConfig, MODULE_CONFIG } from '../ionstack.config';
import { FileType, StoredFile } from '../model/files';
import { FileService } from '../service/file.service';
import { CleanSubscriber } from '../util/subscriber';
import { filterNotNull } from '../util/util';

@Injectable({
  providedIn: 'root'
})
export class SeoController extends CleanSubscriber implements OnDestroy {
  private jsonLdTag: HTMLScriptElement;
  private canonicalTag: HTMLLinkElement;
  private canonicalTagRemoved = false;
  private jsonLdTagRemoved = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    private translateService: TranslateService,
    private fileService: FileService,
    private meta: Meta,
  ) {
    super();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  setTag(name: string, content: string, translate = true) {
    const selector = 'name="' + name + '"';
    if (content) {
      if (translate && content) {
        this.subscribe<string>(
          this.translateService.get(content),
          value => this.meta.updateTag({name, property: name, content: value}, selector),
          {name: name, replace: true}
        );
      } else {
        this.meta.updateTag({name, property: name, content}, selector);
      }
    } else {
      this.meta.removeTag(selector);
    }
  }

  setImages(images: (string | StoredFile)[], version?: string) {
    const tags: MetaDefinition[] = (images || []).filter(img => img && (typeof img === 'string' || img?.fileType === FileType.IMAGE)).map(img => ({
      name: 'og:image',
      property: 'og:image',
      content: typeof img === 'string' ? img : this.fileService.getPath(img, {version, extension: 'jpeg', absolute: true})
    }));
    this.meta.removeTag('name="og:image"');
    this.meta.addTags(tags, true);
    if (tags.length) {
      this.setTag('twitter:card', 'summary_large_image', false);
      this.setTag('twitter:image', tags[0].content, false);
    } else {
      this.setTag('twitter:card', 'summary', false);
      this.setTag('twitter:image', null, false);
    }
  }

  /** https://ogp.me/#types */
  setType(type: string) {
    this.setTag('og:type', type, false);
  }

  setDescription(description: string, translate = true) {
    this.setTag('description', description, translate);
    this.setTag('og:description', description, translate);
  }

  getCanonicalTag() {
    if (!this.canonicalTag) {
      const existing = this.document.head.querySelectorAll('[rel="canonical"]')[0];
      if (existing) {
        this.canonicalTag = existing;
      } else {
        this.canonicalTag = this.document.createElement('link');
        this.canonicalTag.setAttribute('rel', 'canonical');
        this.document.head.appendChild(this.canonicalTag);
      }
    } else if (this.canonicalTagRemoved) {
      this.document.head.appendChild(this.canonicalTag);
    }
    return this.canonicalTag;
  }

  getJsonLdTag() {
    if (!this.jsonLdTag) {
      const existing = this.document.head.querySelectorAll('script[type="application/ld+json"]')[0];
      if (existing) {
        this.jsonLdTag = existing;
      } else {
        this.jsonLdTag = this.document.createElement('script');
        this.jsonLdTag.setAttribute('type', 'application/ld+json');
        this.document.head.appendChild(this.jsonLdTag);
      }
    } else if (this.jsonLdTagRemoved) {
      this.document.head.appendChild(this.jsonLdTag);
    }
    return this.jsonLdTag;
  }

  setCanonicalUrl(url: string | (string | number)[], root?: string) {
    if (root == null) {
      root = this.ionstackModuleConfig.frontBaseUrl;
    }
    if (url?.length) {
      let path = Array.isArray(url) ? url.join('/') : url;
      if (path.startsWith('http')) {
        root = '';
      } else if (path.length && path[0] !== '/') {
        path = '/' + path;
      }
      const href = root + path;
      this.getCanonicalTag().setAttribute('href', href);
      this.setTag('og:url', href, false);
    } else {
      this.removeCanonicalUrl();
      this.setTag('og:url', null, false);
    }
  }

  /** https://schema.org/docs/full.html */
  setJsonLd(type: string, config: any, context = 'https://schema.org/') {
    if (config) {
      this.getJsonLdTag().textContent = JSON.stringify({'@context': context, '@type': type, ...filterNotNull(config)});
    } else {
      this.removeJsonLd();
    }
  }

  removeCanonicalUrl() {
    if (this.canonicalTag && !this.canonicalTagRemoved) {
      this.document.head.removeChild(this.canonicalTag);
      this.canonicalTagRemoved = true;
    }
  }

  removeJsonLd() {
    if (this.jsonLdTag && !this.jsonLdTagRemoved) {
      this.document.head.removeChild(this.jsonLdTag);
      this.jsonLdTagRemoved = true;
    }
  }

}