import { AppControllerState } from '../model/state';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { PermissionService } from '../service/permission.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanLoad {

  constructor(
    private permissionService: PermissionService,
    private router: Router,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, snap: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.hasPermissions(next.data, {next, snap});
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
    return this.hasPermissions(route.data, {route, segments});
  }

  private async hasPermissions<R = any>(data: AppControllerState, redirectFnArg: R): Promise<boolean | UrlTree> {
    let permMap = data?.permissions;
    if (!permMap || Object.keys(permMap).length === 0) {
      return true;
    }
    await this.permissionService.ready;
    for (const permStr of Object.keys(permMap)) {
      if (this.permissionService.canAllSplit(permStr)) {
        let redirect = permMap[permStr];
        if (!Array.isArray(redirect)) {
          const path = '' + (typeof redirect === 'function' ? redirect(redirectFnArg) : redirect);
          const segments = path.split('/');
          if (path.startsWith('/') && segments.length) {
            if (segments.length > 1 && segments[0] === '') {
              segments.shift();
            }
            segments[0] = '/' + segments[0];
          }
          redirect = segments;
        }
        return this.router.createUrlTree(redirect);
      }
    }
    return true;
  }

}
