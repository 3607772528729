<a [href]="route" *ngIf="isFullUrl | call:route; else routeTpl">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>

<ng-template #routeTpl>
  <a [routerLink]="route" [awaitClick]="getClickAction | call:action">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
  <ionstack-widget-children
    *ngIf="params"
    [value]="children"
    [parent]="params.holder"
    [context]="params.context"
  ></ionstack-widget-children>
</ng-template>