import { WidgetParams, consumeWidgetContent, Widget, WidgetableComponent, WidgetData } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'grid',
  widgetName: 'Grid',
  group: 'Container',
  icon: 'grid-outline',
  widgetDescription: 'Responsive grid container with /12 unit columns',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    {name: 'size', label: 'Mobile column size', type: 'number', value: 12, min: 1, max: 12},
    {name: 'sizeSm', label: 'Tablet column size', type: 'number', value: 6, min: 1, max: 12},
    {name: 'sizeMd', label: 'Desktop column size', type: 'number', value: 4, min: 1, max: 12},
    {name: 'pattern', label: 'Grid pattern (deprecated)', type: 'multiline-text', optional: true},
  ],
  childrenFields: [
    {name: 'size', label: 'Mobile size', type: 'number', min: 1, max: 12, optional: true},
    {name: 'sizeSm', label: 'Tablet size', type: 'number', min: 1, max: 12, optional: true},
    {name: 'sizeMd', label: 'Desktop size', type: 'number', min: 1, max: 12, optional: true},
    {name: 'pullSm', label: 'Tablet offset', type: 'number', min: 1, max: 12, optional: true},
    {name: 'pullMd', label: 'Desktop offset', type: 'number', min: 1, max: 12, optional: true},
  ]
})
export class GridComponent implements WidgetableComponent {
  @Input() children: WidgetData[] = [];
  @Input() size = 12;
  @Input() sizeSm = 6;
  @Input() sizeMd = 4;
  pattern: string;
  parsedPattern: number[][];
  params: WidgetParams;

  readonly defaultGetters = [
    () => this.size,
    () => this.sizeSm,
    () => this.sizeMd,
    () => 0,
    () => 0,
  ];

  readonly propGetter = (provided: number, index: number, prop: number) => provided ?? this.parsedPattern?.[index]?.[prop] ?? this.defaultGetters[prop]();

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data);
    this.params = params;
    this.children = params.data.children;
    this.parsedPattern = [];
    if (this.pattern) {
      for (const cellPattern of this.pattern.split('\n')) {
        this.parsedPattern.push(cellPattern.split(/[\/\s,;]/g).map(s => +s));
      }
    }
  }

}
