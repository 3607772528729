import { TranslateService, UserPlan, PaymentRecurrence, SubscriptionService } from '@adeprez/ionstack';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ionstack-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscribeModalComponent implements OnInit {
  @Input() source: 'error' | 'intent' = 'intent';
  selectedPlan: UserPlan;
  plans: UserPlan[] = [];
  form: UntypedFormGroup;
  subscribed: boolean;
  active: string[];
  freeDays: number;
  paymentStep: boolean;
  readonly recurrences = PaymentRecurrence;
  readonly getPrice = (plan: UserPlan, r: PaymentRecurrence) => r === PaymentRecurrence.monthly ? plan.monthlyPrice : plan.yearlyPrice;
  readonly getPlanI18nKey = (plan: UserPlan) => 'plan.' + plan.name + '-title';

  constructor(
    private subscriptionService: SubscriptionService,
    private modalController: ModalController,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    formBuilder: UntypedFormBuilder,
  ) {
    this.form = formBuilder.group({
      recurrence: [, Validators.required],
      userPlan: [],
      paymentMethodId: [, Validators.required],
    });
  }

  async ngOnInit() {
    this.plans = await this.subscriptionService.getPlans();
    this.cd.markForCheck();
    this.active = await this.subscriptionService.getActivePlans();
    this.cd.markForCheck();
  }

  paymentSet() {
    this.paymentStep = false;
    this.cd.markForCheck();
  }

  resetPlan() {
    return this.setPlan(null);
  }

  async setPlan(plan: UserPlan) {
    this.form.get('userPlan').setValue(plan?.name);
    if (plan?.monthlyPrice != null) {
      this.form.get('recurrence').setValue(PaymentRecurrence.monthly);
    } else if (plan?.yearlyPrice != null) {
      this.form.get('recurrence').setValue(PaymentRecurrence.yearly);
    } else {
      this.form.get('recurrence').reset();
    }
    if (plan?.trialDays) {
      this.freeDays = await this.subscriptionService.getPlanFreeDays(plan.name);
    }
    const pmi = this.form.get('paymentMethodId');
    if (this.freeDays) {
      pmi.clearValidators();
      this.paymentStep = plan && this.active && this.active.includes(plan.name);
    } else {
      pmi.setValidators(Validators.required);
      this.paymentStep = true;
    }
    pmi.updateValueAndValidity();
    this.selectedPlan = plan;
    this.cd.markForCheck();
  }

  close() {
    return this.modalController.dismiss();
  }

  async startSubscription() {
    await this.subscriptionService.startSubscription({
      ...this.form.value,
      description: await this.translateService.awaitGet(this.getPlanI18nKey(this.selectedPlan))
    });
    this.subscribed = true;
    this.cd.markForCheck();
  }

}
