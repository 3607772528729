<div *ngIf="computedMenu" [ngClass]="[dir, styleClass]">
  <ion-tab-bar *ngIf="dir === 'tab-bar'; else listMenu">
    <ng-container *ngFor="let menuGroup of getGroups()">
      <ng-container *ngIf="!menuGroup.visibleArg || menuGroup.visibleArg(clickArg)">
        <ng-container *ngFor="let item of menuGroup.items; let i = index">
          <ng-container *ngIf="isItemVisible(item, clickArg, item.notifType ? (item.notifType | notifCount) : 0)">
            <ion-tab-button *ngIf="item.url && !(isExtLink | call:item.url); else noroute"
              routerLinkActive="tab-selected"
              [routerDirection]="getRouterDirection | call:item:router.url"
              [routerLink]="getUrl | call:item:router.url"
              [routerLinkActiveOptions]="{exact: item.selectedExactRoute}"
              [attr.class]="item.styleClass"
              [awaitClick]="getClicker | call:item"
              [bindAwaitClickTo]="this"
            >
              <ion-icon [color]="item.iconColor || item.color" [icon]="item.icon" *ngIf="item.icon"></ion-icon>
              <ng-template [ngTemplateOutlet]="itemLabel" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
              <ion-badge *ngIf="item.notifType | notifCount as n" color="danger" [@InOutHorizontal]>{{ n }}</ion-badge>
            </ion-tab-button>
            <ng-template #noroute>
              <ion-tab-button
                [attr.class]="item.styleClass" 
                [awaitClick]="getClicker | call:item"
                [bindAwaitClickTo]="this"
                class="noroute ion-activatable"
              >
                <ion-ripple-effect></ion-ripple-effect>
                <ion-icon [color]="item.iconColor || item.color" [icon]="item.icon" *ngIf="item.icon"></ion-icon>
                <ng-template [ngTemplateOutlet]="itemLabel" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                <ion-badge *ngIf="item.notifType | notifCount as n" color="danger" [@InOutHorizontal]>{{ n }}</ion-badge>
              </ion-tab-button>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ion-tab-bar>
</div>

<ng-template #listMenu>
  <ng-container *ngFor="let menuGroup of getGroups(); let i = index">
    <ng-container *ngIf="!menuGroup.visibleArg || menuGroup.visibleArg(clickArg)">
      <ion-list [ngClass]="listStyleClass" class="ion-no-padding">
        <ion-list-header *ngIf="dir === 'vertical' && menuGroup.title">
          <ion-label [color]="menuGroup.color" [ngClass]="{i18n: menuGroup.translate}">
            <ng-container *ngIf="menuGroup.translate; else title">
              <itxt [key]="menuGroup.title"></itxt>
            </ng-container>
            <ng-template #title>{{ menuGroup.title }}</ng-template>
          </ion-label>
        </ion-list-header>
        <div *ngIf="menuGroup.separator && i > 0" class="menu-divider"></div>
  
        <ng-container *ngFor="let item of menuGroup.items; let i = index">
          <ng-container *ngIf="isItemVisible(item, clickArg, item.notifType ? (item.notifType | notifCount) : 0)">
            <ion-item *ngIf="item.url && !(isExtLink | call:item.url); else noroute"
              routerLinkActive="active"
              lines="none"
              [routerDirection]="getRouterDirection | call:item:router.url"
              [detail]="false"
              [routerLink]="getUrl | call:item:router.url"
              [routerLinkActiveOptions]="{exact: item.selectedExactRoute}"
              [ngClass]="{iconless: !item.icon, labelless: !item.title}"
              [attr.class]="item.styleClass"
              [awaitClick]="getClicker | call:item"
              [bindAwaitClickTo]="this"
            >
              <ion-icon slot="start" [color]="item.iconColor || item.color" [icon]="item.icon" *ngIf="item.icon"></ion-icon>
              <ng-template [ngTemplateOutlet]="itemLabel" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
              <ion-badge *ngIf="item.notifType | notifCount as n" color="danger" slot="end" [@InOutHorizontal]>{{ n }}</ion-badge>
            </ion-item>
            <ng-template #noroute>
              <ion-item
                [ngClass]="{iconless: !item.icon, labelless: !item.title, noroute: true, 'ion-activatable': true}"
                [attr.class]="item.styleClass" lines="none" [detail]="false"
                [awaitClick]="getClicker | call:item"
                [bindAwaitClickTo]="this"
              >
                <ion-ripple-effect></ion-ripple-effect>
                <ion-icon slot="start" [color]="item.iconColor || item.color" [icon]="item.icon" *ngIf="item.icon"></ion-icon>
                <ng-template [ngTemplateOutlet]="itemLabel" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                <ion-badge *ngIf="item.notifType | notifCount as n" color="danger" slot="end" [@InOutHorizontal]>{{ n }}</ion-badge>
              </ion-item>
            </ng-template>
          </ng-container>
        </ng-container>
      </ion-list>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-item #itemLabel>
  <ion-label *ngIf="item.title" [color]="item.color" [ngClass]="{i18n: item.translate}">
    <ng-container *ngIf="item.translate; else title">
      <itxt [key]="item.title"></itxt>
    </ng-container>
    <ng-template #title>{{ item.title }}</ng-template>
  </ion-label>
</ng-template>