import { Context } from '../model/context';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { User } from '../model/user';
import { IonstackService } from './ionstack.service';
import { StorageService } from './storage.service';
import { Capacitor } from '@capacitor/core';
import { UserAgent } from '@adeprez/capacitor-user-agent';

@Injectable({
  providedIn: 'root'
})
export class ContextService implements OnDestroy {
  private _context: Observable<Context>;
  private currentContext: Context;
  private loadTrigger = new EventEmitter<Context>();
  private loading = false;
  private isCustomUa = true;
  readonly user = new ReplaySubject<User>(1);
  readonly contextUpdate = new EventEmitter<Context>();
  readonly isCookieLess: boolean;
  readonly useLocalContext: boolean;
  currentLangague: string;

  constructor(
    private ionstackService: IonstackService,
    private storageService: StorageService,
  ) {
    this.currentLangague = ionstackService.getModuleConfig().defaultLanguage || 'en';
    this.useLocalContext = Capacitor.isNativePlatform();
    this.isCookieLess = Capacitor.isNativePlatform();
    this._context = new Observable<Context>(observer => {
      const susbcription = this.loadTrigger.subscribe(
        (context: Context) => observer.next(context),
        (err: any) => observer.error(err)
      );
      if (this.currentContext) {
        observer.next(this.currentContext);
      } else if (!this.loading) {
        this.requestContext();
      }
      return susbcription;
    });
  }

  ngOnDestroy() {
    this.loadTrigger.observers.forEach(o => o.complete());
  }

  getCurrentContext() {
    return this.currentContext;
  }

  isCurrentLogged() {
    return this.currentContext?.account;
  }

  getContextUpdatedPromise(): Promise<Context> {
    return new Promise<Context>(resolve => {
      const sub = this.contextUpdate.subscribe(ctx => {
        resolve(ctx);
        sub.unsubscribe();
      })
    });
  }

  async getContextPromise(): Promise<Context> {
    if (this.currentContext) {
      return this.currentContext;
    }
    return this.context.toPromise();
  }

  async isLogged() {
    return (await this.getContextPromise()).account != null;
  }

  async onError(error: any) {
    if (this.ionstackService.isBrowser()) {
      console.error('error requesting context', error?.message || error);
    }
    this.loadTrigger.error(error);
  }

  async dropContext() {
    this.ionstackService.dropState<Context>('context');
    this.storageService.remove('app-context');
    this.currentContext = null;
    if (this.isCookieLess) {
      await UserAgent.reset();
    }
    await this.requestContext();
    return this.currentContext;
  }

  async reloadContext() {
    if (!this.loading) {
      this.ionstackService.dropState<Context>('context');
      await this.requestContext();
    }
    return this._context;
  }

  async reloadAccountContext() {
    try {
      const update = await this.ionstackService.get<User>('/public/context/account', {
        params: this.getRequestContextParams()
      });
      await this.setContext({...this.currentContext, ...update});
    } catch (error) {
      this.onError(error);
    }
  }

  private async requestContext() {
    this.loading = true;
    try {
      let context: Context;
      if (!this.currentContext && this.useLocalContext) {
        context = await this.storageService.get<Context>('app-context');
      }
      context ||= await this.ionstackService.get<Context>('/public/context', {
        cache: 'cache', stateKey: 'context', params: this.getRequestContextParams()
      });
      this.loading = false;
      await this.setContext(context);
    } catch (error) {
      this.loading = false;
      this.onError(error);
    }
  }

  getRequestContextParams() {
    if (this.isCookieLess) {
      return {get_token: true};
    }
    return {};
  }

  async updateUserAgent(context: Context) {
    try {
      if (context.token) {
        // Instead of a cookie, we use 'User-Agent' header to pass auth token (needed for media fetch)
        const ua = (await UserAgent.get()).userAgent.replace(/;\s+Authorization: Bearer .*$/, '');
        await UserAgent.set({userAgent: ua + '; Authorization: Bearer ' + context.token});
        this.isCustomUa = true;
      } else if (this.isCustomUa) {
        await UserAgent.reset();
        this.isCustomUa = false;
      }
    } catch (e) {
      console.error('failed to update user agent', e?.error?.message || e?.error || e);
    }
  }

  async setContext(context: Context) {
    if (this.useLocalContext) {
      await this.storageService.set('app-context', context);
    }
    if (this.isCookieLess) {
      await this.updateUserAgent(context);
    }
    const lastUserId = this.currentContext?.account?.id;
    const isUpdate = !!this.currentContext;
    this.currentContext = context;
    this.currentLangague = context.userLanguage || this.ionstackService.getModuleConfig().defaultLanguage || 'en';
    this.loadTrigger.emit(this.currentContext);
    if (isUpdate) {
      this.contextUpdate.emit(context);
    }
    if (lastUserId !== context?.account?.id) {
      this.user.next(context.account);
    }
  }

  get context(): Observable<Context> {
    return this._context;
  }

}