import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Directive, EventEmitter, forwardRef, HostBinding, Inject, Input, Optional, Output, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  EditWidgetController, AlertService, WidgetService, WidgetData, WidgetDropTarget, supportsWidgetChild, WidgetParent, ParentWidgetHolder,
  WidgetContext, WidgetChildrenHolder, WidgetEditorRoot, parseWidgetParentProps,
} from '@adeprez/ionstack';

@Directive({
  selector: 'ng-template[widgetWrapper]',
})
export class WidgetWrapperDirective {
  static ngTemplateContextGuard(_: WidgetWrapperDirective, context: unknown): context is {
    template: TemplateRef<WidgetWrapperDirective>,
    templateContext: {widget: WidgetData, index: number, count: number},
    parentProps: any,
  } {
    return true;
  }
}

@Directive({
  selector: 'ng-template[childrenWrapper]',
})
export class ChildrenWrapperDirective {
  static ngTemplateContextGuard(_: WidgetWrapperDirective, context: unknown): context is {
    childrenTemplate: TemplateRef<WidgetWrapperDirective>
  } {
    return true;
  }
}

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WidgetChildrenComponent),
    multi: true
  }],
  selector: 'ionstack-widget-children',
  templateUrl: './widget-children.component.html',
  styleUrls: ['./widget-children.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetChildrenComponent extends WidgetChildrenHolder implements WidgetDropTarget, ParentWidgetHolder {
  @ContentChild(ChildrenWrapperDirective, {read: TemplateRef}) childrenWrapper: TemplateRef<ChildrenWrapperDirective>;
  @ContentChild(WidgetWrapperDirective, {read: TemplateRef}) widgetWrapper: TemplateRef<WidgetWrapperDirective>;
  @Output() valueChange = new EventEmitter<WidgetData[]>();
  @HostBinding('class.layout') layoutMode = false;
  @Input() isDisabled: boolean;
  @Input() defaultChildSupport: 'all' | string[] = 'all';
  @Input() parent: WidgetParent;
  displayed: WidgetData[] = [];
  private _slotted: string;
  private _value: WidgetData[] = [];
  private _context: WidgetContext;

  readonly parseWidgetParentProps = parseWidgetParentProps;
  readonly getWidgetName = (type: string) => this.widgetService.getWidget(type).meta.widgetName;
  readonly getDropZone = (target: WidgetDropTarget) => target.childSupport?.length ? target : null;
  readonly getTargetAcceptor = (widget: WidgetData) => (target: WidgetDropTarget) => supportsWidgetChild(target?.childSupport, widget.type);
  private readonly displayedGetter = (widget: WidgetData, slotted: string) => widget.slot === slotted;
  
  readonly modelIndexGetter = (slotted: string, displayIndex: number, value: WidgetData[]) => {
    if (!slotted) {
      return displayIndex;
    }
    for (let i = 0, dIndex = -1; i < value.length; i++) {
      if (this.displayedGetter(value[i], slotted)) {
        dIndex++;
      }
      if (dIndex === displayIndex) {
        return i;
      }
    }
    return -1;  
  };

  constructor(
    private widgetService: WidgetService,
    private editWidgetController: EditWidgetController,
    private alertService: AlertService,
    public cd: ChangeDetectorRef,
    @Inject(WidgetEditorRoot) @Optional() private widgetEditorRoot: WidgetEditorRoot,
  ) {
    super();
  }

  private readonly isDisplayed = (widget: WidgetData) => {
    return widget.slot === this._slotted;
  }

  @Input() set slotted(slotted: string) {
    this._slotted = slotted;
    this.computeDisplayed();
  }

  get slotted() {
    return this._slotted;
  }

  @Input() set value(value: WidgetData[]) {
    this._value = value;
    this.computeDisplayed();
  }

  get value() {
    return this._value;
  }

  get childSupport() {
    return this.parent ? this.parent.childSupport : this.defaultChildSupport;
  }

  get slots() {
    return this.parent?.slots;
  }

  @Input() set context(context: WidgetContext) {
    this._context = context;
    this.layoutMode = context?.postOptions?.layoutMode;
  }

  get context() {
    return this._context;
  }

  private computeDisplayed() {
    if (this._value) {
      this.displayed = this._slotted ? this._value.filter(w => this.isDisplayed(w)) : this._value;
    } else {
      this.displayed = [];
    }
  }

  getModelIndex(displayIndex: number) {
    if (!this.slotted) {
      return displayIndex;
    }
    for (let i = 0, dIndex = -1; i < this._value.length; i++) {
      if (this.isDisplayed(this._value[i])) {
        dIndex++;
      }
      if (dIndex === displayIndex) {
        return i;
      }
    }
    return -1;
  }

  setValue(value: WidgetData[]) {
    const changed = super.setValue(value);
    this.cd.markForCheck();
    return changed;
  }

  reorder(details: {from: number, to: number, complete: () => void}) {
    const from = this.getModelIndex(details.from);
    const to = this.getModelIndex(details.to);
    if (from !== to && from >= 0 && to >= 0 && from < this.value.length) {
      const children = [...this.value];
      const tmp = children[from];
      children.splice(from, 1);
      children.splice(to, 0, tmp);
      this.setValue(children);
    }
    details.complete();
  }

  override addChild(widget: WidgetData, index?: number) {
    if (this.slotted) {
      widget.slot = this.slotted;
    }
    super.addChild(widget, index);
  }

  moveChildInto(widget: WidgetData, index = this._value?.length) {
    this.addChild(widget, index);
  }

  moveOut(widget: WidgetData, index: number) {
    if (this.widgetEditorRoot) {
      this.widgetEditorRoot.deleteWidgetAsync(widget);
    }
  }

  edit(index: number, event: any) {
    const value = this.value[index];
    event.preventDefault();
    event.stopPropagation();
    this.editWidgetController.editWidget({
      infos: this.widgetService.getWidget(value.type),
      widget: value,
      context: this.context,
      parentHolder: this,
      siblings: this.parent?.siblings,
      index,
      container: this,
    }, async data => {
      try {
        this.updateChild(index, {...value, ...data});
      } catch (e) {
        this.alertService.showError(e);
      }
    });
  }

}
