import { Debounce } from '../util/util';
import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { DragAndDropController } from '../controller/dragndrop.controller';

@Directive({
  selector: '[ionstackDrag]'
})
export class DragDirective<T> {
  @HostBinding('draggable') draggable: boolean;
  @HostBinding('style.cursor') cursor: string;
  @Input() dragMoveEffect: 'move' | 'link' | 'copy' = 'move';
  @Output() willDragOut = new EventEmitter<T>();
  @Output() draggedOut = new EventEmitter<T>();
  @Output() dragStart = new EventEmitter<T>();
  @Input() targetAcceptor: (target: any) => boolean;
  private debounce = new Debounce(50);
  private src: T;

  constructor(private dragAndDropController: DragAndDropController) { }

  @Input() set dropTarget(value: any) {
    this.targetAcceptor = (target: any) => target === value;
  }

  @Input() set dropTargetIn(values: any[]) {
    this.targetAcceptor = (target: any) => values.includes(target);
  }

  @Input() set ionstackDrag(src: T) {
    this.src = src;
    this.draggable = src != null && src as any !== false;
    this.cursor = this.draggable ? 'grab' : 'default';
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(evt: DragEvent) {
    evt.stopPropagation();
    this.dragStart.emit(this.src);
    this.debounce.trigger(() => this.dragAndDropController.setSource(
      this.src,
      evt.target as HTMLElement,
      this.targetAcceptor,
      () => this.willDragOut.emit(this.src),
      () => this.draggedOut.emit(this.src)
    ));
    evt.dataTransfer.dropEffect = this.dragMoveEffect;
  }

  @HostListener('dragend')
  onDragEnd() {
    this.dragAndDropController.onDragStop.emit(this.src);
  }

}
