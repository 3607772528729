import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { consumeWidgetContent, Widget, WidgetParams, WidgetableComponent } from '@adeprez/ionstack';
import { IconPopupPickerComponent } from './icon-popup-picker/icon-popup-picker.component';

@Component({
  selector: 'ionstack-icon',
  template: '<ion-icon [name]="name" [color]="color" [size]="size"></ion-icon>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'icon',
  widgetName: 'Icon',
  icon: 'planet',
  contentType: 'json',
  editorPopup: IconPopupPickerComponent,
  contentFields: [
    {name: 'size', label: 'Size', type: 'select', value: 'large', optional: true, options: ['small', 'large']},
    {name: 'color', label: 'Color', optional: true},
  ],
  newInstance: {
    content: '{"name":"add-circle"}'
  }
})
export class IconComponent implements WidgetableComponent {
  @Input() name: string;
  @Input() color: string;
  @Input() size: 'small' | 'large';

  constructor() { }

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data);
  }

}
