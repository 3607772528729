import { ChangeDetectorRef } from '@angular/core';
import {
  WidgetParams, WidgetData, consumeWidgetContent, Widget, ActionService, FormSubmitData, insertFormControl, removeFormControl,
  SimpleFormField, getFormFieldValidators,
} from '@adeprez/ionstack';
import { Input, forwardRef } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: WIDGET_PARENT_FORM,
    useExisting: forwardRef(() => FormContainerComponent)
  }],
})
@Widget({
  widgetID: 'form-cn',
  widgetName: 'Form container',
  group: 'Forms',
  icon: 'clipboard-outline',
  widgetDescription: 'Container for form fields',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    {name: 'action', label: 'Submit action'}
  ],
  newInstance: {
    children: [
      {type: 'button', content: '{"type":"submit"}'}
    ]
  }
})
export class FormContainerComponent implements WidgetParentForm {
  @Input() action: string;
  params: WidgetParams;
  children: WidgetData[] = [];
  formGroup: UntypedFormGroup;
  readonly willSave = new Subject<FormSubmitData>();

  constructor(
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private actionService: ActionService,
  ) { }

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data);
    this.params = params;
    this.children = params.data.children;
    this.formGroup = new UntypedFormGroup({});
  }

  get formValid() {
    return this.formGroup?.valid;
  }

  get statusChanges() {
    return this.formGroup.statusChanges;
  }

  createControl(field: SimpleFormField): UntypedFormControl {
    const control = new UntypedFormControl(field.value, getFormFieldValidators(field));
    if (field.name) {
      insertFormControl(field.name, this.formGroup, control);
      this.formGroup.updateValueAndValidity();
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
    return control;
  }

  removeControl(field: SimpleFormField, control: UntypedFormControl): void {
    if (field.name && this.formGroup.get(field.name) === control) {
      removeFormControl(field.name, this.formGroup, control);
      this.formGroup.updateValueAndValidity();
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  reset() {
    this.formGroup.reset();
  }

  get fields() {
    return this.children;
  }

  async submit() {
    const action = this.actionService.getAction(this.action, null, null);
    if (action) {
      const data: FormSubmitData = {
        routeSnapshot: this.activatedRoute.snapshot,
        data: this.formGroup.value,
        source: this,
      };
      this.willSave.next(data);
      await action(data);
    }
  }

}
