import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatableComponent } from './translatable.component';
import { TranslaterModule } from '../translater/translater.module';
import { WIDGET } from '../../model/widget';
import { EditTranslatableWidgetPopupModule } from '../edit-translatable-widget-popup/edit-translatable-widget-popup.module';
import { TranslateModule } from '../../translate/translate.module';

@NgModule({
  imports: [
    CommonModule,
    TranslaterModule,
    TranslateModule,
    EditTranslatableWidgetPopupModule,
  ],
  declarations: [TranslatableComponent],
  exports: [TranslatableComponent],
  providers: [{ provide: WIDGET, useValue: TranslatableComponent, multi: true}]
})
export class TranslatableModule { }
