import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DefaultWidgetContainer, Widget } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-card',
  template: `
  <ion-card>
    <ionstack-widget-children [value]="children" [parent]="params.holder" [context]="params.context"></ionstack-widget-children>
  </ion-card>
  `,
  styles: ['ion-card { overflow: visible }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'card',
  widgetName: 'Card',
  group: 'Card',
  icon: 'square-outline',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  newInstance: {
    children: [
      {type: 'card-header', children: [{
        type: 'card-title', children: [{type: 'html', content: 'Title'}]
      }]},
      {type: 'card-content'}
    ]
  }
})
export class CardComponent extends DefaultWidgetContainer {}


@Component({
  selector: 'ionstack-card-content',
  template: `
  <ion-card-content>
    <ionstack-widget-children [value]="children" [parent]="params.holder" [context]="params.context"></ionstack-widget-children>
  </ion-card-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'card-content',
  widgetName: 'Card content',
  group: 'Card',
  icon: 'scan',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
})
export class CardContentComponent extends DefaultWidgetContainer {}