import { CleanSubscriber } from '../util/subscriber';
import { TranslateService } from '../translate/translate.service';
import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';

const secondsAgo = 'Just now';
const minute = 'One minute ago';
const minutes = '{{t}} minutes ago';
const hour = 'One hour ago';
const hours = '{{t}} hours ago';

const i18nTexts = [secondsAgo, minute, minutes, hour, hours];

@Pipe({
  name: 'dateAgo',
  pure: false
})
export class DateAgoPipe extends CleanSubscriber implements PipeTransform, OnDestroy {
  private texts: any = {};

  constructor(
    private cd: ChangeDetectorRef,
    translateService: TranslateService,
  ) {
    super();
    this.subscribe(translateService.get(i18nTexts), texts => {
      this.texts = texts;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  transform(value: string | Date, includeHour = false): any {
    if (value) {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return null;
      }
      const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
      if (seconds < 60) {
        return this.texts[secondsAgo];
      }
      if (seconds < 120) {
        return this.texts[minute];
      }
      if (seconds < 3600) {
        return this.texts[minutes].replace('{{t}}', Math.floor(seconds / 60));
      }
      if (seconds < 7200) {
        return this.texts[hour];
      }
      if (seconds < 86400) {
        return this.texts[hours].replace('{{t}}', Math.floor(seconds / 3600));
      }
      if (includeHour) {
        return date.toLocaleString();
      }
      return date.toLocaleDateString();
    }
    return value;
  }

}
