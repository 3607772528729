import { IonstackPipesModule } from '../../pipe/ionstack-pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemsComponent } from './menu-items.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslatableModule } from '../../component/translatable/translatable.module';
import { IonstackDirectivesModule } from '../../directive/ionstack-directives.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    TranslatableModule,
    IonstackPipesModule,
    IonstackDirectivesModule,
  ],
  declarations: [MenuItemsComponent],
  exports: [MenuItemsComponent]
})
export class MenuItemsModule { }
