
//import { FacebookLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

import { InjectionToken, NgModule } from '@angular/core';

export class FacebookLoginProvider {
  static PROVIDER_ID: 'FacebookLoginProvider';
  
  constructor(public clientId: string) { }
};

export class GoogleLoginProvider {
  static PROVIDER_ID: 'GoogleLoginProvider';

  constructor(public clientId: string) { }
};

export type SocialAuthService = {
  signIn(provider: string): Promise<SocialUser>;
};

export type SocialUser = {
  authToken: string;
  idToken: string;
};

export class SocialAuthServiceConfig {}

@NgModule({})
export class SocialLoginModule {};