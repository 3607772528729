import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { filter } from 'rxjs/operators';

export type CRUDEventType = 'create' | 'update' | 'delete' | 'read';

export interface CreateEvent<K, U> {
  value: U;
  key?: K;
  type: 'create';
}

export interface UpdateEvent<K, U> {
  value: U;
  key: K;
  type: 'update';
}

export interface DeleteEvent<K, U> {
  value?: U;
  key: K;
  type: 'delete';
}

export interface ReadEvent<K, U> {
  value: U;
  key: K;
  type: 'read';
}

export type SaveEvent<K, U> = CreateEvent<K, U> | UpdateEvent<K, U>;

export type CRUDEvent<K, U> = SaveEvent<K, U> | DeleteEvent<K, U> | ReadEvent<K, U>;

export function crudSaveEvent<U, K>(value: U, key?: K): CRUDEvent<K, U> {
  return key == null ? {value, type: 'create'} : {value, key, type: 'update'};
}

export class ObservableCRUD<K, U> extends EventEmitter<CRUDEvent<K, U>> {
  public readonly readAll$ = new EventEmitter<U[]>();
  public readonly error$ = new EventEmitter<any>();

  constructor(private keyGetter: (u: U) => K) {
    super();
  }

  public ofTypes(...types: CRUDEventType[]): Observable<CRUDEvent<K, U>> {
    return this.pipe(filter(e => types.includes(e.type)));
  }

  public ofRead(): Observable<ReadEvent<K, U>> {
    return this.ofTypes('read') as Observable<ReadEvent<K, U>>;
  }

  public ofDelete(): Observable<DeleteEvent<K, U>> {
    return this.ofTypes('delete') as Observable<DeleteEvent<K, U>>;
  }

  public ofCreate(): Observable<CreateEvent<K, U>> {
    return this.ofTypes('create') as Observable<CreateEvent<K, U>>;
  }

  public ofUpdate(): Observable<UpdateEvent<K, U>> {
    return this.ofTypes('update') as Observable<UpdateEvent<K, U>>;
  }

  public ofSave(): Observable<SaveEvent<K, U>>  {
    return this.ofTypes('create', 'update') as Observable<SaveEvent<K, U>>;
  }

  public async awaitEmit<T extends U>(promise: Promise<T>, type: CRUDEventType) {
    try {
      const value = await promise;
      this.emit({value, key: this.keyGetter(value), type});
      return value;
    } catch (e) {
      this.error$.emit(e);
      throw e;
    }
  }

  public async create<T extends U>(promise: Promise<T>) {
    return this.awaitEmit<T>(promise, 'create');
  }

  public async update<T extends U>(promise: Promise<T>) {
    return this.awaitEmit<T>(promise, 'update');
  }

  public async updateOrCreate<T extends U>(currentId: K, promise: Promise<T>) {
    return currentId == null ? this.create<T>(promise) : this.update<T>(promise);
  }

  public async updateOrCreateValue<T extends U>(current: T, promise: Promise<T>) {
    return this.updateOrCreate<T>(this.keyGetter(current), promise);
  }

  public async read<T extends U>(promise: Promise<T>) {
    return this.awaitEmit<T>(promise, 'read');
  }

  public async deleteValue<T = void>(u: U, promise: Promise<T>) {
    return this.delete<T>(this.keyGetter(u), promise);
  }

  public async delete<T = void>(key: K, promise: Promise<T>) {
    try {
      const value = await promise;
      this.emit({key, type: 'delete'});
      return value;
    } catch (e) {
      this.error$.emit(e);
      throw e;
    }
  }

  public async readAll(promise: Promise<U[]>) {
    try {
      const values = await promise;
      this.readAll$.emit(values);
      for (const value of values) {
        this.emit({value, key: this.keyGetter(value), type: 'read'});
      }
      return values;
    } catch (e) {
      this.error$.emit(e);
      throw e;
    }
  }

}