import { ChangeDetectorRef, Directive, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { DragAndDropController } from '../controller/dragndrop.controller';
import { CleanSubscriber } from '../util/subscriber';

@Directive({
  selector: '[ionstackDrop]'
})
export class DropDirective<T, S> extends CleanSubscriber implements OnDestroy {
  @Input() ionstackDrop: S;
  @HostBinding('class.dragover') isDragOver: boolean;
  @HostBinding('class.dropable') dropable: boolean;
  @Output() droppedIn = new EventEmitter<T>();
  @Output() dragOverChange = new EventEmitter<boolean>();
  @Input() sourceAcceptor: (src: T) => boolean;

  constructor(private dragAndDropController: DragAndDropController, private cd: ChangeDetectorRef) {
    super();
    this.subscribe(dragAndDropController.onDrag, () => this.setDropable(dragAndDropController.accepts(this.ionstackDrop, null, this.sourceAcceptor)));
    this.subscribe(dragAndDropController.onDragStop, () => this.setDropable(false));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  setDropable(dropable: boolean) {
    if (this.dropable !== dropable) {
      this.dropable = dropable;
      this.cd.markForCheck();
    }
  }

  private setDragOver(isDragOver: boolean) {
    if (this.isDragOver !== isDragOver) {
      this.isDragOver = isDragOver;
      this.cd.markForCheck();
      this.dragOverChange.emit(this.isDragOver);
    }
  }

  @HostListener('dragover', ['$event'])
  dragOver(evt: Event) {
    if (this.isActive(evt)) {
      evt.preventDefault();
      evt.stopPropagation();
      this.setDragOver(true);
    }
  }

  @HostListener('dragleave')
  dragOverLeave() {
    this.setDragOver(false);
  }

  @HostListener('drop', ['$event'])
  onDrop(evt: Event) {
    this.setDragOver(false);
    if (this.isActive(evt)) {
      evt.preventDefault();
      evt.stopPropagation();
      this.dragAndDropController.consumeSource(src => this.droppedIn.emit(src));
    }
  }

  private isActive(evt?: Event) {
    return this.ionstackDrop && this.dragAndDropController.accepts(this.ionstackDrop, evt?.target as HTMLElement, this.sourceAcceptor);
  }

}
