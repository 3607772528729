import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'absoluteRoute',
  pure: true
})
export class AbsoluteRoutePipe implements PipeTransform {

  constructor(private router: Router) {}

  transform(route: string | (string | number)[]): (string | number)[] {
    if (typeof route === 'string') {
      return this.transform(route.split('/').filter(r => r));
    }
    if (!route || (route?.length && typeof route[0] === 'string' && route[0].startsWith('/'))) {
      return route;
    }
    const path: (number | string)[] = this.router.url.split('?')[0].split('/');
    for (const r of route) {
      if (r === '..') {
        path.pop();
      } else {
        path.push(r);
      }
    }
    path.shift();
    path.unshift('/');
    return path;
  }

}
