import { Attribute, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { AnimationBuilder, RouterDirection } from '@ionic/core';
import { RouteService } from '../service/route.service';
import { NavController } from '@ionic/angular';
import { AbsoluteRoutePipe } from '../pipe/absolute-route.pipe';

@Directive({
  selector: '[providedRoute]',
  providers: [AbsoluteRoutePipe]
})
export class ProvidedRouteDirective extends RouterLink implements OnInit, OnChanges {
  @Input() routerDirection: RouterDirection = 'forward';
  @Input() routerAnimation?: AnimationBuilder;
  @Input() defaultRoute?: string;
  private _query?: {[key: string]: any};
  private _name: string;
  private _args: any[];

  constructor(
    private mRouter: Router,
    route: ActivatedRoute,
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined,
    renderer: Renderer2,
    el: ElementRef,
    private locationStrategy: LocationStrategy,
    private navCtrl: NavController,
    private elementRef: ElementRef,
    private routeService: RouteService,
    private absoluteRoutePipe: AbsoluteRoutePipe,
  ) {
    super(mRouter, route, tabIndexAttribute, renderer, el);
  }

  @Input() set providedRoute(config: string | {name: string, args?: any[]}) {
    if (!config) {
      this._name = null;
      this._args = [];
    } else if (typeof config === 'string') {
      this._name = config;
      this._args = [];
    } else {
      this._name = config.name;
      this._args = config.args ?? [];
    }
    this.compute();
  }

  @Input() set args(args: any[]) {
    this._args = args;
    this.compute();
  }

  @Input() set arg(arg: any) {
    this._args = [arg];
    this.compute();
  }

  @Input() set query(query: {[key: string]: any}) {
    this._query = query;
    this.compute();
  }

  ngOnInit(): void {
    this.updateTargetUrlAndHref();
  }

  ngOnChanges(): void {
    this.updateTargetUrlAndHref();
  }

  /**
   * @internal
   */
  @HostListener('click', ['$event'])
  onElementClick(ev: UIEvent): void {
    this.navCtrl.setDirection(this.routerDirection, undefined, undefined, this.routerAnimation);
    ev.preventDefault();
  }

  private updateTargetUrlAndHref() {
    if (this.urlTree) {
      const href = this.locationStrategy.prepareExternalUrl(this.mRouter.serializeUrl(this.urlTree));
      this.elementRef.nativeElement.href = href;
    }
  }

  private compute() {
    const route = this.routeService.getRoute(this._name, ...(this._args ?? []));
    this.routerLink = this.absoluteRoutePipe.transform(route?.path ?? this.defaultRoute);
    this.queryParams = {...(route?.query || {}), ...(this._query || {})};
  }

}
