import { CRUDEvent } from '../util/observable-crud';
import { EventEmitter } from '@angular/core';
import { ParamMap } from '@angular/router';
import { RequestParams } from '../service/ionstack.service';

export const PARAM_LENGTH = '.length';
export const LOAD_ALL_PAGES = 9999;

export interface PageMeta {
  number: number;
  totalElements: number;
}

export interface Page<T> {
  data: T[];
  page: PageMeta
}

export const EMPTY_PAGE: Page<any> = {
  data: [],
  page: {
    number: 0,
    totalElements: 0,
  },
};

export type PageLoader<T> = (request: RequestParams) => Promise<Page<T>>;

export function extractMapParams(paramMap: ParamMap, ...what: string[]) {
  const params = {};
  for (const p of what) {
    params[p] = paramMap.get(p);
  }
  return params;
}

export class RequestParamsBuilder {
  private params: RequestParams = {};
  private changed = false;

  hasChanged() {
    return this.changed;
  }

  clearChanged() {
    this.changed = false;
  }

  setChanged() {
    this.changed = true;
  }

  getParams() {
    return this.params;
  }

  getParamValue<T>(key: string, defaultValue?: T): T {
    const val = this.params[key];
    return val == null ? defaultValue : val;
  }

  removeIfNoLength(key: string) {
    if (!this.params[key].length) {
      delete this.params[key];
      this.setChanged();
    }
  }

  arrayKeepIf(key: string, predicate: (value: any) => boolean) {
    if (this.hasParam(key)) {
      this.params[key] = (this.params[key] as any[]).filter(predicate);
      this.removeIfNoLength(key);
      this.setChanged();
    }
  }

  hasParam(key: string) {
    return key in this.params;
  }

  addParam(key: string, value: any) {
    if (value != null) {
      if (this.hasParam(key)) {
        (this.params[key] as any[]).push(value);
      } else {
        this.params[key] = [value];
      }
      this.setChanged();
      return true;
    }
    return false;
  }

  removeParam(key: string, value?: any) {
    if (this.hasParam(key)) {
      const val = this.params[key];
      if (Array.isArray(val)) {
        this.params[key] = value == null ? [] : (this.params[key] as any[]).filter(v => v !== value);
      } else {
        this.params[key] = [];
      }
      this.removeIfNoLength(key);
      this.setChanged();
      return true;
    }
    return false;
  }

  isParamDifferent(key: string, value: any) {
    const existing = this.params[key];
    if (existing == null && value == null) {
      return false;
    }
    if ((existing == null && value != null) || (existing != null && value == null)) {
      return true;
    }
    if (Array.isArray(existing) && Array.isArray(value)) {
      return existing !== value && existing.length !== value.length || existing.some((v, i) => v !== value[i]);
    }
    return existing !== value;
  }

  setParam(key: string, value: any, markAsChanged = true) {
    if (value == null) {
      if (key in this.params) {
        delete this.params[key];
        if (markAsChanged) {
          this.setChanged();
        }
        return true;
      }
    } else if (this.isParamDifferent(key, value)) {
      this.params[key] = value;
      if (markAsChanged) {
        this.setChanged();
      }
      return true;
    }
  }

}

export class Pageable<T> {
  private loader: PageLoader<T>;
  onChange = new EventEmitter<Page<T>>();
  paramsBuilder = new RequestParamsBuilder();
  page: PageMeta = {number: 0, totalElements: 0};
  loading = false;
  hasMore = true;
  isLoadedEmpty = false;
  data: T[] = [];
  error: any;
  pageCount = 0;
  required: string[] = [];
  waitingRequiredParam = false;
  inited = false;
  disabled = false;

  constructor(public loadCount = 20, sort: string[] = [], public paginated = false) {
    this.setParam('sort', sort);
  }

  setEmpty() {
    this.page = {number: 0, totalElements: 0};
    this.pageCount = 0;
    this.hasMore = false;
    this.data = [];
    this.error = null;
    this.isLoadedEmpty = true;
    this.onChange.emit({data: this.data, page: this.page});
  }

  replace(oldValue: T, newValue: T, areEquals = (oldValue: T, givenOldValue: T) => oldValue === givenOldValue): boolean {
    for (let i = 0; i < this.data.length; i++) {
      if (areEquals(this.data[i], oldValue ?? newValue)) {
        this.data[i] = newValue;
        this.data = [...this.data];
        this.isLoadedEmpty = false;
        this.onChange.emit({data: this.data, page: this.page});
        return true;
      }
    }
    return false;
  }

  replaceOrAdd(value: T, areEquals = (v: T) => value === v, prepend = false) {
    if (!this.replace(null, value, areEquals)) {
      this.data = prepend ? [value, ...this.data] : [...this.data, value];
      this.page.totalElements++;
      this.isLoadedEmpty = false;
      this.onChange.emit({data: this.data, page: this.page});
    }
  }

  remove(isToRemove: (v: T) => boolean) {
    const newData = this.data.filter(v => !isToRemove(v));
    if (newData.length !== this.data.length) {
      this.page.totalElements -= this.data.length - newData.length;
      this.data = newData;
      this.isLoadedEmpty = this.page.totalElements === 0;
      this.onChange.emit({data: this.data, page: this.page});
      return true;
    }
    return false;
  }

  setLoader(loader: PageLoader<T>) {
    this.loader = loader;
    return this;
  }

  addParam(key: string, value: any) {
    if (this.paramsBuilder.addParam(key, value)) {
      this.checkShouldRequest();
    }
    return this;
  }

  removeParam(key: string, value?: any) {
    this.paramsBuilder.removeParam(key, value);
    return this;
  }

  setParam(key: string, value: any, markAsChanged = key !== 'page', canTriggerLoad = true) {
    if (this.paramsBuilder.setParam(key, value, markAsChanged) && markAsChanged && canTriggerLoad) {
      this.checkShouldRequest();
    }
    return this;
  }

  setParams(params: {[name: string]: number | string}, removeMissing = false) {
    const keys = Object.keys(this.paramsBuilder.getParams());
    for (const param of Object.keys(params)) {
      if (param === 'page') {
        this.setPage(params[param]);
      } else {
        this.setParam(param, params[param], true, false);
      }
    }
    if (removeMissing) {
      for (const key of keys) {
        if (!(key in params)) {
          this.paramsBuilder.removeParam(key);
        }
      }
    }
    this.checkShouldRequest();
    return this;
  }

  private async requestPage() {
    let changed = false;
    let pageMeta = this.page;
    if (this.paramsBuilder.hasChanged()) {
      this.paramsBuilder.clearChanged();
      changed = true;
      pageMeta = {number: 0, totalElements: 0};
      if (this.inited) {
        this.setPage(0);
      }
    }
    let params: RequestParams = {
      size: '' + this.loadCount,
    };
    if (pageMeta.totalElements) {
      params.elementsCount = '' + pageMeta.totalElements;
    }
    const extras = this.paramsBuilder.getParams();
    if (extras) {
      params = {...params, ...extras};
    }
    params = {...params, sort: this.paramsBuilder.getParamValue('sort', [])};
    const page = await this.loader(params);
    this.page = page.page;
    this.data = this.paginated || changed ? page.data : [...this.data, ...page.data];
    this.hasMore = (1 + page.page.number) * this.loadCount < this.page.totalElements;
    this.isLoadedEmpty = !(this.hasMore || this.data.length || this.error);
    this.pageCount = Math.ceil(this.page.totalElements / this.loadCount);
    this.inited = true;
    return page;
  }

  setRequired(...required: string[]) {
    this.required = required;
    return this;
  }

  isRequiredParamsMissing() {
    if (this.disabled) {
      return true;
    }
    if (this.required?.length) {
      for (const r of this.required) {
        if (r.endsWith(PARAM_LENGTH)) {
          const key = r.substring(0, r.length - PARAM_LENGTH.length);
          if (!this.paramsBuilder.getParamValue(key, []).length) {
            return true;
          }
        } else if (!this.paramsBuilder.hasParam(r)) {
          return true;
        }
      }
    }
    return false;
  }

  private shouldRequest() {
    return this.waitingRequiredParam && !this.isRequiredParamsMissing();
  }

  private async checkShouldRequest() {
    return !this.shouldRequest() || await this.loadPage();
  }

  private finishEvent(event?: any) {
    if (typeof event?.target?.complete === 'function') {
      event.target.complete();
    }
  }

  async loadPage(event?: any, forceLoad = false, warnConcurrent = true) {
    if (this.isRequiredParamsMissing()) {
      this.waitingRequiredParam = true;
      this.finishEvent(event);
    } else if (forceLoad || (!this.data.length && !this.isLoadedEmpty) || this.paramsBuilder.hasChanged() || this.paramsBuilder.getParamValue('page', 0) !== this.page.number) {
      this.waitingRequiredParam = false;
      let page: Page<T>;
      if (this.loading) {
        if (warnConcurrent) {
          console.error('concurrent request aborted');
        }
      } else if (this.loader) {
        this.loading = true;
        this.error = undefined;
        try {
          page = await this.requestPage();
        } catch (e) {
          this.error = e;
          console.error(e);
        } finally {
          this.loading = false;
        }
      }
      this.finishEvent(event);
      if (page) {
        this.onChange.emit(page);
        return true;
      }
    }
    return false;
  }

  setPage(page: number | string) {
    return this.setParam('page', page == null ? 0 : +page, false);
  }

  loadMore(event?: any, warnConcurrent = true) {
    this.setPage(this.page.number + 1);
    return this.loadPage(event, false, warnConcurrent);
  }

  refresh(event?: any) {
    this.paramsBuilder.setChanged();
    this.setPage(0);
    return this.loadPage(event, true);
  }

  updateFromEvent<K, U>(event: CRUDEvent<K, U>, opts: FromEventUpdater<U, K, T>) {
    if (event.type === 'delete') {
      this.remove(t => opts.isKeyEqual(event.key, t));
    } else {
      this.replaceOrAdd(opts.converter(event.value), t => opts.areEqual(event.value, t), opts.prepend);
    }
  }

}

export interface FromEventUpdater<U, K, T> {
  converter: (u: U) => T;
  areEqual: (u: U, t: T) => boolean;
  isKeyEqual: (k: K, t: T) => boolean;
  prepend?: boolean;
}

export class KeyFromEventUpdater<U> implements FromEventUpdater<U, any, U> {

  constructor(private k: keyof U, public prepend = false) {}

  converter = (u: U) => u;
  areEqual = (u1: U, u2: U) => u1[this.k] === u2[this.k];
  isKeyEqual = (k: any, u: U) => u[this.k] === k;
}

export type SharedProp<U, T> = {
  [K in keyof U]: K extends keyof T ? K : never;
}[keyof U];

export class KeyFromEventConvertUpdater<U, T> implements FromEventUpdater<U, keyof SharedProp<U, T>, T> {
  constructor(private k: SharedProp<U, T>, public converter: (u: U) => T, public prepend = false) {}

  areEqual = (u: U, t: T) => u[this.k] as any === t[this.k];
  isKeyEqual = (k: keyof SharedProp<U, T>, t: T) => t[this.k] === k;
}