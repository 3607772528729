import { InjectionToken, Injector } from '@angular/core';

export abstract class InjectableService<T> {
  private injectables: {[name: string]: T} = {};

  constructor(private childInjector: Injector) { }

  abstract tokenStore: {[name: string]: InjectionToken<T>};
  abstract defaultInjectable: T;

  getInjectablesNames(): string[] {
    return Array.from(new Set([...Object.keys(this.injectables), ...Object.keys(this.tokenStore)]));
  }

  hasInjectable(name: string): boolean {
    return name in this.tokenStore || !!this.childInjector.get<T>(this.tokenStore[name], null);
  }

  getInjectable(name: string, defaultInjectable: T = this.defaultInjectable): T {
    return this.injectables[name] || this.childInjector.get<T>(this.tokenStore[name], defaultInjectable);
  }
  
  setInjectable(name: string, injectable: T) {
    this.injectables[name] = injectable;
  }

}
