import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { IonstackService, CleanSubscriber } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioPlayerComponent extends CleanSubscriber implements OnDestroy {
  @ViewChild('vizu') vizuEl: ElementRef;
  @ViewChild('canvas') canvasEl: ElementRef;
  @Input() lineWidth = 1;
  @Input() strokeStyle = '#eee';
  @Input() samples = 400;
  @Input() canvasWidth = 400;
  @Input() canvasHeight = 200;
  @Input() padding = 20;
  @Input() autoplay = false;
  @Input() allowPlay = true;
  startedAt = 0;
  pausedAt = 0;
  displayTime = 0;
  readError = false;
  audioBuffer: AudioBuffer;
  source: AudioBufferSourceNode;
  playing = false;
  readonly enabled: boolean;
  private audioContext: AudioContext;
  private url: string;
  readonly formatTime = (time: number) => Math.floor(time / 60) + ':' + (Math.round(time) % 60).toString().padStart(2, '0');
  readonly onReadError = () => {
    this.readError = true;
    this.cd.markForCheck();
  };

  constructor(
    ionstackService: IonstackService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    super();
    this.enabled = ionstackService.isBrowser();
    if (this.enabled) {
      this.audioContext = new (AudioContext || window['webkitAudioContext'])();
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
    if (this.enabled) {
      this.end();
    }
  }

  get src() {
    return this.url;
  }

  @Input() set src(url: string) {
    if (this.enabled && url && this.url !== url) try {
      this.url = url;
      fetch(url).then(async response => {
        try {
          this.parseBuffer(await response.arrayBuffer());
        } catch (e) {
          this.onReadError();
        }
      }).catch(this.onReadError);
    } catch (e) {
      console.error(e)
    }
  }

  @Input() set data(data: Blob[]) {
    this.setPlaying(false);
    new Response(new Blob(data)).arrayBuffer().then(buffer => this.parseBuffer(buffer)).catch(this.onReadError);
  }

  updateDisplayTime() {
    this.displayTime = Math.min((this.playing ? this.audioContext.currentTime - this.startedAt : this.pausedAt || this.startedAt), this.audioBuffer?.duration || 0);
  }

  end() {
    this.stopAudio();
    this.startedAt = this.pausedAt = 0;
    this.updateDisplayTime();
    this.cd.markForCheck();
  }

  stopAudio() {
    if (this.source) {
      this.source.onended = null;
      this.source.stop(0);
      this.source.disconnect();
      this.source = null;
    }
    this.unsubscribe('timer');
  }

  seekClick(event: MouseEvent) {
    const bcr = this.vizuEl.nativeElement.getBoundingClientRect();
    const t = this.audioBuffer.duration * Math.min(Math.max(0, (event.clientX - bcr.left) / bcr.width), 1);
    const wasPlaying = this.playing;
    if (this.playing) {
      this.setPlaying(false);
    }
    this.pausedAt = t;
    this.startedAt = t;
    if (wasPlaying) {
      this.setPlaying(true);
    }
    this.updateDisplayTime();
  }

  setPlaying(playing = !this.playing) {
    if (this.allowPlay && this.enabled && playing !== this.playing) {
      if (playing) {
        const offset = this.pausedAt;
        this.source = this.audioContext.createBufferSource();
        this.source.buffer = this.audioBuffer;
        this.source.connect(this.audioContext.destination);
        this.source.onended = () => this.zone.run(() => {
          this.playing = false;
          this.end();
        });
        this.source.start(0, offset);
        this.startedAt = this.audioContext.currentTime - offset;
        this.pausedAt = 0;
        this.subscribe(timer(0, 300), () => {
          this.updateDisplayTime();
          this.cd.markForCheck();
        }, {replace: true, name: 'timer'});
        this.updateDisplayTime();
        this.playing = true;
      } else if (this.source) {
        const elapsed = (this.audioContext.currentTime - this.startedAt) * (this.source?.playbackRate?.value || 1);
        this.stopAudio();
        this.pausedAt = elapsed;
        this.updateDisplayTime();
        this.playing = false;
      }
      this.cd.markForCheck();
    }
  }

  async parseBuffer(buffer: ArrayBuffer) {
    try {
      this.audioBuffer = await this.audioContext.decodeAudioData(buffer);
      if (this.allowPlay) {
        this.setPlaying(false);
        if (this.autoplay) {
          this.setPlaying(true);
        }
      }
      this.draw(this.getFilteredData());
      this.readError = false;
    } catch (e) {
      this.onReadError();
    }
    this.cd.markForCheck();
  }

  private getFilteredData(): number[] {
    const rawData = this.audioBuffer.getChannelData(0); // We only need to work with one channel of data
    const blockSize = Math.floor(rawData.length / this.samples); // the number of samples in each subdivision
    const filteredData = [];
    for (let i = 0; i < this.samples; i++) {
      let blockStart = blockSize * i; // the location of the first sample in the block
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum = sum + rawData[blockStart + j]; // find the sum of all the samples in the block
      }
      filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }
    return filteredData.map(n => n * Math.pow(Math.max(...filteredData.map(f => Math.abs(f))), -1));
  };

  private draw(normalizedData: number[]) {
    const canvas: HTMLCanvasElement = this.canvasEl.nativeElement;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    const width = this.canvasWidth / normalizedData.length;
    const maxH = this.canvasHeight / 2 - this.padding;
    ctx.beginPath();
    ctx.moveTo(0, this.canvasHeight / 2);
    for (let i = 0; i < normalizedData.length; i++) {
      ctx.lineTo(width * i, this.canvasHeight / 2 + normalizedData[i] * maxH);
    }
    ctx.lineTo(this.canvasWidth, this.canvasHeight / 2);
    ctx.stroke();
  };

}
