import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { DefaultWidgetContainer, Widget } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';
import { AccordionParent } from '../accordion/accordion.component';

@Component({
  selector: 'ionstack-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: AccordionParent,
    useExisting: forwardRef(() => AccordionGroupComponent)
  }]
})
@Widget({
  widgetID: 'accordion-group',
  widgetName: 'Accordion group',
  group: 'Container',
  icon: 'list-outline',
  widgetDescription: 'A group for accordions',
  childSupport: ['accordion'],
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    {name: 'value', label: 'Initial selection', optional: true},
    {name: 'expand', label: 'Expand', value: 'compact', type: 'select', options: ['compact', 'inset']},
    {name: 'multiple', label: 'Allow multiple accordions expanded at the same time', value: false, type: 'checkbox'},
  ],
  newInstance: {
    content: '{"value":"a1"}',
    children: [
      {type: 'accordion', content: '{"value":"a1","next":"Next"}', children: [
        {type: 'text', slot: 'header', content: 'Accordion 1'},
      ]},
      {type: 'accordion', content: '{"value":"a2","next":"Next"}', children: [
        {type: 'text', slot: 'header', content: 'Accordion 2'},
      ]},
      {type: 'accordion', content: '{"value":"a3","next":"Next"}', children: [
        {type: 'text', slot: 'header', content: 'Accordion 3'},
      ]},
    ]
  }
})
export class AccordionGroupComponent extends DefaultWidgetContainer implements AccordionParent {
  @Input() value: string;
  @Input() expand: 'compact' | 'inset';
  @Input() multiple: boolean;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  open(index: number) {
    const content = this.children[index].content;
    const value = content ? JSON.parse(content).value : null;
    if (value) {
      this.value = value;
      this.cd.markForCheck();
    }
  }

}
