<ion-accordion [value]="value">
  <ion-item slot="header">
    <ionstack-widget-children
      *ngIf="hasStartSlot | call:params.context?.postOptions.layoutMode:children"
      slot="start"
      slotted="start"
      [value]="children"
      [parent]="params.holder"
      [context]="params.context"
    ></ionstack-widget-children>
    <ionstack-widget-children
      slotted="header"
      [value]="children"
      [parent]="params.holder"
      [context]="params.context"
    ></ionstack-widget-children>
  </ion-item>
  <div [slot]="params.context?.postOptions.layoutMode ? 'header' : 'content'">
    <ionstack-widget-children
      slotted="content"
      [value]="children"
      [parent]="params.holder"
      [context]="params.context"
    ></ionstack-widget-children>
    <div *ngIf="hasNext | call:form:next:params.holder.index:params.holder.siblings" class="next">
      <ion-button fill="clear" [disabled]="controlsInvalid" (click)="nextSection()">
        <ion-icon slot="start" name="chevron-forward"></ion-icon>
        <ion-label><itxt [key]="next"></itxt></ion-label>
      </ion-button>
    </div>
  </div>
</ion-accordion>