import { NgIf, NgIfContext } from '@angular/common';
import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppControllerState } from '../model/state';
import { Subscription } from 'rxjs';
import { AppController } from '../controller/app.controller';

type AppStateDirectiveValue = keyof AppControllerState | 'small' | 'large';

@Directive({
  selector: '[ifState]'
})
export class AppStateDirective extends NgIf<boolean> implements OnDestroy {
  private sub1: Subscription;
  private sub2: Subscription;
  private key: AppStateDirectiveValue;
  private undefinedIsTrue = false;

  constructor(
    _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext<boolean>>,
    private appController: AppController,
    private cd: ChangeDetectorRef,
  ) {
    super(_viewContainer, templateRef);
    this.sub1 = appController.onStateChange.subscribe(() => this.refresh());
    this.sub2 = appController.onSizeChange.subscribe(() => this.refresh());
  }

  @Input() set ifState(key: AppStateDirectiveValue) {
    this.key = key;
    this.undefinedIsTrue = (key as string).startsWith('show');
    this.refresh(false);
  }

  @Input() set ifStateElse(ref: TemplateRef<NgIfContext<boolean>>) {
    this.ngIfElse = ref;
  }

  private refresh(check = true) {
    if (this.key === 'small') {
      this.ngIf = this.appController.isSmall;
    } else if (this.key === 'large') {
      this.ngIf = !this.appController.isSmall;
    } else {
      const state = this.appController.getState()[this.key];
      this.ngIf = state || (this.undefinedIsTrue && state === undefined);
    }
    if (check) {
      this.cd.markForCheck();
    }
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
  }

}
