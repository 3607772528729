import { FileDisplayConfig } from '../file-display/file-display.component';
import {
  AccessRuleUpdate, TranslateService, RequireAny, DEFAULT_IMAGE_FORMAT, IonstackModuleConfig, MODULE_CONFIG, IonstackMenu, FileService,
  FormControlComponent, randomString, DisplayableFile, ImageFormat, StoredFile, StoredFileDetails, ALL_FILE_TYPES, FileType
} from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { LoadingController, ToastController } from '@ionic/angular';
import { FileCaptureConfig } from '../file-config';
import { ExtraFileUpload } from '../file-capture';

export interface EditFileConfig extends FileDisplayConfig {
  title?: string;
  skipTranslateTitle?: boolean;
  actionMenu?: string | IonstackMenu;
  defaultName?: string;
  defaultCredit?: string;
  hideNameInput?: boolean;
  hideCreditInput?: boolean;
  uploadBlockUi?: boolean;
  canDelete?: boolean;
  okText?: string;
  changeText?: string;
  uploadText?: string;
  cancelText?: string;
  deleteText?: string;
  filenameName?: string;
  imageMinSize?: number;
  allowedTypes?: FileType[];
  readRule?: AccessRuleUpdate;
  imageAspectRatio?: number;
  imageMaintainAspectRatio?: boolean;
  imageMaxCropperHeight?: string;
  imageRoundCropper?: boolean;
  updateFileOnEdit?: boolean;
  imageFormat?: ImageFormat;
  isPrivate?: boolean;
}

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditFileComponent),
    multi: true
  }],
  selector: 'ionstack-edit-file',
  templateUrl: './edit-file.component.html',
  styleUrls: ['./edit-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFileComponent extends FormControlComponent<number> implements OnInit {
  @Output() valueChange = new EventEmitter<number>();
  @Output() onExtraUpload = new EventEmitter<ExtraFileUpload>();
  @Input() config: EditFileConfig = {};
  @Input() isDisabled: boolean;
  @Input() file: StoredFile;
  @Input() value: number;
  @Input() onClose: () => void;
  @Input() extraUploadCallback: (upload: ExtraFileUpload) => void;
  @Input() activeType: FileType = FileType.IMAGE;
  @Input() save: (value: StoredFileDetails) => Promise<void>;
  @Input() inPopup = true;
  @Input() fileCaptureConfig: FileCaptureConfig;
  @Input() allowExtraUpload = false;
  imageFormat: ImageFormat;

  imageForm: UntypedFormGroup;
  skipFirstLoadEmit = false;
  imageUrl: string;
  forms: {[type in FileType]: UntypedFormGroup};
  readonly type = FileType;
  readonly allTypes = ALL_FILE_TYPES;
  private _defaultName: string;

  readonly ifOfType = (file: DisplayableFile, type: FileType) => file?.fileType === type ? file : null;
  readonly fileTypes = FileType;
  readonly nameLength = 64;
  readonly creditLength = 64;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private translateService: TranslateService,
    private loadingController: LoadingController,
    private toastService: ToastController,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    public cd: ChangeDetectorRef,
  ) {
    super();
  }

  async ngOnInit() {
    if (this.value != null && !this.file) {
      this.file = await this.fileService.getFile(this.value);
    } else if (this.value == null && this.file) {
      this.value = this.file.id;
    }
    this.imageFormat = this.config.imageFormat || this.ionstackModuleConfig.defaultImageFormat || DEFAULT_IMAGE_FORMAT;

    if (this.file) {
      this.activeType = this.file.fileType;
      if (this.file.fileType === 'IMAGE') {
        this.skipFirstLoadEmit = true;
        this.imageUrl = this.fileService.getPath(this.file);
      }
    }
    this.initForms();
    this.cd.markForCheck();
  }

  private initForms() {
    let name = this.file?.name || this.defaultName?.substring(0, this.nameLength);
    if (!name && this.config.hideNameInput) {
      this.defaultName = randomString(6);
      name = this.defaultName;
    }
    const base = {
      id: [this.file?.id],
      name: [name, Validators.required],
      credit: [this.file?.credit || this.config.defaultCredit?.substring(this.creditLength)],
      isPublic: [!this.config.isPrivate && !this.config.readRule],
      readRule: [this.config.readRule],
    };
    const rawUpload = {
      ...base,
      tempFile: [],
      extUrl: [this.file?.extUrl],
      extension: [this.file?.extension],
    };
    const extensionUpload = {
      ...rawUpload,
      extension: [this.file?.extension, Validators.required],
    };
    const rawRequire = RequireAny('id', 'extUrl', 'tempFile');
    this.forms = {
      IMAGE: this.formBuilder.group({
        ...base,
        extension: [this.file?.extension || this.imageFormat, Validators.required],
        base64: this.file ? [] : ['', Validators.required],
      }),
      AUDIO: this.formBuilder.group({
        ...extensionUpload,
        fileType: FileType.AUDIO,
      }, {validators: rawRequire}),
      DOCUMENT: this.formBuilder.group({
        ...rawUpload,
        fileType: FileType.DOCUMENT,
      }, {validators: rawRequire}),
      VIDEO: this.formBuilder.group({
        ...extensionUpload,
        fileType: FileType.VIDEO,
      }, {validators: rawRequire}),
    };
  }

  handleFileName(filename: string) {
    const cname = this.formGroup.get('name');
    if (!cname.value || (this.defaultName && cname.value === this.defaultName?.substring(0, this.nameLength))) {
      cname.setValue(filename.replace(/\.[^/.]+$/, '').replace('_', ' ').substring(0, this.nameLength));
      this.cd.markForCheck();
    }
  }

  get defaultName() {
    return this._defaultName;
  }

  get formGroup() {
    return this.forms?.[this.activeType];
  }

  @Input() set defaultName(defaultName: string) {
    if (this._defaultName !== defaultName) {
      if (this.formGroup) {
        const current = this.formGroup.get('name').value;
        if (!current || current === this._defaultName) {
          this._defaultName = defaultName;
          this.formGroup.get('name').setValue(defaultName);
        }
      } else {
        this._defaultName = defaultName;
      }
    }
  }

  onExtraUploading(upload: ExtraFileUpload) {
    this.onExtraUpload.emit(upload);
    if (this.extraUploadCallback) {
      this.extraUploadCallback(upload);
    }
  }

  updateExtUrl(extUrl: string) {
    if (extUrl) {
      this.formGroup.get('extension').setValue('ext');
      this.formGroup.get('tempFile').setValue(null);
    }
    this.formGroup.get('extUrl').setValue(extUrl);
    this.formGroup.markAsDirty();
    this.cd.markForCheck();
  }

  submitForm() {
    if (this.activeType === FileType.IMAGE) {
      return this.fileService.uploadImage(this.formGroup.value);
    }
    return this.fileService.uploadRaw(this.formGroup.value);
  }

  async deleteFile() {
    await this.save(null);
    this.onClose();
  }

  async submit() {
    if (this.formGroup.valid) {
      const uploadText = (await this.translateService.awaitGet(this.config.uploadText || 'Uploading')) + '...';
      const popup = await (this.config.uploadBlockUi ?
        this.loadingController.create({message: uploadText}) :
        this.toastService.create({message: uploadText})
      );
      await popup.present();
      try {
        if (!this.config.uploadBlockUi) {
          this.onClose?.();
        }
        const value = await this.submitForm();
        await this.save(value);
        this.setValue(value.id);
        if (this.config.uploadBlockUi) {
          this.onClose?.();
        }
      } finally {
        await popup.dismiss();
      }
    }
  }

  onPickNewFile() {
    if (!this.config.updateFileOnEdit) {
      this.formGroup.get('id').setValue(null);
      this.cd.markForCheck();
    }
    if (!this.formGroup.get('name').value) {
      this.formGroup.get('name').setValue(this.fileCaptureConfig?.getName?.(this.formGroup.value) || (new Date().toLocaleDateString()));
      this.cd.markForCheck();
    }
  }

  changed() {
    this.cd.markForCheck();
  }

}
