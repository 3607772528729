import { Widget, DatasourceService, SimpleFormField } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Inject, Optional } from '@angular/core';
import { Component } from '@angular/core';
import { BaseFormWidget, BASE_META_FIELDS, FORM_META_FIELDS, WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

interface DateInputConfig extends SimpleFormField {
  presentation?: 'date' | 'time' | 'date-time' | 'time-date';
  clear?: boolean;
}

@Component({
  selector: 'ionstack-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['../input-style.scss', './date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'date',
  widgetName: 'Date input',
  group: 'Forms',
  icon: 'calendar',
  widgetDescription: 'Datetime input field',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    ...BASE_META_FIELDS,
    { name: 'presentation', label: 'Presentation', type: 'select', options: ['date', 'time', 'date-time', 'time-date'], value: 'date' },
    { name: 'clear', label: 'Clear button', type: 'checkbox', value: false }, 
    FORM_META_FIELDS.label,
    FORM_META_FIELDS.min,
    FORM_META_FIELDS.max,
    FORM_META_FIELDS.placeholder,
    FORM_META_FIELDS.color,
  ]
})
export class DateInputComponent extends BaseFormWidget<DateInputConfig> {

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) form: WidgetParentForm,
    datasourceService: DatasourceService,
    public cd: ChangeDetectorRef,
  ) {
    super(form, datasourceService);
  }

}
