import { ComponentRef } from '@ionic/core';
import { IonstackMenu } from './model/menu';
import { HttpClient } from '@angular/common/http';
import { AudioFormat, ImageFormat, VideoFormat } from './model/files';
import { AppControllerState } from './model/state';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const MODULE_CONFIG = 'IonstackModuleConfig';
export interface IonstackModuleConfig {
    browserApi: string;
    serverApi: string;
    appApi?: string;
    frontBaseUrl: string;
    storageBaseUrl?: string;
    storageApi?: string;
    defaultLanguage?: string;
    apiPort?: number;
    websocketBrokerUrl?: string;
    extraHeaders?: string[];
    extraResponseHeaders?: string[];
    facebookAppId?: string;
    googleAppId?: string;
    otpLength?: number;
    defaultImageFormat?: ImageFormat;
    defaultVideoFormat?: VideoFormat;
    defaultAudioFormat?: AudioFormat;
    afterLoginRoute?: string;
    afterRegisterRoute?: string;
    afterLogoutRoute?: string;
    termsOfServiceRoute?: string;
    salesTermsRoute?: string;
    privacyPolicyRoute?: string;
    controllerState: AppControllerState;
    defaultChatEnterToSubmit?: boolean;
    notifListRoute?: string | string[];
    menus?: IonstackMenu[];
    galleryComponent?: ComponentRef;
    sirenApiKey?: string;
    notifPlaySound?: boolean | {[type: string]: boolean | string};
    showPopNotificationDelay?: number;
    showPopNotificationDuration?: number;
    paymentConfig?: {
        apiKey: string;
        createTokenUrl?: string;
    };
    firebase?: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
    };
    webpushVapidKey?: string;
}

export const API_CLIENT = 'ClientApiInterface';
export const SOCIAL_AUTH_SERVICE = 'SocialAuthService';
export const SERVER_SECRET = 'ServerSecret';

export interface ClientApiInterface extends HttpClient {}

export const DEFAULT_OTP_LENGTH = 4;
export const DEFAULT_IMAGE_FORMAT: ImageFormat = 'webp';
export const DEFAULT_VIDEO_FORMAT: VideoFormat = 'webm';
export const DEFAULT_AUDIO_FORMAT: AudioFormat = 'mp3';

export interface NotificationClick {
    action: string;
    notification: NotificationOptions & {
        title: string;
    };
}

export interface ServiceWorkerPush {
    get isEnabled(): boolean;
    requestSubscription(options: {serverPublicKey: string;}): Promise<PushSubscription>;
    subscription: Observable<PushSubscription | null>;
    messages: Observable<object>;
    notificationClicks: Observable<NotificationClick>;
}

export const ServiceWorkerPush = new InjectionToken<ServiceWorkerPush>('ServiceWorkerPush');