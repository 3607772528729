import { ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Input } from '@angular/core';
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoredFile, InOutVertical } from '@adeprez/ionstack';
import { SwiperComponent } from 'swiper/angular';
import { FileDisplayConfig } from '@adeprez/ionstack/file';
import SwiperCore, { Swiper, Thumbs, Navigation } from 'swiper';

SwiperCore.use([Thumbs, Navigation]);

export interface GalleryDisplayConfig extends FileDisplayConfig {
  thumbnailConfig?: FileDisplayConfig;
  hideName?: boolean;
  title?: string;
}

const defaultConfig: GalleryDisplayConfig = {
  imageFit: 'contain',
  height: '100%',
  hideName: true,
};

type SwiperType = 'thumb' | 'swiper';

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [InOutVertical]
})
export class ModalGalleryComponent {
  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;
  @Input() files: StoredFile[];
  @Input() activeIndex: number;
  zoomed: boolean;
  thumbsSwiper: Swiper;
  mainSwiper: Swiper;
  private _config: GalleryDisplayConfig = defaultConfig;

  constructor(
    private cd: ChangeDetectorRef,
    private modalController: ModalController,
  ) { }

  readonly isVisible = (activeIndex: number, index: number) => activeIndex != null && Math.abs(index - activeIndex) < 2;

  inited(what: SwiperType, swiper: Swiper) {
    if (what === 'thumb') {
      this.thumbsSwiper = swiper;
    } else {
      this.mainSwiper = swiper;
    }
    this.cd.markForCheck();
    this.checkIndexes();
  }

  setZoomed(zoomed: boolean) {
    this.zoomed = zoomed;
    this.cd.markForCheck();
  }

  setIndex(index: number) {
    this.zoomed = false;
    if (index != null && this.activeIndex !== index) {
      this.activeIndex = index;
      this.checkIndexes();
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  close() {
    this.modalController.dismiss();
  }

  @Input() set config(config: GalleryDisplayConfig) {
    this._config = config ? { ...defaultConfig, ...config } : defaultConfig;
  }

  get config() {
    return this._config;
  }

  private checkIndexes() {
    if (this.mainSwiper?.slides?.length && this.mainSwiper.activeIndex !== this.activeIndex) {
      this.mainSwiper.slideTo(this.activeIndex);
    }
    if (this.thumbsSwiper?.slides?.length && this.thumbsSwiper.activeIndex !== this.activeIndex) {
      this.thumbsSwiper.slideTo(this.activeIndex);
    }
  }

}
