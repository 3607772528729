import { AppController, AppControllerState, CleanSubscriber, IonstackService } from '@adeprez/ionstack';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Platform } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends CleanSubscriber implements OnInit, OnDestroy {

  constructor(
    private appController: AppController,
    private platform: Platform,
    private renderer: Renderer2,
    private zone: NgZone,
    private router: Router,
    private ionstackService: IonstackService,
    @Inject(DOCUMENT) private document: any,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribe<AppControllerState>(this.appController.onStateChange, state => {
      if (state.hideIntercom) {
        this.renderer.addClass(this.document.body, 'hide-intercom');
      } else {
        this.renderer.removeClass(this.document.body, 'hide-intercom');
      }
      if (state.pushIntercom) {
        this.renderer.addClass(this.document.body, 'push-intercom');
      } else {
        this.renderer.removeClass(this.document.body, 'push-intercom');
      }
    });
    if (this.ionstackService.isBrowser()) {
      this.platform.ready().then(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => this.zone.run(() => {
          const path = /https?:\/\/[a-zA-Z0-0.:-]+(.*)/.exec(event.url)?.[1] ?? '/';
          console.log('open dynamic link ' + event.url + ' -> ' + path);
          this.router.navigateByUrl(path);
        }));
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

}
