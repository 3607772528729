<ion-toolbar class="gallery-toolbar">
  <ion-label slot="start" class="counter" *ngIf="files.length !== 1">
    {{ activeIndex + 1 }} / {{ files.length }}
  </ion-label>
  <ion-buttons slot="primary">
    <ion-button (click)="close()">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title *ngIf="files[activeIndex] as file">
    <span *ngIf="!config.hideName || config.title" class="fname">{{ config.title || file.name }}</span>
    <span *ngIf="file.credit" class="credit">{{ file.credit }}</span>
  </ion-title>
</ion-toolbar>
<swiper
  (swiper)="inited('swiper', $event)"
  (activeIndexChange)="setIndex(mainSwiper?.activeIndex)"
  [initialSlide]="activeIndex"
  [navigation]="true"
  [enabled]="!zoomed"
  class="gallery-swiper"
  #swiper
>
  <ng-template swiperSlide *ngFor="let file of files; let i = index">
    <ionstack-file-display
      [file]="file"
      [config]="config"
      [visible]="isVisible | call:activeIndex:i"
      [zoomOnClick]="4"
      (zoomed)="setZoomed($event)"
    ></ionstack-file-display>
  </ng-template>
</swiper>
<swiper
  *ngIf="files.length > 1 && !zoomed"
  (swiper)="inited('thumb', $event)"
  (activeIndexChange)="setIndex(thumbsSwiper?.activeIndex)"
  [@InOutVertical]
  [spaceBetween]="10"
  [slidesPerView]="'auto'"
  [slideToClickedSlide]="true"
  [watchSlidesProgress]="true"
  [centerInsufficientSlides]="true"
  class="gallery-thumb"
>
  <ng-template swiperSlide *ngFor="let file of files; let i = index">
    <ionstack-file-display
      [file]="file"
      [config]="config?.thumbnailConfig || {}"
      (click)="setIndex(i)"
    ></ionstack-file-display>
  </ng-template>
</swiper>