import { Widget, WidgetParams, DatasourceService } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Inject, Optional } from '@angular/core';
import { Component } from '@angular/core';
import { BaseFormWidget, BASE_META_FIELDS, FORM_META_FIELDS, WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['../input-style.scss', './select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'input-select',
  widgetName: 'Dropdown',
  group: 'Forms',
  icon: 'caret-down',
  widgetDescription: 'Dropdown value selector',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    ...BASE_META_FIELDS,
    FORM_META_FIELDS.color,
    { name: 'options', label: 'Option', type: 'multiline-text', rows: 5, placeholder: 'One by line, either as string or {name, value} JSON'},
    { name: 'defaultOption', label: 'Default option', optional: true, placeholder: 'A string or {name, value} JSON' }
  ]
})
export class SelectInputComponent extends BaseFormWidget {

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) form: WidgetParentForm,
    datasourceService: DatasourceService,
    public cd: ChangeDetectorRef,
  ) {
    super(form, datasourceService);
  }

  override set widget(widget: WidgetParams) {
    super.widget = widget;
    const opts: any = this.field.options; 
    if (typeof opts === 'string') {
      this.field.options = opts.split('\n').map(this.parseJsonOrText);
    }
    const dOpt: any = this.field.defaultOption;
    if (typeof dOpt === 'string') {
      this.field.defaultOption = this.parseJsonOrText(dOpt);
    }
  }

}
