import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStateDirective } from './app-state.directive';
import { AwaitClickDirective } from './await-click.directive';
import { ClickStopPropagationDirective } from './click-stop-propagation.directive';
import { FileDropDirective } from './file-drop.directive';
import { PopupMenuDirective } from './popup-menu.directive';
import { AwaitSubmitDirective } from './await-submit.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { HasPermissionDirective } from './has-permission.directive';
import { ShareDirective, QRCodeModalComponent } from './share.directive';
import { DragDirective } from './drag.directive';
import { DropDirective } from './drop.directive';
import { GalleryClickDirective } from './gallery-click.directive';
import { ClosePopupDirective } from './close-popup.directive';
import { InputPatternDirective } from './input-pattern.directive';
import { IonicModule } from '@ionic/angular';
import { ProvidedRouteDirective } from './provided-route.directive';
import { IonstackPipesModule } from '../pipe/ionstack-pipes.module';
import { ZoomOnClickDirective } from './zoom.directive';
import { ResponsiveAttachDirective } from './responsive-attach.directive';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonstackPipesModule,
  ],
  declarations: [
    AppStateDirective,
    AwaitClickDirective,
    AwaitSubmitDirective,
    ClickStopPropagationDirective,
    ClosePopupDirective,
    FileDropDirective,
    GalleryClickDirective,
    PopupMenuDirective,
    ScrollToDirective,
    HasPermissionDirective,
    InputPatternDirective,
    ShareDirective,
    DragDirective,
    DropDirective,
    ProvidedRouteDirective,
    ResponsiveAttachDirective,
    QRCodeModalComponent,
    ZoomOnClickDirective,
  ],
  exports: [
    AppStateDirective,
    AwaitClickDirective,
    AwaitSubmitDirective,
    ClickStopPropagationDirective,
    ClosePopupDirective,
    FileDropDirective,
    GalleryClickDirective,
    PopupMenuDirective,
    ScrollToDirective,
    HasPermissionDirective,
    InputPatternDirective,
    ShareDirective,
    DragDirective,
    DropDirective,
    ProvidedRouteDirective,
    ResponsiveAttachDirective,
    ZoomOnClickDirective,
  ]
})
export class IonstackDirectivesModule { }
