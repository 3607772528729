import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IonstackModuleConfig, MODULE_CONFIG } from '../ionstack.config';
import { ContextService } from '../service/context.service';
import { IonstackService } from '../service/ionstack.service';

@Injectable()
export class IonstackHttpInterceptor implements HttpInterceptor {
    private keptResponseHeaders: string[];

    constructor(
        private contextService: ContextService,
        private ionstackService: IonstackService,
        @Inject(MODULE_CONFIG) ionstackModuleConfig: IonstackModuleConfig,
    ) {
        this.keptResponseHeaders = [...(ionstackModuleConfig.extraResponseHeaders || []), 'ssr-set-cookie'];
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.contextService.getCurrentContext()?.token;
        let headers = req.headers;
        if (token && !headers.has('Authorization')) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        return next.handle(req.clone({headers})).pipe(map(event => {
            if (event.type === HttpEventType.Response) {
                this.checkResponse(event);
            }
            return event;
        }));
    }

    private checkResponse(response: HttpResponse<any>) {
        this.checkContextVersion(response);
        this.checkSSRHeaders(response);
    }

    private checkContextVersion(response: HttpResponse<any>) {
        const context = this.contextService.getCurrentContext();
        if (context && !(response.body?.version && response.body?.account)) {
            const cV = response.headers.get('context-version');
            if ((cV && +cV !== context.version)) {
                this.contextService.reloadContext();
            } else {
                const ucV = response.headers.get('user-context-version');
                if (ucV && context.account && +ucV !== context.account.contextVersion) {
                    this.contextService.reloadAccountContext();
                }
            }
        }
    }

    private checkSSRHeaders(response: HttpResponse<any>) {
        if (this.ionstackService.isServer()) {
            const cookieSplitter = new RegExp('(?<!Expires=\\w\\w\\w),', 'g');
            for (const header of this.keptResponseHeaders) {
                if (response.headers.has(header)) {
                    let values = response.headers.getAll(header);
                    if (values?.length === 1) {
                        values = values[0].split(cookieSplitter);
                    }
                    const resolvedHeader = this.handleSSRResponseHeader(header, values);
                    this.ionstackService.getResponse().setHeader(resolvedHeader, values);
                }
            }
        }
    }

    private handleSSRResponseHeader(header: string, values: string[]) {
        if (header === 'ssr-set-cookie') {
            this.ionstackService.setAdditionalHeader('cookie', values.map(cookie => cookie.split(';')[0]).join('; '));
            return 'set-cookie';
        }
        return header;
    }
}