import { TranslatableModule, IonstackPipesModule, IonstackDirectivesModule } from '@adeprez/ionstack';
import { AudioCaptureModule } from '../audio-capture/audio-capture.module';
import { VideoCaptureModule } from '../video-capture/video-capture.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowseFileComponent } from './browse-file.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonstackDirectivesModule,
    IonstackPipesModule,
    VideoCaptureModule,
    AudioCaptureModule,
    TranslatableModule,
  ],
  declarations: [BrowseFileComponent],
  exports: [BrowseFileComponent]
})
export class BrowseFileModule { }
