
export function proxyTo(request: any, url: string, req: any, res: any) {
  const proxyRequest = request(url, { method: req.method, headers: req.headers }, (proxyResponse: any) => {
    proxyResponse.pipe(res, { end: true });
    res.writeHead(proxyResponse.statusCode, proxyResponse.headers);
  });
  req.pipe(proxyRequest, { end: true });
  proxyRequest.on('error', (err: any) => res.status(err?.statusCode ?? err?.status ?? 500).send(err?.message).end());
  req.on('end', () => proxyRequest.end());
}

export function pdfGenerator(request: any, config: {frontBaseUrl: string, cookieDomain: string}, secret: string, pdfGenPort = 3003) {
  return (req: any, res: any) => {
    let url = 'http://localhost:' + pdfGenPort +
      '?domain=' + config.cookieDomain +
      '&secret=' + secret +
      '&url=' + config.frontBaseUrl + encodeURIComponent(req.query.r);
    if (req.query.width) {
      url += '&width=' + req.query.width;
    }
    if (req.query.height) {
      url += '&height=' + req.query.height;
    }
    if (req.query.format) {
      url += '&format=' + req.query.format;
    }
    if (req.query.orientation) {
      url += '&orientation=' + req.query.orientation;
    }
    if (req.query.margin) {
      url += '&margin=' + req.query.margin;
    }
    proxyTo(request, url, req, res);
  };
}
