import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from './widget.component';
import { InjectModule } from '@adeprez/ionstack/ui/inject';

@NgModule({
  imports: [
    CommonModule,
    InjectModule,
  ],
  declarations: [WidgetComponent],
  exports: [WidgetComponent],
})
export class WidgetModule { }
