import { StoredFile } from './files';
import { SignatureRequest } from './signature';
import { SmallUser } from './user';

export interface PaymentAccountSettings {
  fullyIntegrated: boolean;
}

export interface PaymentAccountRep {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  title: string;
  address: PaymentAddress;
  dob: {
    day: number;
    month: number;
    year: number;
  };
  status: 'unverified' | 'pending' | 'verified';
  verificationDetails: string;
}

export interface BankAccount {
  country: string;
  currency: string;
  holderName: string;
  holderType: 'company' | 'individual';
  accountNumber: string;
  bicNumber: string;
}

export interface PaymentAccount {
  id: number;
  user: SmallUser;
  type: string;
  legalName: string;
  taxId: string;
  servicePhone: string;
  website: string;
  vatId: string;
  address: PaymentAddress;
  individual: PaymentAccountRep;
  representative: PaymentAccountRep;
  bankAccount: BankAccount;
  quoteFooter: string;
  invoiceFooter: string;
  billingActive: boolean;
  shopActive: boolean;
  disabledReason?: string;
  deadline: string;
  requirements: string[];
  verification: VerificationStatus;
}

export interface VerificationStatus {
  status: 'verified' | 'pending' | 'unverified';
  details: string;
}

export interface PaymentAccountDocument {
  id: number;
  type: string;
  files: StoredFile[];
}

export type BusinessType = 'company' | 'individual';

export interface SaveAccount {
  businessType: BusinessType;
  accountToken: string;
  bankAccountToken: string;
  representativeToken: string;
  taxId: string;
  vatId: string;
  accountNumber: string;
  bicNumber: string;
  quoteFooter?: string;
  invoiceFooter?: string;
  manualPayout?: boolean;
  documents?: SaveAccountDocument[];
}

export interface SaveAccountDocument {
  type: string;
  files: number[];
}

export interface PaymentAddress {
  line1: string;
  line2?: string;
  city: string;
  postalCode: string;
  country: string;
  state?: string;
}

export interface Customer {
  id: number;
  name: string;
  email: string;
  phone: string;
  taxId: string;
  vatId: string;
  address: PaymentAddress;
}

export interface PaymentCard {
  brand: string;
  country: string;
  expMonth: string;
  expYear: string;
  last4: string;
}

export interface PaymentMethod {
  id: string;
  type: string;
  card: PaymentCard;
}

export interface PayInvoice {
  paymentMethodId: number;
}

export interface FullCustomer extends Customer {
  paymentMethods: PaymentMethod[];
}

export interface ListedCustomer {
  id: number;
  name: string;
  email: string;
  phone: string;
}

export interface SaveCustomerInfo {
  name: string;
  email: string;
  phone: string;
  taxId: string;
  address: PaymentAddress;
}

export interface SaveCustomer extends SaveCustomerInfo {
  id?: number;
}

export enum InvoiceStatus {
  draft = 'draft',
  sent = 'sent',
  paid = 'paid',
  overdue = 'overdue',
  cancelled = 'cancelled',
}

export enum QuoteStatus {
  draft = 'draft',
  sent = 'sent',
  accepted = 'accepted',
  rejected = 'rejected',
  cancelled = 'cancelled',
  expired = 'expired',
  billed = 'billed',
}

export const BillingStatusUI: { [key in InvoiceStatus | QuoteStatus]: {color: string, icon: string} } = {
  draft: {color: 'medium', icon: 'create'},
  sent: {color: 'secondary', icon: 'send'},
  paid: {color: 'success', icon: 'checkmark-circle-outline'},
  overdue: {color: 'danger', icon: 'alert-circle-outline'},
  cancelled: {color: 'warning', icon: 'close-circle-outline'},
  accepted: {color: 'success', icon: 'checkmark-circle-outline'},
  rejected: {color: 'danger', icon: 'close-circle-outline'},
  expired: {color: 'danger', icon: 'alert-circle-outline'},
  billed: {color: 'success', icon: 'checkmark-done-circle-outline'},
};

export const InvoiceStatusChange: { [key in InvoiceStatus]: InvoiceStatus[] } = {
  draft: [InvoiceStatus.sent],
  sent: [InvoiceStatus.paid],
  overdue: [InvoiceStatus.paid],
  cancelled: [InvoiceStatus.draft],
  paid: [],
};

export const InvoiceExtraStatusChange: { [key in InvoiceStatus]: InvoiceStatus[] } = {
  draft: [InvoiceStatus.cancelled],
  sent: [InvoiceStatus.cancelled],
  overdue: [InvoiceStatus.cancelled],
  cancelled: [],
  paid: [],
};


export const QuoteStatusChange: { [key in QuoteStatus]: QuoteStatus[] } = {
  draft: [QuoteStatus.sent],
  sent: [QuoteStatus.accepted, QuoteStatus.rejected],
  accepted: [QuoteStatus.billed],
  rejected: [],
  cancelled: [QuoteStatus.draft],
  expired: [],
  billed: [],
};

export const QuoteExtraStatusChange: { [key in QuoteStatus]: QuoteStatus[] } = {
  draft: [QuoteStatus.cancelled],
  sent: [QuoteStatus.cancelled, QuoteStatus.draft],
  accepted: [QuoteStatus.rejected, QuoteStatus.cancelled],
  rejected: [QuoteStatus.accepted, QuoteStatus.cancelled],
  cancelled: [],
  expired: [QuoteStatus.accepted, QuoteStatus.rejected, QuoteStatus.cancelled],
  billed: [],
};

export interface ListedBilling {
  id: number;
  number: string;
  description: string;
  total: number;
  currency: string;
  customer: ListedCustomer;
  issueDate: string;
}

export interface ListedQuote extends ListedBilling {
  status: QuoteStatus;
  expiresAt: string;
}

export interface ListedInvoice extends ListedBilling {
  status: InvoiceStatus;
  paidDate: string;
}

export interface Billing {
  id: number;
  number: string;
  description: string;
  customer: Customer;
  creation: string;
  currency: string;
  lineItems: PaymentLineItem[];
  vat: number;
  total: number;
  totalVat: number;
  issueDate: string;
  emitter: PaymentAccount;
  file?: StoredFile;
}

export interface Quote extends Billing {
  status: QuoteStatus;
  expiresAt: string;
  invoiceNumber?: string;
  invoiceId?: number;
  signature?: SignatureRequest;
}

export interface Invoice extends Billing {
  dueDate: string;
  paidDate: string;
  status: InvoiceStatus;
  quoteNumber?: string;
  quoteId?: number;
  onlinePayment: boolean;
  transfer: boolean;
  holdByPlatform?: boolean;
  platformHoldReleasedDate?: string;
}

export interface InvoiceFile {
  id: number;
  number: string;
  status: string;
  file?: StoredFile;
}

export interface PublicInvoice {
  id: number;
  customerId: number;
  status: InvoiceStatus;
  emitter: SmallUser;
  number: string;
  toPay: number;
  currency: string;
  paidDate?: string;
  invoice?: Invoice;
  extPaymentUrl?: string;
  transferTo?: BankAccount;
}

export interface PaymentLineItem {
  id: number;
  name: string;
  description: string;
  quantity: number;
  unitPrice: number;
  currency: string;
  vat: number;
}

export interface SavePaymentLineItem {
  id?: number;
  name: string;
  description: string;
  quantity: number;
  unitPrice: number;
  currency: string;
  vat: number;
}

export interface SaveBilling {
  id?: number;
  number?: string;
  description?: string;
  customerId?: number;
  currency?: string;
  issueDate?: string;
  lineItems?: PaymentLineItem[];
}

export interface SaveQuote extends SaveBilling {
  validityDays?: number;
  status?: QuoteStatus;
}

export interface SaveInvoice extends SaveBilling {
  dueDays?: number;
  status?: InvoiceStatus;
  onlinePayment?: boolean;
  transfer?: boolean;
}

export interface SendBill {
  to: string;
  subject: string;
  text: string;
}

export interface BillingStatusAmount {
  currency: string;
  total: number;
  vat: number;
}

export interface BillingStatusCount {
  count: number;
  amounts: BillingStatusAmount[];
}

export type BillingStatusCountMap = {
  total: BillingStatusCount;
  statuses: {[key in InvoiceStatus]: BillingStatusCount};
}