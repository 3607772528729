import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifComponent } from './notif.component';
import { IonicModule } from '@ionic/angular';
import { IonstackPipesModule } from '../../pipe/ionstack-pipes.module';
import { RouterModule } from '@angular/router';
import { IonstackDirectivesModule } from '../../directive/ionstack-directives.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonstackPipesModule,
    RouterModule,
    IonstackDirectivesModule,
  ],
  declarations: [NotifComponent],
  exports: [NotifComponent]
})
export class NotifModule { }
