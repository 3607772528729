
<ion-item (click)="modal.present()" [button]="true" [detail]="false">
  <ion-label position="stacked"><itxt [key]="label"></itxt>{{ labelSuffix }}</ion-label>
  <ion-text [ngClass]="{empty: !localValue}"><itxt *ngIf="!localValue && placeholder; else valueTpl" [key]="placeholder"></itxt></ion-text>
  <ion-icon *ngIf="clear && localValue" (click)="setValue(null); $event.preventDefault(); $event.stopPropagation()" name="close" size="small" slot="end"></ion-icon>
</ion-item>
<ion-modal [keepContentsMounted]="true" class="ion-datetime-button-overlay" #modal>
  <ng-template>
    <ion-datetime
      [min]="min"
      [max]="max"
      [value]="localValue"
      [firstDayOfWeek]="1"
      [disabled]="isDisabled"
      [cancelText]="'Cancel' | translate"
      [doneText]="'OK' | translate"
      [presentation]="presentation"
      (ionChange)="setValue(getEventValue($event))"
    ></ion-datetime>
  </ng-template>
</ion-modal>

<ng-template #valueTpl>
  {{ localValue ? (localValue | date) + (presentation.includes('time') ? ' ' + (localValue | date:'shortTime') : '') : '-- ---- ----' }}
</ng-template>