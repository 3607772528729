import { findInTree, findTreeLeafIds } from '../util/util';
import { StoredFile } from './files';

export interface CategoryLeaf {
	id?: number;
	i18nKey?: string;
	slug: string;
	illustration?: StoredFile;
	icon?: string;
}

export interface Category extends CategoryLeaf {
	children: Category[];
}

export function getCategoriesAndChildren(categories: Category[]): Category[] {
	const all = [];
	findInTree(categories, (cat: Category) => !all.push(cat), (cat: Category) => cat.children);
	return [...new Set(all)];
}

export function getCategoryLeafs(categories: Category[]): Category[] {
	return getCategoriesAndChildren(categories).filter(c => !c.children?.length);
}

export class CategoryFilter {
	public leafSlugs: string[];
	public leafIds: number[];
	public rootIds: number[];
	public filterIds: number[];

	constructor(
		public filter: Category[] = [],
		public selected: Category[] = [],
	) {
		this.setSelection(filter);
	}

	setSelection(filter: Category[]) {
		this.filter = filter;
		this.filterIds = filter.map(c => c.id);
		this.rootIds = findTreeLeafIds(filter, c => c.children, c => c.id);
		this.selected = getCategoriesAndChildren(this.filter);
		this.leafIds = [];
		this.leafSlugs = [];
		getCategoryLeafs(this.selected).forEach(c => {
			this.leafIds.push(c.id);
			this.leafSlugs.push(c.slug);
		});
		return this;
	}

}