import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Widget, WidgetParams, interpolateString } from '@adeprez/ionstack';
import { RichtextEditPopupComponent } from './richtext-edit-popup/richtext-edit-popup.component';

@Component({
  selector: 'ionstack-richtext',
  templateUrl: './richtext.component.html',
  styleUrls: ['./richtext.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'text',
  widgetName: 'Rich text',
  icon: 'text',
  widgetDescription: 'Rich text formatted without translations',
  editorPopup: RichtextEditPopupComponent,
  editorPopupClass: 'large-modal',
  contentType: 'text',
})
export class RichtextComponent {
  @Input() content: any;

  constructor() { }

  @Input() set widget(params: WidgetParams) {
    this.content = interpolateString(params.data?.content, params.context?.data)?.replace(/\n/g, '\\n');
  }

}

export function richToPlainText(rich: string) {
  let text = '';
  const data = JSON.parse(rich || '{}');
  for (const op of data.ops || []) {
    if (typeof op.insert === 'string') {
      text += op.insert;
    }
  }
  return text;
}
