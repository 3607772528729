import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textBoldMatcher',
  pure: true
})
export class TextBoldMatcherPipe implements PipeTransform {

  transform(value: string, keyword?: string): any {
    if (keyword && value) {
      return value.replace(this.getRegex(keyword), str => str.bold());
    }
    return value;
  }

  getRegex(value: string, flags = 'gi') {
    return new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), flags);
  }

}
