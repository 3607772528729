import { StoredFileDetails, WidgetPopupBase, parseWidgetContent } from '@adeprez/ionstack';
import { EditFileComponent } from '../edit-file/edit-file.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ionstack-edit-widget-file-popup',
  templateUrl: './edit-widget-file-popup.component.html',
  styleUrls: ['./edit-widget-file-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditWidgetFilePopupComponent extends WidgetPopupBase implements OnInit {
  @ViewChild(EditFileComponent) editFileComponent: EditFileComponent;
  @Input() filenameName = 'Short description';
  fileId: number;
  ready = false;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  saveHandler = async (data: {content: string}) => {
    await this.editFileComponent.submit();
    const updated = JSON.parse(data.content || '{}');
    updated.id = this.fileId;
    data.content = JSON.stringify(updated);
  };

  fileSetter = async (file: StoredFileDetails) => {
    this.fileId = file.id;
    this.cd.markForCheck();
  };

  ngOnInit() {
    this.fileId = parseWidgetContent(this.infos.meta, this.widget)?.id;
  }

}
