import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Context } from '../model/context';
import { ContextService } from '../service/context.service';
import { CleanSubscriber } from '../util/subscriber';

@Component({
  selector: 'ionstack-page',
  template: '',
})
export class IonstackPage extends CleanSubscriber implements OnDestroy {
  public pageIsInTransition = false;
  public pageIsVisible = true;
  public error: any;

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  ionViewWillEnter() {
    this.pageIsVisible = true;
    this.pageIsInTransition = true;
    this.cd.detectChanges();
  }

  ionViewDidEnter() {
    this.pageIsInTransition = false;
    this.cd.detectChanges();
  }

  ionViewWillLeave() {
    this.pageIsInTransition = true;
    this.cd.detectChanges();
  }

  ionViewDidLeave() {
    this.pageIsVisible = false;
    this.pageIsInTransition = false;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  async try(runnable: Promise<void | any> | (() => Promise<void | any> | (() => void | any))): Promise<void | any> {
    try {
      const r = typeof runnable === 'function' ? await runnable() : await runnable;
      this.error = null;
      return r;
    } catch (e) {
      console.error(e);
      this.setError(e);
    }
  }

  setError(e: any) {
    this.error = e;
    this.cd.detectChanges();
  }

  withContext(contextService: ContextService, cb: (context: Context) => Promise<void | any> | any, name = 'context') {
    this.subscribe<Context>(contextService.context, context => this.try(cb(context)).then(() => this.cd.markForCheck()), {
      name: name,
      replace: true,
      error: e => this.setError(e),
    });
  }

}
