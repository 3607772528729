import { Pageable } from '../../model/page';
import { NotificationService } from '../../service/notification.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { CleanSubscriber } from '../../util/subscriber';
import { extractNotificationTypes, Notif } from '../../model/notification';

@Component({
  selector: 'ionstack-notif-list',
  templateUrl: './notif-list.component.html',
  styleUrls: ['./notif-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotifListComponent extends CleanSubscriber implements OnInit, OnDestroy {
  notifs = new Pageable<Notif>(5, ['creationDate,desc']);
  @Input() notifListRoute: string[] | string;
  @Input() onAction: () => void;

  constructor(
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
  ) {
    super();
    this.notifs.setLoader(req => this.notificationService.getNotifications(req));
  }

  ngOnInit() {
    this.subscribe(this.notifs.onChange, () => this.cd.markForCheck());
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  @Input() set notifType(typesP: string) {
    const { types, param} = extractNotificationTypes(typesP ?? '!');
    this.notifs.setParam(param, types).loadPage();
  }

}
