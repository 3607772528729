import { Widget, DatasourceService } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Inject, Optional } from '@angular/core';
import { Component } from '@angular/core';
import { BaseFormWidget, BASE_META_FIELDS, FORM_META_FIELDS, WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['../input-style.scss', './textarea-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'textarea',
  widgetName: 'Text area',
  group: 'Forms',
  icon: 'create',
  widgetDescription: 'Multiline text input',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    ...BASE_META_FIELDS,
    FORM_META_FIELDS.rows,
    FORM_META_FIELDS.maxLength,
    FORM_META_FIELDS.placeholder,
    FORM_META_FIELDS.position,
    FORM_META_FIELDS.autoGrow,
    FORM_META_FIELDS.color,
  ]
})
export class TextareaInputComponent extends BaseFormWidget {

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) form: WidgetParentForm,
    datasourceService: DatasourceService,
    public cd: ChangeDetectorRef,
  ) {
    super(form, datasourceService);
  }

}