import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconpickerComponent } from './iconpicker.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, IonstackDirectivesModule } from '@adeprez/ionstack';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    IonstackDirectivesModule,
  ],
  declarations: [IconpickerComponent],
  exports: [IconpickerComponent]
})
export class IconpickerModule { }
