import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from '../model/date';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string | Date, compareTo?: Date | string, opts: {
    omitHour?: boolean,
    omitSeconds?: boolean,
  } = {}): any {
    if (!compareTo) {
      compareTo = new Date();
    }
    const d = new Date(value);
    if (isNaN(d.getTime())) {
      return '';
    }
    if (!opts.omitHour && isSameDay(d, compareTo)) {
      if (d.getSeconds() && !opts.omitSeconds) {
        return 'mediumTime';
      }
      if (d.getMinutes()) {
        return d.getHours() < 12 ? 'H:mm' : 'shortTime';
      }
      return 'H\'h\'';
    }
    if (d.getFullYear() === new Date(compareTo).getFullYear()) {
      if (opts.omitHour) {
        return 'd MMMM';
      }
      if (d.getSeconds() && !opts.omitSeconds) {
        return 'd MMMM H:mm:ss';
      }
      if (d.getMinutes()) {
        return 'd MMMM H:mm';
      }
      return 'd MMMM H\'h\'';
    }
    if (opts.omitHour) {
      return 'd MMMM yyyy';
    }
    return 'short';
  }

}
