import { Injectable, Injector } from '@angular/core';
import { WIDGET, WidgetInfo, WidgetMeta } from '../model/widget';
import 'reflect-metadata';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  private widgets: {[id: string]: WidgetInfo} = {};

  constructor(private injector: Injector) {}

  private collectWidgets() {
    for (const widget of this.injector.get(WIDGET, [])) {
      const meta = Reflect.getMetadata('widgetMeta', widget) as WidgetMeta;
      this.widgets[meta.widgetID] = {type: widget, meta};
    }
  }

  getWidgetInfos() {
    this.collectWidgets();
    const list = Object.values(this.widgets);
    return list.sort((a, b) => a.meta.widgetName.localeCompare(b.meta.widgetName));
  }

  getWidget(type: string): WidgetInfo {
    let widget = this.widgets[type];
    if (!widget) {
      this.collectWidgets();
      widget = this.widgets[type];
    }
    if (!widget) {
      console.error('widget not found: ' + type);
    }
    return widget;
  }

  getWidgetInfosGroups() {
    const groups: {[group: string]: WidgetInfo[]} = {};
    for (const w of this.getWidgetInfos()) {
      const g = w.meta.group || '';
      if (!(g in groups)) {
        groups[g] = [];
      }
      groups[g].push(w);
    }
    return Object.values(groups).map(children => ({group: children[0].meta.group ?? '', children})).sort((g1, g2) => g1.group.localeCompare(g2.group));
  }
  
}
