import { DOCUMENT } from '@angular/common';
import { ContextService } from './context.service';
import { CleanSubscriber } from '../util/subscriber';
import { InjectionToken, OnDestroy, Optional } from '@angular/core';
import { MODULE_CONFIG, IonstackModuleConfig } from '../ionstack.config';
import { Inject, Injectable } from '@angular/core';
import { initializeApp } from '@firebase/app';
import { getAuth, Auth, RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult } from '@firebase/auth';
import { Context } from '../model/context';

export interface FirebaseServiceErrorHandler {
  phoneVerificationError?(e: any): Promise<boolean>;
}

export const FirebaseServiceErrorHandler = new InjectionToken<FirebaseServiceErrorHandler>('FirebaseServiceErrorHandler');

@Injectable({
  providedIn: 'root'
})
export class FirebaseService extends CleanSubscriber implements OnDestroy {
  readonly enabled: boolean;
  readonly auth: Auth;

  constructor(
    private contextService: ContextService,
    @Optional() @Inject(FirebaseServiceErrorHandler) private errorHandler: FirebaseServiceErrorHandler,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    @Inject(DOCUMENT) private document: any,
  ) {
    super();
    if (this.enabled = !!ionstackModuleConfig.firebase) {
      initializeApp(this.ionstackModuleConfig.firebase);
      this.auth = getAuth();
      this.init();
    }
  }

  private init() {
    this.subscribe<Context>(this.contextService.context, context => {
      this.auth.languageCode = context.userLanguage;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  createRecaptcha() {
    const el = this.document.createElement('div');
    this.document.body.appendChild(el);
    return new RecaptchaVerifier(el, {
      size: 'invisible',
      callback: () => this.document.body.removeChild(el),
      'expired-callback': () => {}
    }, this.auth);
  }

  async requestVerification(phoneNumber: string): Promise<ConfirmationResult> {
    if (!this.enabled) throw new Error('Phone verification is not enabled');
    const verifier = this.createRecaptcha();
    try {
      return await signInWithPhoneNumber(this.auth, phoneNumber, verifier);
    } catch (e) {
      const handled = this.errorHandler?.phoneVerificationError?.(e);
      if (handled == null || !(await handled)) {
        throw e;
      }
    }
  }

}
