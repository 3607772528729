import { WidgetChildrenHolder, WidgetContext, WidgetPopupBase, WidgetPopupBaseInputs } from '../model/widget';
import { Injectable, InjectionToken, Type, Injector } from '@angular/core';
import { supportsWidgetChild, WidgetData, WidgetInfo, ParentWidgetHolder } from '../model/widget';
import { ModalController } from '@ionic/angular';
import { WidgetService } from '../service/widget.service';
import { ComponentRef } from '@ionic/core';
import { randomString } from '../util/util';

@Injectable({
  providedIn: 'root'
})
export class EditWidgetController {

  constructor(
    private modalController: ModalController,
    private widgetService: WidgetService,
    private injector: Injector,
  ) {}

  async pickWidget(filter: 'all' | string[], picker: ComponentRef, title: string, cb: (choice: WidgetInfo[]) => Promise<void>) {
    const popup = await this.modalController.create({
      component: picker,
      animated: false,
      componentProps: {
        title,
        items: this.widgetService.getWidgetInfos().filter(wi => supportsWidgetChild(filter, wi.meta.widgetID)),
        labelGetter: (w: WidgetInfo) => w.meta.widgetName,
        subtitleGetter: (w: WidgetInfo) => w.meta.widgetDescription,
        iconGetter: (w: WidgetInfo) => w.meta.icon,
        onCancel: () => popup.dismiss(),
        onSubmit: async (widgets: WidgetInfo[]) => {
          await popup.dismiss();
          await cb(widgets);
        }
      }
    });
    popup.present();
  }
  
  async addWidget(filter: 'all' | string[], cb: (data: WidgetData) => void, args: {
    context: WidgetContext, parentHolder: ParentWidgetHolder, container: WidgetChildrenHolder, component: ComponentRef
  }) {
    const index = args.container?.value?.length ?? 0;
    await this.pickWidget(filter, args.component, 'admin.Add widget', async (widgets: WidgetInfo[]) => {
      for (const w of widgets) {
        await this.editWidget({
          infos: w,
          context: args.context,
          parentHolder: args.parentHolder,
          container: args.container,
          index,
          siblings: index,
          widget: {
            type: w.meta.widgetID,
            ...(w.meta.newInstance ?? {}),
            uuid: randomString(6),
          },
        }, cb);
      }
    });
  }

  async changeWidgetType(filter: 'all' | string[], picker: ComponentRef, widget: WidgetData, cb: (change: {data: WidgetData, infos: WidgetInfo}) => void) {
    await this.pickWidget(filter, picker, 'admin.Change widget', async (widgets: WidgetInfo[]) => {
      if (widgets.length) {
        cb({infos: widgets[0], data: {...widget, type: widgets[0].meta.widgetID}});
      }
    });
  }

  async editWidget(opts: WidgetPopupBaseInputs, cb: (data: WidgetData) => void) {
    const modal = await this.modalController.create({
      component: this.getWidgetPopupType(opts.infos.meta.editorPopup),
      animated: false,
      componentProps: opts,
      cssClass: opts.infos.meta.editorPopupClass,
    });
    await modal.present();
    const data = (await modal.onWillDismiss()).data;
    if (data) {
      cb({...opts.widget, ...data});
    }
  }

  getWidgetPopupType(arg: Type<WidgetPopupBase> | InjectionToken<Type<WidgetPopupBase>>): Type<WidgetPopupBase> {
    if (arg instanceof Type) {
      return arg;
    }
    return this.injector.get(arg);
  }
  
}
