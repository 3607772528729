<div [style.--default-file-width]="config.height || '120px'" class="cn dropzone" ionstackFileDrop (fileDropped)="browser.uploadFileList($event)" [ngClass]="{reorder: dragging}">
  <ng-container *ngFor="let control of array.controls; let i = index">
    <ng-template *ngIf="before" [ngTemplateOutlet]="before" [ngTemplateOutletContext]="{$implicit: control.value}"></ng-template>
    <div *ngIf="dragging && i < draggingIndex" class="drop" [ionstackDrop]="true" (droppedIn)="reorder($event, i)"></div>
    <ionstack-editable-file
      [config]="getBrowseConfig | call:config"
      [formControl]="$any(control)"
      [visible]="visible"
      [file]="getFile | call:control.value:cacheBuster"
      [allowExtraUpload]="!config.disallowMultiupload"
      [canDrop]="false"
      (valueChange)="changed($event, i)"
      (onExtraUpload)="browser.uploadExtraFile($event)"
      (onExtraFile)="addFile($event)"
      [ionstackDrag]="i"
      (dragStart)="setDraggingIndex($event)"
      class="file"
    ></ionstack-editable-file>
    <div *ngIf="dragging && i > draggingIndex" class="drop" [ionstackDrop]="true" (droppedIn)="reorder($event, i)"></div>
    <ng-template *ngIf="after" [ngTemplateOutlet]="after" [ngTemplateOutletContext]="{$implicit: control.value}"></ng-template>
  </ng-container>
  <div *ngFor="let u of uploading" class="file uploading" [style.height]="config.height || '120px'">
    <ion-spinner></ion-spinner>
  </div>
  <ionstack-browse-file
    #browser
    [config]="getBrowseConfig | call:config"
    (valueChange)="addFile($event)"
    (uploadingChange)="uploading = $event"
  ></ionstack-browse-file>
</div>
