import { RichtextEditorModule } from './../richtext-editor/richtext-editor.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichtextEditPopupComponent } from './richtext-edit-popup.component';
import { EditWidgetPopupModule } from '@adeprez/ionstack/ui/edit-widget-popup';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    EditWidgetPopupModule,
    FormsModule,
    RichtextEditorModule,
  ],
  declarations: [RichtextEditPopupComponent],
  exports: [RichtextEditPopupComponent],
})
export class RichtextEditPopupModule { }
