<ion-list (click)="onAction ? onAction() : 0">
  <ion-list-header>
    <ion-item lines="none">
      <ion-label><itxt key="Notifications"></itxt></ion-label>
      <ion-button *ngIf="notifListRoute" fill="clear" [routerLink]="notifListRoute" slot="end" size="small">
        <itxt key="See all"></itxt>
      </ion-button>
    </ion-item>
  </ion-list-header>

  <ionstack-notif *ngFor="let notif of notifs.data" [notif]="notif"></ionstack-notif>

</ion-list>