<ion-header>
  <ion-toolbar>
    <ion-title><ion-icon name="ticket" class="vmiddle ion-margin-end"></ion-icon> <itxt key="plan.Upgrade"></itxt></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()"><ion-icon slot="icon-only" name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="light" class="ion-padding">
  <div *ngIf="subscribed; else subscribeTemplate" class="subscribed">
    <ion-icon name="happy"></ion-icon>
    <itxt key="plan.Subscribed"></itxt>
    <ion-button (click)="close()">
      <ion-label><itxt key="plan.Done"></itxt></ion-label>
    </ion-button>
  </div>
</ion-content>

<ng-template #subscribeTemplate>
  <form [formGroup]="form">

    <ng-container *ngIf="!selectedPlan; else payTemplate">
      <div class="heading">
        <div *ngIf="source === 'error'" class="locked">
          <ion-icon name="lock-open"></ion-icon> <itxt key="plan.Locked feature"></itxt>
        </div>
        <p><itxt key="plan.Description"></itxt></p>
      </div>
      <div class="plans">
        <div *ngFor="let plan of plans" class="plan glass opaque">
          <ion-label class="title"><itxt [key]="getPlanI18nKey | call:plan"></itxt></ion-label>
          <ion-chip *ngIf="active && active.includes(plan.name); else notActiveTemplate" color="success"><itxt key="plan.active"></itxt></ion-chip>
          <ng-template #notActiveTemplate>
            <div *ngIf="plan.trialDays" class="free-trial"><itxt key="plan.Free trial" [args]="{n: plan.trialDays}"></itxt></div>
            <div *ngIf="plan.monthlyPrice != null">
              <itxt key="plan.then" class="then" *ngIf="plan.trialDays"></itxt>&nbsp;
              <span class="price">{{ plan.monthlyPrice | currency:plan.currency }}</span> / <itxt [key]="'plan.' + recurrences.monthly"></itxt>
            </div>
            <div *ngIf="plan.yearlyPrice != null">
              <itxt [key]="plan.monthlyPrice ? 'plan.or' : 'plan.then'" class="then" *ngIf="plan.trialDays || plan.monthlyPrice"></itxt>&nbsp;
              <span class="price">{{ plan.yearlyPrice | currency:plan.currency }}</span> / <itxt [key]="'plan.' + recurrences.yearly"></itxt>
            </div>
          </ng-template>
          <p class="desc"><itxt [key]="'plan.' + plan.name + '-text'"></itxt></p>
          <ion-button expand="block" [awaitClick]="setPlan" [awaitClickArg]="plan" [bindAwaitClickTo]="this" type="button">
            <ion-label><itxt [key]="active && active.includes(plan.name) ? 'plan.Update' : 'plan.Choose'"></itxt></ion-label>
          </ion-button>
        </div>
      </div>
    </ng-container>

    <ng-template #payTemplate>
      <div class="selection" *ngIf="paymentStep">
        <ion-radio-group formControlName="recurrence">
          <ion-list>
            <ion-list-header class="infos">
              <ion-buttons>
                <ion-button [awaitClick]="resetPlan" [bindAwaitClickTo]="this" type="button">
                  <ion-icon slot="icon-only" icon="arrow-back"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-label class="ion-margin-start title"><itxt [key]="getPlanI18nKey | call:selectedPlan"></itxt></ion-label>
            </ion-list-header>
            <ion-item class="padding-start" *ngIf="selectedPlan.monthlyPrice != null">
              <ion-radio slot="start" [value]="recurrences.monthly"></ion-radio>
              <ion-label><span class="price">{{ selectedPlan.monthlyPrice | currency:selectedPlan.currency }}</span> / <itxt [key]="'plan.' + recurrences.monthly"></itxt></ion-label>
            </ion-item>
            <ion-item class="padding-start" *ngIf="selectedPlan.yearlyPrice != null">
              <ion-radio slot="start" [value]="recurrences.yearly"></ion-radio>
              <ion-label><span class="price">{{ selectedPlan.yearlyPrice | currency:selectedPlan.currency }}</span> / <itxt [key]="'plan.' + recurrences.yearly"></itxt></ion-label>
            </ion-item>
          </ion-list>
        </ion-radio-group>
        <div class="pay-title">
          <ion-icon name="wallet-outline" size="large" class="vmiddle"></ion-icon>
          <itxt class="ion-margin-start vmiddle" key="plan.Payment method"></itxt>
        </div>
        <ionstack-payment-form formControlName="paymentMethodId" (onConfirm)="paymentSet()"></ionstack-payment-form>
      </div>
    </ng-template>

    <div class="ion-text-center" *ngIf="form.valid && !paymentStep">
      <ion-buttons>
        <ion-button (click)="form.controls.paymentMethodId.value == null ? setPlan(null) : paymentStep = true" type="button">
          <ion-icon slot="icon-only" icon="arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
      <div class="summary">
        <div class="infos">
          <div><ion-label><itxt key="plan.Plan"></itxt></ion-label> : <ion-text><itxt [key]="getPlanI18nKey | call:selectedPlan"></itxt></ion-text></div>
          <div>
            <ion-text>
              <itxt *ngIf="freeDays" key="plan.Free trial then" [args]="{n: freeDays}"></itxt>
              {{ (getPrice | call:selectedPlan:form.controls.recurrence.value) | currency:selectedPlan.currency }} / <itxt [key]="'plan.' + form.controls.recurrence.value"></itxt>
            </ion-text>
          </div>
          <div class="info" *ngIf="selectedPlan.vat">
            <itxt key="plan.VAT" [args]="{
              percent: selectedPlan.vat,
              vat: ((getPrice | call:selectedPlan:form.controls.recurrence.value) * selectedPlan.vat / 100) | currency:selectedPlan.currency
            }"></itxt>
          </div>
        </div>
        <ion-button type="submit" expand="block" [awaitClick]="startSubscription" [bindAwaitClickTo]="this">
          <ion-icon slot="start" name="ticket"></ion-icon>
          <ion-label><itxt key="plan.Start subscription"></itxt></ion-label>
        </ion-button>
      </div>
    </div>
  </form>
</ng-template>