import { AlertService, consumeWidgetContent, Widget, WidgetParams, Action, ActionService, FormSubmitData } from '@adeprez/ionstack';
import { SimpleFormComponent } from '@adeprez/ionstack/ui/simple-form';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ionstack-simple-form-widget',
  templateUrl: '../../ui/simple-form/simple-form.component.html',
  styleUrls: ['../../ui/simple-form/simple-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'form',
  widgetName: 'Simple form',
  group: 'Forms',
  icon: 'clipboard',
  widgetDescription: 'A form to fill and submit',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    {name: 'fields', type: 'multiline-text', label: 'JSON form fields', placeholder: '{"name":"email","label":"Email","type":"email"}, ...'},
    {name: 'saveActionName', label: 'Save action identifier'},
    {name: 'title', label: 'Title', optional: true},
    {name: 'okText', label: 'OK text', value: 'Save', optional: true},
    {name: 'cancelText', label: 'Cancel text', value: 'Cancel', optional: true},
    {name: 'toolbarColor', label: 'Toolbar color', optional: true},
  ]
})
export class SimpleFormWidgetComponent<T> extends SimpleFormComponent<T> implements OnDestroy {
  @Input() saveAction: Action;

  constructor(
    private actionService: ActionService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data, {
      fields: (json: string) => json ? JSON.parse('[' + json + ']') : []
    });
  }

  @Input() set saveActionName(name: string) {
    this.saveAction = this.actionService.getAction(name);
  }

  reset() {
    super.reset();
    this.cd.markForCheck();
  }

  async submit() {
    if (this.saveAction) {
      try {
        const data: FormSubmitData = {
          routeSnapshot: this.activatedRoute.snapshot,
          data: this.formGroup.value,
          source: this,
        };
        await this.saveAction(data);
        await super.submit();
      } catch (e) {
        this.alertService.showError(e);
      }
    }
  }

}