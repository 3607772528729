import { InjectionToken } from '@angular/core';
import { toUrlString } from '../util/util';

type ValueOrFn<T> = T | ((...args: any[]) => T);

export type RouteQuery = {[param: string]: string | number};
export type RouteParts = (string | number)[];

export type FullRoute = {
  path: RouteParts;
  query?: RouteQuery;
  click?: () => Promise<void>;
}

export type SingleRouteProvider = ValueOrFn<RouteParts>;
export type SingleRouteQueryProvider = ValueOrFn<RouteQuery>;

export type FullRouteProvider = ValueOrFn<RouteParts> | {
  path: SingleRouteProvider;
  query?: SingleRouteQueryProvider;
} | ((...args: any[]) => ({
  path: SingleRouteProvider,
  query: SingleRouteQueryProvider
}));

export interface RoutesProvider {
  [routeName: string]: FullRouteProvider;
}

export const RoutesProvider = new InjectionToken<RoutesProvider>('RoutesProvider');

export function routeToUrl(route: FullRoute) {
  let url = (route.path ?? []).join('/');
  if (route.path?.[0] === '/') {
    url = url.substring(1);
  }
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return toUrlString(url, route.query);
}

export function urlToRoute(url: string): FullRoute {
  url = url.replace(/^https?:\/\/[^/]+/, '');
  const parts = url.split('?');
  const path = parts[0].split('/');
  path[0] = '/';
  const query = parts[1] ? (new URLSearchParams(parts[1])).entries() : undefined;
  return {
    path,
    query: query ? Object.fromEntries(query) : undefined
  };
}

export function isFullUrl(url: string) {
  return url && (url.startsWith('http://') || url.startsWith('https://'));
}