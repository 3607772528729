import { FormControlComponent, Pageable, searchable } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ChangeDetectorRef, forwardRef, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PickerInputComponent),
    multi: true
  }],
  selector: 'ionstack-picker-input',
  templateUrl: './picker-input.component.html',
  styleUrls: ['./picker-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerInputComponent<T, V> extends FormControlComponent<V> {
  @Output() valueChange = new EventEmitter<V>();
  @Input() labelPosition = 'floating';
  @Input() from: Pageable<T>;
  @Input() filterSearch = true;
  @Input() title: string;
  @Input() height = 'auto';
  @Input() selected: T;
  @Input() placeholder: string;
  @Input() searchPlaceholder = 'Search';
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Ok';
  @Input() confirm = false;
  @Input() labelGetter: (value: T) => string = (value: T) => value + '';
  @Input() subtitleGetter: (value: T) => string;
  @Input() iconGetter: (value: T) => string;
  @Input() isDisabled: boolean;
  @Input() valueGetter: (value: T) => V;
  @Input() searchParam = 'search';
  @Input() initValue: T;
  @Input() clearInput: boolean;
  search: string;
  currentSearch: string;
  displayValue: T;
  readonly searchable = searchable;
  private _value: V;
  private _loader: (v: V) => Promise<T>;

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  setSearch(search: string) {
    this.currentSearch = search;
    this.from.setParam(this.searchParam, searchable(search)).loadPage();
  }

  @Input() set loader(loader: (v: V) => Promise<T>) {
    this._loader = loader;
    this.updateDisplay();
  }

  get loader() {
    return this._loader;
  }

  @Input() set value(value: V) {
    this._value = value;
    this.updateDisplay();
  }

  get value() {
    return this._value;
  }

  load() {
    this.search = this.currentSearch;
    if (this.from && !this.from.inited) {
      this.from.loadPage();
    }
  }

  pickValue(value: T) {
    this.displayValue = value;
    this.setValue(this.valueGetter(value));
    this.cd.markForCheck();
  }

  writeValue(obj: V): void {
    this.value = obj;
    this.updateDisplay();
  }

  updateDisplay() {
    if (!this.value) {
      this.displayValue = null;
    } else if (!this.displayValue || this.value !== this.valueGetter(this.displayValue)) {
      this.displayValue = this.from.data.find(i => this.valueGetter(i) === this.value);
      if (!this.displayValue) {
        if (this.initValue && this.valueGetter(this.initValue) === this.value) {
          this.displayValue = this.initValue;
        } else if (this.loader) {
          this.loader(this.value).then(v => {
            this.displayValue = v;
            this.cd.markForCheck();
          });
        }
      }
      this.cd.markForCheck();
    }
  }

}
