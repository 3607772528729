import { Inject, Injectable, Optional } from '@angular/core';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Platform } from '@ionic/angular';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from '../model/social';
import { SOCIAL_AUTH_SERVICE } from '../ionstack.config';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  readonly providers: string[];

  constructor(
    private userService: UserService,
    private platform: Platform,
    @Optional() private facebook: Facebook,
    @Optional() @Inject('SocialAuthServiceConfig') config: any,
    @Optional() @Inject(SOCIAL_AUTH_SERVICE) private socialAuthService: SocialAuthService,
  ) {
    if (config) {
      this.providers = socialAuthService ? (config.providers || []).map((p: any) => p.id) : [];
    }
  }

  async mobileLoginForToken(provider: string): Promise<string> {
    if (provider === FacebookLoginProvider.PROVIDER_ID) {
      return (await this.facebook.login(['email', 'public_profile'])).authResponse.accessToken
    }
    throw 'Unknown provider: ' + provider;
  }

  getToken(provider: string, socialUser: SocialUser) {
    if (provider == FacebookLoginProvider.PROVIDER_ID) {
      return socialUser.authToken;
    }
    return socialUser.idToken;
  }

  async login(provider: string) {
    let token: string;
    if (this.platform.is('capacitor') || this.platform.is('cordova')) {
      token = await this.mobileLoginForToken(provider);
    } else {
      token = this.getToken(provider, await this.socialAuthService.signIn(provider));
    }
    await this.userService.continueWithSocial(provider, token);
  }

}
