import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export type OTPChannel = 'EMAIL' | 'SMS';

export type OTPBasicType = 'REGISTER_VERIFY' | 'LOG_IN' | 'RESET_PASSWORD';

export interface OTPUrlParam {
	action: OTPBasicType | string;
	login: string;
	secret: string;
	channel: OTPChannel;
}

export function otpActionName(type: OTPBasicType | string) {
	return 'OTP_' + type;
}

export type OTPHandlerResult = boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>;
export type OTPHandlerFn = (otp: OTPUrlParam) => OTPHandlerResult;

export interface OTPRequest {
	login: string;
	extra?: string;
}
