import { DatasourceService, SimpleFormField, Widget } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { BaseFormWidget, BASE_META_FIELDS, WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';

interface FileInputConfig extends SimpleFormField {
  config?: string;
}

@Component({
  selector: 'ionstack-file-input-widget',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Widget({
  widgetID: 'editfile',
  widgetName: 'Editable media',
  group: 'Forms',
  icon: 'document-attach',
  widgetDescription: 'Attach a media',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    ...BASE_META_FIELDS,
    { name: 'config', label: 'Config', optional: true },
  ]
})
export class FileInputComponent extends BaseFormWidget<FileInputConfig> {

  readonly parse = (json: string) => JSON.parse(json || '{}');

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) form: WidgetParentForm,
    datasourceService: DatasourceService,
    public cd: ChangeDetectorRef,
  ) {
    super(form, datasourceService);
  }

}
