<input
  type="file"
  (change)="fileChangeEvent($event)"
  [accept]="formatExtensions | call:extensionSupport:requiredExtension"
  [multiple]="allowExtraUpload"
  #input
  style="display:none"
/>

<ion-toolbar *ngIf="!hideToolbar || recorder">
  <ion-buttons slot="secondary" *ngIf="(value || url || extUrl) && !stream">
    <ion-button (click)="reset()" color="danger">
      <ion-icon name="close" slot="icon-only" size="small"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="primary">
    <ion-button [awaitClick]="capture" [bindAwaitClickTo]="this" *ngIf="!stream && captureEnabled && !browseAllowed">
      <ion-icon slot="start" [name]="captureIcon || 'document'"></ion-icon>
      <ion-label *ngIf="!url"><itxt [key]="captureLabel"></itxt></ion-label>
    </ion-button>
    <ion-button [awaitClick]="stopRecord" [bindAwaitClickTo]="this" *ngIf="recorder">
      <ion-icon slot="start" name="stop-circle"></ion-icon>
      <ion-label><itxt key="Finish record"></itxt></ion-label>
      <ion-label slot="end" class="time">{{ time | async }}″</ion-label>
    </ion-button>
    <ion-button (click)="openFileInput()" *ngIf="!stream && browseAllowed">
      <ion-icon slot="start" [name]="browseIcon"></ion-icon>
      <ion-label *ngIf="!url"><itxt [key]="browseText"></itxt></ion-label>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ng-container *ngIf="!url && !stream && !extUrl">
  <div ionstackFileDrop (fileDropped)="onDrop($event)" class="dropzone" *ngIf="browseAllowed; else startCapture">
    <div class="empty" (click)="openFileInput()">
      <ion-icon [name]="browseIcon"></ion-icon>
      <div class="types">{{ getExtensionDisplay | call:fileType:requiredExtension }}</div>
    </div>
  </div>
</ng-container>

<ng-content></ng-content>

<ionstack-file-display
  *ngIf="url && !stream && !uploading"
  [config]="{height: previewHeight}"
  [file]="{extUrl: url, fileType: fileType, file: null, extension: 'ext', public: false, name: filename}"
></ionstack-file-display>

<div *ngIf="allowExtUrl && !value && (!url || url === extUrl) && !recorder" class="ext-link">
  <div class="hint" *ngIf="!extUrl"><itxt [key]="extUrlText"></itxt></div>
  <ion-item color="light">
    <ion-label position="floating"><itxt [key]="extUrlLabel"></itxt></ion-label>
    <ion-input [(ngModel)]="extUrl" [maxlength]="250" #urlInput type="url" autocomplete="url" [placeholder]="extUrlPlaceholder"></ion-input>
  </ion-item>
</div>

<ng-template #startCapture>
  <div class="start-capture">
    <ion-button [awaitClick]="capture" [bindAwaitClickTo]="this">
      <ion-icon [name]="captureIcon || 'document'" slot="icon-only"></ion-icon>
    </ion-button>
  </div>
</ng-template>