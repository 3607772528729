<div class="autocomplete" [ngClass]="{expand: expand}">
    <ion-input
        [placeholder]="placeholder | translate"
        [autocomplete]="autocomplete"
        [maxlength]="maxLength"
        [value]="inputText"
        [disabled]="isDisabled"
        [clearInput]="clear"
        (ionChange)="inputChanged($event)"
        (ionFocus)="changed($event, false)"
        (keydown.shift.tab)="dismiss()"
        (keydown.tab)="dismiss()"
        (keydown.arrowDown)="highlightItem(1, $event)"
        (keydown.arrowUp)="highlightItem(-1, $event)"
        (keydown.enter)="submit()"
        #input
    ></ion-input>
    <div class="select-icon" role="presentation" part="icon" (click)="changed($event, true)" *ngIf="expand && !clear && with?.length">
        <div class="select-icon-inner"></div>
    </div>
</div>