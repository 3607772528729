import { Input, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'htmlLinks',
  pure: true,
})
export class HtmlLinksPipe implements PipeTransform {
  @Input() linkClass = 'a';
  
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (!value) {
      return value;
    }
    // url
    value = value.replace(
      /(?:http(s)?:\/\/|(www\.))([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))/g,
      '<a class="a" href="http$1://$2$3" target="_blank" rel="noopener">$3</a>'
    );
    // mail
    value = value.replace(/((?:[a-zA-Z0-9_\-\.+]+)@(?:[a-zA-Z0-9_\-\.]+)\.(?:[a-zA-Z]{2,5}))/g, '<a class="a" href="mailto:$1">$1</a>');
    //phone
    value = value.replace(/([\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6})/g, '<a class="a" href="tel:$1">$1</a>');
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

}
