import { CleanSubscriber, FormControlComponent, insertFormControl, SimpleFormField } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SimpleFormComponent),
    multi: true
  }],
  selector: 'ionstack-simple-form',
  templateUrl: './simple-form.component.html',
  styleUrls: ['./simple-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFormComponent<T> extends FormControlComponent<T> implements OnDestroy {
  @Output() valueChange = new EventEmitter<T>();
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<void>();
  @Input() formClass: string;
  @Input() translateTitle = true;
  @Input() cancelText = 'Cancel';
  @Input() okText = 'Save';
  @Input() title: string;
  @Input() submitButtons = true;
  @Input() color = 'light';
  @Input() toolbarColor: string;
  @Input() value: T;
  @Input() isDisabled: boolean;
  private sub = new CleanSubscriber();
  private _fields: SimpleFormField[];
  formGroup: UntypedFormGroup;
  controls: {[name: string]: UntypedFormControl};

  constructor(
    public cd: ChangeDetectorRef,
  ) {
    super();
    this.formGroup = new UntypedFormGroup({});
  }

  getControl = (field: SimpleFormField, controls: {[name: string]: UntypedFormControl}) => {
    return controls[field.name];
  }

  ngOnDestroy() {
    this.sub.unsubscribeAll();
  }

  @Input() set fields(fields: SimpleFormField[]) {
    this._fields = fields ? fields.filter(f => f) : [];
    this.controls = {};
    this.formGroup = new UntypedFormGroup({});
    for (const field of this._fields) {
      if (field.name) {
        let value = this.value ? this.value[field.name] : (typeof field.value === 'function' ? field.value() : field.value);
        if (field.type === 'checkbox' && typeof value === 'string') {
          value = value === 'true' || value === '1' || value === 'on';
        }
        const control = new UntypedFormControl(value);
        this.controls[field.name] = control;
        insertFormControl(field.name, this.formGroup, control);
      }
    }
    this.sub.subscribe<any>(this.formGroup.valueChanges, value => this.setValue(value), {name: 'form', replace: true});
  }

  writeValue(value: T) {
    super.writeValue(value);
    if (value) {
      this.formGroup.patchValue(value, {emitEvent: false});
    }
  }

  reset() {
    this.fields = [...this._fields];
  }

  get fields() {
    return this._fields;
  }

  canAbort() {
    return !!this.onCancel.length;
  }

  onAbort() {
    this.onCancel.emit();
  }

  async submit() {
    this.onSubmit.emit(this.formGroup.value);
  }

}

@Component({
  templateUrl: './simple-form.component.html',
  styleUrls: ['./simple-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFormPopover<T> extends SimpleFormComponent<T> {
  @Input() useModal = false;
  @Input() save: (value: any) => Promise<void>;
  
  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  getController() {
    return this.useModal ? this.modalController : this.popoverController;
  }

  override async submit() {
    super.submit();
    await this.save(this.formGroup.value);
    this.getController().dismiss(this.formGroup.value);
  }

  override onAbort() {
    super.onAbort();
    return this.getController().dismiss();
  }

  override canAbort() {
    return true;
  }

}

export interface SimpleFormCreateOptions {
  ev?: Event;
  title?: string;
  skipTranslateTitle?: boolean;
  cssClass?: string;
  formClass?: string;
  cancelText?: string;
  okText?: string;
}

export async function showSimpleFormForResult(
  cb: (value: {[name: string]: any}) => void,
  opts: SimpleFormCreateOptions,
  controller: PopoverController | ModalController,
  ...fields: SimpleFormField[]
): Promise<HTMLIonPopoverElement | HTMLIonModalElement> {
  const f = fields.filter(f => !!f);
  const modal = !f.length ? null : await controller.create({
    component: SimpleFormPopover,
    animated: false,
    event: opts.ev,
    cssClass: opts.cssClass,
    componentProps: {
      fields: f,
      useModal: controller instanceof ModalController,
      title: opts.title,
      translateTitle: !opts.skipTranslateTitle,
      save: cb,
      formClass: opts.formClass,
      cancelText: opts.cancelText || 'Cancel',
      okText: opts.okText || 'Save',
    }
  });
  await modal.present();
  return modal;
}

export async function awaitSimpleFormForResult<T>(
  opts: SimpleFormCreateOptions,
  controller: PopoverController | ModalController,
  ...fields: SimpleFormField[]
) {
  return new Promise<T>(resolve => 
    showSimpleFormForResult(resolve, opts, controller, ...fields)
      .then(p => p ? p.onWillDismiss()
        .then(d => d.data ? resolve(d.data) : resolve(undefined)) : resolve(undefined)
    )
  );
}