import { Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppController } from '../../controller/app.controller';
import { ClickArg, ComputedMenu, getComputedMenuPosition, IonstackMenuItem, MenuInput } from '../../model/menu';
import { MenuService } from '../../service/menu.service';
import { CleanSubscriber } from '../../util/subscriber';
import { MenuItemsController } from '../../controller/menu-items.controller';
import { InOutHorizontal } from '../../model/animation';

@Component({
  selector: 'ionstack-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutHorizontal]
})
export class MenuItemsComponent extends CleanSubscriber implements OnInit, OnDestroy {
  @Input() styleClass: string = '';
  @Input() listStyleClass: string;
  @Input() dir: 'horizontal' | 'vertical' | 'tab-bar' = 'vertical';
  @Input() menuItemClick: (arg: ClickArg) => void;
  @Input() subMenuOpen: (arg: ClickArg) => void;
  @Input() event: Event;
  @Input() toolbar = false;
  @Input() clickArg: any;
  @Input() isSmall: boolean;
  @Input() closeCb: (arg: ClickArg) => void;
  @Input() getUrl = (item: IonstackMenuItem, baseUrl: string) => item.url.startsWith('/') ? item.url.split('?')[0] : (baseUrl + '/' + item.url);
  @Input() getRouterDirection = (item: IonstackMenuItem, baseUrl: string) => {
    if (!this.getUrl) {
      return 'root';
    }
    const url = this.getUrl(item, baseUrl);
    if (!url) {
      return 'root';
    }
    return url.startsWith(baseUrl.split('?')[0]) || !url.startsWith('/') ? 'forward' : 'root';
  };
  @Output() close = new EventEmitter<IonstackMenuItem>();
  menuOpen: IonstackMenuItem;
  computedMenu: ComputedMenu;
  private menuInput: MenuInput;

  constructor(
    private menuService: MenuService,
    private appController: AppController,
    private cd: ChangeDetectorRef,
    private menuItemsController: MenuItemsController,
    public router: Router,
  ) {
    super();
  }

  readonly getClicker = (item: IonstackMenuItem) => (event: Event) => this.click(item, event);
  readonly isExtLink = this.menuItemsController.isExtLink;
  readonly isItemVisible = (item: IonstackMenuItem, clickArg: any, notifCount: number) => (
    (!item.visibleArg || item.visibleArg(clickArg)) &&
    (!item.hideNotifless || notifCount > 0)
  );

  ngOnInit() {
    this.subscribe(this.appController.onSizeChange, () => this.cd.markForCheck());
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  getGroups() {
    return this.computedMenu.getGroups(getComputedMenuPosition(this.isSmall == null ? this.appController.isSmall : this.isSmall, this.toolbar));
  }

  isSelfManaged() {
    return !(this.menuInput instanceof ComputedMenu);
  }

  @Input()
  set menu(menu: MenuInput) {
    this.menuInput = menu;
    if (menu) {
      this.computedMenu = this.menuService.getComputedMenu(menu);
      this.subscribe(this.menuService.onChange, () => {
        this.menu = this.menuInput;
        this.cd.markForCheck();
      }, {name: 'change'});
    } else {
      this.computedMenu = null;
    }
    if (this.isSelfManaged()) {
      this.subscribe(this.appController.onSizeChange, () => {
        if (this.computedMenu) {
          this.computedMenu.reset();
        }
        this.cd.markForCheck();
      }, {name: 'size', replace: false});
    } else {
      this.unsubscribe('size');
    }
  }

  getIsSmall() {
    return this.isSmall === undefined ? this.appController.isSmall : this.isSmall;
  }

  openSubmenu(item: IonstackMenuItem, event: Event) {
    return this.menuItemsController.openSubmenu(MenuItemsComponent, item, event, this, this.clickArg);
  }

  async click(item: IonstackMenuItem, event: Event) {
    return this.menuItemsController.click(MenuItemsComponent, item, event, this, this.clickArg);
  }

}
