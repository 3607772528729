<ion-app [ngClass]="appController.getState().appClasses">
  <ng-content select="[slot=start]"></ng-content>
  <ion-header *ifState="'showToolbar'" id="top-header" [ngClass]="{mcenter: centerMobileToolbar}">
    <ion-toolbar id="top-toolbar">
      <ion-buttons slot="start">
        <ion-button (click)="appController.back()" *ngIf="appController.canGoBack()" class="back-button">
          <ion-icon slot="icon-only" ios="chevron-back" md="arrow-back" ariaLabel="Back"></ion-icon>
        </ion-button>
        <ion-button *ngIf="appController.isSmall && isMenuVisible(leftMenu, 'small')" (click)="toggleMenu('leftmenu')">
          <ion-icon [icon]="leftMenuOpen ? 'close-outline' : 'menu-outline'" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ng-content></ng-content>
      <div *ngIf="topMenu" slot="end" class="top-right">
        <ionstack-menu-items
          [menu]="topMenu" [toolbar]="true" dir="horizontal" styleClass="top-bar"
          *ngIf="isMenuVisible(topMenu, appController.isSmall ? 'smalltoolbar' : 'toolbar')"
        ></ionstack-menu-items>
        <ion-buttons class="top-right-btns" *ngIf="appController.isSmall && isMenuVisible(topMenu, 'small')">
          <ion-button (click)="toggleMenu('topmenu')" shape="round">
            <ion-icon [icon]="topMenuOpen ? 'close-outline' : 'ellipsis-vertical-outline'" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content id="root">
    <ion-menu menuId="topmenu" contentId="main" type="overlay" content-id="main" id="topmenu" side="end"
      *ngIf="appController.isSmall && isMenuVisible(topMenu, 'small')" (ionDidClose)="topMenuOpen = false" (ionDidOpen)="topMenuOpen = true" #top>
      <ion-header *ngIf="topMenu.isNameVisible()">
        <ion-toolbar>
          <ion-title><itxt [key]="'menu.' + topMenu.getName()" [args]="{defaultText: 'Menu'}"></itxt></ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ionstack-menu-items [menu]="topMenu" styleClass="top-floating" (close)="top.close()"></ionstack-menu-items>
      </ion-content>
    </ion-menu>
    <ion-split-pane contentId="main" content-id="main" id="main-layout" (ionSplitPaneVisible)="appController.isSmall = !$event['detail'].visible">
      <ion-menu menuId="leftmenu" contentId="main" type="reveal" content-id="main" id="leftmenu" side="start"
        *ngIf="appController.isSmall && isMenuVisible(leftMenu, 'small')" (ionDidClose)="leftMenuOpen = false" (ionDidOpen)="leftMenuOpen = true" #left>
        <ion-header *ngIf="leftMenu.isNameVisible()">
          <ion-toolbar>
            <ion-title><itxt [key]="'menu.' + leftMenu.getName()" [args]="{defaultText: 'Menu'}"></itxt></ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ionstack-menu-items [menu]="leftMenu" styleClass="left-floating" (close)="left.close()"></ionstack-menu-items>
        </ion-content>
      </ion-menu>
      <div *ngIf="!appController.isSmall && isMenuVisible(leftMenu, 'regular')" [@InOutHorizontal] class="menu-left-base" [ngClass]="leftMenuClass">
        <ionstack-menu-items [menu]="leftMenu" listStyleClass="left-in-page" styleClass="left-menu-large"></ionstack-menu-items>
      </div>
      <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>
  </ion-content>
  <ionstack-menu-items [menu]="bottomMenu" dir="tab-bar" *ngIf="isMenuVisible(bottomMenu, appController.isSmall ? 'small' : 'regular')"  styleClass="bottom-menu-bar"></ionstack-menu-items>
  <ionstack-inject *ngIf="injectAfter" [component]="injectAfter"></ionstack-inject>
  <div [ngClass]="{empty: !notifs.length}" class="notifs">
    <ionstack-notif [@InOutVertical] class="glass opaque" *ngFor="let n of notifs" [notif]="n.notif"></ionstack-notif>
  </div>
</ion-app>

<ng-content select="[after-content]"></ng-content>