import { CRUDEvent, crudSaveEvent } from '../util/observable-crud';
import { EventEmitter } from '@angular/core';
import { SaveInvoice, Invoice, ListedInvoice, BillingStatusCountMap, SendBill, PublicInvoice, PayInvoice } from '../model/payment';
import { Injectable } from '@angular/core';
import { IonstackService, RequestParams } from './ionstack.service';
import { Page } from '../model/page';
import { DEFAULT_PAYMENT_ACCOUNT } from './payment.service';
import { StorageService } from './storage.service';

export interface PaymentPrefs {
  onlinePayment: boolean;
  transfer: boolean;
}

export const DEFAULT_PAYMENT_PREFS: PaymentPrefs = {
  onlinePayment: false,
  transfer: true,
};

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  readonly onChange = new EventEmitter<CRUDEvent<number, Invoice>>();

  constructor(
    private ionstackService: IonstackService,
    private storageService: StorageService,
  ) { }

  getInvoicePaymentPrefs(): Promise<PaymentPrefs> {
    return this.storageService.get<PaymentPrefs>('invoicePaymentPrefs', DEFAULT_PAYMENT_PREFS);
  }

  async updatePaymentPrefs<T extends keyof PaymentPrefs>(field: T, value: PaymentPrefs[T]): Promise<PaymentPrefs> {
    const prefs = await this.getInvoicePaymentPrefs();
    prefs[field] = value;
    return this.storageService.set('invoicePaymentPrefs', prefs);
  }

  async saveInvoice(invoice: SaveInvoice, type = DEFAULT_PAYMENT_ACCOUNT) {
    const value = await this.ionstackService.post<Invoice>(`/payment/mine/${type}/invoices`, invoice);
    this.onChange.emit(crudSaveEvent(value, value.id));
    return value;
  }

  sendInvoice(id: number, send: SendBill, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.post<void>(`/payment/mine/${type}/invoices/${id}/delivery`, send);
  }

  getInvoice(id: number, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.get<Invoice>(`/payment/mine/${type}/invoices/${id}`);
  }

  getSharedInvoice(id: number) {
    return this.ionstackService.get<Invoice>(`/payment/invoices/${id}`);
  }

  getPublicInvoice(id: number) {
    return this.ionstackService.get<PublicInvoice>(`/public/payment/invoices/${id}`);
  }

  getPublicInvoiceStatus(id: number) {
    return this.ionstackService.get<string>(`/public/payment/invoices/${id}/status`, {responseType: 'text'});
  }

  payInvoice(id: number, data: PayInvoice) {
    return this.ionstackService.post<PublicInvoice>(`/public/payment/invoices/${id}/payment`, data);
  }

  getLastInvoiceNumber(type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.get<string>(`/payment/mine/${type}/invoices/last/number`, {responseType: 'text'});
  }

  getInvoices(params: RequestParams, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.get<Page<ListedInvoice>>(`/payment/mine/${type}/invoices`, {params});
  }

  getInvoiceStatuses(params: RequestParams, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.get<BillingStatusCountMap>(`/payment/mine/${type}/invoices/statuses`, {params});
  }

}
