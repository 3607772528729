import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { consumeWidgetContent, Widget, WidgetParams, ActionService, WidgetData } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';
import { isFullUrl } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'link',
  widgetName: 'Link',
  icon: 'link',
  widgetDescription: 'A link wrapper for routing or action',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    { name: 'route', label: 'Route', optional: true },
    { name: 'action', label: 'Button action', optional: true },
  ]
})
export class LinkComponent {
  @Input() children: WidgetData[];
  @Input() route: string;
  @Input() action: string;
  params: WidgetParams;

  readonly getClickAction = (action: string) => this.actionService.getAction(action, null, null);
  readonly isFullUrl = isFullUrl;

  constructor(private actionService: ActionService) { }

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data);
    this.params = params;
    this.children = params.data?.children;
  }

}
