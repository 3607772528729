import { NotificationService } from '../service/notification.service';
import { CleanSubscriber } from '../util/subscriber';
import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NotifsCount } from '../model/notification';
import { ContextService } from '../service/context.service';
import { CartService } from '../service/cart.service';
import { Cart } from '../model/cart';

@Pipe({
  name: 'notifCount',
  pure: false
})
export class NotifCountPipe extends CleanSubscriber implements PipeTransform, OnDestroy {
  private count: NotifsCount = { total: 0, byType: {} };
  private lastTypes: string | string[];
  private types: string[] = [];
  private test = true;
  private cachedValue = 0;
  private cart: Cart;

  constructor(
    private cd: ChangeDetectorRef,
    private cartService: CartService,
    notificationService: NotificationService,
    contextService: ContextService,
  ) {
    super();
    this.subscribe(contextService.user, usr => {
      if (usr) {
        this.subscribe<NotifsCount>(notificationService.count, count => {
          this.count = count;
          this.computeValue();
          this.cd.markForCheck();
        }, {name: 'c', replace: true});
      } else {
        this.unsubscribe('c');
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  private computeTypes(types: string | string[]) {
    this.lastTypes = types;
    this.test = true;
    if (typeof types === 'string') {
      if (types.charAt(0) === '!') {
        this.test = false;
        types = types.substring(1);
      }
      this.types = types.split(' ');
    } else {
      this.types = types;
    }
    if (this.types && this.types.includes('cart')) {
      this.subscribe<Cart>(this.cartService.cart, cart => {
        this.cart = cart;
        this.computeValue();
        this.cd.markForCheck();
      }, {name: 'cart', replace: true});
    } else {
      this.unsubscribe('cart');
    }
    this.computeValue();
  }

  private computeValue() {
    this.cachedValue = 0;
    for (const type of Object.keys(this.count.byType)) {
      if (this.test === this.types.includes(type)) {
        this.cachedValue += this.count.byType[type];
      }
    }
    if (this.types && this.types.includes('cart') && this.cart?.products?.length) {
      this.cachedValue += this.cart.products.length;
    }
  }

  transform(types?: string | string[]): number {
    if (!types?.length) {
      return 0;
    }
    if (this.lastTypes !== types) {
      this.computeTypes(types);
    }
    return this.cachedValue;
  }

}
