import { DOCUMENT } from '@angular/common';
import { Inject, Input } from '@angular/core';
import { IonstackService } from '../../service/ionstack.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MenuService } from '../../service/menu.service';
import { AppController } from '../../controller/app.controller';
import { ComputedMenu, ComputedMenuPosition } from '../../model/menu';
import { ContextService } from '../../service/context.service';
import { CleanSubscriber } from '../../util/subscriber';
import { IonMenu } from '@ionic/angular';
import { InOutHorizontal, InOutVertical } from '../../model/animation';
import { fixMenu } from '../../util/menu-fix';
import { NotifController, NotifRender } from '../../controller/notif.controller';
import { InjectableComponent } from '@adeprez/ionstack/ui/inject';

@Component({
  selector: 'ionstack-app',
  templateUrl: './app-base.component.html',
  styleUrls: ['./app-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutHorizontal, InOutVertical]
})
export class AppBaseComponent extends CleanSubscriber implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('top') topMenuRef: IonMenu;
  @ViewChild('left') leftMenuRef: IonMenu;
  @Input() leftMenuClass: string;
  @Input() centerMobileToolbar = false;
  leftMenu: ComputedMenu;
  topMenu: ComputedMenu;
  bottomMenu: ComputedMenu;
  topMenuOpen = false;
  leftMenuOpen = false;
  notifs: NotifRender[] = [];
  injectAfter: InjectableComponent<any>;

  constructor(
    public appController: AppController,
    private contextService: ContextService,
    private menuService: MenuService,
    private cd: ChangeDetectorRef,
    private ionstackService: IonstackService,
    private notifController: NotifController,
    @Inject(DOCUMENT) private document: any,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeAll(() => {
      const state = this.appController.getState();
      this.leftMenu = this.menuService.getComputedMenu(state.leftMenu);
      this.topMenu = this.menuService.getComputedMenu(state.topMenu);
      this.bottomMenu = this.menuService.getComputedMenu(state.bottomMenu);
      this.injectAfter = state.injectAfter;
      this.closeMenus();
      this.cd.markForCheck();
      this.fixMenu();
    }, this.appController.onStateChange, this.menuService.onChange);
    this.subscribeAll(() => {
      this.fixMenu();
      this.closeMenus();
      this.cd.markForCheck();
    },
      this.appController.onSizeChange,
      this.contextService.context
    );
    this.subscribe<NotifRender[]>(this.notifController.visibleNotifChange, notifs => {
      this.notifs = notifs;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngAfterViewInit(): void {
    this.fixMenu();
  }

  closeMenus() {
    if (this.ionstackService.isBrowser()) {
      this.topMenuRef?.close(false);
      this.leftMenuRef?.close(false);
      this.topMenuOpen = false;
      this.leftMenuOpen = false;
      const collection: HTMLCollection = this.document.getElementsByClassName('menu-content-open');
      for (let i = 0; i < collection.length; i++) {
        collection[i].classList.remove('menu-content-open');
      }
    }
  }

  toggleMenu(menuId: 'topmenu' | 'leftmenu') {
    if (menuId === 'topmenu') {
      this.topMenuRef.disabled = false;
      this.topMenuRef.toggle();
    } else {
      this.leftMenuRef.disabled = false;
      this.leftMenuRef.toggle();
    }
  }

  fixMenu() {
    if (this.isMenuVisible(this.topMenu, 'small')) {
      fixMenu(() => this.topMenuRef, this.ionstackService, this.cd);
    }
    if (this.isMenuVisible(this.leftMenu, 'small')) {
      fixMenu(() => this.leftMenuRef, this.ionstackService, this.cd);
    }
  }

  isMenuVisible(menu: ComputedMenu, position: ComputedMenuPosition) {
    return menu && menu.hasContent(position);
  }

}
