import { Directive, HostListener, Input } from '@angular/core';
import { IonModal, IonPopover, ModalController, PopoverController } from '@ionic/angular';

@Directive({
  selector: '[closePopup]',
  host: {
    '[style.cursor]': 'pointer',
  }
})
export class ClosePopupDirective {
  @Input() closePopup: boolean | IonModal | IonPopover | 'modal' | 'popover' | '' = 'modal';

  constructor(private modalController: ModalController, private popoverController: PopoverController) { }

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent) {
    if (this.closePopup !== false) {
      event.preventDefault();
      event.stopPropagation();
      if (this.closePopup === 'popover') {
        this.popoverController.dismiss();
      } else if (this.closePopup === 'modal' || this.closePopup === '' || this.closePopup === true) {
        this.modalController.dismiss();
      } else {
        this.closePopup.dismiss();
      }
    }
  }

}
