import { ReactiveFormsModule } from '@angular/forms';
import { PaymentFormModule } from '@adeprez/ionstack/ui/payment-form';
import { UpgradePlanProvider, IonstackPipesModule, IonstackDirectivesModule, TranslatableModule } from '@adeprez/ionstack';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeModalComponent } from './subscribe-modal.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslatableModule,
    IonstackDirectivesModule,
    IonstackPipesModule,
    PaymentFormModule,
    ReactiveFormsModule,
  ],
  declarations: [SubscribeModalComponent],
  providers: [
    {
      provide: UpgradePlanProvider,
      useValue: SubscribeModalComponent,
    },
  ]
})
export class SubscribeModalModule { }
