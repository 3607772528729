import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pageable, CleanSubscriber } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent<T> extends CleanSubscriber implements OnDestroy {
  @Output() pageClick = new EventEmitter<number>();
  @Input() align: 'center' | 'right' | 'left' = 'center';
  @Input() pagesAround = 3;
  @Input() pageParam: string | null = 'page';
  pageNumbers: number[] = [];
  private _paged: Pageable<T>;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  @Input() set paged(paged: Pageable<T>) {
    this.subscribe(paged.onChange, () => {
      const queryPage = this.pageParam && !paged.inited ? this.activatedRoute.snapshot.queryParams[this.pageParam] : null;
      const pg = queryPage ? +queryPage : this._paged.page.number;
      const count = this._paged.pageCount;
      this.pageNumbers = [];
      if (count > 1) {
        const startPage = Math.max(1, pg - this.pagesAround + 1);
        const endPage = Math.min(count, pg + this.pagesAround + 1);
        for (let i = startPage; i <= endPage; i++) {
          this.pageNumbers.push(i);
        }
        if (this.pageNumbers[0] !== 1) {
          this.pageNumbers.unshift(1);
          if (this.pageNumbers[1] !== 2) {
            this.pageNumbers.splice(1, 0, null);
          }
        }
        if (this.pageNumbers[this.pageNumbers.length - 1] !== count) {
          if (this.pageNumbers[this.pageNumbers.length - 1] !== count - 1) {
            this.pageNumbers.push(null);
          }
          this.pageNumbers.push(count);
        }
      }
      if (this.pageParam && paged.paginated) {
        this.router.navigate(['.'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          replaceUrl: true,
          queryParams: {[this.pageParam]: pg <= 0 ? null : pg}
        });
      }
      this.cd.markForCheck();
    }, {name: 'change', replace: true});
    this._paged = paged;
  }

  get paged() {
    return this._paged;
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

}
