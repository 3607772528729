import { WidgetableComponent, WidgetParams, consumeWidgetContent, SimpleFormField, FIELD_TYPES, FormSubmitData, DatasourceService } from '@adeprez/ionstack';
import { UntypedFormControl, FormControlStatus } from '@angular/forms';
import { Injectable, Input, InjectionToken, Inject, Optional, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';

export const FORM_ALL_META_FIELDS: SimpleFormField[] = [
  { name: 'name', label: 'Field name' },
  { name: 'label', label: 'Label', optional: true },
  { name: 'type', label: 'Field type', type: 'select', value: 'text', options: FIELD_TYPES },
  { name: 'value', label: 'Default value', optional: true },
  { name: 'placeholder', label: 'Placeholder', optional: true },
  { name: 'optional', label: 'Optional', type: 'checkbox', value: true },
  { name: 'maxLength', label: 'Max length', type: 'number', optional: true },
  { name: 'autocomplete', label: 'Autocomplete', optional: true },
  { name: 'autofocus', label: 'Autofocus', type: 'checkbox', value: false },
  { name: 'autoGrow', label: 'Autogrow', type: 'checkbox', value: false },
  { name: 'rows', label: 'Rows count', type: 'number', optional: true },
  { name: 'min', label: 'Min value', type: 'number', optional: true },
  { name: 'max', label: 'Max value', type: 'number', optional: true },
  { name: 'position', label: 'Label position', type: 'select', optional: true, options: ['stacked', 'floating', 'static'] },
  { name: 'color', label: 'Color', value: 'light', optional: true },
  { name: 'pattern', label: 'Pattern (number = 0, any = *, not space = ~, any beyond : +)', optional: true },
  { name: 'datasource', label: 'Datasource', optional: true },
];

export interface WidgetParentForm {
  readonly formValid: boolean;
  readonly statusChanges: Observable<FormControlStatus>;
  readonly willSave: Observable<FormSubmitData>;
  removeControl(field: SimpleFormField, control: UntypedFormControl): void;
  createControl(field: SimpleFormField): UntypedFormControl;
}

export const WIDGET_PARENT_FORM = new InjectionToken<WidgetParentForm>('WidgetParentForm');

export type FormMetaKey = keyof SimpleFormField;

export const FORM_META_FIELDS: {[k in FormMetaKey]?: SimpleFormField} = FORM_ALL_META_FIELDS.reduce(function(map: any, obj: SimpleFormField) {
  map[obj.name] = obj;
  return map;
}, {});

export const BASE_META_FIELDS: SimpleFormField[] = [FORM_META_FIELDS.name, FORM_META_FIELDS.label, FORM_META_FIELDS.value, FORM_META_FIELDS.optional, FORM_META_FIELDS.datasource];

@Injectable()
export abstract class BaseFormWidget<T extends SimpleFormField = SimpleFormField> implements WidgetableComponent, OnDestroy {
  @Input() field: T;
  control: UntypedFormControl;
  private _widget: WidgetParams;

  abstract cd: ChangeDetectorRef;

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) private form: WidgetParentForm,
    private datasourceService: DatasourceService,
  ) {}

  set widget(widget: WidgetParams) {
    this.field = {} as any;
    consumeWidgetContent(this.field, widget.data);
    this._widget = widget;
    this.control = this.form && this.field.name ? this.form.createControl(this.field) : new UntypedFormControl(this.field.value);
    if (this.field.datasource) {
      this.datasourceService.getData(this.field.datasource).then((value: any) => {
        if (this.control.pristine && value != null) {
          this.control.setValue(value);
          this.cd.markForCheck();
          this.cd.detectChanges();
        }
      });
    }
    this.cd.markForCheck();
  }

  get widget() {
    return this._widget;
  }

  parseJsonOrText(s: string) {
    if (s.startsWith('{') && s.endsWith('}')) {
      try {
        return JSON.parse(s);
      } catch {}
    }
    return s;
  }

  ngOnDestroy() {
    if (this.form) {
      this.form.removeControl(this.field, this.control);
    }
  }

}