import { WidgetEditorRoot, WidgetParams, EditWidgetController, Widget, WidgetService, AlertService } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Input, Optional } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { WidgetChildrenComponent } from '@adeprez/ionstack/ui/widget-children';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WidgetChildrenWidget),
    multi: true
  }],
  selector: 'ionstack-widget-children-widget',
  templateUrl: '../ui/widget-children/widget-children.component.html',
  styleUrls: ['../ui/widget-children/widget-children.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'container',
  widgetName: 'Container',
  group: 'Container',
  icon: 'tablet-landscape-outline',
  widgetDescription: 'Simple container',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
})
export class WidgetChildrenWidget extends WidgetChildrenComponent {

  constructor(
    widgetService: WidgetService,
    editWidgetController: EditWidgetController,
    cd: ChangeDetectorRef,
    @Inject(WidgetEditorRoot) @Optional() widgetEditorRoot: WidgetEditorRoot,
    alertService: AlertService,
  ) {
    super(widgetService, editWidgetController, alertService, cd, widgetEditorRoot);
  }

  @Input() set widget(params: WidgetParams) {
    this.parent = params.holder;
    this.context = params.context;
    this.value = params.data.children;
  }

}
