import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getI18nKeyGroup } from '../model/i18n';
import { I18nService } from '../service/i18n.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '../translate/missing-translation-handler';
import { TranslateLoader } from '../translate/translate.loader';
import { TranslateModule } from '../translate/translate.module';

@Injectable({
  providedIn: TranslateModule
})
export class TranslateHttpLoader implements TranslateLoader {

  constructor(private i18nService: I18nService) { }

  public getTranslation(_: any): Observable<any> {
    return this.i18nService.getDict();
  }
  
  public getGroup(group: string): Promise<any> {
    return this.i18nService.getGroup(group);
  }

}

@Injectable({
  providedIn: TranslateModule
})
export class GroupTranslationHandler implements MissingTranslationHandler {

  constructor(private i18nService: I18nService) {
  }

  handle(params: MissingTranslationHandlerParams) {
    const group = getI18nKeyGroup(params.key);
    return group ? this.i18nService.loadGroup(group, params) : this.i18nService.processMissingTranslation(params.key, params.interpolateParams);
  }

}