import { NotificationService, NotifRouteProvider } from '../service/notification.service';
import { IonstackModuleConfig, MODULE_CONFIG } from '../ionstack.config';
import { NotifListComponent } from '../component/notif-list/notif-list.component';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { MenuItemClickEvent } from './menu-items.controller';
import { Notif, WSNotif } from '../model/notification';
import { FullRoute } from '../model/route';
import { SoundController } from './sound.controller';
import { CleanSubscriber } from '../util/subscriber';
import { RouteService } from '../service/route.service';

export interface NotifRender {
  notif: WSNotif;
  showTimeout?: any;
  dismiss: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class NotifController extends CleanSubscriber implements NotifRouteProvider, OnDestroy {
  readonly visibleNotifChange = new EventEmitter<NotifRender[]>();
  private visibleNotifs: NotifRender[] = [];
  private displayableNotifs: {[notifId: number]: {notif: WSNotif, timeout: any, render?: NotifRender}} = {};

  constructor(
    private popoverController: PopoverController,
    private soundController: SoundController,
    private notificationService: NotificationService,
    private routeService: RouteService,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
  ) {
    super();
  }

  init() {
    this.subscribe<WSNotif>(this.notificationService.onNotification, notif => this.onNotification(notif));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  getAssetToPlay(notif: WSNotif) {
    const setting = this.ionstackModuleConfig.notifPlaySound ?? true;
    if (setting === false) {
      return null;
    }
    if (setting !== true) {
      const byType = setting[notif.type];
      if (byType === false) {
        return null;
      }
      if (typeof byType === 'string') {
        return byType;
      }
    }
    return 'notification';
  }

  onNotification(notif: WSNotif) {
    if (!notif.readDate) {
      if (this.ionstackModuleConfig.showPopNotificationDelay !== -1) {
        this.displayableNotifs[notif.id] = {
          notif,
          timeout: setTimeout(() => this.showNotif(notif), this.ionstackModuleConfig.showPopNotificationDelay ?? 1500)
        };
      }
      this.playNotifSound(notif);
    } else if (notif.id in this.displayableNotifs) {
      const dn = this.displayableNotifs[notif.id];
      delete this.displayableNotifs[notif.id];
      clearTimeout(dn.timeout);
      dn.render?.dismiss();
    }
  }

  playNotifSound(notif: WSNotif) {
    const sound = this.getAssetToPlay(notif);
    if (sound) {
      if (sound.includes('/')) {
        this.soundController.play(sound);
      } else {
        this.soundController.playAsset(sound);
      }
    }
  }

  async presentNotif(click: MenuItemClickEvent) {
    const popover = await this.popoverController.create({
      component: NotifListComponent,
      event: click.event,
      componentProps: {
        notifType: click.item.notifType,
        notifListRoute: this.ionstackModuleConfig.notifListRoute,
        onAction: () => popover.dismiss(),
      },
    });
    return await popover.present();
  }

  getRoute(notif: Notif): FullRoute {
    if (notif.meta?.route || notif.meta?.queryParams) {
      let path: (string | number)[] = ['.'];
      if (notif.meta?.route) {
        path = notif.meta.route.split('/');
        if (!path.length) {
          path = ['.'];
        } else if (!(path[0] + '').startsWith('/')) {
          path[0] = '/' + path[0];
        }
      }
      return {
        path,
        query: JSON.parse(notif.meta?.queryParams || '{}')
      };
    }
    if (notif.type === 'msg') {
      return this.routeService.getRoute('chat', notif.meta?.convId);
    }
  }

  private showNotif(notif: WSNotif) {
    const nr: NotifRender = {
      notif,
      dismiss: () => {
        clearTimeout(nr.showTimeout);
        this.visibleNotifChange.emit(this.visibleNotifs = this.visibleNotifs.filter(vn => vn.notif.id !== notif.id));
      }
    };
    const display = this.displayableNotifs[notif.id];
    if (display) {
      display.render = nr;
    }
    this.visibleNotifs = [...this.visibleNotifs, nr];
    this.visibleNotifChange.emit(this.visibleNotifs);
    nr.showTimeout = setTimeout(nr.dismiss.bind(nr), this.ionstackModuleConfig.showPopNotificationDuration ?? 6000);
  }

}