<ionstack-edit-widget-popup
  [widget]="widget"
  [infos]="infos"
  [context]="context"
  [index]="index"
  [parentHolder]="parentHolder"
  [extraContent]="{name}"
  class="ion-page"
>
  <ionstack-iconpicker [(value)]="name"></ionstack-iconpicker>
</ionstack-edit-widget-popup>