import { PermissionService } from '../../service/permission.service';
import { Dictionary } from '../../model/i18n';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslaterComponent } from '../../component/translater/translater.component';
import { WidgetPopupBase } from '../../model/widget';
import { randomString } from '../../util/util';
import { I18nService } from '../../service/i18n.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ionstack-edit-translatable-widget-popup',
  templateUrl: './edit-translatable-widget-popup.component.html',
  styleUrls: ['./edit-translatable-widget-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTranslatableWidgetPopupComponent extends WidgetPopupBase implements OnInit {
  @ViewChild(TranslaterComponent) translater: TranslaterComponent;
  translations: Dictionary;
  form: UntypedFormGroup;
  permissions: string[] = [];

  constructor(
    private modalController: ModalController,
    private i18nService: I18nService,
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private permissionService: PermissionService,
  ) {
    super();
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      content: [this.widget.content || (this.context.postOptions.defaultI18nGroup + '.' + randomString(4))],
      style: [this.widget.style],
      classes: [this.widget.classes],
      permissions: [this.widget.permissions],
      slot: [this.widget.slot],
    });
    this.translations = await this.i18nService.getTranslations(this.form.controls.content.value);
    this.permissions = await this.permissionService.getKnownPermissions();
    this.cd.markForCheck();
  }

  cancel() {
    this.modalController.dismiss();
  }

  async confirm() {
    const value = this.translater.getValue();
    if (Object.keys(value).length) {
      await this.i18nService.setTranslations(this.form.controls.content.value, this.translater.getValue());
    }
    this.modalController.dismiss(this.form.value);
  }

  deleteWidget() {
    this.parentHolder.deleteChild(this.index);
    this.cancel();
  }

}
