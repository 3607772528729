import { Injectable } from '@angular/core';
import { otpActionName, OTPBasicType, OTPChannel, OTPHandlerFn, OTPHandlerResult, OTPRequest, OTPUrlParam } from '../model/verification';
import { ActionService } from './action.service';
import { IonstackService } from './ionstack.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  private queryParamOTP: OTPUrlParam;

  constructor(
    private ionstackService: IonstackService,
    private actionService: ActionService,
    private userService: UserService,
  ) {
    this.registerHandler('LOG_IN', otp => this.userService.loginByOTP(otp.channel, otp.login, otp.secret).then(() => true).catch(() => true));
  }

  sendOTP(request: OTPRequest | string, action: OTPBasicType | string, channel: OTPChannel, redirectUrl?: string) {
    if (typeof request === 'string') {
      request = { login: request };
    }
    const params = redirectUrl ? {redirectUrl} : {};
    return this.ionstackService.post<void>(`/public/otp/${action}/${channel}`, request, {params});
  }

  getQueryParamOTP() {
    return this.queryParamOTP;
  }

  registerHandler(action: OTPBasicType | string, handler: OTPHandlerFn) {
    this.actionService.setAction(otpActionName(action), handler);
  }

  handleOTP(otp: OTPUrlParam): OTPHandlerResult {
    this.queryParamOTP = otp;
    const handler = this.actionService.getAction(otpActionName(otp.action));
    return handler ? handler(otp) : true;
  }

}
