import { Injectable, InjectionToken, Injector } from '@angular/core';
import { InjectableService } from './injectable.service';

export type Datasource = (prop: string) => Promise<any>;
const DS_TOKENS: {[name: string]: InjectionToken<Datasource>} = {};

export function datasourceToken(name: string): InjectionToken<Datasource> {
  return DS_TOKENS[name] ?? (DS_TOKENS[name] = new InjectionToken<Datasource>(name));
}

@Injectable({
  providedIn: 'root'
})
export class DatasourceService extends InjectableService<Datasource> {
  defaultInjectable = () => new Promise(r => r(null));
  tokenStore = DS_TOKENS;

  constructor(injector: Injector) {
    super(injector);
  }

  async getData(name: string, field = '') {
    const d = super.getInjectable(name);
    const value = d ? await d(name) : null;
    const data = field && value ? this.getFromPath(value, field) : value;
    if (data == null && name.includes('.')) {
      const idx = name.indexOf('.');
      return await this.getData(name.substring(0, idx), name.substring(idx + 1));
    }
    return data;
  }

  private getFromPath(o: any, path: string) {
    let value = o;
    if (path) {
      for (let n of path.split('.')) {
        value = value?.[n];
      }
    }
    return value;
  }

}
