import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileCaptureComponent } from './file-capture.component';
import { IonicModule } from '@ionic/angular';
import { IonstackPipesModule, TranslatableModule, IonstackDirectivesModule } from '@adeprez/ionstack';
import { FileDisplayModule } from '../file-display/file-display.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    IonstackPipesModule,
    FileDisplayModule,
    IonstackDirectivesModule,
    TranslatableModule,
  ],
  declarations: [FileCaptureComponent],
  exports: [FileCaptureComponent]
})
export class FileCaptureModule { }
