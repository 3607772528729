import { Widget, DatasourceService, FieldType } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Inject, Optional } from '@angular/core';
import { Component } from '@angular/core';
import { BaseFormWidget, BASE_META_FIELDS, FORM_META_FIELDS, WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

const supportedTypes: FieldType[] = [
  'date', 'email', 'number', 'password', 'search', 'tel', 'text', 'url', 'time', 'week', 'month', 'datetime-local'
];

@Component({
  selector: 'ionstack-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['../input-style.scss', './form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'input',
  widgetName: 'Input',
  group: 'Forms',
  icon: 'pencil',
  widgetDescription: 'Typed input field',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    ...BASE_META_FIELDS,
    { ...FORM_META_FIELDS.type, options: supportedTypes },
    FORM_META_FIELDS.min,
    FORM_META_FIELDS.max,
    FORM_META_FIELDS.maxLength,
    FORM_META_FIELDS.placeholder,
    FORM_META_FIELDS.position,
    FORM_META_FIELDS.color,
    FORM_META_FIELDS.pattern,
  ]
})
export class FormInputComponent extends BaseFormWidget {

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) form: WidgetParentForm,
    datasourceService: DatasourceService,
    public cd: ChangeDetectorRef,
  ) {
    super(form, datasourceService);
  }

}
