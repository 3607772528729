import { NotificationService } from '../service/notification.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { b64decode } from '../util/util';

@Injectable({
  providedIn: 'root'
})
export class ConsumeNotifGuard implements CanActivate {

  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, snap: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const notif = next.paramMap.get('notif') || next.queryParamMap.get('notif');
    if (!notif) {
      return true;
    }
    try {
      const notifObj = JSON.parse(b64decode(notif));
      const route = this.notificationService.getNotifRoute(notifObj);
      if (route) {
        if (route.path || route.query) {
          return this.router.createUrlTree(
            route.path,
            { queryParams: route.query }
          );
        }
        if (route.click) {
          await route.click();
        }
      }
    } catch (e) {
      console.error('Invalid notification', e);
      return true;
    }
    return true;
  }

}
