<div *ngIf="argKeys?.length" class="ion-margin args">
  <div *ngFor="let arg of argKeys" class="var" (click)="input.select()">
    <input type="text" readonly value="{{ argText(arg) }}" size="{{ argText(arg).length }}" #input />
    <span>{{ args[arg] }}</span>
  </div>
</div>
<form [formGroup]="formGroup" [awaitSubmit]="submit" [bindAwaitSubmitTo]="this">
  <ion-item *ngFor="let lang of languages">
    <ion-label position="floating">{{ lang.name }}</ion-label>
    <ion-textarea type="text" [formControlName]="lang.code" [placeholder]="key"></ion-textarea>
  </ion-item>
  <ion-toolbar *ngIf="submitBar">
    <ion-buttons slot="end">
      <ion-button (click)="onAbort()" type="button">
        <ion-icon name="close" slot="start" color="danger"></ion-icon>
        {{ 'Cancel' | translate }}
      </ion-button>
      <ion-button [disabled]="!formGroup.valid || !formGroup.dirty" type="submit">
        <ion-icon slot="start" name="checkmark" color="success"></ion-icon>
        {{ 'Save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</form>