import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker.component';
import { IonicModule } from '@ionic/angular';
import { TranslatableModule, TranslateModule } from '@adeprez/ionstack';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    TranslatableModule,
  ],
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent]
})
export class DatePickerModule { }
