import { BrowseFileModule } from './../browse-file/browse-file.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditableFileComponent } from './editable-file.component';
import { EditFileModule } from '../edit-file/edit-file.module';
import { IonicModule } from '@ionic/angular';
import { FileDisplayModule } from '../file-display/file-display.module';
import { TranslatableModule, IonstackPipesModule, IonstackDirectivesModule } from '@adeprez/ionstack';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    EditFileModule,
    FileDisplayModule,
    TranslatableModule,
    IonstackPipesModule,
    IonstackDirectivesModule,
    BrowseFileModule,
  ],
  declarations: [EditableFileComponent],
  exports: [EditableFileComponent]
})
export class EditableFileModule { }
