import { Customer, InvoiceFile, SaveCustomerInfo } from './payment';
import { Product } from './product';
import { SmallUser } from './user';

export enum OrderStatus {
  init = 'INIT',
  confirmSale = 'CONFIRM_SALE',
  pay = 'PAY',
  deliver = 'DELIVER',
  delivery = 'DELIVERY',
  delivered = 'DELIVERED',
  complete = 'COMPLETE',
  canceled = 'CANCELED',
}

export const OrderStatusSteps: {[status in OrderStatus]: number} = {
  [OrderStatus.init]: 0,
  [OrderStatus.confirmSale]: 1,
  [OrderStatus.pay]: 2,
  [OrderStatus.deliver]: 3,
  [OrderStatus.delivery]: 4,
  [OrderStatus.delivered]: 5,
  [OrderStatus.complete]: 6,
  [OrderStatus.canceled]: 9,
};

export const ORDER_CUSTOMER_REQ_ACTION: OrderStatus[] = [OrderStatus.pay, OrderStatus.delivered];
export const ORDER_SELLER_REQ_ACTION: OrderStatus[] = [OrderStatus.confirmSale, OrderStatus.deliver, OrderStatus.delivery];

export interface ListedGroupedOrder {
	id: number;
	orders: CustomerOrder[];
	description: string;
	status: OrderStatus;
}

export interface GroupedOrder extends ListedGroupedOrder {
	invoice?: InvoiceFile;
	creation: string;
	statusChange: string;
	paymentMethodId?: string;
}

export interface CustomerOrder {
	id: number;
  products: OrderProduct[];
  statusChange: string;
	status: OrderStatus;
  invoice?: InvoiceFile;
}

export interface OrderProduct {
	id: number;
  product: Product;
  name: string;
  unitPrice: number;
  vat: number;
  currency: string;
  quantity: number;
	confirmSale: boolean;
	deliverable: boolean;
	status: OrderStatus;
  deliveryLink: string;
  statusUpdate: {[status in OrderStatus]?: string};
}

export interface Order {
	id: number;
  products: OrderProduct[];
  invoice?: InvoiceFile;
  platformInvoice?: InvoiceFile;
  statusChange: string;
	status: OrderStatus;
  customer: Customer;
  user: SmallUser;
  commission: number;
}

export interface OrderProductUpdate {
  status?: OrderStatus;
  customer?: SaveCustomerInfo;
  deliveryLink?: string;
}

export interface GroupedOrderUpdate {
  status?: OrderStatus;
  customer?: SaveCustomerInfo;
  paymentMethodId?: string;
}

export function getGroupedOrderPrice(order: GroupedOrder, includeCancel = false): number {
  return order?.orders ? order.orders.reduce((n, o) => n + getOrderPrice(o, includeCancel), 0) : 0;
}

export function getOrderPrice(order: Order | CustomerOrder, includeCancel = false): number {
  return order.products.filter(p => includeCancel || p.status !== OrderStatus.canceled).reduce((n2, p) => n2 + p.quantity * p.unitPrice, 0);
}

export function orderHasDelivery(order: Order | CustomerOrder | GroupedOrder): boolean {
  if (isGroupedOrder(order)) {
    return !!order.orders.find(o => orderHasDelivery(o));
  }
  return order?.products && !!order.products.find(p => p.status !== OrderStatus.canceled && p.deliverable);
}

export function orderHasConfirm(order: Order | CustomerOrder | GroupedOrder): boolean {
  if (isGroupedOrder(order)) {
    return !!order.orders.find(o => orderHasConfirm(o));
  }
  return order?.products && !!order.products.find(p => p.confirmSale);
}

export function isGroupedOrder(order: Order | CustomerOrder | GroupedOrder): order is GroupedOrder {
  return order && 'orders' in order;
}