import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionService, DefaultWidgetContainer, Widget } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'item',
  widgetName: 'Item',
  group: 'Container',
  icon: 'phone-landscape-sharp',
  widgetDescription: 'An item for listing, click action or routing',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    { name: 'icon', label: 'Icon', value: 'save' },
    { name: 'route', label: 'Route', optional: true },
    { name: 'action', label: 'Button action', optional: true },
    { name: 'detail', label: 'Show detail icon', type: 'checkbox', optional: true },
    { name: 'button', label: 'Make item clickable', type: 'checkbox', optional: true },
  ]
})
export class ItemComponent extends DefaultWidgetContainer {
  @Input() detail: boolean;
  @Input() button: boolean;
  @Input() icon: string;
  @Input() route: string;
  @Input() action: string;

  readonly getClickAction = (action: string) => this.actionService.getAction(action, null, null);

  constructor(
    private actionService: ActionService,
  ) {
    super();
  }

}
