import { PopoverController } from '@ionic/angular';
import { PickerPopup } from '@adeprez/ionstack/ui/picker';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from '@adeprez/ionstack';
import { AutocompleteComponent } from '@adeprez/ionstack/ui/autocomplete';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChipListComponent),
    multi: true
  }],
  selector: 'ionstack-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipListComponent<T> extends FormControlComponent<T[]> {
  @ViewChild('autoComplete') autoComplete: AutocompleteComponent<T>;
  @Output() valueChange = new EventEmitter<T[]>();
  @Input() label: string;
  @Input() labelGetter: (value: T) => string = (value: T) => value + '';
  @Input() valueGetter: (input: string, values: T[]) => T = (input: string) => input as any;
  @Input() newValueGetter: (input: string) => T;
  @Input() autoConvertToString = false;
  @Input() allowDoubles = false;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;
  @Input() color: string;
  @Input() with: T[];
  @Input() value: T[] = [];
  @Input() autocompleteEnabled = true;
  @Input() popupSearchEnabled = false;

  constructor(
    private popoverController: PopoverController,
    public cd: ChangeDetectorRef,
  ) {
    super();
  }

  onSubmit(value: T) {
    if (!this.value) {
      this.value = [];
    }
    if (this.allowDoubles || !this.value.find((v: T) => v === value)) {
      this.autoComplete.inputText = '';
      this.value.push(value);
      this.valueChanged();
    }
  }

  remove(index: number) {
    this.value.splice(index, 1);
    this.valueChanged();
  }

  async openSearch() {
    const popup = await this.popoverController.create({
      component: PickerPopup,
      cssClass: 'autosize-popover',
      componentProps: {
        height: 'fit-content',
        selected: this.value,
        items: this.with,
        maxSelected: this.with.length,
        labelGetter: this.labelGetter,
        onCancel: () => popup.dismiss(),
        onSubmit: async (items: T[]) => {
          this.setValue(items);
          popup.dismiss();
          this.cd.markForCheck();
        }
      }
    });
    popup.present();
  }

}
