import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { consumeWidgetContent, Widget, WidgetParams, WidgetableComponent, WidgetData } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'title',
  widgetName: 'Title',
  icon: 'bookmark',
  widgetDescription: 'Title tag',
  childSupport: ['itxt', 'text', 'html'],
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    {name: 'level', label: 'Title level', type: 'number', value: 1, min: 1, max: 6},
  ],
  newInstance: {
    children: [{type: 'itxt'}]
  }
})
export class TitleComponent implements WidgetableComponent {
  @Input() children: WidgetData[];
  @Input() level = 1;
  params: WidgetParams;

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data);
    this.params = params;
    this.children = params.data?.children;
  }
}
