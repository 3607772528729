import {
  AlertService, FileService, TranslateService, base64toBlob, ALL_FILE_TYPES, FILE_TYPE_ICONS, StoredFileDetails, FileType, getAllowedExtensions,
  TempUploadSuccess, getFileExtension, getFilenameWithoutExtension, VideoFormat, AudioFormat
} from '@adeprez/ionstack';
import { ExtraFileUpload, ImageUploaderWithExtra, heicToJpg, isHeic } from './../file-capture';
import { ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActionSheetButton, ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { EditFileConfig, EditFileComponent } from '../edit-file/edit-file.component';
import { ChangeDetectionStrategy, EventEmitter, forwardRef, Input } from '@angular/core';
import { Component, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CameraSource, Photo } from '@capacitor/camera';

interface BrowseFile {
  file?: File;
  type: FileType;
  extension: string;
}

export interface BrowseFileConfig extends EditFileConfig {
  defaultNameGetter?: () => string;
  defaultCreditGetter?: () => string;
  buttonColor?: string;
  openPopup?: boolean;
  disallowMultiupload?: boolean;
  submitCaptureText?: string;
}

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BrowseFileComponent),
    multi: true
  }],
  selector: 'ionstack-browse-file',
  templateUrl: './browse-file.component.html',
  styleUrls: ['./browse-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowseFileComponent extends ImageUploaderWithExtra<StoredFileDetails> {
  @ViewChild('input') inputFile: ElementRef;
  @Output() valueChange = new EventEmitter<StoredFileDetails>();
  @Input() config: BrowseFileConfig = {};
  @Input() showButton = true;
  @Input() isDisabled: boolean;
  @Input() value: StoredFileDetails;
  @Input() requiredExtension: string[] = [];
  @Input() tmpExtraVersion: string;
  @Input() allowExtraUpload = true;
  @Output() onExtraUpload = new EventEmitter<ExtraFileUpload>();
  @Output() uploadingChange = new EventEmitter<BrowseFile[]>();
  capturingType: FileType;
  capturingName: string;
  videoFormat: VideoFormat;
  audioFormat: AudioFormat;
  extCaptureUrl: string;
  tmpCaptureFile: string;
  readonly fileTypes = FileType;
  readonly allTypes = ALL_FILE_TYPES;
  readonly icons = FILE_TYPE_ICONS;
  private uploading: BrowseFile[] = [];

  readonly formatExtensions = (types: FileType[], req: string[]) => {
    const formats = getAllowedExtensions(...(types || []));
    return (req || formats).length ? (req || formats).map(f => '.' + f).join(',') : '*';
  };

  constructor(
    public translateService: TranslateService,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private fileService: FileService,
    public platform: Platform,
    public cd: ChangeDetectorRef,
    public alertService: AlertService,
  ) {
    super();
  }

  get fileType(): FileType {
    return null;
  }

  fileChangeEvent(event: Event): void {
    const files: FileList = event.target?.['files'];
    this.uploadFileList(Array.from(files));
  }

  uploadFileList(files: File[]) {
    this.uploadArray(files, async (file, isPrincipal) => await this.upload(file, file.name, isPrincipal));
  }

  allowType(type: FileType) {
    return !this.config?.allowedTypes?.length || this.config.allowedTypes.includes(type);
  }

  async openAddPopup() {
    const popup = await this.modalController.create({
      component: EditFileComponent,
      componentProps: {
        allowExtraUpload: !this.config.disallowMultiupload,
        config: {
          ...this.config,
          defaultName: this.config.defaultNameGetter?.() || this.config.defaultName,
          defaultCredit: this.config.defaultCreditGetter?.() || this.config.defaultCredit,
        },
        onClose: () => popup.dismiss(),
        save: async (file: StoredFileDetails) => {
          this.setValue(file);
          this.cd.markForCheck();
        },
        extraUploadCallback: (upload: ExtraFileUpload) => this.uploadExtraFile(upload)
      }
    });
    popup.present();
  }

  uploadExtraFile(upload: ExtraFileUpload) {
    return this.onUpload(upload.file, upload.extension, upload.type, upload.name);
  }

  capture(type: FileType) {
    this.capturingType = type;
    this.cd.markForCheck();
  }

  async openAddSheet() {
    const buttons: ActionSheetButton[] = [];
    if (this.allowType(FileType.IMAGE)) {
      buttons.push({
        text: await this.translateService.awaitGet('Browse gallery'),
        icon: 'images',
        handler: () => this.takePicture(CameraSource.Photos)
      });
      buttons.push({
        text: await this.translateService.awaitGet('Take picture'),
        icon: 'camera',
        handler: () => this.takePicture(CameraSource.Camera)
      });
    }
    if (this.allowType(FileType.VIDEO)) {
      buttons.push({
        text: await this.translateService.awaitGet('Capture video'),
        icon: 'videocam',
        handler: () => this.capture(FileType.VIDEO)
      });
    }
    if (this.allowType(FileType.AUDIO)) {
      buttons.push({
        text: await this.translateService.awaitGet('Capture audio'),
        icon: 'mic',
        handler: () => this.capture(FileType.AUDIO)
      });
    }
    if (this.allowType(FileType.DOCUMENT)) {
      buttons.push({
        text: await this.translateService.awaitGet('Browse'),
        icon: 'folder-open',
        handler: () => this.openBrowseFile()
      });
    }
    buttons.push({
      text: await this.translateService.awaitGet('Cancel'),
      icon: 'close',
      role: 'cancel',
    });
    const actionSheet = await this.actionSheetController.create({buttons});
    await actionSheet.present();
  }

  add() {
    return this.config.openPopup ? this.openAddPopup() : this.openAddSheet();
  }

  override async onPhotoTaken(photo: Photo) {
    let format = photo.format;
    if (!format) {
      const urlType = photo.dataUrl.split(';')[0];
      format = urlType.substring(urlType.lastIndexOf('image/') + 6);
    }
    const blob = base64toBlob(photo.dataUrl.substring(photo.dataUrl.indexOf(',') + 1), 'image/' + format);
    await this.upload(blob, null);
    this.cd.markForCheck();
  }

  override openBrowseFile() {
    this.inputFile.nativeElement.value = '';
    this.inputFile.nativeElement.click();
  }

  async afterUpload() {
    this.cd.markForCheck();
  }

  async doUpload(file: Blob, extension: string): Promise<TempUploadSuccess> {
    return await this.fileService.uploadTmp(file, this.tmpExtraVersion ? {
      extraVersion: this.tmpExtraVersion,
      type: getFileExtension(file.type, '.' + file.type).type,
      extension,
    } : undefined);
  }

  async beforeUpload() {}

  async onUpload(file: Blob | string, extension: string, fileType?: FileType, name?: string, extUrl?: string) {
    if (file instanceof Blob && await isHeic(file, extension)) {
      file = await heicToJpg(file);
      extension = 'jpeg';
      fileType = FileType.IMAGE;
    }
    const filename = file?.['name'];
    fileType ||= getFileExtension(typeof file === 'string' ? extension : file.type, filename || ('.' + extension)).type;
    const item = { type: fileType, extension };
    this.uploading.push(item);
    this.uploadingChange.emit(this.uploading);
    try {
      let tempFile: string;
      if (extUrl) {
        tempFile = undefined;
      } else if (typeof file === 'string') {
        tempFile = file;
      } else {
        tempFile = (await this.fileService.uploadTmp(file)).name;
      }
      this.setValue(await this.fileService.uploadRaw({
        fileType,
        extension,
        name: name || getFilenameWithoutExtension(filename) || this.config.defaultNameGetter?.() || this.config.defaultName,
        isPublic: !this.config.isPrivate && !this.config.readRule,
        readRule: this.config.readRule,
        tempFile,
        extUrl
      }));
    } finally {
      this.uploading = this.uploading.filter(upload => upload !== item);
      this.uploadingChange.emit(this.uploading);
    }
  }

  async submitCapture() {
    await this.onUpload(
      this.tmpCaptureFile,
      this.capturingType === FileType.AUDIO ? this.audioFormat : this.videoFormat,
      this.capturingType,
      this.capturingName,
      this.extCaptureUrl,
    );
    this.resetCapture();
  }

  resetCapture() {
    this.tmpCaptureFile = null;
    this.capturingType = null;
    this.capturingName = null;
    this.extCaptureUrl = null;
    this.cd.markForCheck();
  }

  async noMatchForUpload() {
    this.alertService.showError(await this.translateService.awaitGet(
      'Supported format are: {{formats}}',
      {formats: (this.requiredExtension?.length ? this.requiredExtension : this.getAllowedExtensions()).join(', ')}
    ));
  }

  getAllowedExtensions() {
    return getAllowedExtensions(...(this.config.allowedTypes || ALL_FILE_TYPES));
  }

}
