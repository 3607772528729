import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: { [url: string]: Promise<void> } = {};

  constructor(
    @Inject(DOCUMENT) private document: any,
  ) {}
  
  loadScript(url: string): Promise<void> {
    return this.scripts[url] ??= this.retrieveScript(url);
  }

  private retrieveScript(url: string) {
    return new Promise<void>((resolve, reject) => {
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      if (script['readyState']) { //IE
        script['onreadystatechange'] = () => {
          if (script['readyState'] === 'loaded' || script['readyState'] === 'complete') {
              script['onreadystatechange'] = null;
              resolve();
          }
        };
      } else { //Others
        script.onload = resolve;
      }
      script.onerror = reject;
      this.document.head.appendChild(script);
    });
  }
}
