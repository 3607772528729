import { SiretSearchEtablissementWrap } from '../model/datagouv';
import { Injectable } from '@angular/core';
import { SiretSearchEtablissement, RechercheEntreprises } from '../model/datagouv';
import { IonstackService } from './ionstack.service';

@Injectable({
  providedIn: 'root'
})
export class DatagouvService {

  constructor(private ionstackService: IonstackService) { }

  /** https://api.insee.fr/catalogue/site/themes/wso2/subthemes/insee/pages/item-info.jag?name=Sirene&version=V3&provider=insee */
  async searchSiret(siret: string): Promise<SiretSearchEtablissementWrap> {
    return new SiretSearchEtablissementWrap((await this.ionstackService.get<{etablissement: SiretSearchEtablissement}>('https://api.insee.fr/entreprises/sirene/V3/siret/' + siret, {
      isRootUrl: true, withoutAuth: true, stateKey: 'siret' + siret, headers: {Authorization: 'Bearer ' + this.ionstackService.config.sirenApiKey}
    })).etablissement);
  }

  /** https://api.gouv.fr/documentation/api-recherche-entreprises */
  searchEntreprise(name: string, page = 0, perPage = 20): Promise<RechercheEntreprises> {
    return this.ionstackService.get<RechercheEntreprises>('https://recherche-entreprises.api.gouv.fr/search', {
      isRootUrl: true, withoutAuth: true, params: {page: (page + 1) + '', per_page: perPage + '', q: name}
    });
  }

}
