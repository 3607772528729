
export interface Notif {
  id: number;
  type: string;
  title: string;
  message: string;
  icon: string;
  creationDate: string;
  readDate: string;
  meta: { [key: string]: string };
}

export interface WSNotif extends Notif {
  new: boolean;
}

export interface NotifsCount {
  total: number;
  byType: { [type: string]: number };
}

export function extractNotificationTypes(types: string): { param: 'type' | 'not', types: string[] } {
  let param: 'type' | 'not' = 'type';
  if (types.charAt(0) === '!') {
    param = 'not';
    types = types.substring(1);
  }
  return { param, types: types.split(' ') };
}