import { TranslatableModule, IonstackDirectivesModule, IonstackPipesModule } from '@adeprez/ionstack';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentFormComponent } from './payment-form.component';
import { Payment3DSModalComponent } from './3ds-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    IonstackDirectivesModule,
    IonstackPipesModule,
    TranslatableModule,
  ],
  declarations: [PaymentFormComponent, Payment3DSModalComponent],
  exports: [PaymentFormComponent],
})
export class PaymentFormModule { }
