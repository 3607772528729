
export interface UserPlan {
  name: string;
  monthlyPrice: number;
  yearlyPrice: number;
  currency: string;
  vat: number;
  trialDays: number;
}

export enum PaymentRecurrence {
  oneTime = 'ONE_TIME',
  monthly = 'MONTHLY',
  yearly = 'YEARLY',
}

export interface Sub {
  id: number;
  plan: UserPlan;
  price: number;
  recurrence: PaymentRecurrence;
  currency: string;
  startDate: string;
  endDate: string;
  cancelRequested?: string;
  active: boolean;
}

export interface StartSubscription {
  userPlan: string;
  paymentMethodId: string;
  recurrence: PaymentRecurrence;
  description?: string;
}