import { IonstackService } from './ionstack.service';
import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

const encoder: HttpParameterCodec = {
  encodeKey: encodeURIComponent,
  encodeValue: encodeURIComponent,
  decodeKey: decodeURIComponent,
  decodeValue: decodeURIComponent,
}

@Injectable({
  providedIn: 'root'
})
export class DataTokenService {

  constructor(private ionstackService: IonstackService) { }

  async createTokens(config: {url: string, auth: string, keysReplacer?: (key: string) => string}, ...data: any[]) {
    return Promise.all(data.map(d => this.createToken(config, d)));
  }

  async createToken(config: {url: string, auth: string, keysReplacer?: (key: string) => string}, data: any): Promise<string> {
    if (data == null || !config.url) {
      return null;
    }
    return !config.url ? null : (await this.ionstackService.post<{id: string}>(
      config.url,
      new HttpParams({fromObject: this.flattenObject(data, config.keysReplacer), encoder}), {
        isRootUrl: true,
        headers: {'Authorization': `Bearer ${config.auth}`}
      }
    )).id;
  }

  flattenObject(obj: any, formatKey: (key: string) => string = s => s, target = {}, combinedKey = ''): {[key: string]: any} {
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          this.flattenObject(obj[key], formatKey, target, `${combinedKey}[${formatKey ? formatKey(key) : key}]`);
        }
      }
    } else {
      target[combinedKey] = obj;
    }
    return target;
  }

}
