import { IonstackPipesModule, IonstackDirectivesModule } from '@adeprez/ionstack';
import { WidgetModule } from '@adeprez/ionstack/ui/widget';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChildrenWrapperDirective, WidgetChildrenComponent, WidgetWrapperDirective } from './widget-children.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    WidgetModule,
    IonstackPipesModule,
    ReactiveFormsModule,
    IonstackDirectivesModule,
  ],
  declarations: [
    WidgetChildrenComponent,
    WidgetWrapperDirective,
    ChildrenWrapperDirective,
  ],
  exports: [
    WidgetChildrenComponent,
    WidgetWrapperDirective,
    ChildrenWrapperDirective,
  ],
})
export class WidgetChildrenModule { }
