import { ContextService } from './context.service';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({
  providedIn: 'root'
})
export class InitAppService {

  constructor(private contextService: ContextService) { }

  async initApp() {
    try {
      await this.contextService.context.pipe(first()).toPromise();
      SplashScreen.hide();
    } catch (e) {
      SplashScreen.hide();
      throw 'The app is under maintenance, we will come back very soon!';
    }
  }

}