import { Cached } from '../util/cache';
import { Injectable } from '@angular/core';
import { IonstackService } from './ionstack.service';
import { MergeChanges } from '../util/util';
import { CountryPhoneList } from '../model/phone';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  readonly countries = new Cached<string[]>(() => this.ionstackService.get<string[]>('/public/countries'));

  constructor(private ionstackService: IonstackService) { }

  getCountries() {
    return this.countries.toPromise();
  }

  getCountriesCities() {
    return this.ionstackService.get<{[alpha3Code: string]: number}>('/countries');
  }

  deleteCountry(alpha3Code: string) {
    return this.ionstackService.delete('/countries/' + alpha3Code);
  }

  createCountry(alpha3Code: string) {
    return this.ionstackService.post('/countries/' + alpha3Code, {});
  }

  syncCities(alpha3Code: string) {
    return this.ionstackService.post<MergeChanges>('/countries/' + alpha3Code + '/cities', {});
  }

  getCountriesPhones() {
    return this.ionstackService.get<CountryPhoneList>('/public/countries/phones');
  }

}
