import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, NgZone, OnDestroy, Output, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaptureError, MediaCapture, MediaFile } from '@ionic-native/media-capture/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import { Observable, timer } from 'rxjs';
import { ExtraFileUpload, readFile, UploaderWithExtra } from '../file-capture';
import { MIME_TYPES, TempUploadSuccess, TranslateService, FileType, getExtensionDisplay, AlertService, FileService, b64decode } from '@adeprez/ionstack';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileCaptureComponent),
    multi: true
  }],
  selector: 'ionstack-file-capture',
  templateUrl: './file-capture.component.html',
  styleUrls: ['../capture.scss', './file-capture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileCaptureComponent<T extends string> extends UploaderWithExtra<string> implements OnDestroy, AfterContentInit {
  @ViewChild('input', { static: true }) inputFile: ElementRef;
  @Output() streamChange = new EventEmitter<MediaStream>();
  @Output() extUrlChange = new EventEmitter<string>();
  @Output() formatChange = new EventEmitter<T>();
  @Output() onPickFile = new EventEmitter<Blob>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() urlChange = new EventEmitter<string>();
  @Output() onFileName = new EventEmitter<string>();
  @Output() onExtra = new EventEmitter<string>();
  @Output() onExtraUpload = new EventEmitter<ExtraFileUpload>();
  @Input() hideToolbar: boolean;
  @Input() autoStart = false;
  @Input() browseAllowed = true;
  @Input() uploadText = 'Uploading';
  @Input() captureEnabled = true;
  @Input() previewHeight = '300px';
  @Input() captureLabel = 'Capture';
  @Input() captureIcon = 'document';
  @Input() browseIcon = 'document';
  @Input() browseText = 'Browse';
  @Input() extUrlText: string;
  @Input() extUrlLabel = 'URL';
  @Input() extUrlPlaceholder: string;
  @Input() allowExtUrl = false;
  @Input() tmpExtraVersion: string;
  @Input() constraints: MediaStreamConstraints;
  @Input() value: T;
  @Input() isDisabled: boolean;
  @Input() prefExtension: string;
  @Input() requiredExtension: string[];
  @Input() allowExtraUpload = false;
  mimeTypes: {[format in T]?: string} = {};
  mimeSupport: string[] = [];
  extensionSupport: string[] = [];
  filename: string;
  recordChunks: Blob[];
  uploading = false;
  accept: string;
  time: Observable<number>;
  stream: MediaStream;
  recorder: any;
  stopCallback = () => {};
  private popup: HTMLIonLoadingElement;
  private _url: string;
  private _extUrl: string;
  private _fileType: FileType;
  readonly getExtensionDisplay = getExtensionDisplay;

  formatExtensions = (formats: T[], req: string[]) => (req || formats).length ? (req || formats).map(f => '.' + f).join(',') : '*';;

  constructor(
    private platform: Platform,
    private mediaCapture: MediaCapture,
    public alertService: AlertService, 
    private fileService: FileService,
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private zone: NgZone,
    public cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterContentInit() {
    if (this.autoStart) {
      this.capture();
    }
  }

  ngOnDestroy() {
    this.stopRecord();
    if (this.url) {
      URL.revokeObjectURL(this.url);
      this.url = null;
    }
  }

  openFileInput() {
    this.inputFile.nativeElement.value = '';
    this.inputFile.nativeElement.click();
  }

  @Input() set fileType(fileType: FileType) {
    this._fileType = fileType;
    this.parseSupport();
  }

  get fileType() {
    return this._fileType; 
  }

  @Input() set url(url: string) {
    if (url !== this._url) {
      this._url = url;
      this.urlChange.emit(url);
    }
  }

  get url() {
    return this._url;
  }

  @Input() set extUrl(extUrl: string) {
    if (this._extUrl !== extUrl) {
      this._extUrl = extUrl;
      this.extUrlChange.emit(extUrl);
    }
  }

  get extUrl() {
    return this._extUrl;
  }

  fileChangeEvent(event: Event): void {
    this.setTouched();
    const files: FileList = event.target?.['files'];
    this.uploadArray(Array.from(files), async (file, isPrincipal) => await this.upload(file, file.name, isPrincipal));
  }

  onDrop(files: File[]) {
    this.setTouched();
    this.uploadArray(files, async (file, isPrincipal) => await this.upload(file, file.name, isPrincipal));
  }

  async stopRecord() {
    return await new Promise<boolean>(resolve => {
      this.stopCallback = () => resolve(true);
      if (this.recorder) {
        this.recorder.stop();
      } else {
        resolve(false);
      }
    });
  }

  reset() {
    this.setValue(null);
    this.extUrl = null;
    this.url = null;
    this.cd.markForCheck();
  }

  private async capacitorCapture() {
    try {
      let capture: MediaFile[] | CaptureError;
      const limit = this.allowExtraUpload ? {} : {limit: 1};
      if (this.constraints.video) {
        capture = await this.mediaCapture.captureVideo(limit);
      } else if (this.constraints.audio) {
        capture = await this.mediaCapture.captureAudio(limit);
      }
      if (capture && Array.isArray(capture) && capture.length) {
        this.uploadArray(capture, async (media, isPrincipal) => {
          let path = media.fullPath;
          if (!path.startsWith('file://')) {
            path = 'file://' + path;
          }
          return await this.upload(await readFile(path, media.type), media.name, isPrincipal);
        });
      }
    } catch (e) {
      this.alertService.showError(e);
    }
  }

  getCapturableFormat() {
    if (this.prefExtension && this.extensionSupport.includes(this.prefExtension) && MediaRecorder.isTypeSupported(this.mimeTypes[this.prefExtension])) {
      return this.mimeTypes[this.prefExtension];
    }
    const record = this.mimeSupport.find(mimeType => MediaRecorder.isTypeSupported(mimeType));
    if (!record) {
      throw {error: 'You cannot record'};
    }
    return record;
  }

  async browserCapture() {
    try {
      const mimeType = this.getCapturableFormat();
      this.setValue(null);
      this.stream = await navigator.mediaDevices.getUserMedia(this.constraints);
      this.recorder = new MediaRecorder(this.stream, {mimeType: mimeType});
      const chunks: Blob[] = [];
      this.recorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          chunks.push(event.data);
          this.zone.run(() => {
            this.recordChunks = [...chunks];
            this.cd.markForCheck();
          });
        }
      };
      this.recorder.onstop = () => {
        this.zone.run(() => {
          this.stream.getTracks().forEach(t => t.stop());
          this.uploading = true;
          this.stream = null;
          this.recorder = null;
          this.streamChange.emit(this.stream);
          if (chunks?.length) {
            this.upload(new Blob(chunks, {type: mimeType}), new Date().toLocaleString().replace(/[^a-z0-9]/gi, '-')).then(this.stopCallback);
          } else {
            this.stopCallback();
          }
          this.cd.markForCheck();
        });
      };
      this.recorder.start(500);
      this.time = timer(0, 1000);
      this.cd.markForCheck();
      this.streamChange.emit(this.stream);
    } catch (e) {
      this.alertService.showError(e);
    }
  }

  async capture() {
    this.setTouched();
    if (this.platform.is('capacitor')) {
      await this.capacitorCapture();
    } else if (navigator.mediaDevices) {
      await this.browserCapture();
    } else {
      this.alertService.showError('Your device does not support media capture');
    }
  }

  async doUpload(file: Blob, extension: string): Promise<TempUploadSuccess> {
    return await this.fileService.uploadTmp(file, this.tmpExtraVersion ? {
      extraVersion: this.tmpExtraVersion,
      type: this.fileType,
      extension,
    } : undefined);
  }

  async beforeUpload(extension: string, filename: string) {
    this.uploading = true;
    this.formatChange.emit(extension as T);
    this.onFileName.emit(filename);
    const uploadText = (await this.translateService.get(this.uploadText).toPromise()) + '...';
    this.popup = await this.loadingController.create({message: uploadText});
    await this.popup.present();
  }

  async onUpload(file: Blob, extension: string) {
    const upload = await this.doUpload(file, extension);
    this.onPickFile.emit(file);
    this.setValue(upload.name);
    this.url = URL.createObjectURL(file);
    if (upload.extra) {
      this.onExtra.emit(b64decode(upload.extra, true));
    }
  }

  async afterUpload() {
    await this.popup.dismiss();
    this.uploading = false;
    this.cd.markForCheck();
  }

  async noMatchForUpload() {
    this.alertService.showError(await this.translateService.awaitGet(
      'Supported format are: {{formats}}',
      {formats: (this.requiredExtension || this.extensionSupport).join(', ')}
    ));
  }

  private parseSupport() {
    this.mimeTypes = {};
    this.mimeSupport = [];
    this.extensionSupport = [];
    const mimeTypes = MIME_TYPES[this.fileType];
    for (const ext of Object.keys(mimeTypes)) {
      this.mimeSupport.push(mimeTypes[ext]);
      this.extensionSupport.push(ext);
      this.mimeTypes[ext] = mimeTypes[ext];
    }
  }

}
