import { ComponentRef } from '@ionic/core';
import { FileType } from '../model/files';
import { IonstackModuleConfig, MODULE_CONFIG } from '../ionstack.config';
import { Directive, HostListener, Input, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoredFile } from '../model/files';

export async function openGalleryModal(galleryComponent: ComponentRef, controller: ModalController, files: StoredFile[], file?: StoredFile, config?: any) {
  return (await controller.create({
    component: galleryComponent,
    cssClass: 'modal-gallery',
    componentProps: {
      files,
      config,
      activeIndex: file ? files.findIndex(f => f.id === file.id) : 0,
    }
  })).present();
}

@Directive({
  selector: '[galleryClick]',
  host: {
    '[style.cursor]': '"pointer"'
  }
})
export class GalleryClickDirective {
  @Input() galleryClick: StoredFile;
  @Input() files: StoredFile[];
  @Input() triggerTypes: FileType[] = [FileType.IMAGE, FileType.DOCUMENT];
  @Input() galleryConfig: any;

  constructor(
    private modalController: ModalController,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
  ) { }

  @HostListener('click', ['$event']) async onClick(event: Event) {
    if (this.ionstackModuleConfig.galleryComponent && (this.galleryClick || this.files?.length) && this.triggerTypes.includes(this.galleryClick.fileType)) {
      event.preventDefault();
      event.stopPropagation();
      const files = this.files?.length ? [...this.files] : [this.galleryClick];
      if (!files.find(f => f.id === this.galleryClick.id)) {
        files.unshift(this.galleryClick);
      }
      await openGalleryModal(this.ionstackModuleConfig.galleryComponent, this.modalController, files, this.galleryClick, this.galleryConfig);
    }
  }

}
