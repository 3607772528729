import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DefaultWidgetContainer, Widget } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';


@Component({
  selector: 'ionstack-card-header',
  template: `
  <ion-card-header>
    <ionstack-widget-children [value]="children" [parent]="params.holder" [context]="params.context"></ionstack-widget-children>
  </ion-card-header>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'card-header',
  widgetName: 'Card header',
  group: 'Card',
  icon: 'bookmark',
  childSupport: ['card-title', 'card-subtitle'],
  editorPopup: EditWidgetPopupComponent,
  newInstance: {
    children: [
      {type: 'card-title', children: [{type: 'html', content: 'Title'}]},
    ]
  }
})
export class CardHeaderComponent extends DefaultWidgetContainer {}


@Component({
  selector: 'ionstack-card-title',
  template: `
  <ion-card-title>
    <ionstack-widget-children [value]="children" [parent]="params.holder" [context]="params.context"></ionstack-widget-children>
  </ion-card-title>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'card-title',
  widgetName: 'Card title',
  group: 'Card',
  icon: 'information-circle',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  newInstance: {
    children: [
      {type: 'html', content: 'Title'},
    ]
  }
})
export class CardTitleComponent extends DefaultWidgetContainer {}


@Component({
  selector: 'ionstack-card-subtitle',
  template: `
  <ion-card-subtitle>
    <ionstack-widget-children [value]="children" [parent]="params.holder" [context]="params.context"></ionstack-widget-children>
  </ion-card-subtitle>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'card-subtitle',
  widgetName: 'Card subtitle',
  group: 'Card',
  icon: 'information',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  newInstance: {
    children: [
      {type: 'html', content: 'Subtitle'},
    ]
  }
})
export class CardSubtitleComponent extends DefaultWidgetContainer {}