import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DEFAULT_VIDEO_FORMAT, IonstackModuleConfig, MODULE_CONFIG, FileType, VideoFormat, FormControlComponent } from '@adeprez/ionstack';
import { ExtraFileUpload } from '../file-capture';


@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VideoCaptureComponent),
    multi: true
  }],
  selector: 'ionstack-video-capture',
  templateUrl: './video-capture.component.html',
  styleUrls: ['./video-capture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoCaptureComponent extends FormControlComponent<string> implements AfterViewChecked {
  @ViewChild('record') videoStream: ElementRef;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onFileName = new EventEmitter<string>();
  @Output() onExtraUpload = new EventEmitter<ExtraFileUpload>();
  @Input() hideToolbar: boolean;
  @Input() allowExtraUpload = false;
  @Input() value: string;
  @Input() isDisabled: boolean;
  @Output() onPickFile = new EventEmitter<Blob>();
  @Output() formatChange = new EventEmitter<VideoFormat>();
  @Output() extUrlChange = new EventEmitter<string>();
  @Input() previewHeight = '300px';
  @Input() allowExtUrl = true;
  @Input() uploadText = 'Uploading';
  @Input() url: string;
  @Input() captureVideoFormat: VideoFormat;
  @Input() extUrl: string;
  @Input() browseAllowed = true;
  @Input() autoStart = false;
  readonly prefExtension: VideoFormat;
  readonly fileType = FileType.VIDEO;
  readonly constraints: MediaStreamConstraints = {
    video: true,
    audio: { echoCancellation: true },
  };

  constructor(
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    public cd: ChangeDetectorRef,
  ) {
    super();
    this.prefExtension = this.ionstackModuleConfig.defaultVideoFormat || DEFAULT_VIDEO_FORMAT
  }

  ngAfterViewChecked() {
    if (this.videoStream && !this.videoStream.nativeElement?.muted) {
      this.videoStream.nativeElement.muted = true;
    }
  }

}
