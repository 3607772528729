<div class="paginator" [style.textAlign]="align">
  <span class="previous"></span>
  <ng-container *ngFor="let number of pageNumbers">
    <span *ngIf="number === null; else num" class="page-number">...</span>
    <ng-template #num>
      <a routerLink="."
        class="page-number active"
        queryParamsHandling="merge"
        [queryParams]="{page: (number - 1) || null}"
        [ngClass]="{selected: number - 1 === paged.page.number}"
        (click)="pageClick.emit(number)"
      >{{ number }}</a>
    </ng-template>
  </ng-container>
  <span class="next"></span>
</div>