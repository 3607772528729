import { InitAppService } from './service/init-app.service';
import { NotifController } from './controller/notif.controller';
import { NotifRouteProvider } from './service/notification.service';
import { APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { GroupTranslationHandler } from './util/i18n-loader';
import { TranslateHttpLoader } from './util/i18n-loader'
import { IonstackPage } from './component/ionstack.page';
import { IonstackModuleConfig, MODULE_CONFIG } from './ionstack.config';
import { ContextService } from './service/context.service';
import { MissingTranslationHandler } from './translate/missing-translation-handler';
import { TranslateLoader } from './translate/translate.loader';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { RxStompService  } from '@stomp/ng2-stompjs';
import { ActionsModule } from './actions.module';
import { ActionController } from './controller/action.controller';
import { actionToken } from './service/action.service';
import { datasourceToken } from './service/datasource.service';
import { first, map } from 'rxjs/operators';
import { PaymentService } from './service/payment.service';
import { AppController } from './controller/app.controller';

export function initApp(initApp: InitAppService) {
  return function() {
    return initApp.initApp();
  };
}

export function getLocale(contextService: ContextService) {
  return contextService.currentLangague;
}

// @dynamic
@NgModule({
    declarations: [IonstackPage],
    exports: [IonstackPage],
    imports: [
        BrowserTransferStateModule,
        IonicStorageModule.forRoot(),
        ActionsModule,
    ]
})
export class IonstackModule {
  public static forRoot(config: IonstackModuleConfig): ModuleWithProviders<IonstackModule> {
    return {
      ngModule: IonstackModule,
      providers: [
        MediaCapture,
        RxStompService,
        { provide: TranslateLoader, useClass: TranslateHttpLoader },
        { provide: MissingTranslationHandler, useClass: GroupTranslationHandler },
        { provide: NotifRouteProvider, useExisting: NotifController, multi: true },
        { provide: MODULE_CONFIG, useValue: config },
        {
          provide: APP_INITIALIZER,
          useFactory: (initApp),
          deps: [InitAppService],
          multi: true
        },
        {
          provide: LOCALE_ID,
          useFactory: (getLocale),
          deps: [ContextService]
        },
        {
          provide: actionToken('logout'),
          useFactory: (c: AppController) => c.logout.bind(c),
          deps: [AppController]
        },
        {
          provide: actionToken('email-contact'),
          useFactory: (s: ActionController) => s.sendContactEmail.bind(s),
          deps: [ActionController]
        },
        {
          provide: datasourceToken('context'),
          useFactory: (s: ContextService) => () => s.context.pipe(first()).toPromise(),
          deps: [ContextService]
        },
        {
          provide: datasourceToken('context.account.fullname'),
          useFactory: (s: ContextService) => () => s.context.pipe(first()).pipe(map(c => [c.account?.firstname, c.account?.lastname].filter(s => s).join(' '))).toPromise(),
          deps: [ContextService]
        },
        {
          provide: datasourceToken('context'),
          useFactory: (s: ContextService) => () => s.context.pipe(first()).toPromise(),
          deps: [ContextService]
        },
        {
          provide: datasourceToken('payment-account'),
          useFactory: (s: PaymentService) => () => s.defaultAccount.toPromise(),
          deps: [PaymentService]
        },
        {
          provide: datasourceToken('payment-account.fullAddress'),
          useFactory: (s: PaymentService) => () => s.defaultAccount.toPromise().then(a => [a.address.line1, a.address.line2, a.address.city].filter(s => s).join(' ')),
          deps: [PaymentService]
        },
        {
          provide: datasourceToken('customer'),
          useFactory: (s: PaymentService) => () => s.customer.toPromise(),
          deps: [PaymentService]
        },
        {
          provide: datasourceToken('today'),
          useValue: () => new Date().toLocaleDateString(),
        },
      ]
    };
  }
}
