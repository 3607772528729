import { FileCaptureModule } from './../file-capture/file-capture.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditFileComponent } from './edit-file.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatableModule, MenuItemsModule, IonstackDirectivesModule, IonstackPipesModule } from '@adeprez/ionstack';
import { ImageCaptureModule } from '../image-capture/image-capture.module';
import { VideoCaptureModule } from '../video-capture/video-capture.module';
import { AudioCaptureModule } from '../audio-capture/audio-capture.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatableModule,
    MenuItemsModule,
    IonstackDirectivesModule,
    ImageCaptureModule,
    VideoCaptureModule,
    AudioCaptureModule,
    IonstackPipesModule,
    FileCaptureModule,
  ],
  declarations: [EditFileComponent],
  exports: [EditFileComponent],
})
export class EditFileModule { }
