import { IonstackDirectivesModule, TranslatableModule } from '@adeprez/ionstack';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AdviceComponent } from '../component/advice/advice.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslatableModule,
    IonstackDirectivesModule,
  ],
  declarations: [
    AdviceComponent,
  ],
  exports: [
    AdviceComponent,
  ],
})
export class ControllerModule { }