import { AbsoluteRoutePipe } from './absolute-route.pipe';
import { JoinPipe } from './join.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextBoldMatcherPipe } from './text-bold-matcher.pipe';
import { ReplacePipe } from './replace.pipe';
import { MapPipe } from './map.pipe';
import { HtmlLinksPipe } from './html-links.pipe';
import { SafePipe } from './safe.pipe';
import { FilePathPipe } from './file-path.pipe';
import { RenderErrorPipe } from './render-error.pipe';
import { CategoryPipe } from './category.pipe';
import { FindPipe } from './find.pipe';
import { CallPipe } from './call.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { NotifCountPipe } from './notif-count.pipe';
import { FileSizePipe } from './file-size.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { BarCodePipe } from './barcode.pipe';
import { ProvidedRoutePipe } from './provided-route.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AbsoluteRoutePipe,
    CallPipe,		
    TextBoldMatcherPipe,
    ReplacePipe,
    MapPipe,
    HtmlLinksPipe,
    SafePipe,
    FilePathPipe,
    FileSizePipe,
    RenderErrorPipe,
    CategoryPipe,
    FindPipe,
    JoinPipe,
    DateAgoPipe,
    NotifCountPipe,
    DateFormatPipe,
    BarCodePipe,
    ProvidedRoutePipe,
   ],
  exports: [
    AbsoluteRoutePipe,
    CallPipe,
    TextBoldMatcherPipe,
    ReplacePipe,
    MapPipe,
    HtmlLinksPipe,
    SafePipe,
    FilePathPipe,
    FileSizePipe,
    RenderErrorPipe,
    CategoryPipe,
    FindPipe,
    JoinPipe,
    DateAgoPipe,
    NotifCountPipe,
    DateFormatPipe,
    BarCodePipe,
    ProvidedRoutePipe,
  ]
})
export class IonstackPipesModule { }
