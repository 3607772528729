import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditWidgetPopupComponent } from './edit-widget-popup.component';
import { IonicModule } from '@ionic/angular';
import { TranslatableModule, TranslateModule } from '@adeprez/ionstack';
import { WidgetChildrenModule } from '@adeprez/ionstack/ui/widget-children';
import { SimpleFormModule } from '@adeprez/ionstack/ui/simple-form';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslatableModule,
    SimpleFormModule,
    TranslateModule,
    FormsModule,
    WidgetChildrenModule,
  ],
  declarations: [EditWidgetPopupComponent],
  exports: [EditWidgetPopupComponent]
})
export class EditWidgetPopupModule { }
