import { Directive, ElementRef, Renderer2, HostListener, HostBinding, Input, Output, EventEmitter } from '@angular/core';

const defaultZoom = 'scale(1)';

@Directive({
  selector: '[zoomOnClick]',
})
export class ZoomOnClickDirective {
  @Output() dragging = new EventEmitter<boolean>();
  @Output() zoomed = new EventEmitter<boolean>();
  @Input() zoomOnClick: number | string = 1.5;
  @HostBinding('style.transform') transform: string = defaultZoom;
  @HostBinding('style.transformOrigin') transformOrigin: string;
  @Input() @HostBinding('style.transition') transition: string = 'transform 0.3s';
  private isDragging = false;
  private startX = 0;
  private startY = 0;
  private currentX = 0;
  private currentY = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.hasMoved()) {
      const rect = this.el.nativeElement.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;
      if (this.transform === defaultZoom) {
        this.transform = (`scale(${this.zoomOnClick || 1.5})`);
        this.transformOrigin = `${offsetX}px ${offsetY}px`;
      } else {
        this.transform = defaultZoom;
      }
      this.updateCursor();
      this.zoomed.emit(this.transform !== defaultZoom);
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.updateCursor();
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (this.transform != defaultZoom) {
      this.setDragging(true);
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.currentX = this.startX;
      this.currentY = this.startY;
      this.updateCursor();
      event.preventDefault();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const dx = event.clientX - this.currentX;
      const dy = event.clientY - this.currentY;
      this.currentX = event.clientX;
      this.currentY = event.clientY;
      this.scroll(dx, dy);
    }
  }

  @HostListener('mouseleave')
  @HostListener('mouseup')
  onMouseUp() {
    this.setDragging(false);
    this.updateCursor();
  }

  private setDragging(isDragging: boolean) {
    if (this.isDragging !== isDragging) {
      this.isDragging = isDragging;
      this.dragging.emit(isDragging);
    }
  }

  private updateCursor() {
    let cursor: string;
    if (this.isDragging) {
      cursor = 'grabbing';
    } else if (this.transform === defaultZoom) {
      cursor = 'zoom-in';
    } else { 
      cursor = 'grab';
    }
    this.renderer.setStyle(this.el.nativeElement, 'cursor', cursor);
  }

  private scroll(dx: number, dy: number) {
    const pos = this.transformOrigin.replace(/px/g, '').split(' ');
    this.transformOrigin = `${+pos[0] - dx / 2}px ${+pos[1] - dy / 2}px`;
  }

  private hasMoved() {
    return Math.abs(this.currentX - this.startX) > 5 || Math.abs(this.currentY - this.startY) > 5;
  }

}