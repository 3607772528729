import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioCaptureComponent } from './audio-capture.component';
import { FileCaptureModule } from '../file-capture/file-capture.module';
import { TranslatableModule } from '@adeprez/ionstack';
import { IonicModule } from '@ionic/angular';
import { AudioPlayerModule } from '../audio-player/audio-player.module';

@NgModule({
  imports: [
    CommonModule,
    FileCaptureModule,
    TranslatableModule,
    IonicModule,
    AudioPlayerModule,
  ],
  declarations: [AudioCaptureComponent],
  exports: [AudioCaptureComponent],
})
export class AudioCaptureModule { }
