import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipListComponent } from './chip-list.component';
import { IonicModule } from '@ionic/angular';
import { AutocompleteModule } from '@adeprez/ionstack/ui/autocomplete';
import { TranslateModule } from '@adeprez/ionstack';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AutocompleteModule,
    TranslateModule,
  ],
  declarations: [ChipListComponent],
  exports: [ChipListComponent],
})
export class ChipListModule { }
