import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditTranslatableWidgetPopupComponent } from './edit-translatable-widget-popup.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '../../translate/translate.module';
import { TranslaterModule } from '../translater/translater.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    TranslaterModule,
    ReactiveFormsModule,
  ],
  declarations: [EditTranslatableWidgetPopupComponent],
  exports: [EditTranslatableWidgetPopupComponent],
})
export class EditTranslatableWidgetPopupModule { }
