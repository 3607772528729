import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { Dictionary, Language } from '../../model/i18n';
import { ContextService } from '../../service/context.service';
import { CleanSubscriber } from '../../util/subscriber';
import { getDirty } from '../../util/form';
import { Context } from '../../model/context';

@Component({
  selector: 'ionstack-translater',
  templateUrl: './translater.component.html',
  styleUrls: ['./translater.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslaterComponent extends CleanSubscriber implements OnInit, OnDestroy {
  @Input() key: string;
  @Input() languages: Language[];
  @Input() args: any = {};
  @Input() translations: Dictionary = {};
  @Input() submitBar = true;
  @Output() translationsChange = new EventEmitter<Dictionary>();
  @Output() onCancel = new EventEmitter<Dictionary>();
  argKeys = [];
  formGroup: UntypedFormGroup;

  constructor(private contextService: ContextService, private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.argKeys = Object.keys(this.args || {});
    if (this.languages) {
      this.initForm();
    } else {
      this.subscribe<Context>(this.contextService.context, context => {
        this.languages = context.languages;
        this.initForm();
      })
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  getValue()  {
    const filled: {[lang: string]: string} = {};
    for (const l of Object.keys(this.formGroup.value)) {
      const txt = this.formGroup.value[l];
      if (txt) {
        filled[l] = txt;
      }
    }
    return filled;
  }

  private initForm() {
    const group = {};
    for (const lang of this.languages) {
      group[lang.code] = [this.translations[lang.code]];
    }
    this.formGroup = this.formBuilder.group(group);
  }

  argText(arg: string) {
    return '{{' + arg + '}}';
  }

  onAbort() {
    this.translationsChange.emit(this.translations);
  }

  async submit() {
    this.translationsChange.emit(this.getValue());
  }

}

@Component({
  templateUrl: './translater.component.html',
  styleUrls: ['./translater.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslaterPopover extends TranslaterComponent {
  @Input() save: (dic: Dictionary) => Promise<void>;
  
  constructor(contextService: ContextService, formBuilder: UntypedFormBuilder, private popoverController: PopoverController) {
    super(contextService, formBuilder);
  }

  async submit() {
    await this.save(getDirty(this.formGroup));
    this.popoverController.dismiss(this.getValue());
  }

  onAbort() {
    this.popoverController.dismiss();
  }

}
