import { IonstackPipesModule, TranslatableModule, IonstackDirectivesModule, MenuItemsModule } from '@adeprez/ionstack';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCaptureComponent } from './image-capture.component';
import { SimpleFormModule } from '@adeprez/ionstack/ui/simple-form';
import { IonicModule } from '@ionic/angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SimpleFormModule,
    ImageCropperModule,
    TranslatableModule,
    IonstackDirectivesModule,
    ReactiveFormsModule,
    MenuItemsModule,
    IonstackPipesModule,
  ],
  declarations: [ImageCaptureComponent],
  exports: [ImageCaptureComponent]
})
export class ImageCaptureModule { }
