import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { Widget, DatasourceService } from '@adeprez/ionstack';
import { BaseFormWidget, FORM_META_FIELDS, WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-hidden-input',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'input-hidden',
  widgetName: 'Hidden input',
  group: 'Forms',
  icon: 'eye-off',
  widgetDescription: 'Hidden input field',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    FORM_META_FIELDS.name,
    FORM_META_FIELDS.value,
  ]
})
export class HiddenInputComponent extends BaseFormWidget {

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) form: WidgetParentForm,
    datasourceService: DatasourceService,
    public cd: ChangeDetectorRef,
  ) {
    super(form, datasourceService);
  }

}
