import { StoredFile } from './files';

export enum RolePermission {
	ADMIN = 'ROLE_ADMIN',
	USER = 'ROLE_USER',
	GUEST = 'ROLE_GUEST',
	VERIFIED = 'ROLE_VERIFIED',
}

export enum Permission {
	EDIT_I18N = 'edit_i18n',
	ADMIN_CHAT = 'admin_chat',
	MANAGE_USERS = 'manage_users',
	EDIT_WEBSITE = 'edit_website',
	UPLOAD = 'upload',
	MANAGE_CATEGORIES = 'manage_categories',
	BACKOFFICE = 'backoffice',
	EDIT_POSTS = 'edit_posts',
	ORDER = 'order',
}

export interface PermissionChecker {
	can(permission: string): boolean;
	canAll(permissions: string[]): boolean;
}

export interface SmallUser {
	id: number;
	slug: string;
	fullname: string;
	picture: StoredFile;
	verified: boolean;
	presence: string;
}

export interface User extends SmallUser {
	firstname: string;
	lastname: string;
	emails: string[];
	mainEmail: string;
	lang: string;
	contextVersion: number;
	creation: string;
}

export interface UserUpdate {
	fullname?: string;
	firstname?: string;
	lastname?: string;
	lang?: string;
	pictureId?: number;
}

export type AuthMethodType = 'EMAIL' | 'FACEBOOK' | 'GOOGLE' | 'OTP_EMAIL' | 'OTP_SMS' | 'INVITATION' | 'TOKEN';

export interface AuthMethod {
	type: AuthMethodType;
	identifier: string;
	email: string;
	added: string;
}

export interface FullUser extends User {
	isAccountDisabled: false;
	roles: string[];
	auth: AuthMethod[];
}

export const AUTH_METHOD_NAMES: {[key in AuthMethodType]: string} = {
	EMAIL: 'email',
	FACEBOOK: 'facebook',
	GOOGLE: 'google',
	OTP_EMAIL: 'OTP',
	OTP_SMS: 'SMS',
	INVITATION: 'invitation',
	TOKEN: 'token',
}

export const AUTH_METHOD_ICONS: {[key in AuthMethodType]: string} = {
	EMAIL: 'mail',
	FACEBOOK: 'logo-facebook',
	GOOGLE: 'logo-google',
	OTP_EMAIL: 'mail-unread',
	OTP_SMS: 'call',
	INVITATION: 'share-social',
	TOKEN: 'lock-closed',
}

export interface PasswordReset {
	newPassword: string;
	login: string;
	otp: string;
	type: AuthMethodType;
}

export function getUserGroupName(users: SmallUser[], userId: number, maxCount = 4) {
  return users
    .filter(u => u.id !== userId)
    .map(u => u.fullname)
    .slice(0, maxCount).join(', ')
    + (users.length > maxCount ? ' (+' + (users.length - maxCount)  + ')' : '');
}