import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Pageable, CleanSubscriber, IonstackService, Page } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-paged',
  templateUrl: './paged.component.html',
  styleUrls: ['./paged.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagedComponent<T> extends CleanSubscriber implements OnDestroy {
  @Input() loadText = 'Loading';
  @Input() pageParam = 'page';
  @Input() pagesAround = 3;
  @Input() position: 'top' | 'bottom' = 'bottom';
  private _paged: Pageable<T>;

  constructor(
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private viewRef: ViewContainerRef,
    private ionstackService: IonstackService,
  ) {
    super();
  }

  @Input() set subToRoute(subToRoute: boolean) {
    if (subToRoute) {
      this.subscribe<ParamMap>(
        this.activatedRoute.queryParamMap,
        params => this._paged.setPage(params.get(this.pageParam)).loadPage(), {name: 'route', replace: true}
      );
    } else {
      this.unsubscribe('route');
    }
  }

  @Input() set paged(paged: Pageable<T>) {
    if (paged) {
      this.subscribe<Page<T>>(paged.onChange, () => this.cd.markForCheck(), {name: 'change', replace: true});
    } else {
      this.unsubscribe('change');
    }
    this._paged = paged;
  }

  get paged() {
    return this._paged;
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  scrollTop() {
    if (this.ionstackService.isBrowser()) {
      this.viewRef.element.nativeElement?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

}
