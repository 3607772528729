
export interface SiretSearchEtablissement {
  siren: string;
  siret: string;
  statutDiffusionEtablissement: 'O' | 'N';
  dateCreationEtablissement: string;
  trancheEffectifsEtablissement: string;
  etablissementSiege: boolean;
  activitePrincipaleRegistreMetiersEtablissement: string;
  uniteLegale: {
    dateCreationUniteLegale: string;
    denominationUniteLegale: string;
    sigleUniteLegale: string;
    denominationUsuelle1UniteLegale: string;
    denominationUsuelle2UniteLegale: string;
    denominationUsuelle3UniteLegale: string;
    activitePrincipaleUniteLegale: string;
    sexeUniteLegale: 'M' | 'F';
    nomUniteLegale: string;
    prenom1UniteLegale: string;
  };
  adresseEtablissement: {
    complementAdresseEtablissement: string;
    numeroVoieEtablissement: string;
    indiceRepetitionEtablissement: string;
    typeVoieEtablissement: string;
    libelleVoieEtablissement: string;
    codePostalEtablissement: string;
    libelleCommuneEtablissement: string;
  };
}

export interface RechercheEntreprises {
  results: EntrepriseRecherchee[];
  total_results: number;
  per_page: number;
  total_pages: number;
}

export interface EntrepriseRecherchee {
  siren: string;
  siege: {
    siret: string;
    date_creation: string;
    activite_principale: string;
    complement_adresse: string;
    numero_voie: string;
    type_voie: string;
    libelle_voie: string;
    commune: string;
    libelle_commune: string;
    adresse_complete: string;
    code_postal: string;
    longitude: number;
    latitude: number;
  };
  nom_raison_sociale: string;
  activite_principale: string;
  nom_complet: string;
  is_entrepreneur_individuel: boolean;
}

export class SiretSearchEtablissementWrap {

  constructor(private data: SiretSearchEtablissement) { }

  get name() {
    return this.data.uniteLegale.denominationUniteLegale || [
      this.data.uniteLegale.denominationUsuelle1UniteLegale,
      this.data.uniteLegale.denominationUsuelle2UniteLegale,
      this.data.uniteLegale.denominationUsuelle3UniteLegale
    ].filter(s => s).join(' ');
  }

  get etablissement() {
    return this.data;
  }

  get address() {
    return {
      line1: [
        this.data.adresseEtablissement.numeroVoieEtablissement,
        this.data.adresseEtablissement.indiceRepetitionEtablissement,
        this.data.adresseEtablissement.typeVoieEtablissement,
        this.data.adresseEtablissement.libelleVoieEtablissement,
      ].filter(s => s).join(' '),
      line2: this.data.adresseEtablissement.complementAdresseEtablissement,
      city: this.data.adresseEtablissement.libelleCommuneEtablissement,
      postalCode: this.data.adresseEtablissement.codePostalEtablissement,
    };
  }

  fillValue<T>(control: {pristine: boolean, value: T, setValue: (value: T) => void}, value: T) {
    if (!control.value || control.pristine) {
      control.setValue(value);
    }
  }

}
