<ion-header>
  <ion-toolbar color="primary">
    <ion-segment [(ngModel)]="editMode" *ngIf="infos.meta.childSupport || parentPropsFields?.length; else childLess">
      <ion-segment-button value="widget">
        <ion-label>{{ infos.meta.widgetName }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="parentProps" *ngIf="parentPropsFields?.length">
        <ion-label>{{ 'admin.Layout' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="children" *ngIf="infos.meta.childSupport">
        <ion-label>{{ 'admin.Widgets' | translate }} ({{ widget.children?.length || 0 }})</ion-label>
      </ion-segment-button>
    </ion-segment>
    <ng-template #childLess>
      <ion-title>{{ infos.meta.widgetName }}</ion-title>
    </ng-template>
    <ion-buttons slot="end">
      <ion-button class="padding-start" (click)="changeWidget()" [title]="'admin.Change widget' | translate">
        <ion-icon slot="icon-only" name="swap-horizontal"></ion-icon>
      </ion-button>
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div [ngClass]="{'ion-hide': editMode !== 'widget'}">
    <ng-content></ng-content>
    <ionstack-simple-form [fields]="fields" [submitButtons]="false" #simpleForm></ionstack-simple-form>
  </div>
  <div [ngClass]="{'ion-hide': editMode !== 'parentProps'}">
    <ionstack-simple-form [fields]="parentPropsFields" [submitButtons]="false" #propsForm></ionstack-simple-form>
  </div>
  <div *ngIf="editMode === 'children'">
    <ng-content select="[before-children]"></ng-content>
    <div class="ion-text-end ion-margin">
      <ng-content select="[children-actions]"></ng-content>
      <ion-button (click)="addWidget()">
        <ion-icon name="add" slot="start"></ion-icon>
        <itxt key="Add widget"></itxt>
      </ion-button>
    </div>
    <ionstack-widget-children
      [value]="widget.children"
      [defaultChildSupport]="infos.meta.childSupport"
      [context]="context"
      [parent]="this"
      (valueChange)="setChildren($event)"
    ></ionstack-widget-children>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="deleteWidget()" *ngIf="parentHolder && index != null">
        <ion-icon name="trash" slot="start" color="danger"></ion-icon>
        <itxt key="Delete"></itxt>
      </ion-button>
      <ion-button (click)="confirm()" [disabled]="!simpleForm.formGroup || simpleForm.formGroup.invalid || !propsForm.formGroup || propsForm.formGroup.invalid">
        <ion-icon slot="start" name="checkmark" color="success"></ion-icon>
        <itxt key="Save"></itxt>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>