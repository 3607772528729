import { NotifController } from './controller/notif.controller';
import { NgModule } from '@angular/core';
import { actionToken } from './service/action.service';

@NgModule({
  providers: [
    {
      provide: actionToken('notif-popover'),
      deps: [NotifController],
      useFactory: (nc: NotifController) => nc.presentNotif.bind(nc),
    },
  ]
})
export class ActionsModule { }