import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DEFAULT_AUDIO_FORMAT, IonstackModuleConfig, MODULE_CONFIG, AudioFormat, FileType, FormControlComponent } from '@adeprez/ionstack';
import { ExtraFileUpload } from '../file-capture';
import { FileCaptureComponent } from '../file-capture/file-capture.component';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AudioCaptureComponent),
    multi: true
  }],
  selector: 'ionstack-audio-capture',
  templateUrl: './audio-capture.component.html',
  styleUrls: ['./audio-capture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioCaptureComponent extends FormControlComponent<string> {
  @ViewChild('capture') capture: FileCaptureComponent<string>;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onFileName = new EventEmitter<string>();
  @Input() allowExtraUpload = false;
  @Input() value: string;
  @Input() isDisabled: boolean;
  @Output() onPickFile = new EventEmitter<Blob>();
  @Output() formatChange = new EventEmitter<AudioFormat>();
  @Output() extUrlChange = new EventEmitter<string>();
  @Output() onExtraUpload = new EventEmitter<ExtraFileUpload>();
  @Input() hideToolbar: boolean;
  @Input() allowExtUrl = true;
  @Input() url: string;
  @Input() extUrl: string;
  @Input() browseAllowed = true;
  @Input() autoStart = false;
  @Input() previewHeight = '300px';
  readonly prefExtension: AudioFormat;
  readonly fileType = FileType.AUDIO;
  readonly constraints: MediaStreamConstraints = {
    audio: { echoCancellation: true },
  };

  constructor(
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    public cd: ChangeDetectorRef,
  ) {
    super();
    this.prefExtension = this.ionstackModuleConfig.defaultAudioFormat || DEFAULT_AUDIO_FORMAT;
  }

}
