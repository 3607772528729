import { SmallUser } from './user';
import { StoredFile } from './files';

export interface ConversationMessage {
	id: number;
	type: string;
	content: string;
  authorId: number;
	creationDate: string;
	attachment?: StoredFile;
}

export interface Conversation {
  id: number;
  participants: SmallUser[];
  name?: string;
  type: string;
  lastReadMessages: {[userId: number]: number};
  lastMessage: ConversationMessage;
  lastMessageDate: string;
  closed: boolean;
  userUnread: boolean;
  picture?: StoredFile;
}

export interface AddConversationMessage {
  content: string;
  type?: string;
  attachmentId?: number;
}

export interface MessageRead {
  userId: number;
  messageId: number;
}

export interface WritingMessage {
  userId: number;
  date?: string;
}

export interface ConversationSave {
  participantsIds: number[];
  name?: string;
  type?: string;
  permission?: string;
  closed?: boolean;
  pictureId?: number;
}

export interface ConversationCreate extends ConversationSave {
  message?: AddConversationMessage;
}

export class MessageWriters {
  public writersIds: number[] = [];
  private writers: {[userId: number]: Date} = {};

  constructor(private writeDelay = 7000) { }

  public updateWriterState(msg: WritingMessage) {
    if (msg.date) {
      this.writers[msg.userId] = new Date(msg.date);
    } else {
      delete this.writers[msg.userId];
    }
    this.writersIds = Object.keys(this.writers).map(k => +k);
  }

  public isWriting(userId: number, currentTime = new Date()) {
    return userId in this.writers && currentTime.getTime() - this.writers[userId].getTime() < this.writeDelay;
  }

}