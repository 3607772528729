<ionstack-edit-widget-popup
  [widget]="widget"
  [infos]="infos"
  [context]="context"
  [index]="index"
  [parentHolder]="parentHolder"
  [beforeSave]="saveHandler"
  [extraValue]="{id: fileId}"
  class="ion-page"
  #widgetEditor>
  <ionstack-edit-file
    *ngIf="widgetEditor.form?.formGroup"
    [config]="{
      filenameName: filenameName,
      height: widgetEditor.form.formGroup.value.content?.height,
      imageVersion: widgetEditor.form.formGroup.value.content?.imageVersion,
      imageRoundCropper: widgetEditor.form.formGroup.value.content?.roundImage
    }"
    [inPopup]="false"
    [save]="fileSetter"
    [value]="fileId"
    (valueChange)="fileId = $event"
  ></ionstack-edit-file>
</ionstack-edit-widget-popup>