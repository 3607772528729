import { ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export abstract class FormControlComponent<T> implements ControlValueAccessor {
  private onTouched: any;
  private onChange: any;
  abstract isDisabled: boolean;
  abstract valueChange: EventEmitter<T>;
  abstract cd: ChangeDetectorRef;
  abstract value: T;
  
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: T): void {
    this.value = obj;
    this.cd.markForCheck();
  }

  valueChanged() {
    this.onChange?.(this.value);
    this.valueChange.emit(this.value);
  }

  setValue(value: T) {
    if (this.value !== value && !(value == null && this.value == value)) {
      this.value = value;
      this.valueChanged();
      this.setTouched();
      return true;
    }
    return false;
  }

  setTouched() {
    this.onTouched?.();
  }

  isDetached() {
    return !this.onChange || this.onChange.name === 'noop';
  }

}

export function moveArrayControl(array: UntypedFormArray, from: number, to: number, markDirty = true, markAsTouched = true) {
  if (from !== to && from >= 0 && to >= 0 && from < array.length) {
    const moving = array.at(from);
    array.removeAt(from);
    array.insert(to, moving);
    if (markAsTouched) {
      array.markAsTouched();
    }
    if (markDirty) {
      array.markAsDirty();
    }
  }
}

export function controlHasParent(control: AbstractControl, parent: AbstractControl): boolean {
  if (control.parent) {
    if (control.parent === parent) {
      return true;
    }
    return controlHasParent(control.parent, parent);
  }
  return false;
}

export function getFormControlChildren(control: AbstractControl) {
  if (control instanceof UntypedFormGroup) {
    return Object.values(control.controls);
  }
  if (control instanceof UntypedFormArray) {
    return control.controls;
  }
  return [];
}

export function getDirty(formItem: UntypedFormGroup | UntypedFormArray | UntypedFormControl, updatedValues: any = {}, name?: string) {
  if (formItem instanceof UntypedFormControl) {
    if (name && formItem.dirty) {    
      updatedValues[name] = formItem.value;
    }
  } else {
    for (const formControlName in formItem.controls) {
      if (formItem.controls.hasOwnProperty(formControlName)) {
        const formControl = formItem.controls[formControlName];
        if (formControl instanceof UntypedFormControl) {
          getDirty(formControl, updatedValues, formControlName);
        } else if (formControl instanceof UntypedFormArray && formControl.dirty && formControl.controls.length > 0) {
          updatedValues[formControlName] = [];
          getDirty(formControl, updatedValues[formControlName]);
        } else if (formControl instanceof UntypedFormGroup && formControl.dirty) {
          updatedValues[formControlName] = {};
          getDirty(formControl, updatedValues[formControlName]);
        }
      }
    }
  }
  return updatedValues;
}

export function UniqueValidator(field: string): ValidatorFn {
  return (array: UntypedFormArray) => {
    const byValue: {[value: string]: AbstractControl} = {};
    for (const control of array.controls) {
      const c = control.get(field);
      const value = c.value;
      if (value in byValue) {
        byValue[value].setErrors({unique: true});
        c.setErrors({unique: true});
      } else {
        byValue[value] = c;
        c.setErrors(null);
      }
    }
    return null;
  };
}

type Unbox<T> = T extends Array<infer V> ? V : T;
export type ModelFormGroup<T> = FormGroup<{
  [K in keyof T]: T[K] extends Array<any> ? FormArray<FormControl<Unbox<T[K] | null>>> : FormControl<T[K] | null>;
}>;