import { Injectable } from '@angular/core';
import { FullShop, SaveShop, Shop, ShopStatistics } from '../model/shop';
import { Page } from '../model/page';
import { IonstackService, RequestParams } from './ionstack.service';
import { filterNotNull } from '../util/util';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(private ionstackService: IonstackService) { }

  getShops(req: RequestParams) {
    return this.ionstackService.get<Page<Shop>>('/public/shops', {params: req});
  }

  getShop(slug: string) {
    return this.ionstackService.get<Shop>('/public/shops/' + slug);
  }

  getMyShops(type?: string) {
    return this.ionstackService.get<FullShop[]>('/shops/mine', {params: filterNotNull({type})});
  }

  saveShop(shop: SaveShop) {
    return this.ionstackService.post<FullShop>('/shops/mine', shop);
  }

  deleteShop(shopId: number) {
    return this.ionstackService.delete<void>('/shops/mine/' + shopId);
  }

  getShopCommission(shopId: number, price: number) {
    return this.ionstackService.get<number>('/shops/mine/' + shopId + '/commission', {responseType: 'number', params: {price}});
  }

  getStatistics(shopId: number, from?: string, to?: string) {
    return this.ionstackService.get<ShopStatistics>(`/shops/mine/${shopId}/statistics`, {params: filterNotNull({from, to})});
  }
}
