const domain = 'wepost.adeprez.com';

export const environment = {
  production: true,
  port: null,
  appName: 'WePost!',
  browserApi: '/api',
  cookieDomain: domain,
  frontBaseUrl: `https://${domain}`,
  appApi: `https://${domain}/api`,
  serverApi: 'http://localhost:8181/api',
  websocketBrokerUrl: `wss://${domain}/api/stomp`,
  webpushVapidKey: 'BChZ6tyRLhb9A-6yQM_GyfCPQM69MRvhVdJqK1qKSNiiZybP_xTJrU9vNyvj6liKPtGOptQbWx5sTn6LhoLwEBA',
  robotsTxt: 'User-agent: *\nDisallow:',
  paymentConfig: {
    apiKey: 'pk_test_51ItXf0LOBbywCvuYbp9yRH3KylVdCElR4krS7xbIMRDUVZ0gZcLeKXg2F333fE6vQbHY4bDnQStgTTEUnjwfZpw300bpPhunCi',
    createTokenUrl: 'https://api.stripe.com/v1/tokens'
  },
  gAnalytics: 'G-XPRZFG7CRV',
  intercomAppId: 'nslu0kzp',
  fakeHasTransporter: true,
};
