import { Platform } from '@ionic/angular';
import { FileType, UsedSpace } from '../model/files';
import { Inject, Injectable } from '@angular/core';
import { IonstackModuleConfig, MODULE_CONFIG } from '../ionstack.config';
import { DisplayableFile, ImageUpload, RawUpload, StoredFile, StoredFileDetails, TempUploadSuccess } from '../model/files';
import { IonstackService } from './ionstack.service';
import { Debounce } from '../util/util';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private readonly storageApi: string;
  private toRequest = new Set<number>();
  private debounce = new Debounce(10);
  private debouncePromise: Promise<StoredFile[]>;

  constructor(
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    private ionstackService: IonstackService,
    private platform: Platform,
  ) {
    this.storageApi = ionstackModuleConfig.storageApi ? ionstackModuleConfig.storageApi : ionstackService.frontApiUrl('/storage');
  }

  private upload(path: string, upload: ImageUpload | RawUpload): Promise<StoredFileDetails> {
    return this.ionstackService.post<StoredFileDetails>(this.storageApi + path, upload, {isRootUrl: true});
  }

  getUsedSpace(): Promise<UsedSpace> {
    return this.ionstackService.get<UsedSpace>(this.storageApi + '/usage', {isRootUrl: true});
  }

  async getFile(id: number, debounce = true): Promise<StoredFile> {
    if (debounce) {
      this.toRequest.add(id);
      const debounced = await this.getDebounce();
      const f = debounced.find(file => file.id === id);
      if (f) {
        return f;
      }
    }
    return this.ionstackService.get<StoredFile>(this.storageApi + '/file/' + id, {isRootUrl: true});
  }

  getFiles(ids: number[]): Promise<StoredFile[]> {
    if (!ids?.length) {
      return Promise.resolve([]);
    }
    return this.ionstackService.get<StoredFile[]>(this.storageApi + '/files', {isRootUrl: true, params: {id: ids}});
  }

  getFileDetails(id: number): Promise<StoredFileDetails> {
    return this.ionstackService.get<StoredFileDetails>(this.storageApi + '/file/' + id + '/details', {isRootUrl: true});
  }

  uploadImage(image: ImageUpload) {
    return this.upload('/image', image);
  }

  async uploadRaw(raw: RawUpload, data?: Blob) {
    if (!raw.tempFile && data) {
      raw.tempFile = (await this.uploadTmp(data)).name;
    }
    if (!raw.extension && data?.type) {
      const parts = data.type.split('/');
      if (parts.length) {
        raw.extension = parts[parts.length - 1];
      }
    }
    return this.upload('/raw', raw);
  }

  uploadTmp(data: Blob, extraParams?: {extraVersion: string, type: FileType, extension: string}) {
    return this.ionstackService.post<TempUploadSuccess>(this.storageApi + '/tmp', data, {isRootUrl: true, params: extraParams});
  }

  getPath(file: DisplayableFile, opts: {version?: string | string[], extension?: string, absolute?: boolean} = {}) {
    if (!file) {
      return null;
    }
    let path = (file.public ? '/public/' : '/private/') + file.file;
    if (opts.version?.length) {
      path += '.' + (typeof opts.version === 'string' ? opts.version : opts.version.join('.'));
    }
    const extension = opts.extension || file.extension;
    if (extension) {
      path += '.' + extension;
    }
    let prefix = opts.absolute ? this.ionstackModuleConfig.storageBaseUrl ?? (
      this.platform.is('desktop') ? this.ionstackModuleConfig.frontBaseUrl : this.ionstackModuleConfig.appApi
    ) : '';
    if (this.storageApi.startsWith(prefix)) {
      prefix = '';
    }
    return prefix + this.storageApi + path;
  }

  private getDebounce() {
    this.debouncePromise ??= new Promise((resolve, reject) => this.debounce.setCallback(async () => {
      try {
        resolve(await this.getFiles([...this.toRequest]));
      } catch (e) {
        reject(e);
      } finally {
        this.debouncePromise = null;
        this.toRequest.clear();
      }
    }));
    this.debounce.update();
    return this.debouncePromise;
  }

}
