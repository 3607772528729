import { PaymentAccount, SaveAccount, SaveCustomer, Customer, ListedCustomer, FullCustomer, PaymentMethod, SaveCustomerInfo, PaymentAccountSettings, PaymentAccountDocument, SaveAccountDocument } from '../model/payment';
import { EventEmitter, Injectable, OnDestroy, Inject } from '@angular/core';
import { IonstackService, RequestParams } from './ionstack.service';
import { Page } from '../model/page';
import { DataTokenService } from './data-token.service';
import { Cached } from '../util/cache';
import { ContextService } from './context.service';
import { CleanSubscriber } from '../util/subscriber';
import { Router } from '@angular/router';
import { IonstackModuleConfig, MODULE_CONFIG } from '../ionstack.config';

export const DEFAULT_PAYMENT_ACCOUNT = 'default';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends CleanSubscriber implements OnDestroy {
  readonly accountChange = new EventEmitter<PaymentAccount>();
  readonly config = new Cached<PaymentAccountSettings>(() => this.getPaymentAccountConfig());
  readonly defaultAccount = new Cached<PaymentAccount>(() => this.getPaymentAccount());
  readonly customer = new Cached<FullCustomer>(
    async () => this.contextService.isCurrentLogged() ? await this.ionstackService.get<FullCustomer>('/payment/mine/customer') : null
  );

  constructor(
    private ionstackService: IonstackService,
    private dataTokenService: DataTokenService,
    private contextService: ContextService,
    private router: Router,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
  ) {
    super();
    this.subscribe(contextService.user, () => {
      this.customer.reset();
      this.defaultAccount.reset();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  getPaymentAccountConfig() {
    return this.contextService.isCurrentLogged() ? this.ionstackService.get<PaymentAccountSettings>('/payment/accounts/configuration') : null;
  }

  getPaymentAccount(type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.contextService.isCurrentLogged() ? this.ionstackService.get<PaymentAccount>(`/payment/mine/${type}/profile`) : null;
  }

  getPaymentAccountDocuments(type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.contextService.isCurrentLogged() ? this.ionstackService.get<PaymentAccountDocument>(`/payment/mine/${type}/profile/documents`) : [];
  }

  uploadVerificationDocument(doc: SaveAccountDocument) {
    return this.ionstackService.post<string[]>('/payment/mine/documents', doc);
  }

  getPaymentAccountId(type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.contextService.isCurrentLogged() ? this.ionstackService.get<number>(`/payment/mine/${type}/profile/id`) : null;
  }

  getPaymentAccountLink(returnUrl?: string, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.contextService.isCurrentLogged() ?
      this.ionstackService.get<string>(`/payment/mine/${type}/profile/link`, {params: {
        returnUrl: returnUrl || (this.ionstackModuleConfig.frontBaseUrl + this.router.url)
      }, responseType: 'text'}) : null;
  }

  async savePaymentAccount(save: Partial<SaveAccount>, type = DEFAULT_PAYMENT_ACCOUNT) {
    const a = await this.ionstackService.put<PaymentAccount>(`/payment/mine/${type}/profile`, save);
    if (type === DEFAULT_PAYMENT_ACCOUNT) {
      this.defaultAccount.setValue(a);
    }
    this.accountChange.emit(a);
    return a;
  }

  getPlatformPaymentAccount() {
    return this.ionstackService.get<PaymentAccount>('/payment/platform/profile');
  }

  getShopPaymentAccount() {
    return this.ionstackService.get<PaymentAccount>('/shops/payment-account/mine');
  }

  savePlatformPaymentAccount(save: SaveAccount) {
    return this.ionstackService.put<PaymentAccount>('/payment/platform/profile', save);
  }

  deletePaymentAccount(type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.delete(`/payment/mine/${type}/profile`);
  }

  async saveUserCustomer(customer: SaveCustomerInfo) {
    const res = await this.ionstackService.post<Customer>(`/payment/mine/customer`, customer);
    this.customer.reset();
    return res;
  }

  saveCustomer(customer: SaveCustomer, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.post<Customer>(`/payment/mine/${type}/customers`, customer);
  }

  deleteCustomer(id: number, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.delete<Customer>(`/payment/mine/${type}/customers/${id}`);
  }

  getCustomer(id: number, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.get<Customer>(`/payment/mine/${type}/customers/${id}`);
  }

  getCustomers(params: RequestParams, type = DEFAULT_PAYMENT_ACCOUNT) {
    return this.ionstackService.get<Page<ListedCustomer>>(`/payment/mine/${type}/customers`, {params});
  }

  createCardToken(card: any) {
    return this.dataTokenService.createToken({
      url: this.ionstackService.config.paymentConfig?.createTokenUrl,
      auth: this.ionstackService.config.paymentConfig?.apiKey,
    }, {card});
  }

  async addCardToCustomer(customerId: number, card: any) {
    return this.ionstackService.post<PaymentMethod>(`/public/payment/customers/${customerId}/cards`, await this.createCardToken(card));
  }

  async getCurrencies() {
    return [
      {code: 'USD', name: '$ Dollar'},
      {code: 'EUR', name: '€ Euro'},
      {code: 'GBP', name: '£ British Pound'},
      {code: 'CAD', name: 'Canadian Dollar'},
      {code: 'CHF', name: 'Franc suisse'},
    ]
  }

}
