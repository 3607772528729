import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WidgetPopupBase } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-richtext-edit-popup',
  templateUrl: './richtext-edit-popup.component.html',
  styleUrls: ['./richtext-edit-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichtextEditPopupComponent extends WidgetPopupBase implements OnInit {
  content: any;

  constructor() {
    super();
  }

  ngOnInit() {
    this.content = this.widget.content;
  }

}
