import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';
import { Device, SaveDevice, DevicePlatform } from '../model/device';
import { Injectable } from '@angular/core';
import { IonstackService } from './ionstack.service';
import { PushNotifications, Token } from '@capacitor/push-notifications';

const deviceTokenRefreshTime = 1000 * 60 * 60 * 24 * 30; // 30 days

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private ionstackService: IonstackService,
    private storageService: StorageService,
    private platform: Platform,
  ) { }

  getDevices(): Promise<Device[]> {
    return this.ionstackService.get<Device[]>('/devices');
  }

  registerDevice(device: SaveDevice): Promise<Device> {
    return this.ionstackService.post<Device>('/devices', device);
  }

  delete(deviceId: number): Promise<void> {
    return this.ionstackService.delete<void>('/devices/' + deviceId);
  }

  activatePushRegistration() {
    PushNotifications.addListener('registration', (token: Token) => this.sendDevice(token.value, this.platform.is('ios') ? DevicePlatform.ios : DevicePlatform.android));
    PushNotifications.addListener('registrationError', (error: any) => this.registrationError(error));
  }

  private registrationError(error: any) {
    console.error('Error on registration: ' + JSON.stringify(error));
  }

  async sendDevice(token: string, platform: DevicePlatform) {
    const lastSent = await this.storageService.get<number>('device-sent');
    const sentToken = await this.storageService.get<string>('device-token');
    const now = new Date().getTime();
    if (!lastSent || sentToken !== token || (now - lastSent > deviceTokenRefreshTime)) {
      await this.registerDevice({
        name: this.platform.platforms().join(' '),
        platform,
        token,
      });
      this.storageService.set('device-sent', now);
      this.storageService.set('device-token', token);
    }
  }

}
