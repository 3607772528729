import { ChangeDetectionStrategy, Component } from '@angular/core';
import { consumeWidgetContent, WidgetData, WidgetPopupBase } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-icon-popup-picker',
  templateUrl: './icon-popup-picker.component.html',
  styleUrls: ['./icon-popup-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconPopupPickerComponent extends WidgetPopupBase {
  name: string;

  constructor() {
    super();
  }

  override set widget(widget: WidgetData) {
    super.widget = widget;
    consumeWidgetContent(this, widget);
  }

  override get widget() {
    return super.widget;
  }

}
