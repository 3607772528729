import { Injectable, Injector } from '@angular/core';
import { FullRoute, RoutesProvider, routeToUrl } from '../model/route';
import { resolveValueOrFn } from '../util/util';

@Injectable({
    providedIn: 'root'
})
export class RouteService {

    constructor(private injector: Injector) {}

    getRoute(name: string, ...args: any[]): FullRoute {
        args ??= [];
        const provider = name && this.injector.get<RoutesProvider[]>(RoutesProvider, []).find(rp => name in rp)?.[name];
        if (!provider) {
            return null;
        }
        const resolved = resolveValueOrFn(provider, ...args);
        if (Array.isArray(resolved) || typeof resolved === 'string') {
            return {
                path: resolved,
                query: {}
            }
        }
        return {
            path: resolveValueOrFn(resolved.path, ...args),
            query: resolveValueOrFn(resolved.query, ...args)
        }
    }

    getUrl(name: string, ...args: any[]): string {
        const route = this.getRoute(name, ...args);
        if (!route) {
            return null;
        }
        return routeToUrl(route);
    }

}