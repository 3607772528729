import { WidgetParams, Widget, interpolateString } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'html',
  widgetName: 'HTML',
  icon: 'logo-html5',
  widgetDescription: 'Render HTML code',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'text',
  contentFields: [
    {name: 'content', type: 'multiline-text', optional: true, label: 'Content', rows: 6}
  ]
})
export class HtmlComponent {
  @Input() html: string;

  constructor() {}

  @Input() set widget(params: WidgetParams) {
    this.html = interpolateString(params.data?.content, params.context?.data);
  }

}
