import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[clickStopPropagation]'
})
export class ClickStopPropagationDirective {
  @Input() clickStopPropagation: string;

  constructor() { }

  @HostListener('click', ['$event'])
  public onClick(event: any) {
    event.stopPropagation();
    if (this.clickStopPropagation === 'preventDefault') {
      event.preventDefault();
    }
  }

}
