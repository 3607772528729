<div class="gallery" *ngIf="files?.length" [ngStyle]="styleGetter | call:layout:(config.width || defaultWidth)" [ngClass]="layout">
  <div *ngFor="let file of files" class="file" [ngClass]="file.fileType" [ngStyle]="fileStyleGetter | call:layout:(config.width || defaultWidth)">
    <ionstack-file-display
      [file]="file"
      [visible]="visible"
      [config]="getConfig | call:config:layout"
      [galleryClick]="file"
      [files]="galleryFiles || files"
      class="clickable"
    ></ionstack-file-display>
    <div class="about glass opaque" *ngIf="config.showImageTitle">
      <span class="name">{{ file.name }}</span>
      <span class="credit" *ngIf="file.credit">, {{ file.credit }}</span>
    </div>
  </div>
</div>