import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { AnchorDirective } from './anchor.directive';
import { Type } from '@angular/core';

export interface InjectableComponent<T> {
  type: Type<T>;
  inputs?: {[name: string]: any};
}

@Component({
  selector: 'ionstack-inject',
  template: '<ng-template anchor></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InjectComponent<T> {
  @ViewChild(AnchorDirective, {static: true}) anchor!: AnchorDirective;
  private _component: InjectableComponent<T>;

  constructor() { }

  @Input() set component(component: InjectableComponent<T>) {
    this._component = component;
    this.anchor.viewContainerRef.clear();
    
    if (component) {
      const componentRef = this.anchor.viewContainerRef.createComponent<T>(component.type);
      if (component.inputs) {
        for (const input of Object.keys(component.inputs)) {
          componentRef.instance[input] = component.inputs[input];
        }
      }
    }
  }

  get component() {
    return this._component;
  }

}
