<ion-header *ngIf="title || canSearch" [ngClass]="{'has-search': canSearch}">
  <ion-toolbar>
    <ion-title><itxt [key]="title"></itxt></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel.emit()" color="light">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-searchbar
  *ngIf="canSearch"
  #searchBar
  [value]="searchText"
  (ionInput)="search($event['detail'].value)"
  [placeholder]="placeholder | translate" [debounce]="300"
></ion-searchbar>

<ion-content [style.height]="height" [ngClass]="{'has-search': canSearch}">
  <ion-list>
    <ng-container *ngIf="maxSelected > 1; else confirm ? radio : direct">
      <ion-item *ngFor="let item of displayed" [button]="true" [ngClass]="{active: isSelected(item)}" (click)="toggleSelection(item); $event.stopPropagation(); $event.preventDefault()">
        <ion-checkbox
          #check
          (ionChange)="toggleSelection(item)" slot="start"
          [checked]="isSelected(item)"
          [disabled]="selected.length >= maxSelected && !isSelected(item)">
        </ion-checkbox>
        <ng-container *ngIf="iconGetter | call:item as icon">
          <ion-thumbnail *ngIf="isImage | call:icon; else iconTemplate" slot="start">
            <ion-img [src]="icon" class="ion-margin-end"></ion-img>
          </ion-thumbnail>
          <ng-template #iconTemplate>
            <ion-icon [name]="icon" slot="start"></ion-icon>
          </ng-template>
        </ng-container>
        <div>
          <ng-container [ngTemplateOutlet]="label" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
          <div *ngIf="subtitleGetter" class="sublbl">{{ subtitleGetter | call:item  }}</div>
        </div>
      </ion-item>
    </ng-container>
  </ion-list>
  <ion-infinite-scroll (ionInfinite)="paged.loadMore($event, false)" [disabled]="!paged || paged.paginated || !paged?.hasMore">
    <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-text-end">
    <ion-button (click)="cancel.emit()" fill="clear" color="danger">
      <ion-icon name="close" slot="start"></ion-icon>
      {{ cancelText | translate }}
    </ion-button>
    <ion-button (click)="pick.emit(selected)" [disabled]="!selected.length || selected[0] === null" *ngIf="confirm || maxSelected > 1" fill="clear">
      <ion-icon slot="start" name="checkmark"></ion-icon>
      {{ confirmText | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>

<ng-template #direct>
  <ion-item *ngFor="let item of displayed" [ngClass]="{active: isSelected(item)}" (click)="pick.emit([item])" class="clickable">
    <ng-container *ngIf="iconGetter | call:item as icon">
      <ion-thumbnail *ngIf="isImage | call:icon; else iconTemplate" slot="start">
        <ion-img [src]="icon" class="ion-margin-end"></ion-img>
      </ion-thumbnail>
      <ng-template #iconTemplate>
        <ion-icon [name]="icon" slot="start"></ion-icon>
      </ng-template>
    </ng-container>
    <div>
      <ng-container [ngTemplateOutlet]="label" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
      <div *ngIf="subtitleGetter" class="sublbl">{{ subtitleGetter | call:item  }}</div>
    </div>
  </ion-item>
</ng-template>

<ng-template #radio>
  <ion-radio-group [value]="selected.length ? selected[0] : null" (ionChange)="selected = [$event['detail'].value]">
    <ion-item *ngFor="let item of displayed" [ngClass]="{active: isSelected(item)}">
      <ion-radio [value]="item" slot="start"></ion-radio>
      <ng-container [ngTemplateOutlet]="label" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
      <div *ngIf="subtitleGetter" class="sublbl">{{ subtitleGetter | call:item  }}</div>
    </ion-item>
  </ion-radio-group>
</ng-template>

<ng-template #label let-item>
  <div [innerHTML]="((labelGetter | call:item) | translate) | textBoldMatcher:searchText"></div>
</ng-template>