import { Pipe, PipeTransform } from '@angular/core';

const YOUTUBE_REGEX = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
const VIMEO_REGEX = /^.*vimeo\.com\/(?:video\/|)([0-9]+)/;

@Pipe({
  name: 'extVideo',
  pure: true
})
export class ExtVideoPipe implements PipeTransform {

  transform(extUrl: string, autoplay = false): {type: 'iframe', url: string} {
    if (!extUrl) {
      return null;
    }
    const youtube = extUrl.match(YOUTUBE_REGEX);
    if (youtube) {
      return {
        type: 'iframe',
        url: 'https://www.youtube-nocookie.com/embed/' + youtube[1] + '?autoplay=' + (autoplay ? 1 : 0),
      };
    }
    const vimeo = extUrl.match(VIMEO_REGEX);
    if (vimeo) {
      return {
        type: 'iframe',
        url: 'https://player.vimeo.com/video/' + vimeo[1] + '?autoplay=' + (autoplay ? 1 : 0),
      }
    }
    return null;
  }

}
