import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IonItem } from '@ionic/angular';
import { CleanSubscriber } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-autocomplete-items',
  templateUrl: './autocomplete-items.component.html',
  styleUrls: ['./autocomplete-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteItemsComponent<T> extends CleanSubscriber implements OnInit, OnDestroy {
  @ViewChildren(IonItem, {read: ElementRef}) items: QueryList<ElementRef>;
  @Input() controller: AutocompleteItemsController<T>;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.controller.filteredItemsChange, () => this.cd.markForCheck());
    this.subscribe(this.controller.highlightedItemChange, selectedIndex => {
      this.cd.markForCheck();
      const elem = this.items.find((_, index) => index === selectedIndex);
      if (elem) {
        elem.nativeElement.scrollIntoView({behavior: 'smooth'});
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

}

export interface AutocompleteItemsController<T> {
  filteredItems: T[];
  inputText: string;
  highlighted: number;
  filteredItemsChange: EventEmitter<T[]>;
  highlightedItemChange: EventEmitter<number>;
  pickValue: (value: T) => void;
  labelGetter: (value: T) => string;
  valueGetter: (input: string, values: T[]) => T;
}