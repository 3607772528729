import { Pipe, PipeTransform } from '@angular/core';

const SPOTIFY_REGEX = /^.*spotify\.com\/(playlist|album|show|episode|podcast|artist)\/([0-9a-z]+)/i;
const DEEZER_REGEX = /^.*deezer\.com\/[a-z]{0,3}\/(album|playlist|track|artist|podcast|episode)\/([0-9a-z]+)/i;

@Pipe({
  name: 'extAudio',
  pure: true
})
export class ExtAudioPipe implements PipeTransform {

  transform(extUrl: string, autoplay = false): {type: 'iframe', url: string} {
    if (!extUrl) {
      return null;
    }
    const spotify = extUrl.match(SPOTIFY_REGEX);
    if (spotify) {
      return {
        type: 'iframe',
        url: 'https://open.spotify.com/embed/' + spotify[1] + '/' + spotify[2] + '?autoplay=' + (autoplay ? 1 : 0),
      };
    }
    const deezer = extUrl.match(DEEZER_REGEX);
    if (deezer) {
      return {
        type: 'iframe',
        url: 'https://widget.deezer.com/widget/auto/' + deezer[1] + '/' + deezer[2] + '?autoplay=' + (autoplay ? 1 : 0),
      };
    }
    return null;
  }

}
