import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  pure: true
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, pattern: string, by = '', flags = 'g'): any {
    return value.replace(new RegExp(pattern, flags), by);
  }

}
