import { Directive, EventEmitter, InjectionToken, Input, Output, Type } from '@angular/core';
import { SimpleFormField } from './form';
import { filterNotNull, findInTree, randomString } from '../util/util';
import 'reflect-metadata';
import { FormControlComponent } from '../util/form';

export interface WidgetContainer {
	id?: number;
	children?: WidgetData[];
	classes?: string;
	style?: string;
}

export interface WidgetData extends WidgetContainer {
	id?: number;
	type: string;
	content?: string;
	parentProps?: string;
	slot?: string;
	permissions?: string[];
	uuid?: string;
}

export interface WidgetPostOptions {
	layoutMode?: boolean;
	defaultI18nGroup?: string;
	pageVisible?: boolean;
}

export interface WidgetParams {
	holder: WidgetParent;
	data: WidgetData;
	context: WidgetContext;
}

export interface WidgetableComponent {
	widget: WidgetParams;
}

export interface WidgetDropTarget {
	childSupport?: 'all' | string[];
}

export interface WidgetMeta {
  widgetName?: string;
  widgetDescription?: string;
	widgetID: string;
	group?: string;
	icon?: string;
	editorPopup: Type<WidgetPopupBase> | InjectionToken<Type<WidgetPopupBase>>;
	editorPopupClass?: string;
	contentType?: 'text' | 'json';
	contentFields?: SimpleFormField[];
	childSupport?: 'all' | string[];
	slots?: string[];
	childrenFields?: SimpleFormField[];
	newInstance?: Partial<WidgetData>;
}

export interface WidgetInfo {
	type: Type<WidgetableComponent>;
	meta: WidgetMeta;
}

export interface WidgetContext {
	data: any;
	postOptions: WidgetPostOptions;
}

export interface WidgetParent extends WidgetDropTarget {
	setChildren(children: WidgetData[]): void;
	slots: string[];
	context: WidgetContext;
	container: WidgetChildrenHolder;
	propsFields: SimpleFormField[];
	index: number;
	siblings: number;
}

export interface ParentWidgetHolder {
	deleteChild(index: number): void;
	slots: string[];
}

export function Widget(meta: WidgetMeta) {
	return <T extends Type<WidgetableComponent>>(target: T) => {
		Reflect.defineMetadata('widgetMeta', meta, target);
	};
}

export function supportsWidgetChild(childSupport: 'all' | string[], type?: string) {
	return childSupport === 'all' || (type && childSupport && childSupport.includes(type));
}

export function isJSONContentWidget(meta: WidgetMeta) {
	return meta.contentType === 'json' || (!meta.contentType && meta.contentFields?.length);
}

export function parseWidgetContent(meta: WidgetMeta, data: WidgetData) {
	return isJSONContentWidget(meta) ? JSON.parse(data?.content || '{}') : data?.content;
}

export function stringifyWidgetContent(meta: WidgetMeta, data: WidgetData) {
	if (isJSONContentWidget(meta)) {
		const filtered = data?.content ? filterNotNull(JSON.parse(data.content)) : null;
		return filtered ? JSON.stringify(filtered) : null;
	}
	return data?.content;
}

export function parseWidgetParentProps<T = any>(widget: WidgetData, defaultValue: T = {} as T): T {
	return widget.parentProps ? JSON.parse(widget.parentProps) as T : defaultValue;
}

export function consumeWidgetContent(target: any, data: WidgetData, parsers: {[key: string]: (value: any) => any} = {}) {
	const d = JSON.parse(data?.content || '{}');
	for (const key of Object.keys(d)) {
		let val = d[key];
		if (parsers[key]) {
			val = parsers[key](val);
		}
		if (val != null) {
			target[key] = val;
		}
	}
	return d;
}

export const WIDGET = new InjectionToken<Type<WidgetableComponent>[]>('IONSTACK_WIDGET');

export interface WidgetEditorRoot {
	deleteWidgetAsync(widget: WidgetData): void;
}

export const WidgetEditorRoot = new InjectionToken<WidgetEditorRoot>('WidgetEditorRoot');

export interface WidgetPopupBaseInputs {
  widget: WidgetData;
  infos: WidgetInfo;
  context: WidgetContext;
  index: number;
	siblings: number;
  parentHolder: ParentWidgetHolder;
	container: WidgetChildrenHolder;
}

@Directive()
export class DefaultWidgetContainer implements WidgetableComponent {
  children: WidgetData[];
  params: WidgetParams;

  constructor() { }

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data);
    this.params = params;
    this.children = params.data.children;
  }
}


@Directive()
export class WidgetPopupBase implements WidgetPopupBaseInputs {
  @Output() widgetChange = new EventEmitter<WidgetData>();
  @Input() infos: WidgetInfo;
  @Input() context: WidgetContext;
  @Input() index: number;
  @Input() parentHolder: ParentWidgetHolder;
	@Input() container: WidgetChildrenHolder;
	@Input() siblings: number;
	private _widget: WidgetData;

  @Input() set widget(widget: WidgetData) {
		this._widget = widget;
	}

	get widget() {
		return this._widget;
	}

	setWidget(widget: WidgetData) {
		this.widget = widget;
		this.widgetChange.emit(widget);
	}
	
}

export abstract class WidgetChildrenHolder extends FormControlComponent<WidgetData[]> {
	abstract parent: WidgetParent;

  updateChild(index: number, data: WidgetData) {
    const children = [...this.value];
    children[index] = data;
    this.setValue(children);
  }

  setValue(value: WidgetData[]) {
    const changed = super.setValue(value);
    if (this.parent) {
      this.parent.setChildren(value);
    }
    return changed;
  }

  deleteChild(index: number) {
    const children = [...this.value];
    children.splice(index, 1);
    this.setValue(children);
  }

  addChild(widget: WidgetData, index?: number) {
		widget = {...widget, id: null, uuid: randomString(6)};
    findInTree(widget.children, child => child.id = null, w => w.children);
		if (index == null) {
			this.setValue([...(this.value ?? []), widget]);
		} else {
			const newValue = [...(this.value ?? [])];
			newValue.splice(index, 0, widget);
			this.setValue(newValue);
		}
  }

}