import { CategoryLeaf } from './category';
import { StoredFile } from './files';
import { ProductShop } from './shop';

export const UNLIMITED_STOCK = -1;

export enum ProductType {
  good = 'GOOD',
  service = 'SERVICE',
  virtual = 'VIRTUAL',
}

export interface ListedProduct {
  id: number;
  categories: CategoryLeaf[];
  media: StoredFile;
  name: string;
  unitPrice: number;
  currency: string;
  stock: number;
  shopName: string;
}

export interface AbstractProduct {
  id: number;
  name: string;
  description: string;
  categories: CategoryLeaf[];
  media: StoredFile[];
  unitPrice: number;
  currency: string;
  type: ProductType;
  vat: number;
  stock: number;
	confirmSale: boolean;
  deliverable: boolean;
  meta: {
    [key: string]: string;
  }
}

export interface Product extends AbstractProduct {
  shop: ProductShop;
}

export interface FullProduct extends AbstractProduct  {
  sold: number;
  soldOut: boolean;
  visible: boolean;
  views: number;
  uniqueViews: number;
}

export interface SaveProduct {
  id?: number;
  categoriesIds?: number[];
  mediaIds?: number[];
  name?: string;
  description?: string;
  unitPrice?: number;
  currency?: string;
  type?: ProductType;
  stock?: number;
  visible?: boolean;
	confirmSale?: boolean;
}