import { EditWidgetFilePopupComponent } from '../edit-widget-file-popup/edit-widget-file-popup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDisplayComponent, FileDisplayPopup } from './file-display.component';
import { IonstackPipesModule, WIDGET } from '@adeprez/ionstack';
import { IonicModule } from '@ionic/angular';
import { FileDisplayPipesModule } from './pipes/file-display-pipes.module';
import { AudioPlayerModule } from '../audio-player/audio-player.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonstackPipesModule,
    FileDisplayPipesModule,
    AudioPlayerModule,
  ],
  declarations: [FileDisplayComponent],
  exports: [FileDisplayComponent],
  providers: [
    { provide: WIDGET, useValue: FileDisplayComponent, multi: true},
    { provide: FileDisplayPopup, useValue: EditWidgetFilePopupComponent },
  ]
})
export class FileDisplayModule { }
