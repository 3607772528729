<ionstack-edit-widget-popup
  [widget]="widget"
  [infos]="infos"
  [context]="context"
  [index]="index"
  [parentHolder]="parentHolder"
  [extraValue]="{content: content}"
  class="ion-page"
>
<ionstack-richtext-editor class="sticky-editor" [(ngModel)]="content"></ionstack-richtext-editor>
</ionstack-edit-widget-popup>