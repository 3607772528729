import { EventEmitter } from '@angular/core';
import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input, Output } from '@angular/core';
import { AlertService } from '../service/alert.service';

@Directive({
  selector: '[awaitClick]'
})
export class AwaitClickDirective<T> {
  @Input() awaitClick: (eventOrArg: any) => T;
  @Input() toastAwaitClickErr = true;
  @Input() bindAwaitClickTo: any;
  @Input() awaitClickArg: any;
  @Input() clickConfirmMessage: string;
  @Output() awaitClickDone = new EventEmitter<T>();
  @Input() @HostBinding('attr.disabled') disabled = null;
  @HostBinding('class') styleClass = '';

  constructor(private cd: ChangeDetectorRef, private alertService: AlertService) { }

  @HostListener('click', ['$event']) async onClick(event: Event) {
    if (!this.disabled && this.awaitClick) {
      try {
        this.disabled = true;
        this.styleClass = 'await-clicked';
        this.cd.markForCheck();
        if (!this.clickConfirmMessage || await this.alertService.awaitConfirmOk(this.clickConfirmMessage)) {
          try {
            const res = await this.awaitClick.bind(this.bindAwaitClickTo || event.target)(this.awaitClickArg != null ? this.awaitClickArg : event);
            this.awaitClickDone.emit(res);
          } catch (e) {
            if (this.toastAwaitClickErr) {
              this.alertService.showError(e);
              console.error(e);
            } else {
              throw e;
            }
          }
        }
      } finally {
        this.disabled = null;
        this.styleClass = 'await-clicked-done';
        this.cd.markForCheck();
      }
    }
  }

}
