import {Injectable} from '@angular/core';
import {isDefined} from './util';

const templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

export function extractValueFromPath(target: any, key: string): any {
  let keys = typeof key === 'string' ? key.split('.') : [key];
  key = '';
  do {
    key += keys.shift();
    if (isDefined(target) && isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
      target = target[key];
      key = '';
    } else if (!keys.length) {
      target = undefined;
    } else {
      key += '.';
    }
  } while (keys.length);

  return target;
}

export function interpolateString(expr: string, params?: any) {
  if (!params || !expr) {
    return expr;
  }
  return expr.replace(templateMatcher, (substring: string, b: string) => {
    let r = extractValueFromPath(params, b);
    return isDefined(r) ? r : substring;
  });
}

@Injectable({
  providedIn: 'root'
})
export class TranslateDefaultParser {

  public interpolate(expr: string | Function, params?: any): string {
    let result: string;

    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr as string;
    }

    return result;
  }

  getValue(target: any, key: string): any {
    return extractValueFromPath(target, key);
  }

  private interpolateFunction(fn: Function, params?: any) {
    return fn(params);
  }

  private interpolateString(expr: string, params?: any) {
    return interpolateString(expr, params);
  }
}