import { TranslatableModule, IonstackPipesModule, TranslateModule } from '@adeprez/ionstack';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickerComponent, PickerPopup } from './picker.component';
import { IonicModule } from '@ionic/angular';
import { PagedModule } from '@adeprez/ionstack/ui/paged';
import { PickerInputComponent } from './input/picker-input.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslatableModule,
    TranslateModule,
    IonstackPipesModule,
    PagedModule,
  ],
  declarations: [
    PickerComponent,
    PickerPopup,
    PickerInputComponent,
  ],
  exports: [
    PickerComponent,
    PickerPopup,
    PickerInputComponent,
  ],
})
export class PickerModule { }
