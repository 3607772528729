import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslaterComponent, TranslaterPopover } from './translater.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonstackDirectivesModule } from '../../directive/ionstack-directives.module';
import { TranslateModule } from '../../translate/translate.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    IonstackDirectivesModule,
    TranslateModule,
  ],
  declarations: [TranslaterComponent, TranslaterPopover],
  exports: [TranslaterComponent, TranslaterPopover]
})
export class TranslaterModule { }
