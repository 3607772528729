export enum ChronoUnit {
  millisecond = 1,
  second = 1000,
  minute = 60000,
  hour = 3600000,
  day = 86400000,
  week = 604800000,
  month = 2592000000,
  year = 31536000000,
}

export interface DateInterval {
  from: Date;
  to: Date;
}

export function isSameDay(date1: Date | string, date2: Date | string): boolean {
  if (!date1 || !date2) {
    return date1 === date2;
  }
  date1 = new Date(date1);
  date2 = new Date(date2);
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function isSameInstant(date1: Date | string, date2: Date | string): boolean {
  if (!date1 || !date2) {
    return date1 === date2;
  }
  return new Date(date1).getTime() === new Date(date2).getTime();
}

export function isSameMonth(date1: Date | string, date2: Date | string): boolean {
  if (!date1 || !date2) {
    return date1 === date2;
  }
  date1 = new Date(date1);
  date2 = new Date(date2);
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

export function addToDate(date: Date | string, incr: number, unit: ChronoUnit | number): Date {
  if (!date) {
    return null;
  }
  date = new Date(date);
  date.setTime(date.getTime() + incr * unit);
  return date;
}

export function getElapsed(d1: Date | string, d2: Date | string, unit: ChronoUnit | number = ChronoUnit.millisecond, transform = (i: number) => Math.floor(Math.abs(i))): number {
  if (!d1 || !d2) {
    return null;
  }
  d1 = new Date(d1);
  d2 = new Date(d2);
  return transform((d2.getTime() - d1.getTime()) / unit);
}

export function getYearStartDate(date: Date | string | number) {
  return new Date(typeof date === 'number' ? date : new Date(date).getFullYear(), 0);
}

export function getYearEndDate(date: Date | string | number) {
  return new Date(typeof date === 'number' ? date : new Date(date).getFullYear(), 12, 0, 0, 0, 0, -1);
}

export function getYearInterval(date: Date | string | number): DateInterval {
  if (!date) {
    return null;
  }
  return { from: getYearStartDate(date), to: getYearEndDate(date) };
}

export function getMonthStartDate(month?: number | Date, year?: number): Date {
  if (month instanceof Date) {
    year ||= month.getFullYear();
    month = month.getMonth();
  }
  return new Date(year ?? new Date().getFullYear(), month ?? new Date().getMonth(), 1);
}

export function getMonthEndDate(month?: number | Date, year?: number): Date {
  if (month instanceof Date) {
    year ||= month.getFullYear();
    month = month.getMonth();
  }
  return new Date(year ?? new Date().getFullYear(), (month ?? new Date().getMonth()) + 1, 0);
}

export function getMonthInterval(date: Date): DateInterval {
  if (!date) {
    return null;
  }
  return {
    from: getMonthStartDate(date.getMonth(), date.getFullYear()),
    to: getMonthEndDate(date.getMonth(), date.getFullYear())
  };
}

export function getWeekMonthInterval(date: Date, startMonday = true): DateInterval {
  return date ? {
    from: getWeekStartDate(getMonthStartDate(date.getMonth(), date.getFullYear()), startMonday),
    to: getWeekEndDate(getMonthEndDate(date.getMonth(), date.getFullYear()), startMonday)
  } : null;
}

export function getWeekInterval(date: Date, startMonday = true): DateInterval {
  return date ? {
    from: getWeekStartDate(date, startMonday),
    to: getWeekEndDate(date, startMonday)
  } : null;
}

export function getDayStart(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getDayEnd(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}

export function getDayInterval(date: Date): DateInterval {
  if (!date) {
    return null;
  }
  return {
    from: getDayStart(date),
    to: getDayEnd(date)
  };
}

export function getRebasedWeek(dayIndex: number, startMonday: boolean): number {
  if (startMonday) {
    return dayIndex === 0 ? 6 : dayIndex - 1;
  }
  return dayIndex;
}

export function getWeekStartDate(date: Date | string, startMonday = true): Date {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const day = getRebasedWeek(date.getDay(), startMonday);
  const d = new Date(date);
  d.setDate(date.getDate() - day);
  return d;
}

export function getWeekEndDate(date: Date, startMonday = true): Date {
  if (!date) {
    return null;
  }
  const day = getRebasedWeek(date.getDay(), startMonday);
  const d = new Date(date);
  d.setDate(date.getDate() + 7 - day);
  return d;
}

export function isSameDateInterval(a: DateInterval, b: DateInterval): boolean {
  if (!a || !b) {
    return a === b;
  }
  return a === b || (a.from.getTime() === b.from.getTime() && a.to.getTime() === b.to.getTime());
}

export function isDateIntervalIntersects(a: DateInterval, b: DateInterval): boolean {
  if (!a || !b) {
    return false;
  }
  let isOut = false;
  if (b.from) {
    isOut ||= b.from > a.from && b.from > a.to;
  }
  if (b.to) {
    isOut ||= b.to < a.from && b.to < a.to;
  }
  return !isOut;
}

export function getLocalDatetimeFormValue(date: Date | string): string {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    date = date.toISOString();
  }
  if (date.length > 16) {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    return getDateTimeFormValue(d);
  }
  return date;
}

export function getDateTimeFormValue(date: Date): string {
  return date.toISOString().substring(0, 16);
}

export function getUTCDatetimeFormValue(date: string): string {
  return date ? new Date(date).toISOString() : date;
}

export function getWeekDays(date: Date | string, startMonday = true): Date[] {
  const d = getWeekStartDate(date, startMonday);
  const days = [];
  for (let i = 0; i < 7; i++) {
    days.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + i));
  }
  return days;
}

export function getWeekNumber(date: Date | string, startMonday = true): number {
  const d = getWeekStartDate(date, startMonday);
  const year = d.getFullYear();
  const start = new Date(year, 0, 1);
  const time = new Date(year, d.getMonth(), d.getDate()).getTime() - start.getTime();
  const diff = Math.floor(time / 86400000);
  return Math.floor(diff / 7) + 1;
}