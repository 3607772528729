<ion-item [color]="field.color">
  <ion-label [position]="field.position || 'floating'" *ngIf="field.label"><itxt [key]="field.label"></itxt></ion-label>
  <ion-input
    *ngIf="control"
    [formControl]="control"
    [placeholder]="field.placeholder"
    [autofocus]="field.autofocus"
    [type]="field.type || 'text'"
    [required]="!field.optional"
    [maxlength]="field.maxLength"
    [autocomplete]="field.autocomplete"
    [min]="field.min"
    [max]="field.max"
    [inputPattern]="field.pattern"
  ></ion-input>
</ion-item>