import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DefaultWidgetContainer, Widget, WidgetParams } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-swiper-widget',
  templateUrl: './swiper-widget.component.html',
  styleUrls: ['./swiper-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'swiper',
  widgetName: 'Swiper slides',
  group: 'Container',
  icon: 'albums-sharp',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    {name: 'navigation', label: 'Show navigation', type: 'checkbox', value: true, optional: true},
    {name: 'pagination', label: 'Pagination', type: 'checkbox', optional: true},
    {name: 'center', label: 'Center slides', type: 'checkbox', value: true, optional: true},
    {name: 'slidesPerView', label: 'Slides per view', type: 'select', value: 'auto', optional: true, options: ['auto', 1, 2, 3, 4, 5]}
  ]
})
export class SwiperWidgetComponent extends DefaultWidgetContainer {
  @Input() navigation: boolean;
  @Input() pagination: boolean;
  @Input() slidesPerView: number | 'auto';
  @Input() center: boolean;
  swiperClass: string;

  set widget(params: WidgetParams) {
    super.widget = params;
    this.swiperClass = this.slidesPerView === 'auto' ? 'slides-auto' : '';
  }
}
