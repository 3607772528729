<input
  type="file"
  (change)="fileChangeEvent($event)"
  [multiple]="allowExtraUpload"
  accept="image/*"
  #input
  style="display:none"
/>

<ion-toolbar>
  <ion-buttons slot="secondary" *ngIf="isCropping()">
    <ion-button (click)="reset()" color="danger" *ngIf="hasImage()">
      <ion-icon name="trash-bin-outline" slot="icon-only" size="small"></ion-icon>
    </ion-button>
    <ion-button (click)="rotate()">
      <ion-icon name="refresh" slot="icon-only" size="small"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="primary">
    <ion-button (click)="takePicture()">
      <ion-icon slot="start" name="camera"></ion-icon>
      <ion-label *ngIf="!isCropping()"><itxt key="Take picture"></itxt></ion-label>
    </ion-button>
    <ion-button (click)="openBrowseFile()">
      <ion-icon slot="start" name="image-outline"></ion-icon>
      <ion-label *ngIf="!isCropping()"><itxt key="Browse"></itxt></ion-label>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<div ionstackFileDrop (fileDropped)="onDrop($event)" class="dropzone">
  <image-cropper
    #cropper
    *ngIf="isCropping(); else empty"
    [style.max-height]="maxCropperHeight"
    [imageFile]="imageFile"
    [imageBase64]="base64"
    [imageURL]="imageURL"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="aspectRatio"
    [cropperMinWidth]="minWidth"
    [cropperMinHeight]="minHeight"
    [onlyScaleDown]="!maintainAspectRatio"
    [containWithinAspectRatio]="maintainAspectRatio"
    [format]="getOutputFormat | call:format"
    [roundCropper]="roundCropper"
    [canvasRotation]="rotation"
    (imageCropped)="imageCropped($event)"
    (loadImageFailed)="loadImageFailed()"
    (imageLoaded)="imageLoaded()"
  ></image-cropper>
</div>

<ng-template #empty>
  <div class="empty" (click)="browseFromZone()">
    <ion-icon name="image"></ion-icon>
    <div class="types">{{ getExtensionDisplay | call:requiredExtension }}</div>
  </div>
</ng-template>
