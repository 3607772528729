import { map } from 'rxjs/operators';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { Dictionary } from '../model/i18n';
import { MissingTranslationHandlerParams } from '../translate/missing-translation-handler';
import { TranslateDefaultParser } from '../translate/translate.parser';
import { TranslateService } from '../translate/translate.service';
import { CleanSubscriber } from '../util/subscriber';
import { ContextService } from './context.service';
import { IonstackService } from './ionstack.service';

@Injectable({
  providedIn: 'root'
})
export class I18nService extends CleanSubscriber implements OnDestroy {
  private groups: {[group: string]: AsyncSubject<Dictionary>} = {};
  private loadedGroups: {[group: string]: Dictionary} = {};
  private dict = new BehaviorSubject<Dictionary>({});
  private dictAppUpdate = {};
  private translateServiceInited = false;

  constructor(
    private ionstackService: IonstackService,
    private contextService: ContextService,
    private translateParser: TranslateDefaultParser,
    private injector: Injector,
  ) {
    super();
    this.subscribe(contextService.context, () => {
      this.groups = {};
      this.loadedGroups = {};
      this.dictChanged();
    });
  }

  setAppText(key: string, value: string) {
    if (value == null) {
      delete this.dictAppUpdate[key];
    } else {
      this.dictAppUpdate[key] = value;
    }
    this.dictChanged();
  }

  getDict() {
    return this.dict;
  }

  instant(key: string) {
    return this.dict?.value[key] || key;
  }

  setTranslateServiceInited() {
    if (!this.translateServiceInited) {
      this.translateServiceInited = true;
      this.updateTranslateServiceText();
    }
  }

  updateTranslateServiceText() {
    if (this.translateServiceInited && this.dict.value) {
      const translateService = this.injector.get(TranslateService);
      translateService.setTranslation(translateService.defaultLang, this.dict.value, false);
      translateService.use(translateService.defaultLang);
    }
  }

  dictChanged() {
    let text: Dictionary = this.contextService.getCurrentContext()?.text || {};
    for (const group of Object.values(this.loadedGroups)) {
      text = {...text, ...group};
    }
    text = {...text, ...this.dictAppUpdate};
    this.dict.next(text);
    this.updateTranslateServiceText();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  getTexts(group: string) {
    return this.ionstackService.get<Dictionary>('/public/i18n/groups/' + group);
  }

  getAllTexts() {
    return this.ionstackService.get<{[lang: string]: Dictionary}>('/public/i18n');
  }

  getTranslations(key: string) {
    return this.ionstackService.get<Dictionary>('/public/i18n/' + key);
  }

  setTranslations(key: string, values: Dictionary) {
    return this.ionstackService.put<void>('/i18n/' + key, values);
  }

  replaceTranslations(translations: {[code: string]: Dictionary}) {
    return this.ionstackService.put<void>('/i18n', translations);
  }

  putLanguage(code: string, name: string) {
    return this.ionstackService.put<void>('/languages/' + code, name);
  }

  deleteLanguage(code: string) {
    return this.ionstackService.delete<void>('/languages/' + code);
  }
  
  async getGroup(group: string): Promise<Dictionary> {
    if (group in this.loadedGroups) {
      return this.loadedGroups[group];
    }
    if (group in this.groups) {
      return this.groups[group].toPromise();
    }
    return this.loadNewGroup(group);
  }

  private async loadNewGroup(group: string, subject = new AsyncSubject<Dictionary>()) {
    this.groups[group] = subject;
    const dict = await this.getTexts(group);
    this.loadedGroups[group] = dict;
    subject.next(dict);
    subject.complete();
    this.dictChanged();
    return dict;
  }

  processMissingTranslation(key: string, params: Object, group?: string): string {
    return params?.['defaultText'] || this.translateParser.interpolate(group ? key.substring(group.length + 1) : key, params);
  }

  getTranslated(group: string, dict: Dictionary, params: MissingTranslationHandlerParams) {
    if (params.key in dict) {
      return this.translateParser.interpolate(dict[params.key], params);
    }
    return this.processMissingTranslation(params.key, params.interpolateParams, group);
  }

  loadGroup(group: string, params: MissingTranslationHandlerParams) {
    if (!(group in this.groups)) {
      const subject = new AsyncSubject<Dictionary>();
      this.loadNewGroup(group, subject);
      return subject.asObservable().pipe(map(dict => this.getTranslated(group, dict, params)));
    }
    if (group in this.loadedGroups) {
      return this.getTranslated(group, this.loadedGroups[group], params);
    }
    return this.groups[group].asObservable().pipe(map(dict => this.getTranslated(group, dict, params)));
  }

}
