<form [formGroup]="form" [awaitSubmit]="save" [bindAwaitSubmitTo]="this" *ngIf="customerLoaded || !useKnownCards; else loading">
  <ion-radio-group formControlName="paymentMethodId" *ngIf="useKnownCards && customer?.paymentMethods?.length">
    <ion-list>
      <ion-item *ngFor="let pm of customer.paymentMethods" class="padding-start">
        <ion-icon name="card-outline" slot="start"></ion-icon>
        <ion-label>{{ pm.card.brand }} ****{{ pm.card.last4 }} ({{ pm.card.expMonth }}/{{ pm.card.expYear }})</ion-label>
        <ion-radio [value]="pm.id" slot="end"></ion-radio>
      </ion-item>
      <ion-item class="padding-start">
        <ion-icon name="add" slot="start"></ion-icon>
        <ion-label><itxt key="pay.Add card"></itxt></ion-label>
        <ion-radio [value]="false" slot="end"></ion-radio>
      </ion-item>
    </ion-list>
  </ion-radio-group>
  <div class="add-card" formGroupName="card" *ngIf="!form.controls.paymentMethodId.value">
    <ion-item>
      <ion-label position="floating"><itxt key="pay.Card number"></itxt></ion-label>
      <ion-input type="text" inputPattern="0000000000000000000" formControlName="number" [maxlength]="19" autocomplete="cc-number" [focusAtEnd]="expMonth"></ion-input>
      <ion-icon name="card-outline" slot="end"></ion-icon>
    </ion-item>
    <div class="date-and-code">
      <ion-item class="m">
        <ion-label position="floating"><itxt key="pay.Expiration month"></itxt></ion-label>
        <ion-input type="number" placeholder="--" inputPattern="00" formControlName="exp_month" [maxlength]="2" autocomplete="cc-exp-month"  [focusAtEnd]="expYear" #expMonth></ion-input>
      </ion-item>
      <ion-item class="y">
        <ion-label position="floating"><itxt key="pay.Expiration year"></itxt></ion-label>
        <ion-input type="number" placeholder="----" inputPattern="0000" formControlName="exp_year" [maxlength]="4" autocomplete="cc-exp-year" [focusAtEnd]="cvc" #expYear></ion-input>
      </ion-item>
      <ion-item class="cvc">
        <ion-label position="floating"><itxt key="pay.CVC"></itxt></ion-label>
        <ion-input type="text" placeholder="---" inputPattern="000" formControlName="cvc" [maxlength]="3" autocomplete="cc-csc" #cvc></ion-input>
        <ion-icon slot="end" name="lock-closed-outline"></ion-icon>
      </ion-item>
    </div>
  </div>
  <div class="ion-text-center ion-padding" *ngIf="submitVisible">
    <ion-button type="submit" [disabled]="paymentInvalid || isDisabled" [ngClass]="submitClass" [size]="buttonSize">
      <ion-icon slot="start" [name]="submitIcon"></ion-icon>
      <ion-label><itxt [key]="submitLabel"></itxt></ion-label>
    </ion-button>
  </div>
</form>

<ng-template #loading>
  <ion-spinner></ion-spinner>
</ng-template>