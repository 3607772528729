import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WIDGET, WidgetableComponent, TranslatableModule, IonstackPipesModule, IonstackDirectivesModule } from '@adeprez/ionstack';
import { ButtonComponent } from './button/button.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { IonstackFormModule } from './form/ionstack.form.module';
import { WidgetChildrenModule } from '@adeprez/ionstack/ui/widget-children';
import { SimpleFormModule } from '@adeprez/ionstack/ui/simple-form';
import { HtmlModule } from '@adeprez/ionstack/ui/html';
import { GridComponent } from './container/grid/grid.component';
import { EditWidgetPopupModule } from '@adeprez/ionstack/ui/edit-widget-popup';
import { FileDisplayModule } from '@adeprez/ionstack/file';
import { RichtextModule } from '@adeprez/ionstack/ui/richtext';
import { TitleComponent } from './title/title.component';
import { AccordionComponent } from './container/accordion/accordion.component';
import { AccordionGroupComponent } from './container/accordion-group/accordion-group.component';
import { CardComponent, CardContentComponent } from './container/card/card.component';
import { CardHeaderComponent, CardSubtitleComponent, CardTitleComponent } from './container/card/card-header.component';
import { ListComponent, ListHeaderComponent } from './container/list/list.component';
import { ItemComponent } from './container/item/item.component';
import { IconComponent } from './icon/icon.component';
import { IconPopupPickerComponent } from './icon/icon-popup-picker/icon-popup-picker.component';
import { IconpickerModule } from '@adeprez/ionstack/ui/iconpicker';
import { SwiperWidgetComponent } from './container/swiper-widget/swiper-widget.component';
import { SwiperModule } from 'swiper/angular';
import { WidgetModule } from '@adeprez/ionstack/ui/widget';
import { WidgetChildrenWidget } from './widget-children.widget';
import { LinkComponent } from './link/link.component';

const components: Type<WidgetableComponent>[] = [
  ButtonComponent,
  TitleComponent,
  GridComponent,
  AccordionComponent,
  AccordionGroupComponent,
  CardComponent,
  CardContentComponent,
  CardHeaderComponent,
  CardTitleComponent,
  CardSubtitleComponent,
  ListComponent,
  ListHeaderComponent,
  ItemComponent,
  IconComponent,
  SwiperWidgetComponent,
  LinkComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    IonstackPipesModule,
    IonstackDirectivesModule,
    EditWidgetPopupModule,
    HtmlModule,
    TranslatableModule,
    FileDisplayModule,
    RichtextModule,
    SimpleFormModule,
    WidgetChildrenModule,
    IonstackFormModule,
    IconpickerModule,
    SwiperModule,
    WidgetModule,
  ],
  declarations: [
    IconPopupPickerComponent,
    WidgetChildrenWidget,
    ...components,
  ],
  exports: [
    IconPopupPickerComponent,
    WidgetChildrenWidget,
    ...components,
  ],
  providers: [
    { provide: WIDGET, useValue: WidgetChildrenWidget, multi: true},
    ...components.map(c => ({ provide: WIDGET, useValue: c, multi: true })),
  ]
})
export class IonstackWidgetsModule { }
