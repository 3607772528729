<ng-container
  *ngIf="!layoutMode; else editWrapper"
  [ngTemplateOutlet]="childrenWrapper || children"
  [ngTemplateOutletContext]="{childrenTemplate: children}"
></ng-container>

<ng-template #editWrapper>
  <ion-reorder-group
    [disabled]="false"
    [ionstackDrop]="getDropZone | call:this"
    (droppedIn)="moveChildInto($event)"
    (ionItemReorder)="reorder($event['detail'])"
  >
    <ng-container [ngTemplateOutlet]="childrenWrapper || children" [ngTemplateOutletContext]="{childrenTemplate: children}"></ng-container>
  </ion-reorder-group>
</ng-template>

<ng-template #children>
  <ng-container
    *ngFor="let widget of displayed; let i = index"
    [ngTemplateOutlet]="widgetWrapper || (layoutMode ? editWidgetWrapper : defaultWidgetWrapper)"
    [ngTemplateOutletContext]="{
      template: layoutMode ? editWidgetWrapper : defaultWidgetWrapper,
      templateContext: {index: i, widget, count: displayed.length},
      parentProps: parseWidgetParentProps | call:widget
    }"
  ></ng-container>
</ng-template>

<ng-template #defaultWidgetWrapper widgetWrapper let-templateContext="templateContext">
  <ionstack-widget
    [context]="context"
    [container]="this"
    [widget]="templateContext.widget"
    [index]="templateContext.index"
    [siblings]="templateContext.count"
    (widgetChange)="updateChild(getModelIndex(templateContext.index), $event)"
  ></ionstack-widget>
</ng-template>

<ng-template #editWidgetWrapper widgetWrapper let-ctx="templateContext">
  <div
    class="widget-child"
    [ionstackDrag]="ctx.widget"
    [targetAcceptor]="getTargetAcceptor | call:ctx.widget"
    (willDragOut)="moveOut(ctx.widget, getModelIndex(ctx.index))"
  >
    <div
      class="add-before"
      [ionstackDrop]="getDropZone | call:this"
      (droppedIn)="moveChildInto($event, getModelIndex(ctx.index))"
    ></div>
    <div class="widget-header">
      <ion-buttons>
        <ion-button (click)="deleteChild(getModelIndex(ctx.index)); $event.stopPropagation()" color="danger" size="small">
          <ion-icon name="trash" slot="icon-only" size="small"></ion-icon>
        </ion-button>
        <ion-button (click)="edit(getModelIndex(ctx.index), $event)" size="small">
          <ion-icon name="create" slot="icon-only" size="small"></ion-icon>
        </ion-button>
        <ion-button (click)="addChild(ctx.widget, getModelIndex(ctx.index) + 1)" size="small" color="medium">
          <ion-icon name="copy" slot="icon-only" size="small"></ion-icon>
        </ion-button>
      </ion-buttons>
      <div class="end">
        <ion-reorder></ion-reorder>
        {{ getWidgetName | call:ctx.widget.type }}
      </div>
    </div>
    <div class="widget-content" (click)="edit(getModelIndex(ctx.index), $event)">
      <ng-container
        [ngTemplateOutlet]="defaultWidgetWrapper"
        [ngTemplateOutletContext]="{templateContext: ctx}"
      ></ng-container>
    </div>
  </div>
</ng-template>