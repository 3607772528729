import { Platform } from '@ionic/angular';
import { EventEmitter, Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Observable } from 'rxjs';
import { IonstackModuleConfig, MODULE_CONFIG, ClientApiInterface, API_CLIENT, SERVER_SECRET } from '../ionstack.config';
import { share, take } from 'rxjs/operators';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { slugify } from '../util/util';

export type CachePolicy = 'never' | 'cache' | 'client-refresh' | 'client-consume';

export type RequestParams = HttpParams | {[param: string]: any};

export interface RequestOptions {
  isRootUrl?: boolean;
  params?: RequestParams;
  withoutAuth?: boolean;
  headers?: {[header: string]: string | string[]};
  responseType?: 'json' | 'text' | 'number';
  byPassSW?: boolean;
  preventErrorEmit?: boolean;
}

export interface GetOptions extends RequestOptions {
  cache?: CachePolicy;
  stateKey?: string | false;
}

@Injectable({
  providedIn: 'root'
})
export class IonstackService {
  readonly onError = new EventEmitter<HttpErrorResponse>();
  private activeRequests: {[path: string]: Observable<any>} = {};
  private additionalHeaders: {[header: string]: string | string[]} = {};
  private keptHeaders: string[];
  private ssr: boolean;
  private baseUrl: string;

  constructor(
    @Inject(API_CLIENT) private api: ClientApiInterface,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    @Optional() @Inject('req') private request: any,
    @Optional() @Inject('res') private response: any,
    @Optional() @Inject(SERVER_SECRET) private serverSecret: string,
    private state: TransferState,
    private platform: Platform,
  ) {
    this.keptHeaders = [...(ionstackModuleConfig.extraHeaders || []), 'cookie', 'accept-language', 'pdfgen-secret', 'server-secret'];
    this.ssr = isPlatformServer(this.platformId);
    this.baseUrl = this.ssr ? ionstackModuleConfig.serverApi : this.getPlatformApi();
  }

  get config() {
    return this.ionstackModuleConfig;
  }

  getPlatformApi() {
    if (this.ssr) {
      return this.ionstackModuleConfig.frontBaseUrl + this.ionstackModuleConfig.browserApi;
    }
    return this.platform.is('capacitor') ? this.ionstackModuleConfig.appApi ?? this.ionstackModuleConfig.browserApi : this.ionstackModuleConfig.browserApi;
  }

  getModuleConfig() {
    return this.ionstackModuleConfig;
  }

  isServer() {
    return this.ssr;
  }

  isBrowser() {
    return !this.ssr;
  }

  setAdditionalHeader(header: string, value: string) {
    this.additionalHeaders[header] = value;
  }

  isExtApi(url: string) {
    return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
  }

  apiUrl(path: string, isRootUrl = false) {
    return isRootUrl || this.isExtApi(path) ? path : this.baseUrl + path;
  }

  frontApiUrl(path: string) {
    return this.getPlatformApi() + path;
  }

  getRequest() {
    return this.request;
  }

  getResponse() {
    return this.response;
  }

  getOptions(opts: RequestOptions) {
    const headers: {[header: string]: string | string[]} = opts.headers ? {...opts.headers} : {};
    if (this.isServer()) {
      headers['server-secret'] = this.serverSecret;
      for (const hName of this.keptHeaders) {
        const hValue = this.request.headers[hName];
        if (hValue) {
          headers[hName] = hValue;
        }
      }
      for (const hName of Object.keys(this.additionalHeaders)) {
        headers[hName] = this.additionalHeaders[hName];
      }
    }
    if (opts.byPassSW) {
      headers['ngsw-bypass'] = 'true';
    }
    return {headers, withCredentials: !opts.withoutAuth, params: opts.params};
  }

  doRequest<T>(method: string, path: string, opts: RequestOptions, body?: any): Observable<T> {
    const url = this.apiUrl(path, opts.isRootUrl);
    const options = this.getOptions(opts);
    if (opts.responseType === 'text' || opts.responseType === 'number') {
      return this.api.request(method, url, {...options, body, observe: 'body', responseType: 'text'}).pipe(s => s as any);
    }
    return this.api.request<T>(method, url, {...options, body, observe: 'body', responseType: 'json'});
  }

  dropState<T>(stateKey: string) {
    this.state.remove(makeStateKey<T>(stateKey));
  }

  private computeReqIdentity<T>(path: string, opts: GetOptions) {
    if (opts.stateKey === false) {
      return {footprint: path, key: null};
    }
    const footprint = opts.stateKey || (path + (opts.params ? slugify(JSON.stringify(opts.params)) : ''));
    return {footprint, key: makeStateKey<T>(footprint)}
  }

  private handleError(error: HttpErrorResponse, preventErrorEmit: boolean) {
    if (!preventErrorEmit) {
      this.onError.emit(error);
    }
  }

  private getCachedResponse<T>(key: StateKey<T>, cache: CachePolicy) {
    const value = this.state.get<T>(key, null);
    if (cache === 'client-consume' && !this.isServer()) {
      this.state.remove(key);
    }
    return new Observable<T>(obs => {
      if (value instanceof HttpErrorResponse) {
        obs.error(value);
        this.handleError(value, value?.['opts']?.preventErrorEmit);
      } else if (value?.['ok'] === false && value?.['status'] >= 400) {
        const err = new HttpErrorResponse(value);
        obs.error(err);
        this.handleError(err, value?.['opts']?.preventErrorEmit);
      } else {
        obs.next(value);
      }
      obs.complete();
    });
  }

  private isCached<T>(key: StateKey<T>, cache: CachePolicy) {
    return key && (cache === 'cache' || cache === 'client-consume' || (cache === 'client-refresh' && this.isServer())) && this.state.hasKey(key);
  }

  private performNewGetRequest<T>(path: string, opts: GetOptions, key: StateKey<T>, footprint: string) {
    const obs = this.doRequest<T>('get', path, opts).pipe(share());
    this.activeRequests[footprint] = obs;
    if (this.isServer() && key) {
      obs.pipe(take(1)).subscribe(val => this.state.set(key, val), err => {
        this.state.set(key, {...err, opts});
        this.handleError(err, opts?.preventErrorEmit);
      }, () => delete this.activeRequests[footprint]);
    } else {
      obs.pipe(take(1)).subscribe(() => delete this.activeRequests[footprint], err => {
        delete this.activeRequests[footprint];
        this.handleError(err, opts?.preventErrorEmit);
      });
    }
    return obs;
  }

  getObservable<T>(path: string, opts: GetOptions = {}): Observable<T> {
    const { footprint, key } = this.computeReqIdentity<T>(path, opts);
    const cache = opts.cache || 'client-consume';
    if (this.isCached(key, cache)) {
      return this.getCachedResponse<T>(key, cache);
    }
    if (footprint in this.activeRequests) {
      return this.activeRequests[footprint];
    }
    return this.performNewGetRequest(path, opts, key, footprint);
  }

  get<T>(path: string, opts: GetOptions = {}) {
    return this.getObservable<T>(path, opts).toPromise();
  }

  post<T>(path: string, body: any, opts: RequestOptions = {}): Promise<T> {
    return this.doActionRequest<T>('post', path, opts, body);
  }

  put<T>(path: string, body: any, opts: RequestOptions = {}): Promise<T> {
    return this.doActionRequest<T>('put', path, opts, body);
  }

  delete<T>(path: string, opts: RequestOptions = {}): Promise<T> {
    return this.doActionRequest<T>('delete', path, opts);
  }

  private async doActionRequest<T>(method: string, path: string, opts: RequestOptions, body?: any): Promise<T> {
    try {
      return await this.doRequest<T>(method, path, opts, body).toPromise();
    } catch (e) {
      this.handleError(e, opts?.preventErrorEmit);
      throw e;
    }
  }

}
