<ion-item (ionFocus)="setTouched()" [color]="color">
  <ion-label position="stacked">
    {{ label }}
    <ng-content></ng-content>
  </ion-label>
  <ng-content></ng-content>
  <div>
    <ion-chip *ngFor="let item of value; let i = index">
      <span>{{ labelGetter(item) }}</span>
      <ion-icon name="close-circle" *ngIf="!isDisabled" (click)="remove(i)"></ion-icon>
    </ion-chip>
    <ionstack-autocomplete
      #autoComplete
      *ngIf="autocompleteEnabled"
      [isDisabled]="isDisabled"
      [with]="with"
      [labelGetter]="labelGetter"
      [valueGetter]="valueGetter"
      [placeholder]="placeholder"
      [autoConvertToString]="autoConvertToString"
      [newValueGetter]="newValueGetter"
      (onSubmit)="onSubmit($event)"
    ></ionstack-autocomplete>
    <ion-button class="search" (click)="openSearch()" *ngIf="popupSearchEnabled">
      <ion-icon slot="icon-only" [icon]="autocompleteEnabled ? 'search' : 'add'"></ion-icon>
    </ion-button>
  </div>
</ion-item>