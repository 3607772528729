import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DragAndDropController {
  private source: any;
  private beforeDropCallback: () => void;
  private onDropCallback: () => void;
  private targetAcceptor: (src: any) => boolean;
  private draggingElement: HTMLElement;
  readonly onDrag = new EventEmitter<any>();
  readonly onDrop = new EventEmitter<any>();
  readonly onDragStop = new EventEmitter<any>();

  constructor() { }

  setSource<T>(src: T, draggingElement: HTMLElement, targetAcceptor: (src: T) => boolean, beforeDrop: () => void, onDrop: () => void) {
    this.source = src;
    this.draggingElement = draggingElement;
    this.targetAcceptor = targetAcceptor;
    this.beforeDropCallback = beforeDrop;
    this.onDropCallback = onDrop;
    this.onDrag.emit(src);
  }

  consumeSource(consumer: (src: any) => void) {
    this.beforeDropCallback();
    const src = this.source;
    consumer(src);
    this.onDropCallback();
    this.source = null;
    this.targetAcceptor = null;
    this.onDropCallback = null;
    this.onDragStop.emit(src);
    this.onDrop.emit(src);
    return src;
  }

  getDragSource() {
    return this.source;
  }

  accepts(target: any, dropElement: HTMLElement, sourceAcceptor: (src: any) => boolean) {
    return this.draggingElement && (!this.targetAcceptor || this.targetAcceptor(target))
      && (!sourceAcceptor || sourceAcceptor(this.source))
      && (!dropElement || (
        this.draggingElement !== dropElement && !this.draggingElement.contains(dropElement)
      ));
  }

}