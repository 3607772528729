import { Injectable, OnDestroy } from '@angular/core';
import { CleanSubscriber, Context, ContextService, filterNotNull, IonstackService, ScriptService } from '@adeprez/ionstack';
import { environment } from 'src/environments/environment';

declare const Intercom: any;

@Injectable({
  providedIn: 'root'
})
export class IntercomService extends CleanSubscriber implements OnDestroy {
  private inited: boolean;

  constructor(
    private ionstackService: IonstackService,
    private scriptService: ScriptService,
    private contextService: ContextService,
  ) {
    super();
    this.subscribe<Context>(contextService.context, async context => this.intercom('update', await this.getUserData(context)));
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  async getUserHash() {
    return this.contextService.getCurrentContext().account ? await this.ionstackService.get<string>('/intercom/hash', {responseType: 'text'}) : null;
  }

  intercom(action: 'boot' | 'update' | 'show' | 'hide' | 'showSpace' | 'showNewMessage', data?: any) {
    if (this.inited) {
      try {
        Intercom(action, data);
      } catch (e) {
        console.error(e);
      }
    }
  }

  init() {
    this.doInit();
  }

  private async doInit() {
    if (this.ionstackService.isBrowser() && environment.intercomAppId) {
      try {
        await this.scriptService.loadScript('https://widget.intercom.io/widget/' + environment.intercomAppId);
        this.inited = true;
        await this.contextService.isLogged();
        this.intercom('boot', {
          ...await this.getUserData(this.contextService.getCurrentContext()),
          app_id: environment.intercomAppId,
        });
      } catch (e) {
        console.error('intercom disabled: ' + (e?.message || JSON.stringify(e)));
      }
    }
  }

  private async getUserData(context: Context) {
    const hash = await this.getUserHash();
    return context && filterNotNull(hash ? {
      name: context.account?.fullname,
      email: context.account?.mainEmail,
      user_id: context.account?.id,
      user_hash: hash
    } : {
      name: context.account?.fullname,
    })
  }

}
