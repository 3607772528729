<div class="form-title" *ngIf="title"><itxt [key]="title" *ngIf="translateTitle; else simpleTitle"></itxt></div>
<form [formGroup]="formGroup" [awaitSubmit]="submit" [bindAwaitSubmitTo]="this" [ngClass]="formClass" *ngIf="controls">
  <ng-content select="[before-fields]"></ng-content>
  <ng-container *ngFor="let field of fields">
    <ng-container [ngSwitch]="field.type" *ngIf="getControl | call:field:controls as control">

      <ionstack-chip-list
        *ngSwitchCase="'chip-list'"
        [formControl]="control"
        [with]="field.options"
        [autoConvertToString]="true"
        [placeholder]="field.placeholder"
        [color]="field.color || color"
        [label]="field.label"
      ></ionstack-chip-list>

      <ion-item *ngSwitchCase="'checkbox'" [color]="field.color || color">
        <ion-checkbox class="ion-margin-end" [formControl]="control" [required]="!field.optional"></ion-checkbox>
        <ion-label><itxt [key]="field.label"></itxt></ion-label>
      </ion-item>

      <ion-item *ngSwitchCase="'toggle'" [color]="field.color || color">
        <ion-toggle class="ion-margin-end" [formControl]="control" [required]="!field.optional"></ion-toggle>
        <ion-label><itxt [key]="field.label"></itxt></ion-label>
      </ion-item>

      <ion-item *ngSwitchCase="'select'" [color]="field.color || color">
        <ion-label [position]="field.position || 'floating'"><itxt [key]="field.label"></itxt></ion-label>
        <ion-select [formControl]="control" [placeholder]="field.placeholder" [required]="!field.optional">
          <ion-select-option *ngIf="field.defaultOption" [value]="field.defaultOption.value">{{ field.defaultOption.name || '-' }}</ion-select-option>
          <ion-select-option *ngFor="let item of field.options" [value]="item?.value ?? item">{{ item?.name ?? item }}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngSwitchCase="'multiline-text'" [color]="field.color || color">
        <ion-label [position]="field.position || 'floating'"><itxt [key]="field.label"></itxt></ion-label>
        <ion-textarea
          [formControl]="control"
          [autofocus]="field.autofocus"
          [placeholder]="field.placeholder"
          [required]="!field.optional"
          [maxlength]="field.maxLength"
          [autoGrow]="field.autoGrow"
          [rows]="field.rows || 3"
        ></ion-textarea>
      </ion-item>

      <input *ngSwitchCase="'hidden'" type="hidden" [formControl]="control" />

      <ion-item *ngSwitchDefault [color]="field.color || color">
        <ion-label [position]="field.position || 'floating'" *ngIf="field.label"><itxt [key]="field.label"></itxt></ion-label>
        <ion-input
          [formControl]="control"
          [placeholder]="field.placeholder"
          [autofocus]="field.autofocus"
          [type]="field.type || 'text'"
          [required]="!field.optional"
          [maxlength]="field.maxLength"
          [autocomplete]="field.autocomplete"
          [min]="field.min"
          [max]="field.max"
          [inputPattern]="field.pattern"
        ></ion-input>
      </ion-item>

    </ng-container>
  </ng-container>
  <ng-content select="[after-fields]"></ng-content>
  <ion-toolbar class="actions" *ngIf="submitButtons" [color]="toolbarColor">
    <ion-buttons slot="end">
      <ion-button [awaitClick]="onAbort" [bindAwaitClickTo]="this" type="button" *ngIf="canAbort()">
        <ion-icon name="close" slot="start" color="danger"></ion-icon>
        <itxt [key]="cancelText"></itxt>
      </ion-button>
      <ion-button [disabled]="formGroup.invalid" type="submit">
        <ion-icon slot="start" name="checkmark" color="success"></ion-icon>
        <itxt [key]="okText"></itxt>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</form>
<ng-template #simpleTitle>{{ title }}</ng-template>