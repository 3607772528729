import { TranslatableModule } from '../../component/translatable/translatable.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBaseComponent } from './app-base.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MenuItemsModule } from '../menu-items/menu-items.module';
import { IonstackDirectivesModule } from '../../directive/ionstack-directives.module';
import { NotifModule } from '../notif/notif.module';
import { InjectModule } from '@adeprez/ionstack/ui/inject';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    MenuItemsModule,
    IonstackDirectivesModule,
    TranslatableModule,
    NotifModule,
    InjectModule,
  ],
  declarations: [AppBaseComponent],
  exports: [AppBaseComponent]
})
export class AppBaseModule { }
