import { AlertService } from '../service/alert.service';
import { IonstackService } from '../service/ionstack.service';
import { Injectable } from '@angular/core';
import { FormSubmitData } from '../model/form';

@Injectable({
  providedIn: 'root'
})
export class ActionController {

  constructor(
    private ionstackService: IonstackService,
    private alertService: AlertService,
  ) { }

  async sendContactEmail(data: FormSubmitData) {
    try {
      const ok = await this.ionstackService.post<boolean>('/public/email/contact', data.data);
      if (ok) {
        this.alertService.showToast('contact.Message sent', {duration: 8000});
        data.source.reset();
      } else {
        this.alertService.showError('contact.No one available');
      }
    } catch (e) {
      this.alertService.showError(e);
    }
  }


}