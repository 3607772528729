import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, RendererFactory2 } from '@angular/core';
import { CleanSubscriber, Context, ContextService, IonstackService, ScriptService, SubscriptionService, User, UserService } from '@adeprez/ionstack';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService extends CleanSubscriber implements OnDestroy {

  constructor(
    private ionstackService: IonstackService,
    private rendererFactory: RendererFactory2,
    private subscriptionService: SubscriptionService,
    private userService: UserService,
    private scriptService: ScriptService,
    private contextService: ContextService,
    @Inject(DOCUMENT) private document: any,
  ) {
    super();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  init() {
    this.doInit();
  }

  private async doInit() {
    if (this.ionstackService.isBrowser() && environment.gAnalytics) {
      try {
        await this.loadTag();
        this.subscribe<Context>(this.contextService.context, context => {
          this.track('set', 'user_id', context.account?.id);
          this.track('set', 'user_properties', {
            language: context.account?.lang,
          });
        })
        this.subscribe<'start' | 'cancel' | 'refresh'>(this.subscriptionService.onSubChange.pipe(filter(change => change === 'start')), () => this.event('purchase'));
        this.subscribe<Context>(this.userService.onRegister, () => this.event('sign_up'));
        this.subscribe<User>(this.userService.onSignIn, () => this.event('login'));
      } catch {
        console.error('analytics disabled');
      }
    }
  }

  track(type: 'event' | 'set', name: string, parameters?: any) {
    try {
      if (typeof gtag === 'function') {
        if (parameters != null) {
          gtag(type, name, parameters);
        } else {
          gtag(type, name);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  event(eventName: string, parameters?: any) {
    this.track('event', eventName, parameters);
  }

  private async loadTag() {
    const renderer = this.rendererFactory.createRenderer(null, null);
    let script: string;
    if (environment.gAnalytics.startsWith('GTM')) {
      await this.scriptService.loadScript('https://www.googletagmanager.com/gtag/js?id=' + environment.gAnalytics);
      script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${environment.gAnalytics}');`;
      const noscript = renderer.createElement('noscript');
      renderer.setProperty(noscript, 'innerHTML', `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${environment.gAnalytics}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`);
      renderer.appendChild(this.document.head, noscript);
    } else {
      await this.scriptService.loadScript('https://www.googletagmanager.com/gtag/js?id=' + environment.gAnalytics);
      script = `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','${environment.gAnalytics}');`;
    }
    const scriptElement = renderer.createElement('script');
    renderer.setAttribute(scriptElement, 'id', 'gtag-script');
    renderer.setAttribute(scriptElement, 'type', 'text/javascript');
    renderer.setProperty(scriptElement, 'innerHTML', script);
    renderer.appendChild(this.document.head, scriptElement);
  }

}
