import { Pageable, CleanSubscriber, searchable, search, Page } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

@Component({
  selector: 'ionstack-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerComponent<T> extends CleanSubscriber implements AfterViewInit, OnDestroy {
  @ViewChild('searchBar', {static: false}) sBar: IonSearchbar;
  @Output() cancel = new EventEmitter<void>();
  @Output() pick = new EventEmitter<T[]>();
  @Output() searchChange = new EventEmitter<string>();
  @Input() canSearch = true;
  @Input() filterSearch = true;
  @Input() title: string;
  @Input() height = 'auto';
  @Input() maxSelected = 1;
  @Input() selected: T[] = [];
  @Input() placeholder = 'Search';
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Ok';
  @Input() confirm = false;
  @Input() labelGetter: (value: T) => string = (value: T) => value + '';
  @Input() subtitleGetter: (value: T) => string;
  @Input() iconGetter: (value: T) => string;
  @Input() searchText: string;
  displayed: T[] = [];
  paged: Pageable<T>;
  private allItems: T[] = [];

  readonly isImage = (src: string) => src.includes('/');

  constructor(protected cd: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    if (this.sBar) {
      this.sBar.setFocus();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  isSelected(item: T) {
    return this.selected.includes(item);
  }

  toggleSelection(item: T) {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(i => i !== item);
    } else {
      this.selected = [...this.selected, item];
    }
  }

  @Input() set items(items: T[]) {
    this.allItems = items;
    this.filter();
  }

  @Input() set pageable(pageable: Pageable<T>) {
    this.items = pageable.data;
    this.paged = pageable;
    this.subscribe<Page<T>>(pageable.onChange, () => {
      this.items = pageable?.data || [];
      this.cd.markForCheck();
    }, {name: 'pageable', replace: true});
  }

  get items() {
    return this.allItems;
  }

  filter() {
    if (this.searchText && this.filterSearch) {
      const searching = searchable(this.searchText);
      this.displayed = this.allItems.filter(item => search(searching, searchable(this.labelGetter(item) + (this.subtitleGetter ?  this.subtitleGetter(item) : ''))));
    } else {
      this.displayed = this.allItems;
    }
    for (const item of this.selected) {
      if (!this.displayed.includes(item)) {
        this.displayed.unshift(item);
      }
    }
  }

  search(searchText: string) {
    this.searchText = searchText;
    this.searchChange.emit(searchText);
    this.filter();
  }

}

@Component({
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerPopup<T> extends PickerComponent<T> implements OnDestroy {

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  @Input() set onCancel(onCancel: () => void) {
    this.subscribe(this.cancel, onCancel, {name: 'cancel'});
  }

  @Input() set onSubmit(onSubmit: (value: T[]) => void) {
    this.subscribe(this.pick, onSubmit, {name: 'submit'});
  }

  @Input() set searchHandler(handler: (searchText: string) => Promise<T[] | undefined>) {
    this.subscribe(this.searchChange, async () => {
      const items = await handler(this.searchText);
      if (items && Array.isArray(items)) {
        this.items = items;
        this.cd.markForCheck();
      }
    }, {name: 'search'});
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}