import { WidgetContext, WidgetParams, DisplayableFile, consumeWidgetContent, Widget, WidgetableComponent, FileService } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, InjectionToken, Type } from '@angular/core';

export type Fit = 'contain' | 'fill' | 'cover';

export const FileDisplayPopup = new InjectionToken<Type<any>>('file-display-popup');

export interface FileDisplayConfig {
  height?: string;
  imageVersion?: string;
  defaultDisplayImageUrl?: string;
  imageFit?: Fit;
  defaultImageFit?: Fit;
  imageBlurAround?: boolean;
  defaultImageBlurAround?: boolean;
  roundImage?: boolean;
  videoFit?: Fit;
  videoAutoplay?: boolean;
  audioFit?: Fit;
  audioAutoplay?: boolean;
  hideCtrl?: boolean;
  loop?: boolean;
}

@Component({
  selector: 'ionstack-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Widget({
  widgetID: 'file',
  widgetName: 'Media',
  icon: 'images',
  widgetDescription: 'Image, audio, video, file',
  contentType: 'json',
  editorPopup: FileDisplayPopup,
  contentFields: [
    {name: 'height', label: 'Media height with unit (px, %, ...)', value: 'auto'},
    {name: 'alt', label: 'Alternative text', optional: true},
    {name: 'imageVersion', label: 'Image version (e.g. 64x64 for a resized square image)', optional: true},
    {name: 'imageFit', type: 'select', label: 'Image fit', options: ['contain', 'fill', 'cover'], value: 'fill', optional: true},
    {name: 'videoFit', type: 'select', label: 'Video fit', options: ['contain', 'fill', 'cover'], value: 'contain', optional: true},
    {name: 'audioFit', type: 'select', label: 'Audio fit', options: ['contain', 'fill', 'cover'], value: 'contain', optional: true},
    {name: 'imageBlurAround', label: 'Blur around image (with contain fit)', type: 'checkbox', optional: true},
    {name: 'videoAutoplay', label: 'Auto start video', type: 'checkbox', optional: true},
    {name: 'audioAutoplay', label: 'Auto start audio', type: 'checkbox', optional: true},
    {name: 'roundImage', label: 'Round image', type: 'checkbox', optional: true},
    {name: 'loop', label: 'Loop', type: 'checkbox', optional: true},
    {name: 'hideCtrl', label: 'Hide controls', type: 'checkbox', optional: true},
  ]
})
export class FileDisplayComponent implements WidgetableComponent {
  @Input() config: FileDisplayConfig = {};
  @Input() visible = true;
  @Input() file: DisplayableFile;
  @Input() alt: string;
  @Input() context: WidgetContext;
  @Input() defaultHeight = '128px';
  private fileId: number;

  readonly heightStyle = (height: string, defaultHeight: string) => `var(--media-height, ${height || defaultHeight})`;
  readonly backgroundImage = (file: DisplayableFile, version: string, defaultPath: string) => `url(${file ? this.fileService.getPath(file, {version}) : defaultPath})`;

  constructor(private fileService: FileService, private cd: ChangeDetectorRef) { }

  @Input() set widget(params: WidgetParams) {
    const data = consumeWidgetContent(this.config, params.data);
    this.alt ??= data.alt;
    if (data.id == null) {
      this.file = null;
    } else if (this.fileId !== data.id) {
      this.fileId = data.id;
      this.fileService.getFile(data.id).then(f => {
        this.file = f;
        this.cd.markForCheck();
      }).catch(() => console.error('Could not get file #' + data.id));
    }
  }

}
