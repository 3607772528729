<ion-toolbar class="gallery-toolbar">
  <ion-buttons slot="primary">
    <ion-button (click)="close(false)">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title>
    <itxt key="payment.3DS payment"></itxt>
  </ion-title>
</ion-toolbar>
<iframe [src]="url | safe:'resourceUrl'"></iframe>