import { AlertService, base64toBlob, FileType, getFileExtension, FormControlComponent, TranslateService } from '@adeprez/ionstack';
import { EventEmitter } from '@angular/core';
import { Filesystem } from '@capacitor/filesystem';
import { MediaFile } from '@ionic-native/media-capture/ngx';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Platform } from '@ionic/angular';

export interface ExtraFileUpload {
  file: Blob;
  name: string;
  extension: string;
  type: FileType;
}

export async function readFile(path: string, contentType: string): Promise<Blob> {
  const { data } = (await Filesystem.readFile({ path }));
  return typeof data === 'string' ? base64toBlob(data, contentType) : data;
}

export async function isHeic(blob: Blob, extension?: string): Promise<boolean> {
  if (extension === 'heic' || extension === 'heif') {
    return true;
  }
  try {
    const buffer = await blob.slice(0, 12).arrayBuffer();
    const view = new DataView(buffer);
    const type = view.getUint32(8, false);
    return view.getUint32(4, false) === 0x66747970 && (type === 0x68656963 || type === 1835623985);
  } catch {
    return false;
  }
}

export async function heicToJpg(blob: Blob) {
  const lib = await import('heic2any');
  return await lib.default({ blob, toType: 'image/jpeg', quality: 1 }) as Blob;
}

export abstract class UploaderWithExtra<T> extends FormControlComponent<T> {
  abstract allowExtraUpload: boolean;
  abstract onExtraUpload: EventEmitter<ExtraFileUpload>;
  abstract fileType?: FileType;
  abstract requiredExtension?: string[];
  abstract alertService: AlertService;

  abstract beforeUpload(extension: string, filename: string): Promise<void>;
  abstract onUpload(file: Blob, extension: string, type: FileType): Promise<void>;
  abstract afterUpload(): Promise<void>;
  abstract noMatchForUpload(): Promise<void>;

  async uploadArray<T>(array: T[], uploader: (file: T, isPrincipal: boolean) => Promise<boolean>) {
    for (let i = 0, uploaded = false; i < array.length && (this.allowExtraUpload || !uploaded); i++) {
      uploaded = !!(await uploader(array[i], !uploaded));
    }
  }

  async uploadMedias(capture: MediaFile[]) {
    if (capture && Array.isArray(capture) && capture.length) {
      await this.uploadArray(
        capture,
        async (media, isPrincipal) => await this.upload(await readFile(media.fullPath, media.type), media.name, isPrincipal)
      );
    }
  }

  upload(file: Blob, filename: string, principal = true): Promise<boolean> {
    return principal ? this.uploadPrincipal(file, filename) : this.uploadExtra(file, filename);
  }

  private uploadExtra(file: Blob, filename: string) {
    return this.uploadProcess(file, filename, {
      beforeUpload: async () => {},
      onUpload: async (file: Blob, extension: string, type: FileType) => this.onExtraUpload.emit({ name: filename, extension, type, file }),
      afterUpload: async () => {},
      noMatchForUpload: async () => {}
    });
  }

  private uploadPrincipal(file: Blob, filename: string) {
    return this.uploadProcess(file, filename, this);
  }

  allowType(type: FileType) {
    return this.fileType === type;
  }

  private async uploadProcess(
    file: Blob,
    filename: string,
    callbacks: {
      beforeUpload: (extension: string, filename: string, type: FileType) => Promise<void>,
      onUpload: (file: Blob, extension: string, type: FileType) => Promise<void>,
      afterUpload: () => Promise<void>,
      noMatchForUpload: () => Promise<void>
    }
  ): Promise<boolean> {
    let { type, extension } = getFileExtension(file.type, filename, this.fileType);
    if (file instanceof Blob && await isHeic(file, extension)) {
      file = await heicToJpg(file);
      extension = 'jpeg';
      type = FileType.IMAGE;
    }
    if ((this.allowType(type) || this.fileType === FileType.DOCUMENT) && (!this.requiredExtension?.length || this.requiredExtension.includes(extension))) {
      await callbacks.beforeUpload(extension, filename, type);
      try {
        await callbacks.onUpload(file, extension, type);
        return true;
      } catch (e) {
        await this.alertService.showError(e);
        return false;
      } finally {
        await callbacks.afterUpload();
      }
    }
    console.error('Invalid ' + type + ' format: ' + extension)
    await callbacks.noMatchForUpload();
    return false;
  }

}

export abstract class ImageUploaderWithExtra<T> extends UploaderWithExtra<T> {
  abstract platform: Platform;
  abstract translateService: TranslateService; 

  abstract onPhotoTaken(photo: Photo): Promise<void>;
  abstract openBrowseFile(): void;

  async takePicture(source: CameraSource = CameraSource.Camera) {
    this.setTouched();
    try {
      if (!this.platform.is('mobileweb')) {
        if (!this.platform.is('desktop')) {
          await Camera.requestPermissions();
        }
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          source,
          promptLabelPhoto: await this.translateService.awaitGet('Browse'),
          promptLabelPicture: await this.translateService.awaitGet('Take picture'),
          promptLabelCancel: await this.translateService.awaitGet('Cancel'),
        });
        await this.onPhotoTaken(photo);
      } else if (source === CameraSource.Camera) {
        await this.takePicture(CameraSource.Photos);
      } else {
        this.openBrowseFile();
      }
    } catch (e) {
      if (e?.message !== 'User cancelled photos app' && e?.message !== 'No image picked') {
        this.alertService.showError(e);
      }
    }
  }
}