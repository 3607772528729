import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '../translate/translate.service';
import { AlertButton, AlertService } from '../service/alert.service';

export interface ConfirmLeave {
    canLeave(): Promise<boolean> | boolean;
    canSaveLeaving?(): Promise<boolean> | boolean;
    saveLeaving?(): Promise<boolean> | Promise<void>;
    onLeaveCancel?(): void;
    leaveMessage?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfirmLeaveGuard implements CanDeactivate<ConfirmLeave> {

    constructor(private alertService: AlertService, private translateService: TranslateService) {
    }

    async canDeactivate(component: ConfirmLeave): Promise<boolean> {
        if (!component.canLeave || await component.canLeave()) {
            return true;
        }
        return new Promise<boolean>(async resolve => {
            let shouldResolve = true;
            const buttons: AlertButton[] = [{
                text: this.translateService.instant('Leave'),
                cssClass: 'danger-color',
                handler: () => {
                    shouldResolve = false;
                    resolve(true);
                }
            }, {
                text: this.translateService.instant('Stay'),
                role: 'cancel',
                cssClass: 'primary-color',
            }];
            if (component.saveLeaving && (!component.canSaveLeaving || await component.canSaveLeaving())) {
                buttons.push({
                    text: this.translateService.instant('Save and leave'),
                    cssClass: 'primary-color',
                    handler: async () => {
                        shouldResolve = false
                        try {
                            resolve((await component.saveLeaving()) !== false);
                        } catch (e) {
                            resolve(false);
                            this.alertService.showError(e);
                        }
                    }
                });
            }
            this.alertService.presentAlert(undefined, 
                component.leaveMessage || this.translateService.instant('confirm_leave') || 'You are about to leave the page. Continue without saving?',
                buttons
            ).then(() => {
                if (shouldResolve) {
                    resolve(false);
                    if (component.onLeaveCancel) {
                        component.onLeaveCancel();
                    }
                }
            });
        });
    }

}