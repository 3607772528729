<ionstack-file-capture
  (extUrlChange)="extUrlChange.emit($event)"
  (formatChange)="formatChange.emit($any($event))"
  (onPickFile)="onPickFile.emit($event)"
  (onExtraUpload)="onExtraUpload.emit($event)"
  (valueChange)="setValue($event)"
  (onFileName)="onFileName.emit($event)"
  [value]="value"
  [isDisabled]="isDisabled"
  [browseAllowed]="browseAllowed"
  [autoStart]="autoStart"
  [hideToolbar]="hideToolbar"
  [previewHeight]="previewHeight"
  captureIcon="videocam"
  browseIcon="film"
  captureLabel="Capture video"
  extUrlText="VIDEO_ON_WEB"
  extUrlLabel="Video link"
  extUrlPlaceholder="https://youtube.com/watch?v=..."
  [prefExtension]="prefExtension"
  [(url)]="url"
  [fileType]="fileType"
  [allowExtUrl]="allowExtUrl"
  [extUrl]="extUrl"
  [constraints]="constraints"
  [allowExtraUpload]="allowExtraUpload"
  (streamChange)="cd.markForCheck()"
  (urlChange)="cd.markForCheck()"
  #capture>
  <video autoplay="true" playsinline muted [srcObject]="capture.stream" *ngIf="capture.stream" #record></video>
</ionstack-file-capture>