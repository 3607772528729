<ion-button
  *ngIf="isFullUrl | call:route; else defaultTpl"
  type="button"
  [href]="route"
  [disabled]="!enabled"
  [class]="buttonClass"
  [fill]="fill"
  [color]="color"
  [size]="size"
  [shape]="shape"
>
  <ion-icon *ngIf="icon" [name]="icon" slot="start"></ion-icon>
  <ion-label><itxt [key]="label"></itxt></ion-label>
</ion-button>

<ng-template #defaultTpl>
  <ion-button
    [type]="type"
    [disabled]="!enabled"
    [providedRoute]="route"
    [defaultRoute]="route"
    [class]="buttonClass"
    [fill]="fill"
    [color]="color"
    [size]="size"
    [shape]="shape"
    [awaitClick]="getClickAction | call:action"
  >
    <ion-icon *ngIf="icon" [name]="icon" slot="start"></ion-icon>
    <ion-label><itxt [key]="label"></itxt></ion-label>
  </ion-button>
</ng-template>


