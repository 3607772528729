import { trigger, transition, style, animate } from '@angular/animations';

export function inOutAnimation(name: string, property: 'width' | 'height') {
  return trigger(name, [
    transition(':enter', [
      style({ [property]: 0, opacity: 0 }),
      animate('300ms ease-out', style({ [property]: '*', opacity: 1 }))
    ]),
    transition(':leave', [
      style({ [property]: '*', opacity: 1 }),
      animate('300ms ease-in', style({ [property]: 0, opacity: 0 }))
    ])
  ]);
}

export const InOutHorizontal = inOutAnimation('InOutHorizontal', 'width');
export const InOutVertical = inOutAnimation('InOutVertical', 'height');