import { FormControlComponent, getEventValue, getUTCDatetimeFormValue } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }],
  selector: 'ionstack-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent extends FormControlComponent<string> {
  @Output() valueChange = new EventEmitter<string>();
  @Input() label: string;
  @Input() labelSuffix: string;
  @Input() placeholder: string;
  @Input() min: string;
  @Input() max: string;
  @Input() isDisabled: boolean;
  @Input() clear: boolean;
  @Input() presentation: 'date' | 'time' | 'date-time' | 'time-date' = 'date';
  @Input() iso = true;
  localValue: string;
  isoValue: string;
  readonly getEventValue = getEventValue;

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  @Input() set value(value: string) {
    this.localValue = value;
    this.isoValue = getUTCDatetimeFormValue(value);
  }

  get value() {
    return this.iso ? this.isoValue : this.localValue;
  }

}
