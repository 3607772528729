import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { consumeWidgetContent, Widget, WidgetParams, ActionService, CleanSubscriber } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';
import { WidgetParentForm, WIDGET_PARENT_FORM } from '../form/base-form-widget';
import { isFullUrl } from '@adeprez/ionstack';

@Component({
  selector: 'ionstack-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'button',
  widgetName: 'Button',
  icon: 'save',
  widgetDescription: 'A button for routing, action or form submission',
  childSupport: 'all',
  editorPopup: EditWidgetPopupComponent,
  contentFields: [
    { name: 'label', label: 'Label', value: 'Save' },
    { name: 'icon', label: 'Icon', value: 'save', optional: true },
    { name: 'type', label: 'Type', value: 'button', type: 'select', options: ['button', 'submit'] },
    { name: 'size', label: 'Size', value: 'primary', type: 'select', options: ['default', 'small', 'large'] },
    { name: 'fill', label: 'Fill', value: 'solid', type: 'select', options: ['solid', 'clear', 'outline'] },
    { name: 'shape', label: 'Shape', type: 'select', options: ['', 'round'], optional: true },
    { name: 'color', label: 'Color', value: 'primary' },
    { name: 'route', label: 'Route', optional: true },
    { name: 'action', label: 'Button action', optional: true },
    { name: 'buttonClass', label: 'Button class', optional: true },
  ]
})
export class ButtonComponent extends CleanSubscriber implements OnInit, OnDestroy {
  @Input() type: 'submit' | 'button' = 'button';
  @Input() icon = 'save';
  @Input() label = 'Save';
  @Input() fill = 'fill';
  @Input() shape: string;
  @Input() color = 'primary';
  @Input() size: string;
  @Input() route: string;
  @Input() action: string;
  @Input() buttonClass: string;

  readonly getClickAction = (action: string) => this.actionService.getAction(action, null, null);
  readonly isFullUrl = isFullUrl;

  constructor(
    private actionService: ActionService,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(WIDGET_PARENT_FORM) private form: WidgetParentForm,
  ) {
    super();
  }

  @Input() set widget(params: WidgetParams) {
    consumeWidgetContent(this, params.data);
  }

  get enabled() {
    return this.type !== 'submit' || this.form?.formValid;
  }

  ngOnInit(): void {
    if (this.form?.statusChanges) {
      this.subscribe(this.form.statusChanges, () => {
        this.cd.markForCheck();
        this.cd.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

}
