import { Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionChecker, RolePermission } from '../model/user';
import { ContextService } from './context.service';
import { IonstackService } from './ionstack.service';

export const PermissionProvider = new InjectionToken<string>('PermissionProvider');

export function notPerm(perm: string) {
  return '!' + perm;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements OnDestroy, PermissionChecker {
  private allPermissions: Promise<string[]>;
  private permissions: {[name: string]: boolean} = {};
  private subscription: Subscription;
  readonly ready: Promise<void>;

  constructor(
    private contextService: ContextService, 
    private ionstackService: IonstackService,
    private injector: Injector,
  ) {
    this.ready = new Promise(resolve => {
      this.subscription = this.contextService.context.subscribe(context => {
        this.permissions = {};
        if (context?.permissions?.length) {
          for (const perm of context.permissions) {
            this.permissions[perm] = true;
          }
        }
        resolve();
      });
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setEnabled(permission: string, enabled = true) {
    this.permissions[permission] = enabled;
  }

  can(permission: string): boolean {
    if (permission.startsWith('!')) {
      return !this.can(permission.substring(1));
    }
    if (!permission || this.permissions[permission] === true) {
      return true;
    }
    return this.injector.get<string[]>(PermissionProvider, []).includes(permission);
  }

  canAllSplit(permString: string) {
    const parts = permString.split(/[\s,]/);
    for (const perm of parts) {
      if (!this.can(perm)) {
        return false;
      }
    }
    return true;
  }

  canAll(permissions: string[]) {
    return !permissions || !permissions.find(p => !this.can(p));
  }

  canAny(permissions: string[]) {
    return permissions.find(p => this.can(p));
  }

  async getKnownPermissions() {
    if (this.can(RolePermission.ADMIN)) {
      const all = this.allPermissions || (this.allPermissions = this.ionstackService.get<{permissions: string[]}>('/permissions').then(p => p.permissions));
      for (const p of await all) {
        this.permissions[p] = this.can(p);
      }
    }
    return Object.keys(this.permissions);
  }

}
