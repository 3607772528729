import { IonRouterOutlet, ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy, Type } from '@angular/core';
import { makeStateKey, Title, TransferState } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { TranslateService, TranslationChangeEvent } from '../translate/translate.service';
import { IonstackModuleConfig, MODULE_CONFIG } from '../ionstack.config';
import { AppControllerState } from '../model/state';
import { NotificationService } from '../service/notification.service';
import { IonstackService } from '../service/ionstack.service';
import { ContextService } from '../service/context.service';
import { CleanSubscriber } from '../util/subscriber';
import { SeoController } from './seo.controller';
import { I18nService } from '../service/i18n.service';
import { RedirectController } from './redirect.controller';
import { App } from '@capacitor/app';
import { filterNotNull } from '../util/util';
import { NotifController } from '../controller/notif.controller';
import { Capacitor } from '@capacitor/core';
import { UserService } from '../service/user.service';
import { Context } from '../model/context';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class AppController extends CleanSubscriber implements OnDestroy {
  private routerState: AppControllerState = {};
  private activeState: AppControllerState = {};
  private currentState: AppControllerState = {};
  private stateChange = new EventEmitter<AppControllerState>();
  private sizeChange = new EventEmitter<boolean>();
  private baseTitle: string;
  private small: boolean = true;
  private lastComponent: string | Type<any>;
  private endComponent: string | Type<any>;
  private hasBackParent = false;
  private lastRoute: string;
  readonly onFocus = new EventEmitter<void>();

  constructor(
    @Inject(MODULE_CONFIG) private ionstackModuleConfig: IonstackModuleConfig,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private translateService: TranslateService,
    private ionstackService: IonstackService,
    private state: TransferState,
    private contextService: ContextService,
    private notificationService: NotificationService,
    private seoController: SeoController,
    private navController: NavController,
    private platform: Platform,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private userService: UserService,
    redirectController: RedirectController,
    notifController: NotifController,
    i18nService: I18nService,
  ) {
    super();
    i18nService.setTranslateServiceInited();
    redirectController.init();
    notifController.init();
    this.init();
  }

  private init() {
    const baseTitleKey = makeStateKey<string>('base-title');
    if (this.state.hasKey(baseTitleKey)) {
      this.baseTitle = this.state.get<string>(baseTitleKey, this.title.getTitle());
    } else if (!this.baseTitle) {
      this.baseTitle = this.title.getTitle();
      this.state.set<string>(baseTitleKey, this.baseTitle);
    }
    this.registerSub(this.platform.backButton.subscribeWithPriority(10, async () => {
      if (!(await this.back())) {
        App.exitApp();
      }
    }));
    this.subscribe(this.router.events, e => {
      if (e instanceof NavigationStart) {
        if (e.navigationTrigger === 'popstate') {
          this.closeModals();
        }
      } else if (e instanceof ActivationEnd) {
        if (e.snapshot.component) {
          this.endComponent = e.snapshot.component;
        }
      } else if (e instanceof NavigationEnd) {
        if (this.lastComponent !== this.endComponent) {
          this.activeState = {};
        }
        if (this.lastComponent) {
          this.seoController.removeCanonicalUrl();
        }
        let r = this.activatedRoute;
        let routerState = {};
        let route: string | string[] = [];
        do {
          routerState = {...routerState, ...r.snapshot.data};
          const url = r.snapshot.url.map(s => s.path);
          if (url.length) {
            route.push(url.join('/'));
          }
        } while (r = r.firstChild);
        route = '/' + route.join('/');
        if (this.lastRoute !== route || this.lastComponent !== this.endComponent) {
          this.lastRoute = route;
          this.routerState = routerState;
          this.lastComponent = this.endComponent;
          this.hasBackParent = ((this.navController as any).topOutlet as IonRouterOutlet)?.canGoBack();
          this.update();
        }
      }
    });
    this.subscribe<TranslationChangeEvent>(this.translateService.onTranslationChange, () => this.updateTitle());
    this.subscribe<Context>(this.contextService.context, ctx => {
      this.document.documentElement.lang = ctx.userLanguage;
      this.update();
    });
    this.subscribe<User>(this.contextService.user, usr => {
      if (usr) {
        this.subscribe(this.notificationService.count, () => this.updateTitle(), {name: 'c', replace: true});
      } else {
        this.unsubscribe('c');
      }
    });
    if (this.ionstackService.isBrowser()) {
      if (Capacitor.isNativePlatform()) {
        this.subscribe(this.platform.resume, () => this.onFocus.emit());
      } else if (window) {
        window.onfocus = () => this.onFocus.emit();
      }
    }
    this.update();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  canGoBack() {
    return (this.hasBackParent || this.currentState.backUrl) && !this.currentState.backDisabled;
  }

  async back(defaultUrl: string | any[] = this.currentState.backUrl): Promise<boolean> {
    const popover = await this.popoverController.getTop();
    if (popover) {
      await popover.dismiss();
      return true;
    }
    const modal = await this.modalController.getTop();
    if (modal) {
      await modal.dismiss();
      return true;
    }
    if (this.hasBackParent) {
      this.navController.back();
      return true;
    }
    return await this.navController.navigateBack(defaultUrl || '/');
  }

  async closeModals() {
    let closed = false;
    let popover: boolean;
    let modal: boolean;
    do {
      modal = await (await this.modalController.getTop())?.dismiss();
      popover = await (await this.popoverController.getTop())?.dismiss();
      closed = popover || modal;
    } while (closed);
    return closed;
  }
  

  set isSmall(small: boolean) {
    this.small = small;
    this.sizeChange.emit(small);
  }

  get isSmall() {
    return this.small;
  }

  get onStateChange() {
    return this.stateChange;
  }

  get onSizeChange() {
    return this.sizeChange;
  }

  setTitle(title: string, updateState = true, transform = true) {
    if (updateState) {
      this.currentState.title = title;
      this.activeState.title = title;
    }
    const unreadNotifs = this.notificationService.count.getCurrent()?.total || 0;
    const fixedTitle = transform ? (this.currentState.titlePrefix || '') + (title || '') + (this.currentState.titleSuffix || '') : (title || '');
    this.title.setTitle((unreadNotifs ? '(' + unreadNotifs + ') ' : '') + fixedTitle);
    this.seoController.setTag('og:title', title, false);
  }

  removeActiveState(...keys: (keyof AppControllerState)[]) {
    for (const key of keys) {
      delete this.activeState[key];
      this.currentState[key as any] = this.routerState[key] ?? this.ionstackModuleConfig.controllerState[key];
    }
    this.update();
  }

  async translateAndSetTitle(title: string, updateState = true, transform = true) {
    this.setTitle(await this.translateService.awaitGet(title), updateState, transform);
  }
  

  addActiveState(state: AppControllerState) {
    this.activeState = {...this.state, ...filterNotNull(state, {recursive: false})};
    this.update();
  }

  private updateTitle() {
    if (this.currentState.title) {
      if (this.currentState.skipTranslateTitle) {
        this.setTitle(this.currentState.title);
      } else {
        this.subscribe<string>(this.translateService.get(this.currentState.title), tr => this.setTitle(tr, false), {replace: true, name: 'title'});
      }
    } else {
      this.setTitle(this.baseTitle, false, false);
    }
  }

  setDescription(description: string, skipTranslateDescription = false) {
    this.activeState.description = description;
    this.activeState.skipTranslateDescription = skipTranslateDescription;
    this.seoController.setDescription(description, !this.activeState.skipTranslateDescription);
  }

  getRouterState<K extends keyof AppControllerState>(k: K): AppControllerState[K] {
    return this.ionstackModuleConfig.controllerState[k] ?? this.routerState[k];
  }

  update() {
    const prev = this.currentState;
    this.currentState = {...this.ionstackModuleConfig.controllerState, ...this.routerState, ...this.activeState};
    if (prev?.title !== this.currentState.title) {
      this.updateTitle();
    }
    this.seoController.setDescription(this.currentState.description, !this.currentState.skipTranslateDescription);
    this.seoController.setType(this.currentState.ogType || 'website');
    this.stateChange.emit(this.currentState);
  }

  getState() {
    return this.currentState;
  }

  findElement(selector: string, steps = 6, stepTime = 300) {
    if (!this.ionstackService.isBrowser()) {
      return new Promise<Element>(resolve => resolve(null));
    }
    return new Promise<Element>(resolve => {
      const fn = (n: number) => {
        const e = this.document.querySelector(selector);
        if (e) {
          resolve(e);
        } else if (n < steps) {
          setTimeout(() => fn(n + 1), stepTime);
        } else {
          resolve(null);
        }
      };
      fn(1);
    });
  }

  async scrollIntoView(selector: string, steps = 6, stepTime = 300, initialTime = 0) {
    if (initialTime > 0) {
      setTimeout(() => this.scrollIntoView(selector, steps, stepTime), initialTime);
    } else {
      const e = await this.findElement(selector, steps, stepTime);
      if (e) {
        e.scrollIntoView({behavior: 'smooth'});
      }
    }
  }

  copyToClipboard(text: string) {
    const textarea = this.document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.left = '0';
    textarea.style.top = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    this.document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(textarea);
  }

  getSeoController() {
    return this.seoController;
  }

  getTranslateService() {
    return this.translateService;
  }

  getRoute() {
    return this.lastRoute;
  }

  async logout(navigate = true) {
    try {
      await this.userService.updateContext(this.ionstackService.delete<Context>('/auth'));
    } catch {
      await this.userService.updateContext(this.contextService.dropContext());
    }
    if (navigate) {
      await this.router.navigateByUrl(
        this.getState().afterLogoutRoute ||
        this.ionstackService.getModuleConfig().afterLogoutRoute ||
        '/'
      );
    }
  }

  async deleteAccount(navigate = true): Promise<void> {
    await this.userService.deleteAccount();
    return await this.logout(navigate);
  }

}
