import { UntypedFormGroup, UntypedFormBuilder, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, EventEmitter, Input, Output, forwardRef, OnDestroy } from '@angular/core';
import { FormControlComponent, PaymentService, FullCustomer, CleanSubscriber, PaymentMethod } from '@adeprez/ionstack';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PaymentFormComponent),
    multi: true
  }],
  selector: 'ionstack-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormComponent extends FormControlComponent<string> implements OnInit, OnDestroy {
  @Output() onConfirm = new EventEmitter<PaymentMethod>();
  @Output() valueChange = new EventEmitter<string>();
  @Input() isDisabled: boolean;
  @Input() country: string;
  @Input() value: string;
  @Input() submitLabel = 'pay.Continue';
  @Input() customerId: number;
  @Input() useKnownCards = true;
  @Input() submitClass: string;
  @Input() submitIcon = 'arrow-forward';
  @Input() submitVisible = true;
  @Input() buttonSize: string;
  customer: FullCustomer;
  customerLoaded: boolean;
  form: UntypedFormGroup;
  private sub = new CleanSubscriber();

  constructor(
    private paymentService: PaymentService,
    public cd: ChangeDetectorRef,
    formBuilder: UntypedFormBuilder,
  ) {
    super();
    this.form = formBuilder.group({
      paymentMethodId: [],
      card: formBuilder.group({
        number: [, Validators.required],
        exp_month: [, Validators.required],
        exp_year: [, Validators.minLength(4)],
        cvc: [, Validators.minLength(3)],
      }),
    });
  }

  ngOnInit() {
    if (this.useKnownCards) {
      this.sub.subscribe<FullCustomer>(this.paymentService.customer, customer => {
        this.customer = customer;
        this.customerLoaded = true;
        if (this.customer?.paymentMethods?.length && this.useKnownCards) {
          this.form.get('paymentMethodId').setValue(this.customer.paymentMethods[0].id);
        }
        this.cd.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribeAll();
  }

  get paymentInvalid() {
    return !this.form.controls.paymentMethodId.value && this.form.controls.card.invalid;
  }

  async save() {
    if (this.form.controls.paymentMethodId.value) {
      this.setValue(this.form.controls.paymentMethodId.value);
      this.onConfirm.emit(this.customer.paymentMethods.find(p => p.id === this.value));
    } else if (this.customer || this.customerId != null) {
      const cid = this.customerId ?? this.customer.id;
      const card = await this.paymentService.addCardToCustomer(cid, this.form.value.card);
      this.form.controls.paymentMethodId.setValue(card.id);
      if (this.customer) {
        this.customer.paymentMethods.push(card);
      }
      this.setValue(card.id);
      this.onConfirm.emit(card);
    } else {
      throw new Error('Unable to pay');
    }
    return this.value;
  }

}
