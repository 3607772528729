import { NgModule } from '@angular/core';
import { TranslateDirective } from './translate.directive';
import { TranslatePipe } from './translate.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    TranslateDirective,
  ],
  exports: [
    TranslatePipe,
    TranslateDirective,
  ],
})
export class TranslateModule { }