import { ComponentRef, RouterDirection } from '@ionic/core';
import { EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActionService } from '../service/action.service';
import { ComputedMenu, IonstackMenuItem, ClickArg, MenuInput } from '../model/menu';
import { PopoverController } from '@ionic/angular';
import { MenuService } from '../service/menu.service';
import { Inject, Injectable } from '@angular/core';

export interface MenuItemClickEvent {
  item: IonstackMenuItem;
  event: Event;
  arg?: any;
}

export interface CreatePopupArgs {
  menuItemClick?: (arg: ClickArg) => void;
  subMenuOpen?: (arg: ClickArg) => void;
  getUrl?: (item: IonstackMenuItem, baseUrl: string) => string;
  getRouterDirection?: (item: IonstackMenuItem, baseUrl: string) => RouterDirection;
}

export interface OpenSubMenuArgs extends CreatePopupArgs {
  event?: Event;
  menuOpen?: IonstackMenuItem;
  subMenuOpen?: (arg: ClickArg) => void;
}

@Injectable({
  providedIn: 'root'
})
export class MenuItemsController {

  constructor(
    private menuService: MenuService,
    private actionService: ActionService,
    private popoverController: PopoverController,
    @Inject(DOCUMENT) private document: any,
  ) { }

  isExtLink = (link: string) => link && /^[a-z0-9]+:.+/.test(link);

  async createPopup(component: ComponentRef, menu: ComputedMenu | MenuInput, event?: Event, opts: CreatePopupArgs = {}, arg?: any) {
    const m = this.menuService.getComputedMenu(menu);
    const popup = !m ? null : await this.popoverController.create({
      component,
      event,
      animated: false,
      showBackdrop: false,
      componentProps: {
        getUrl: opts.getUrl,
        event,
        menu: m,
        menuItemClick: async (item: IonstackMenuItem, event: Event) => {
          await popup.dismiss();
          if (opts.menuItemClick) {
            opts.menuItemClick(this.createClickArg(item, event, arg));
          }
        },
        subMenuOpen: async (item: IonstackMenuItem, event: Event) => {
          await popup.dismiss();
          if (opts.subMenuOpen) {
            opts.subMenuOpen(this.createClickArg(item, event, arg));
          }
        }
      }
    });
    return popup;
  }

  async openMenu(component: ComponentRef, menu: MenuInput, event: Event, opts: OpenSubMenuArgs = {}, arg?: any) {
    return await (await this.createPopup(component, menu, event, opts, arg))?.present?.();
  }

  async openSubmenu(component: ComponentRef, item: IonstackMenuItem, event: Event, opts: OpenSubMenuArgs = {}, arg?: any) {
    const menu = this.menuService.getComputedMenu(item.submenu);
    if (menu) {
      const popup = await this.createPopup(component, menu, opts.event || event, opts, arg);
      popup.onWillDismiss().then(() => opts.menuOpen = undefined);
      popup.present().then(() => {
        opts.menuOpen = item;
        if (opts.subMenuOpen) {
          opts.subMenuOpen(this.createClickArg(item, event, arg));
        }
      });
    } else {
      console.error('menu ' + item.submenu + ' is missing');
    }
  }

  private createClickArg(item: IonstackMenuItem, event: Event, arg?: any): MenuItemClickEvent {
    return {arg, event, item};
  }

  async click(component: ComponentRef, item: IonstackMenuItem, event: Event, opts: OpenSubMenuArgs & {
    closeCb?: (arg: ClickArg) => void,
    close?: EventEmitter<IonstackMenuItem>,
  } = {}, arg?: any) {
    if (item.click) {
      item.click(this.createClickArg(item, event, arg));
    }
    if (item.clickAction) {
      await this.actionService.getAction(item.clickAction, this)(this.createClickArg(item, event, arg));
    }
    if (this.isExtLink(item.url)) {
      this.document.location.href = item.url;
    }
    if (item.submenu) {
      await this.openSubmenu(component, item, event, opts, arg);
    } else if (opts.menuItemClick) {
      opts.menuItemClick(this.createClickArg(item, event, arg));
    }
    if (!item.preventMenuClose) {
      opts.close?.emit?.(item);
      opts.closeCb?.(this.createClickArg(item, event, arg));
    }
  }

}