<ion-icon content [name]="value || defaultIcon" [awaitClick]="show" [bindAwaitClickTo]="this" class="clickable" [ngClass]="styleClass" [color]="color" [size]="size"></ion-icon>
<ion-popover #popup>
  <ng-template>
    <div class="popup-content">
      <ion-item>
        <ion-searchbar (ionInput)="search($event['detail'].value)"></ion-searchbar>
        <ion-button fill="clear" color="danger" slot="end" size="default" (click)="setValue(null)" class="ion-no-margin" *ngIf="deleteText && value">
          {{ deleteText | translate }}
        </ion-button>
        <ion-button fill="clear" slot="end" size="default" (click)="popup.dismiss()" class="ion-no-margin" *ngIf="closeText">
          {{ closeText | translate }}
        </ion-button>
      </ion-item>
      <div class="icons scrollbar">
        <ion-icon [name]="icon.name" *ngFor="let icon of displayed" [lazy]="true" (click)="setValue(icon.name)" [ngClass]="{active: icon.name === value}"></ion-icon>
      </div>
    </div>
  </ng-template>
</ion-popover>