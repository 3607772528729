import { WidgetParams } from '../../model/widget';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Dictionary } from '../../model/i18n';
import { Widget } from '../../model/widget';
import { I18nService } from '../../service/i18n.service';
import { PermissionService } from '../../service/permission.service';
import { EditTranslatableWidgetPopupComponent } from '../../component/edit-translatable-widget-popup/edit-translatable-widget-popup.component';
import { TranslaterPopover } from '../translater/translater.component';

@Component({
  selector: 'itxt',
  templateUrl: './translatable.component.html',
  styleUrls: ['./translatable.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'itxt',
  icon: 'language',
  widgetName: 'Translatable text',
  widgetDescription: 'A text translatable in every platform language',
  editorPopup: EditTranslatableWidgetPopupComponent,
  contentType: 'text',
  contentFields: [
    {name: 'content', type: 'text', optional: true, label: 'Translation key'}
  ]
})
export class TranslatableComponent {
  @Input() classes: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() key: string;
  @Input() args: any;
  @Input() keepEdit = false;
  edit = false;

  constructor(
    private permissionService: PermissionService,
    private popoverController: PopoverController,
    private i18nService: I18nService,
    private cd: ChangeDetectorRef,
  ) { }

  @Input() set widget(params: WidgetParams) {
    this.key = params.data?.content;
  }

  async openEdit(event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.edit = true;
    const pop = (await this.popoverController.create({
      component: TranslaterPopover,
      componentProps: {
        key: this.key,
        args: this.args,
        translations: await this.i18nService.getTranslations(this.key),
        save: (dic: Dictionary) => this.i18nService.setTranslations(this.key, dic)
      }
    }));
    pop.onDidDismiss().then(() => {
      this.edit = false;
      this.cd.markForCheck();
    });
    pop.present();
  }

  isEditable() {
    return this.permissionService.can('edit_i18n') && this.key && this.key.length <= 64;
  }

}
