import { BrowseFileConfig, BrowseFileComponent } from './../browse-file/browse-file.component';
import { ExtraFileUpload } from './../file-capture';
import { FileCaptureConfig } from './../file-config';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, Optional, Inject, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FileType, StoredFile, StoredFileDetails, FileService, FormControlComponent, ALL_FILE_TYPES, FILE_TYPE_ICONS } from '@adeprez/ionstack';
import { EditFileComponent } from '../edit-file/edit-file.component';


export interface EditableFileConfig extends BrowseFileConfig {
  changeText?: string;
  changeTitle?: string;
  addIcon?: string;
}

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditableFileComponent),
    multi: true
  }],
  selector: 'ionstack-editable-file',
  templateUrl: './editable-file.component.html',
  styleUrls: ['./editable-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableFileComponent extends FormControlComponent<number> implements OnInit {
  @ViewChild('browser') browser: BrowseFileComponent;
  @Output() valueChange = new EventEmitter<number>();
  @Output() onExtraUpload = new EventEmitter<ExtraFileUpload>();
  @Output() onExtraFile = new EventEmitter<StoredFileDetails>();
  @Input() canDrop = true;
  @Input() config: EditableFileConfig = {};
  @Input() isDisabled: boolean;
  @Input() value: number;
  @Input() activeType: FileType = FileType.IMAGE;
  @Input() visible = true;
  @Input() editDefaultText = 'Edit';
  @Input() allowExtraUpload = false;
  uploading = false;
  readonly allTypes = ALL_FILE_TYPES;
  readonly icons = FILE_TYPE_ICONS;
  private _file: StoredFile;

  constructor(
    private modalController: ModalController,
    public cd: ChangeDetectorRef,
    private fileService: FileService,
    @Inject(FileCaptureConfig) @Optional() private fileCaptureConfig: FileCaptureConfig,
  ) {
    super();
  }

  readonly getCurrentHeight = (height: any, addIcon: any, value: any) => addIcon && !value ? '40px' : height;

  @Input() set file(file: StoredFile) {
    if (this.value === file?.id || (!this.value && file)) {
      // prevent override from parent when value change
      this._file = file;
    }
  }

  @Input() set onSave(callback: (value: StoredFile) => Promise<void>) {
    const sub = this.valueChange.subscribe(async value => {
      await callback(this._file);
      sub.unsubscribe();
    });
  }

  get file() {
    return this._file;
  }

  private async loadImage() {
    if (this.value != null && (!this.file || this.value !== this.file?.id)) {
      try {
        this.file = await this.fileService.getFile(this.value);
      } catch (e) {
        console.error('could not load image #' + this.value);
      }
      this.cd.markForCheck();
    } else if (this.value == null && this.file) {
      this.value = this.file.id;
      this.cd.markForCheck();
    }
  }

  ngOnInit() {
    this.loadImage();
  }

  writeValue(value: number) {
    super.writeValue(value);
    if (value == null) {
      this._file = null;
      this.cd.markForCheck();
    } else {
      this.loadImage();
    }
  }

  addFile(file: StoredFileDetails) {
    if (this.file) {
      this.onExtraFile.emit(file);
    } else {
      this.file = file;
      this.setValue(file?.id);
    }
    this.cd.markForCheck();
  }

  async edit() {
    if (!this.isDisabled) {
      if (!this.file && this.browser) {
        await this.browser.add();
      } else {
        const popup = await this.modalController.create({
          component: EditFileComponent,
          componentProps: {
            config: {
              ...this.config,
              title: this.value == null ? null : this.config.changeTitle || this.config.changeText || this.editDefaultText,
            },
            value: this.value,
            file: this.file,
            activeType: this.activeType,
            fileCaptureConfig: this.fileCaptureConfig,
            allowExtraUpload: this.allowExtraUpload,
            onClose: () => popup.dismiss(),
            save: async (file: StoredFileDetails) => {
              this._file = file;
              this.setTouched();
              this.setValue(file?.id);
              this.cd.markForCheck();
            },
            extraUploadCallback: (file: ExtraFileUpload) => this.onExtraUpload.emit(file),
          }
        });
        await popup.present();
      }
    }
  }

}
