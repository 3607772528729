import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DefaultWidgetContainer, Widget } from '@adeprez/ionstack';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-list',
  template: `
  <ion-list>
    <ionstack-widget-children [value]="children" [parent]="params.holder" [context]="params.context"></ionstack-widget-children>
  </ion-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'list',
  widgetName: 'Item list',
  group: 'Container',
  icon: 'reorder-four',
  childSupport: ['item', 'list-header'],
  editorPopup: EditWidgetPopupComponent,
  newInstance: {
    children: [
      {type: 'item', children: [{type: 'html', content: 'Item 1'}]},
      {type: 'item', children: [{type: 'html', content: 'Item 2'}]},
      {type: 'item', children: [{type: 'html', content: 'Item 3'}]},
    ]
  }
})
export class ListComponent extends DefaultWidgetContainer {}


@Component({
  selector: 'ionstack-list-header',
  template: `
  <ion-list-header>
    <ionstack-widget-children [value]="children" [parent]="params.holder" [context]="params.context"></ionstack-widget-children>
  </ion-list-header>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'list',
  widgetName: 'List',
  group: 'Container',
  icon: 'list',
  childSupport: ['item', 'list-header'],
  editorPopup: EditWidgetPopupComponent,
})
export class ListHeaderComponent extends DefaultWidgetContainer {}
