import { ActivatedRouteSnapshot } from '@angular/router';
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AutocompleteTypes, TextFieldTypes } from '@ionic/core';
import { InjectionToken } from '@angular/core';

export type FieldType = TextFieldTypes | 'hidden' | 'chip-list' | 'multiline-text' | 'checkbox' | 'select' | 'toggle';

export const FIELD_TYPES: FieldType[] = [
  'date', 'email', 'number', 'password', 'search', 'tel', 'text', 'url', 'time', 'week', 'month', 'datetime-local',
  'hidden', 'chip-list', 'multiline-text', 'checkbox', 'select', 'toggle'
];

export const SimpleFormField = new InjectionToken<SimpleFormField>('SimpleFormField');

export interface SimpleFormField {
  name: string;
  label?: string;
  type?: FieldType;
  value?: any;
  placeholder?: string;
  optional?: boolean;
  maxLength?: number;
  autocomplete?: AutocompleteTypes;
  options?: any[];
  defaultOption?: {
    value: any;
    name?: string;
  };
  autofocus?: boolean;
  autoGrow?: boolean;
  rows?: number;
  min?: number;
  max?: number;
  position?: 'stacked' | 'fixed' | 'floating';
  color?: string;
  pattern?: string;
  datasource?: string;
}

export interface FormSubmitData<T = any> {
  routeSnapshot: ActivatedRouteSnapshot;
  data: T;
  source: {
    reset: () => void;
    formGroup: UntypedFormGroup;
  }; 
}

export function RequireAny(...controls: string[]): ValidatorFn {
  return (group: UntypedFormGroup): ValidationErrors | null => {
    for (const controlName of controls) {
      const control = group.controls[controlName];
      if (control && control.value) {
        return null;
      }
    }
    return {required_any: controls};
  }
}

export function getOrCreateParentFormGroup(path: string, group: UntypedFormGroup): {group: UntypedFormGroup, name: string} {
  const dot = path.indexOf('.');
  if (dot === -1) {
    return {group, name: path};
  }
  const parent = path.substring(0, dot);
  const remain = path.substring(dot + 1);
  let parentCtrl = group.get(parent) as UntypedFormGroup;
  if (!parentCtrl) {
    parentCtrl = new UntypedFormGroup({});
    group.setControl(parent, parentCtrl);
  }
  return getOrCreateParentFormGroup(remain, parentCtrl);
}

export function insertFormControl(path: string, root: UntypedFormGroup, control: AbstractControl) {
  const {group, name} = getOrCreateParentFormGroup(path, root);
  group.setControl(name, control);
}

export function removeFormControl(path: string, root: UntypedFormGroup, control?: AbstractControl) {
  const {group, name} = getOrCreateParentFormGroup(path, root);
  if (!control || group.get(name) === control) {
    group.removeControl(name);
  }
}

export function getFormFieldValidators(field: SimpleFormField): ValidatorFn[] {
  const validators: ValidatorFn[] = [];
  if (!field.optional) {
    validators.push(Validators.required);
  }
  if (field.max != null) {
    validators.push(Validators.max(field.max));
  }
  if (field.min != null) {
    validators.push(Validators.min(field.min));
  }
  if (field.maxLength) {
    validators.push(Validators.maxLength(field.maxLength));
  }
  return validators;
}