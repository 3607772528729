import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { IonstackService } from './ionstack.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storagePromise: Promise<Storage>;

  constructor(private storage: Storage, private ionstackService: IonstackService) { }

  async getStorage() {
    if (!this.storagePromise && this.ionstackService.isBrowser()) {
      this.storagePromise = this.storage.create();
    }
    return this.storagePromise;
  }

  async set<T>(key: string, value: T): Promise<T> {
    return (await this.getStorage())?.set(key, value);
  }

  async get<T>(key: string, defaultValue: T = undefined): Promise<T> {
    return (await (await this.getStorage())?.get(key)) ?? defaultValue;
  }

  async remove<T>(key: string): Promise<T> {
    return (await this.getStorage())?.remove(key);
  }

}
