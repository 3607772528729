import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonPopover } from '@ionic/angular';
import { IonstackService, FormControlComponent, searchable, search } from '@adeprez/ionstack';

@Component({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconpickerComponent),
    multi: true
  }],
  selector: 'ionstack-iconpicker',
  templateUrl: './iconpicker.component.html',
  styleUrls: ['./iconpicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconpickerComponent extends FormControlComponent<string> {
  @ViewChild('popup') popoverComponent: IonPopover;
  @Input() size: 'large' | 'small';
  @Input() color: string;
  @Input() styleClass: string | string[];
  @Input() isDisabled: boolean;
  @Input() value: string;
  @Input() defaultIcon = 'scan';
  @Input() closeText = 'OK';
  @Input() deleteText = 'Delete';
  @Output() valueChange = new EventEmitter<string>();
  displayed: Ionicon[] = [];
  available: Ionicon[] = [];

  constructor(
    private ionstackService: IonstackService,
    public cd: ChangeDetectorRef,
  ) {
    super();
  }

  async show(evt?: any) {
    if (evt) {
      this.popoverComponent.event = evt;
      await Promise.all([this.init(), this.popoverComponent.present()]);
    }
  }

  async init() {
    if (this.ionstackService.isBrowser() && !this.available.length) {
      this.available = (await this.ionstackService.get<{icons: Ionicon[]}>('/public/icons', {cache: 'cache', stateKey: 'ionicons'})).icons;
      this.displayed = this.available;
      this.cd.markForCheck();
    }
  }

  search(searchText: string) {
    searchText = searchable(searchText);
    this.displayed = this.available.filter(i => !searchText || i.tags.find(tag => search(searchText, searchable(tag))));
  }

  writeValue(obj: string): void {
    super.writeValue(obj);
    this.cd.markForCheck();
  }
  
}

interface Ionicon {
  name: string;
  tags: string[];
}