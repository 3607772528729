import { Pipe, PipeTransform } from '@angular/core';
import { DisplayableFile } from '../model/files';
import { FileService } from '../service/file.service';

@Pipe({
  name: 'filepath',
  pure: true,
})
export class FilePathPipe implements PipeTransform {

  constructor(private fileService: FileService) { }

  transform(file: DisplayableFile | string, opts: {version?: string | string[], extension?: string, defaultPath?: string | DisplayableFile} = {}): string {
    if (!file) {
      return null;
    }
    if (typeof file === 'string') {
      return file;
    }
    if (file?.extUrl) {
      return file.extUrl;
    }
    const path = this.fileService.getPath(file, opts);
    if (path) {
      return path;
    }
    if (opts.defaultPath) {
      return typeof opts.defaultPath === 'string' ? opts.defaultPath : this.transform(opts.defaultPath, opts);
    }
  }

}
