import { Injectable, OnDestroy } from '@angular/core';
import { Cart, CartProductUpdate, PlaceCartOrder } from '../model/cart';
import { CleanSubscriber } from '../util/subscriber';
import { Cached } from '../util/cache';
import { IonstackService } from './ionstack.service';
import { ContextService } from './context.service';
import { GroupedOrder } from '../model/order';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class CartService extends CleanSubscriber implements OnDestroy {
  readonly cart = new Cached<Cart>(() => this.fetchCart());
  private prevUserId: number;

  constructor(
    private ionstackService: IonstackService,
    private contextService: ContextService,
  ) {
    super();
    this.subscribe<User>(contextService.user, async user => {
      if (user && user.id !== this.prevUserId) {
        const prevCart = this.cart.getCurrent();
        await this.cart.reset();
        if (user && prevCart) {
          this.importCartProducts(prevCart);
        }
      }
      this.prevUserId = user?.id;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  async updateCartProduct(productId: number, update: CartProductUpdate) {
    return this.cart.setValue(await this.ionstackService.put<Cart>(`/shop/cart/mine/products/${productId}`, update));
  }

  async placeCartOrder(placeOrder?: PlaceCartOrder) {
    const order = await this.ionstackService.post<GroupedOrder>('/shop/cart/mine/order', placeOrder);
    this.cart.reset();
    return order;
  }

  importCartProducts(cart: Cart) {
    return Promise.all((cart?.products ?? []).map(p => this.updateCartProduct(p.product.id, {quantity: p.quantity})));
  }

  private async fetchCart(): Promise<Cart> {
    if (this.contextService.getCurrentContext()?.account) {
      return await this.ionstackService.get<Cart>('/shop/cart/mine');
    }
    return {products: []};
  }

}
