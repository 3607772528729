<div
  [awaitClick]="edit"
  [bindAwaitClickTo]="this"
  [ngClass]="{edit: !isDisabled, empty: !value, oval: config.imageRoundCropper, nobg: config.imageBlurAround, addicon: !!config.addIcon, dropzone: canDrop}"
  [ionstackFileDrop]="canDrop"
  (fileDropped)="browser.uploadFileList($event)"
  class="file"
>
  <ionstack-file-display
    [file]="file"
    [config]="config"
    [defaultHeight]="getCurrentHeight | call:(config.height || '120px'):config.addIcon:value"
    [visible]="visible"
  >
    <div *ngIf="!isDisabled" class="change ion-activatable" [ngClass]="{playable: file?.fileType === 'VIDEO' || file?.fileType === 'AUDIO'}">
      <ion-ripple-effect></ion-ripple-effect>
      <ng-container *ngIf="!value; else changeTemplate">
        <ng-container *ngIf="!config.addIcon">
          <ion-icon *ngFor="let type of (config.allowedTypes || allTypes)" [name]="icons[type]"></ion-icon>
        </ng-container>
        <ion-icon [icon]="config.addIcon || 'add'"></ion-icon>
      </ng-container>
    </div>
  </ionstack-file-display>
</div>

<ng-template #changeTemplate>
  <div class="txt"><itxt [key]="config.changeText || editDefaultText"></itxt></div>
</ng-template>

<ionstack-browse-file
  #browser
  [allowExtraUpload]="allowExtraUpload"
  [config]="config"
  [showButton]="false"
  (valueChange)="addFile($event)"
  (uploadingChange)="uploading = !!$event?.length"
></ionstack-browse-file>