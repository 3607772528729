<ion-item [color]="field.color">
  <ion-label [position]="field.position || 'floating'"><itxt [key]="field.label"></itxt></ion-label>
  <ion-textarea
    *ngIf="control"
    [formControl]="control"
    [autofocus]="field.autofocus"
    [placeholder]="field.placeholder"
    [required]="!field.optional"
    [maxlength]="field.maxLength"
    [autoGrow]="field.autoGrow"
    [rows]="field.rows || 3"
  ></ion-textarea>
</ion-item>