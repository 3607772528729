import { EventEmitter, Injectable } from '@angular/core';
import { Context } from '../model/context';
import { AuthMethod, AuthMethodType, FullUser, PasswordReset, UserUpdate } from '../model/user';
import { OTPChannel } from '../model/verification';
import { randomString } from '../util/util';
import { ContextService } from './context.service';
import { IonstackService } from './ionstack.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly onRegister = new EventEmitter<Context>();
  readonly onSignIn = new EventEmitter<Context>();

  constructor(
    private ionstackService: IonstackService,
    private contextService: ContextService,
    private storageService: StorageService,
  ) { }

  async updateContext(updater: Promise<Context>) {
    const context = await updater;
    await this.contextService.setContext(context);
    return context;
  }

  async signIn(updater: Promise<Context>) {
    const context = await this.updateContext(updater);
    this.onSignIn.emit(context);
    return context;
  }
  
  isEmailVerificationRequired() {
    return this.ionstackService.get<boolean>('/auth/email/verification');
  }
  
  async registerAsAnonymousIfNeeded(): Promise<Context> {
    return (await this.contextService.isLogged()) ? this.contextService.getCurrentContext() : await this.registerAsAnonymous();
  }
  
  async registerAsAnonymous(): Promise<Context> {
    let context: Context;
    const credentials = await this.storageService.get('anonymous-auth');
    if (credentials != null) {
      try {
        context = await this.updateContext(this.ionstackService.post<Context>('/auth/anonymous/login', credentials, {
          params: this.contextService.getRequestContextParams()
        }));
      } catch (e) {
        console.error('Anonymous credentials are not valid anymore', e);
      }
    }
    if (context == null) {
      const anonymous = {
        identifier: randomString(64),
        security: randomString(40)
      };
      context = await this.updateContext(this.ionstackService.post<Context>('/auth/anonymous', anonymous, {
        params: this.contextService.getRequestContextParams()
      }));
      await this.storageService.set('anonymous-auth', anonymous);
    }
    this.onRegister.emit(context);
    return context;
  }
  
  async registerByEmail(auth: {email: string, password: string, otp: string}) {
    const context = await this.updateContext(this.ionstackService.post<Context>('/auth/email', auth, {
      params: this.contextService.getRequestContextParams()
    }));
    this.onRegister.emit(context);
    return context;
  }

  loginByEmail(email: string, password: string) {
    return this.signIn(this.ionstackService.post<Context>('/auth/email/login', {
      email, password, 'remember-me': true
    }, {
      params: this.contextService.getRequestContextParams()
    }));
  }

  loginByOTP(channel: OTPChannel, principal: string, otp: string) {
    return this.signIn(this.ionstackService.post<Context>(`/auth/otp/${channel}/${principal}`, otp, {
      params: this.contextService.getRequestContextParams()
    }));
  }

  deleteAccount(): Promise<void> {
    return this.ionstackService.delete('/users/me');
  }

  continueWithSocial(provider: string, token: string) {
    return this.signIn(this.ionstackService.post<Context>('/auth/provided/' + provider, token, {
      params: this.contextService.getRequestContextParams()
    }));
  }

  changePasswordByOTP(reset: PasswordReset) {
    return this.ionstackService.put('/auth/password/otp', reset);
  }

  changePassword(update: {oldPassword: string, newPassword: string}): Promise<AuthMethodType[]> {
    return this.ionstackService.put('/users/me/password', update);
  }

  updateUser(update: UserUpdate): Promise<FullUser> {
    return this.ionstackService.put('/users/me', update);
  }

  getUser(): Promise<FullUser> {
    return this.ionstackService.get('/users/me');
  }

  getAuth(): Promise<AuthMethod[]> {
    return this.ionstackService.get('/users/me/auth');
  }

  removeAuth(method: AuthMethod): Promise<void> {
    return this.ionstackService.delete(`/users/me/auth/${method.type}/${method.identifier}`);
  }

}
