
export interface Language {
    code: string;
    name: string;
}

export type Dictionary = {[key: string]: string};


export function getI18nKeyGroup(key: string) {
    return key.match(/(^[a-zA-Z0-9_]+)\./)?.[1] || '';
}

export function getI18nKeyText(fullkey: string) {
    const group = getI18nKeyGroup(fullkey);
    return group ? fullkey.substring(group.length + 1) : fullkey;
}