import { ChangeDetectorRef, Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';
import { AlertService } from '../service/alert.service';

@Directive({
  selector: '[awaitSubmit]',
})
export class AwaitSubmitDirective {
  @Input() awaitSubmit: (eventOrArg: any) => Promise<any> | Promise<void>;
  @Input() awaitSubmitArg: any;
  @Input() toastAwaitSubmitErr = true;
  @Input() enterSubmit = false;
  @Input() enterSubmitTextarea = false;
  @Input() requireEnabledButton = true;
  @Input() bindAwaitSubmitTo: any;
  @HostBinding('class') styleClass = '';
  private canSubmit = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private cd: ChangeDetectorRef, private alertService: AlertService) { }

  @HostListener('keydown.enter', ['$event']) async onPressEnter(event: KeyboardEvent) {
    if (this.enterSubmit && !event.shiftKey) {
      const button = this.elementRef.nativeElement.querySelector('[type="submit"]');
      if (button && !button.disabled && ((event.target as any)?.tagName === 'INPUT' || (this.enterSubmitTextarea && (event.target as any)?.tagName === 'TEXTAREA'))) {
        this.onSubmit(event);
      }
    }
  }

  @HostListener('submit', ['$event']) async onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.canSubmit && this.awaitSubmit) {
      const button = this.elementRef.nativeElement.querySelector('[type="submit"]');
      if (!this.requireEnabledButton || (button && !button.disabled)) {
        try {
          this.canSubmit = false;
          this.styleClass = 'await-submitted';
          if (button) {
            this.renderer.setProperty(button, 'disabled', true);
          }
          this.cd.markForCheck();
          await this.awaitSubmit.bind(this.bindAwaitSubmitTo || event.target)(this.awaitSubmitArg == null ? event : this.awaitSubmitArg);
        } catch (e) {
          if (this.toastAwaitSubmitErr) {
            this.alertService.showError(e);
            console.error(e);
          } else {
            throw e;
          }
        } finally {
          this.canSubmit = true;
          this.styleClass = 'await-submitted-done';
          if (button) {
            this.renderer.setProperty(button, 'disabled', false);
          }
          this.cd.markForCheck();
        }
      }
    }
  }
  
}
