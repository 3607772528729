import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'payment-3ds-modal',
  templateUrl: './3ds-modal.component.html',
  styleUrls: ['./3ds-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Payment3DSModalComponent {
  @Input() url: string;
  @Input() modalRef: () => HTMLIonModalElement;

  @HostListener('window:message', ['$event']) onPostMessage(event: any) {
    if (event.data === '3DS-authentication-complete') {
      this.close(true);
    }
  }

  close(complete: boolean) {
    this.modalRef().dismiss({complete});
  }

}
