import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtVideoPipe } from './ext-video.pipe';
import { ExtAudioPipe } from './ext-audio.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ExtVideoPipe,
    ExtAudioPipe,
  ],
  exports: [
    ExtVideoPipe,
    ExtAudioPipe,
  ]
})
export class FileDisplayPipesModule { }
