import { Widget, DatasourceService } from '@adeprez/ionstack';
import { ChangeDetectionStrategy, ChangeDetectorRef, Inject, Optional } from '@angular/core';
import { Component } from '@angular/core';
import { BaseFormWidget, BASE_META_FIELDS, FORM_META_FIELDS, WidgetParentForm, WIDGET_PARENT_FORM } from '../base-form-widget';
import { EditWidgetPopupComponent } from '@adeprez/ionstack/ui/edit-widget-popup';

@Component({
  selector: 'ionstack-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['../input-style.scss', './checkbox-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
  widgetID: 'checkbox',
  icon: 'checkbox',
  group: 'Forms',
  widgetName: 'Checkbox',
  widgetDescription: 'Checkbox item',
  editorPopup: EditWidgetPopupComponent,
  contentType: 'json',
  contentFields: [
    ...BASE_META_FIELDS,
    FORM_META_FIELDS.position,
    FORM_META_FIELDS.color,
  ]
})
export class CheckboxInputComponent extends BaseFormWidget {

  constructor(
    @Optional() @Inject(WIDGET_PARENT_FORM) form: WidgetParentForm,
    datasourceService: DatasourceService,
    public cd: ChangeDetectorRef,
  ) {
    super(form, datasourceService);
  }

}
