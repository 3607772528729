import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundController {
  private loaded: {[src: string]: HTMLAudioElement} = {};

  constructor() { }

  playAsset(name: string) {
    return this.play(`assets/audio/${name}.wav`); 
  }

  play(src: string) {
    return this.get(src).play();
  }

  get(src: string) {
    return this.loaded[src] ||= this.createAudio(src);
  }

  private createAudio(src: string) {
    const audio = new Audio();
    audio.src = src;
    audio.load();
    return audio;
  }

}