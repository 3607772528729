import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
  pure: true
})
export class MapPipe implements PipeTransform {

  transform(key: string, values: {[key: string]: any} | ((key: string) => any), defaultValue: any = ''): any {
    if (typeof values === 'function') {
      const val = values(key);
      return val === null || typeof val === 'undefined' ? defaultValue : val;
    }
    return key in values ? values[key] : defaultValue;
  }

}
