<div *ngIf="readError; else player" class="legacy-player">
  <div class="name">
    <ng-content></ng-content>
  </div>
  <audio
    playsinline controls
    [autoplay]="autoplay"
    [src]="src | safe:'resourceUrl'"
  ></audio>
</div>
<ng-template #player>
  <div class="vizu" (click)="seekClick($event); $event.stopPropagation()" #vizu>
    <canvas [height]="canvasHeight" [width]="canvasWidth" #canvas></canvas>
    <div [style.width.%]="100 * displayTime / audioBuffer.duration" class="played" *ngIf="audioBuffer?.duration"></div>
  </div>
  <div class="controls" *ngIf="allowPlay">
    <ion-button (click)="setPlaying(); $event.stopPropagation()">
      <ion-icon slot="icon-only" [name]="playing ? 'pause' : 'play'"></ion-icon>
    </ion-button>
    <div *ngIf="audioBuffer" class="timer">{{ formatTime | call:displayTime }} / {{ formatTime | call:audioBuffer.duration }}</div>
  </div>
  <div class="name">
    <ng-content></ng-content>
  </div>
</ng-template>