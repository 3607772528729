import { IonstackMenu } from '../model/menu';
import { MODULE_CONFIG, IonstackModuleConfig } from '../ionstack.config';
import { EventEmitter, Inject, Injectable, OnDestroy, Injector, InjectionToken } from '@angular/core';
import { AppController } from '../controller/app.controller';
import { ComputedMenu, MenuInput, MenuProvider } from '../model/menu';
import { CleanSubscriber } from '../util/subscriber';
import { foreachInMap, listToMappedMap } from '../util/util';
import { ContextService } from './context.service';
import { PermissionService } from './permission.service';
import { Context } from '../model/context';

export const Menu = new InjectionToken<IonstackMenu[]>('MENU_TOKEN');

@Injectable({
  providedIn: 'root'
})
export class MenuService extends CleanSubscriber implements OnDestroy, MenuProvider {
  private menus: {[name: string]: ComputedMenu} = {};
  readonly onChange = new EventEmitter<void>();

  constructor(
    private contextService: ContextService,
    private permissionService: PermissionService,
    private appController: AppController,
    injector: Injector,
    @Inject(MODULE_CONFIG) ionstackModuleConfig: IonstackModuleConfig,
  ) {
    super();
    const configMenus = listToMappedMap([
      ...injector.get(Menu, []),
      ...(ionstackModuleConfig.menus || []),
    ], m => m.name, m => new ComputedMenu(m, this, permissionService));
    this.subscribe<Context>(this.contextService.context, context => {
      this.menus = {
        ...configMenus,
        ...listToMappedMap(context.menus, m => m.name, m => new ComputedMenu(m, this, permissionService))
      };
      this.onChange.emit();
    });
    this.subscribe(this.appController.onSizeChange, () => foreachInMap(this.menus, m => m.reset()));
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  getMenus() {
    return this.menus;
  }

  getMenu(name: string): ComputedMenu {
    return this.menus[name];
  }

  getComputedMenu(menuInput: MenuInput): ComputedMenu {
    if (!menuInput) {
      return null;
    }
    if (typeof menuInput === 'string') {
      return this.getMenu(menuInput);
    }
    if (menuInput instanceof ComputedMenu) {
      return menuInput;
    }
    return new ComputedMenu(menuInput, this, this.permissionService);
  }

}
