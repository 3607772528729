<ion-refresher slot="fixed" (ionRefresh)="paged.refresh($event)">
  <ion-refresher-content>
  </ion-refresher-content>
</ion-refresher>

<ng-container [ngTemplateOutlet]="scroller" *ngIf="position === 'top'"></ng-container>

<ng-content select="[empty]" *ngIf="paged.isLoadedEmpty"></ng-content>
<ng-content select="[loading]" *ngIf="paged.loading"></ng-content>
<ng-content></ng-content>

<ionstack-paginator
  [paged]="paged"
  [pageParam]="pageParam"
  [pagesAround]="pagesAround"
  [ngStyle]="paged.paginated ? {} : {display: 'none'}"
  (pageClick)="scrollTop()"
></ionstack-paginator>

<ng-container [ngTemplateOutlet]="scroller" *ngIf="position === 'bottom'"></ng-container>

<ng-template #scroller>
  <ion-infinite-scroll (ionInfinite)="paged.loadMore($event, false)" [disabled]="paged.paginated || !paged?.hasMore" [position]="position">
    <ion-infinite-scroll-content loadingSpinner="bubbles" [loadingText]="loadText | translate"></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ng-template>
