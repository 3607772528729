import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnchorDirective } from './anchor.directive';
import { InjectComponent } from './inject.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [AnchorDirective, InjectComponent],
  exports: [AnchorDirective, InjectComponent],
})
export class InjectModule { }
