<ion-header *ngIf="config.title || !config.allowedTypes || config.allowedTypes.length > 1">
  <ion-toolbar>
    <ion-title *ngIf="config.title"><itxt [key]="config.title" *ngIf="!config.skipTranslateTitle; else simpleTitle"></itxt></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onClose()">
        <ion-icon name="close" slot="start" color="danger"></ion-icon>
        <itxt [key]="config.cancelText || 'Cancel'" class="ion-hide-md-down"></itxt>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar *ngIf="(config.allowedTypes || allTypes).length > 1">
    <ion-segment [scrollable]="true" [(ngModel)]="activeType">
      <ion-segment-button *ngFor="let type of (config.allowedTypes || allTypes)" [value]="type"><itxt [key]="type"></itxt></ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content class="content" *ngIf="inPopup; else content">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ionstack-menu-items [menu]="config.actionMenu" [clickArg]="file" dir="horizontal" *ngIf="config.actionMenu"></ionstack-menu-items>
    <ion-buttons slot="end">
      <ion-button *ngIf="config.canDelete && value != null" [awaitClick]="deleteFile" [bindAwaitClickTo]="this">
        <ion-icon name="trash" slot="start" color="danger"></ion-icon>
        <itxt [key]="config.deleteText || 'Delete'" class="ion-hide-md-down"></itxt>
      </ion-button>
      <ion-button [disabled]="!formGroup?.valid || !formGroup?.dirty" [awaitClick]="submit" [bindAwaitClickTo]="this">
        <ion-icon slot="start" name="checkmark" color="success"></ion-icon>
        <itxt [key]="config.okText || 'Save'"></itxt>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<ng-template #content>
  <form [ngSwitch]="activeType" [formGroup]="formGroup" *ngIf="formGroup">

    <ionstack-image-capture
      *ngSwitchCase="type.IMAGE"
      formControlName="base64"
      [maxCropperHeight]="config.imageMaxCropperHeight || '300px'"
      [maintainAspectRatio]="config.imageMaintainAspectRatio"
      [aspectRatio]="config.imageAspectRatio || 1"
      [format]="imageFormat"
      [imageURL]="imageUrl"
      [roundCropper]="config.imageRoundCropper"
      [minSize]="config.imageMinSize || 32"
      [isDisabled]="isDisabled"
      [skipFirstLoadEmit]="skipFirstLoadEmit"
      [allowExtraUpload]="allowExtraUpload"
      (valueChange)="changed()"
      (formatChange)="formGroup.get('extension').setValue($event); changed()"
      (pickImage)="onPickNewFile()"
      (onExtraUpload)="onExtraUploading($event)"
      (onFileName)="handleFileName($event)"
    ></ionstack-image-capture>
    
    <ionstack-video-capture
      *ngSwitchCase="type.VIDEO"
      formControlName="tempFile"
      [extUrl]="formGroup.controls.extUrl.value"
      [url]="(ifOfType | call:file:fileTypes.VIDEO) | filepath"
      [allowExtraUpload]="allowExtraUpload"
      (valueChange)="changed()"
      (formatChange)="formGroup.get('extension').setValue($event); changed()"
      (extUrlChange)="updateExtUrl($event)"
      (onPickFile)="onPickNewFile()"
      (onExtraUpload)="onExtraUploading($event)"
      (onFileName)="handleFileName($event)"
    ></ionstack-video-capture>
    
    <ionstack-audio-capture
      *ngSwitchCase="type.AUDIO"
      formControlName="tempFile"
      [extUrl]="formGroup.controls.extUrl.value"
      [url]="(ifOfType | call:file:fileTypes.AUDIO) | filepath"
      [allowExtraUpload]="allowExtraUpload"
      (valueChange)="changed()"
      (formatChange)="formGroup.get('extension').setValue($event); changed()"
      (extUrlChange)="updateExtUrl($event)"
      (onPickFile)="onPickNewFile()"
      (onExtraUpload)="onExtraUploading($event)"
      (onFileName)="handleFileName($event)"
    ></ionstack-audio-capture>

    <ionstack-file-capture
      *ngSwitchDefault
      formControlName="tempFile"
      [allowExtraUpload]="allowExtraUpload"
      [captureEnabled]="false"
      [fileType]="fileTypes.DOCUMENT"
      (formatChange)="formGroup.get('extension').setValue($event); changed()"
      (valueChange)="changed()"
      (onFileName)="handleFileName($event)"
      (onExtraUpload)="onExtraUploading($event)"
      (onPickFile)="onPickNewFile()"
      [previewHeight]="config.height"
    ></ionstack-file-capture>
    
    <div class="about mt32">
      <div *ngIf="!config.hideNameInput && value != null">
        <ion-item color="light">
          <ion-label position="floating"><itxt [key]="config.filenameName || 'Short description'"></itxt></ion-label>
          <ion-input formControlName="name" [maxlength]="nameLength" #nameInput></ion-input>
        </ion-item>
      </div>
      <div *ngIf="!config.hideCreditInput && value != null">
        <ion-item color="light">
          <ion-label position="floating"><itxt key="Credit"></itxt></ion-label>
          <ion-input formControlName="credit" [maxlength]="creditLength" #creditInput></ion-input>
        </ion-item>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #simpleTitle>{{ config.title }}</ng-template>