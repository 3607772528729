import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlComponent } from './html.component';
import { WIDGET, IonstackPipesModule } from '@adeprez/ionstack';

@NgModule({
  imports: [
    CommonModule,
    IonstackPipesModule,
  ],
  declarations: [HtmlComponent],
  exports: [HtmlComponent],
  providers: [{ provide: WIDGET, useValue: HtmlComponent, multi: true }]
})
export class HtmlModule { }
