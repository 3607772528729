import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IonstackPreloadingStrategy, AppControllerState, ConsumeNotifGuard, PermissionGuard, Permission, notPerm, OTPGuard } from '@adeprez/ionstack';
import { NOTIFS_ROUTE, SHIPPING_REQ_ROUTE, SIGN_IN_ROUTE, SIGN_UP_ROUTE, TRACKING_ROUTE, TRIP_ROUTE, VERIFY_ROUTE } from './routes.module';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule),
    data: {
      preload: false,
      hideIntercom: true,
      permissions: {[notPerm(Permission.BACKOFFICE)]: '/'}
    } as AppControllerState,
    canLoad: [PermissionGuard],
  },
  {
    path: '',
    pathMatch: 'prefix',
    data: {
      topMenu: 'top',
      bottomMenu: 'bottom',
    } as AppControllerState,
    children: [
      {
        path: SIGN_IN_ROUTE,
        loadChildren: () => import('./page/auth/login/login.module').then( m => m.LoginPageModule),
        data: {preload: false, title: 'Sign in'} as AppControllerState
      },
      {
        path: SIGN_UP_ROUTE,
        loadChildren: () => import('./page/auth/sign-up/sign-up.module').then( m => m.SignUpPageModule),
        data: {preload: false, title: 'Sign up'} as AppControllerState,
      },
      {
        path: NOTIFS_ROUTE,
        loadChildren: () => import('./page/display-notifs.module').then( m => m.DisplayNotifsPageModule),
        canActivate: [OTPGuard, ConsumeNotifGuard],
        data: {title: 'Notifications'}
      },
      {
        path: 'chat',
        loadChildren: () => import('./page/chat/chat.module').then( m => m.ChatPageModule),
        data: {title: 'Chat', permissions: {'!logged': SIGN_UP_ROUTE}},
        canActivate: [PermissionGuard]
      },
      {
        path: 'profil',
        loadChildren: () => import('./page/profile/profile.module').then( m => m.ProfilePageModule),
        data: {title: 'Profile', permissions: {'!logged': SIGN_UP_ROUTE}},
        canActivate: [PermissionGuard]
      },
      {
        path: SHIPPING_REQ_ROUTE,
        loadChildren: () => import('./page/shipping/shipping.module').then( m => m.ShippingModule),
        data: {title: 'Shippings'},
        canActivate: [PermissionGuard]
      },
      {
        path: TRIP_ROUTE,
        loadChildren: () => import('./page/trip/trip.module').then( m => m.TripModule),
        data: {title: 'Trips'},
        canActivate: [PermissionGuard]
      },
      {
        path: 'voyageurs',
        loadChildren: () => import('./page/traveler/traveler.module').then( m => m.TravelerPageModule),
      },
      {
        path: 'expediteurs',
        loadChildren: () => import('./page/shipper/shipper.module').then( m => m.ShipperPageModule),
      },
      {
        path: TRACKING_ROUTE,
        loadChildren: () => import('./page/tracking/tracking.module').then( m => m.TrackingPageModule)
      },
      {
        path: 'notifs',
        loadChildren: () => import('./page/display-notifs.module').then( m => m.DisplayNotifsPageModule),
        canActivate: [OTPGuard, ConsumeNotifGuard]
      },
      {
        path: ':postSlug',
        loadChildren: () => import('./page/display-post.module').then( m => m.DisplayPostPageModule),
      },
      {
        path: '',
        loadChildren: () => import('./page/display-post.module').then( m => m.DisplayPostPageModule),
        data: {postSlug: 'home'}
      },
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'prefix'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: IonstackPreloadingStrategy, initialNavigation: 'enabledBlocking' })
  ],
  exports: [RouterModule],
  providers: [IonstackPreloadingStrategy]
})
export class AppRoutingModule { }
