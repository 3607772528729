import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Subscribable, Unsubscribable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class CleanSubscriber {
    private subscriptions: Unsubscribable[] = [];
    private namedSubs: {[name: string]: Unsubscribable} = {};

    subscribeOnParams(activatedRoute: ActivatedRoute, next: (params: ParamMap, queryParams: ParamMap) => void) {
        this.subscribe<ParamMap[]>(
            combineLatest([activatedRoute.paramMap, activatedRoute.queryParamMap]).pipe(debounceTime(0)),
            ([paramMap, queryParams]) => next(paramMap, queryParams)
        );
    }

    registerSub(sub: Unsubscribable, name = '') {
        if (name) {
            this.unsubscribe(name);
            this.namedSubs[name] = sub;
        }
        this.subscriptions.push(sub);
        return sub;
    }

    subscribe<T>(observable: Subscribable<T>, next?: (value: T) => void, opts: SubscriptionOptions = {}) {
        if (opts.name && !opts.replace && (opts.name in this.namedSubs)) {
            return this.namedSubs[opts.name];
        }
        return this.registerSub(observable.subscribe({next, error: opts.error, complete: opts.complete}), opts.name);
    }

    subscribeAll(next: (value: any) => void, ...observables: Subscribable<any>[]) {
        for (const obs of observables) {
            this.registerSub(obs.subscribe({next}));
        }
    }

    unsubscribe(name: string) {
        if (name in this.namedSubs) {
            const sub = this.namedSubs[name];
            sub.unsubscribe();
            const idx = this.subscriptions.indexOf(sub);
            if (idx > -1) {
                this.subscriptions.splice(idx, 1);
            }
            delete this.namedSubs[name];
        }
    }

    unsubscribeAll() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().unsubscribe();
        }
        this.namedSubs = {};
    }

}

export interface SubscriptionOptions {
    error?: (error: any) => void,
    complete?: () => void,
    name?: string,
    replace?: boolean,
}