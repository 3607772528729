import { Injectable } from '@angular/core';
import { Category } from '../model/category';
import { findInTree, findTreeLeafIds, getStringFromSlugs, getSlugsFromString } from '../util/util';
import { IonstackService } from './ionstack.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private categoriesBySlug: {[slug: string]: Category} = {};
  private categoriesById: {[id: string]: Category} = {};
  private roots: Category[];
  private requesting: {[slugOrId: string]: Promise<void>} = {};

  constructor(private ionstackService: IonstackService) { }

  private cache(categories: Category[], override = false) {
    if (override) {
      this.categoriesBySlug = {};
      this.categoriesById = {};
    }
    findInTree(categories, (cat, parent, i) => {
      if (override) {
        this.categoriesBySlug[cat.slug] = cat;
        this.categoriesById[cat.id] = cat;
      } else {
        // Ensure the first retrieved value will be a singleton, even in branches
        if (!(cat.slug in this.categoriesBySlug)) {
          this.categoriesBySlug[cat.slug] = cat;
        } else if (!(cat.id in this.categoriesById)) {
          this.categoriesById[cat.id] = cat;
        }
        const target = parent ? parent.children : this.roots;
        if (target) {
          target[i] = this.categoriesBySlug[cat.slug];
        }
      }
    }, cat => cat.children);
  }

  resetCache() {
    this.roots = null;
    this.categoriesBySlug = {};
    this.categoriesById = {};
  }

  async getCategoryRoots(): Promise<Category[]> {
    if (!this.roots) {
      this.roots = await this.ionstackService.get<Category[]>('/public/categories/roots');
      this.cache(this.roots, true);
    }
    return this.roots;
  }

  async getCategoryValues(...idOrslug: (string | number)[]): Promise<Category[]> {
    return Object.values(await this.getCategories(...idOrslug));
  }

  async getCategories(...idOrslug: (string | number)[]): Promise<{[slugOrId: string]: Category}> {
    const categories: {[slugOrId: string]: Category} = {};
    const missingSlugs: string[] = [];
    const missingIds: number[] = [];
    for (const idOrS of idOrslug) {
      if (idOrS != null) {
        if (idOrS in this.requesting) {
          try {
            await this.requesting[idOrS]; // Prevent concurrency
          } catch {
            // We're not concerned
          }
        }
        if (typeof idOrS === 'string') {
          if (idOrS in this.categoriesBySlug) {
            categories[idOrS] = this.categoriesBySlug[idOrS];
          } else {
            missingSlugs.push(idOrS);
          }
        } else {
          if (idOrS in this.categoriesById) {
            categories[idOrS] = this.categoriesById[idOrS];
          } else {
            missingIds.push(idOrS);
          }
        }
      }
    }
    if (missingSlugs.length || missingIds.length) {
      const req = this.requestCategories(missingSlugs, missingIds, categories);
      for (const idOrS of idOrslug) {
        this.requesting[idOrS] = req;
      }
      await req;
      for (const idOrS of idOrslug) {
        delete this.requesting[idOrS];
      }
    }
    return categories;
  }

  private async requestCategories(slugs: string[], ids: number[], storeInto: {[slugOrId: string]: Category}) {
    const toAdd = await this.ionstackService.get<Category[]>('/public/categories', {params: {slug: slugs, id: ids.map(id => '' + id)}});
    for (const cat of toAdd) {
      if (slugs.includes(cat.slug)) {
        storeInto[cat.slug] = cat;
      }
      if (ids.includes(cat.id)) {
        storeInto[cat.id] = cat;
      }
    }
    this.cache(toAdd);
  }

  async getCategory(slugOrId: string | number) {
    return (await this.getCategories(slugOrId))[slugOrId];
  }

  async getCategoryChildren(slugOrId: string | number) {
    return (await this.getCategory(slugOrId))?.children || [];
  }

  async getCategoriesChildren(slugOrId: (string | number) []) {
    return Object.values(await this.getCategories(...slugOrId)).map(c => c.children).flat();
  }

  async save(categories: Category[]): Promise<Category[]> {
    this.roots = await this.ionstackService.put<Category[]>('/public/categories', categories);
    this.cache(this.roots, true);
    return this.roots;
  }

  getCategoriesSelectionString(categories: Category[], defaultValue = 'all'): string {
    return categories ? getStringFromSlugs(this.getCategoriesSelectionSlugs(categories)) : defaultValue;
  }

  getCategoriesSelectionSlugs(categories: Category[]): string[] {
    return findTreeLeafIds(categories, c => c.children, c => c.slug);
  }
  
  async stringToCategories(str: string, emptyValue = 'all'): Promise<Category[]> {
    return str === emptyValue ? [] : await this.getCategoryValues(...getSlugsFromString(str));
  }

}
