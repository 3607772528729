import { NotificationService, NotifRoute } from '../../service/notification.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Notif } from '../../model/notification';

@Component({
  selector: 'ionstack-notif',
  templateUrl: './notif.component.html',
  styleUrls: ['./notif.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotifComponent {
  @Output() dismiss = new EventEmitter<void>();
  route: NotifRoute;
  private _notif: Notif;

  constructor(
    private notificationService: NotificationService,
  ) { }

  readonly isImageNotif = (icon: string) => icon && icon.startsWith('http');

  @Input() set notif(notif: Notif) {
    this._notif = notif;
    this.route = this.notificationService.getNotifRoute(notif);
  }

  async click() {
    if (!this.notif.readDate) {
      await this.notificationService.setRead(this.notif.id);
    }
    if (this.route?.click) {
      await this.route.click();
    }
  }

  get notif() {
    return this._notif;
  }

  async read(notif: Notif) {
    if (!notif.readDate) {
      await this.notificationService.setRead(notif.id);
      notif.readDate = new Date().toString();
    }
  }

}
