import { AddConversationMessage } from '../model/conversation';
import { EventEmitter, Injectable } from '@angular/core';
import { Conversation, ConversationMessage } from '../model/conversation';
import { Page } from '../model/page';
import { IonstackService, RequestParams } from './ionstack.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {
  readonly onMessageRead = new EventEmitter<{conversation: Conversation, message: ConversationMessage}>();
  readonly onConversationLeave = new EventEmitter<number>();
  private onRequestFocus: ((conv: Conversation, info?: string) => boolean)[] = [];

  constructor(
    private ionstackService: IonstackService,
    private websocketService: WebsocketService,
  ) { }

  addFocusHandler(handler: (conv: Conversation, info?: string) => boolean) {
    this.onRequestFocus.push(handler);
  }

  removeFocusHandler(handler: (conv: Conversation, info?: string) => boolean) {
    this.onRequestFocus = this.onRequestFocus.filter(orf => handler !== orf);
  }

  requestFocus(conv: Conversation, info?: string) {
    for (const orf of this.onRequestFocus) {
      if (orf(conv, info)) {
        return true;
      }
    }
    return false;
  }

  getConversation(id: number) {
    return this.ionstackService.get<Conversation>('/conversations/' + id);
  }

  getConversations(req: RequestParams) {
    return this.ionstackService.get<Page<Conversation>>('/conversations', {params: req});
  }

  getMessages(conversationId: number, req: RequestParams) {
    return this.ionstackService.get<Page<ConversationMessage>>(`/conversations/${conversationId}/messages`, {params: req});
  }

  sendMessage(conversationId: number, message: AddConversationMessage) {
    return this.ionstackService.post<ConversationMessage>(`/conversations/${conversationId}/messages`, message);
  }

  setMessageRead(conversation: Conversation, message: ConversationMessage) {
    this.websocketService.send(`/conversations/${conversation.id}/messages/${message.id}/read`);
    this.onMessageRead.emit({conversation, message});
  }

  async leave(conversationId: number) {
    await this.ionstackService.delete<void>(`/conversations/${conversationId}/me`, {});
    this.onConversationLeave.emit(conversationId);
  }

  update(conversationId: number, update: {name: string}) {
    return this.ionstackService.put<Conversation>(`/conversations/${conversationId}`, update);
  }

}
